import React, { useState } from 'react';
import PageTabs from '../../../Components/PageTabs';
import Verifications from './Verifications';
import Edit from './Edit';

const tabNameComponentMap = [
  {
    name: 'Edit',
    component: <Edit />,
  },
  {
    name: 'Verifications',
    component: <Verifications />,
  },
];

const ChannelManagement = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <PageTabs
      value={tabValue}
      onChange={handleTabChange}
      tabNameComponentMap={tabNameComponentMap}
    />
  );
};

export default ChannelManagement;
