import React, { useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useParams, useHistory } from 'react-router-dom';
import useStyles from './style';
import addAthleteProfileData from '../../../../API/users/addAthleteProfileData';
import AthleteDetailsForm from '../../../../Components/Forms/AthletDetailsForm';
import Contexts from '../../../../Contexts';

export default function AddPlayerForm() {
  const { mobile } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { addToast } = useToasts();
  const context = useContext(Contexts);
  const { showLoadingSpinner } = context;

  const handleSubmit = async (values, setSubmitting) => {
    try {
      showLoadingSpinner(true);
      const id = await addAthleteProfileData(mobile, values);
      if (id) {
        showLoadingSpinner(false);

        addToast('Athlete Created Successfully', {
          appearance: 'success',
          autoDismiss: 500,
        });
        setSubmitting(false);
        history.goBack();
      }
    } catch (error) {
      showLoadingSpinner(false);
      addToast('ERROR', { appearance: 'error', autoDismiss: 500 });
      setSubmitting(false);
    }
  };

  return (
    <div className={classes.container}>
      <AthleteDetailsForm mobile={mobile} onSubmit={handleSubmit} />
    </div>
  );
}
