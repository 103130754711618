import { createMuiTheme } from '@material-ui/core/styles';
import { pageScrollBar } from './GetStyles';

const themeLight = createMuiTheme({
  palette: {
    background: {
      default: '#fff',
    },
    type: 'light',
  },
  typography: {},
});

themeLight.overrides = {
  MuiCssBaseline: {
    '@global': {
      body: pageScrollBar(false),
    },
  },
};

export default themeLight;
