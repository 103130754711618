export const generateSportDisplayName = (configJSON, sportKey) => {
  if (!configJSON || !sportKey) return '';

  const { sport_data } = configJSON;

  if (!sport_data) return '';

  const selected_sport_data = sport_data[sportKey];

  if (!selected_sport_data) return '';

  const { displayName } = selected_sport_data;

  return displayName || '';
};

export const generateSportPositionDisplayName = (
  configJSON,
  sportKey,
  positionKey,
) => {
  if (!configJSON || !sportKey) return '';

  const { sport_data } = configJSON;

  if (!sport_data) return '';

  const selected_sport_data = sport_data[sportKey];

  if (!selected_sport_data) return '';

  const selected_sport_positon = selected_sport_data[positionKey];

  if (!selected_sport_positon) return '';

  const { displayName } = selected_sport_positon;

  return displayName || '';
};

export const generateStateDisplayName = (configJSON, stateKey) => {
  if (!configJSON || !stateKey) return '';

  const { state_data } = configJSON;

  if (!state_data) return '';

  const selected_state_data = state_data[stateKey];

  if (!selected_state_data) return '';

  const { displayName } = selected_state_data;

  return displayName || '';
};
