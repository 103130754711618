import React, { useState, useRef, useContext, useEffect } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useStyles from './styles';
import AthleteTable from '../../../../Components/AthleteComponents/AthleteTable';
import AthletesSearchForm from '../../../../Components/Forms/SearchForms/AthletesSearchForm';
import Contexts from '../../../../Contexts';
import getAthletes from '../../../../API/athletes/getAthletes';
// eslint-disable-next-line import/extensions
import PlayersTableFormHomeFeed from '../../../../Components/ManagedHomeFeed/AddPlayerDialog';

/**
 * actionLabel is the CTA label in the rendered tabled
 * navigates to player details by default but calls onActionClick instead
 * if passed like onActionCLick(row)
 *
 */
export default function AthleteSearch({
  actionLabel = 'View',
  onActionClick = null,
  isForManagedHomeFeed = false,
  categoryCode = '',
}) {
  const classes = useStyles();

  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);

  const [playersData, setPlayersData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const { path } = useRouteMatch();
  const history = useHistory();

  const ref = useRef({
    first_name: '',
    last_name: '',
    mobile: '',
    sport: categoryCode,
    position: '',
    state: '',
    preferred_section: '',
    is_featured: false,
    pageNo: 1,
    playerId: '',
  });

  const getData = async () => {
    showLoadingSpinner(true);
    try {
      const {
        first_name,
        last_name,
        mobile,
        sport,
        position,
        state,
        is_featured,
        pageNo,
        preferred_section,
        playerId,
      } = ref.current;

      const data = await getAthletes(
        first_name,
        last_name,
        mobile,
        state,
        sport,
        position,
        preferred_section,
        is_featured,
        pageNo,
        10,
        playerId,
      );

      if (data) {
        setPlayersData(data.players);
        setCount(data.total_count);
        showLoadingSpinner(false);
      }
    } catch (err) {
      showMessageDialog('ERROR', err.message);
      showLoadingSpinner(false);
    }
  };

  const onSubmit = async (values, setSubmitting) => {
    const {
      first_name,
      last_name,
      mobile,
      sport,
      position,
      state,
      preferred_section,
      is_featured,
      playerId,
    } = values;
    ref.current = {
      ...ref.current,
      first_name,
      last_name,
      mobile,
      sport,
      position,
      state,
      preferred_section,
      is_featured,
      playerId,
    };

    // reset pages
    setPage(1);
    ref.current.pageNo = 1;

    await getData();
    setSubmitting(false);
  };

  const handleRowActionClick = player => {
    if (onActionClick) {
      onActionClick(player);
      return;
    }

    // default behaviour
    history.push({
      pathname: `${path}${player._id}`,
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
    ref.current.pageNo = newPage + 1;
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={classes.root}>
      <AthletesSearchForm
        onSubmit={onSubmit}
        isForManagedHomeFeed={isForManagedHomeFeed}
        sport={categoryCode}
      />

      {!isForManagedHomeFeed && (
        <div>
          <Typography variant="overline">Add Player</Typography>
          <IconButton
            edge="start"
            className={classes.searchIcon}
            color="default"
            aria-label="menu"
            onClick={() => {
              history.push({
                pathname: `/player/add`,
              });
            }}
          >
            <Add />
          </IconButton>
        </div>
      )}

      {!isForManagedHomeFeed && (
        <div className={classes.addContainer}>
          <AthleteTable
            actionLabel={actionLabel}
            data={playersData}
            onRowActionClicked={handleRowActionClick}
            count={count}
            page={page - 1}
            onChangePage={handlePageChange}
            rowsPerPage={10}
          />
        </div>
      )}

      {isForManagedHomeFeed && playersData.length > 0 && (
        <PlayersTableFormHomeFeed
          totalCount={count}
          rowsPerPage={10}
          page={page - 1}
          onChangePage={handlePageChange}
          data={playersData}
        />
      )}
    </div>
  );
}
