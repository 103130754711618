import React from 'react';
import { Box, Typography } from '@material-ui/core';

const EmptyCard = ({ label = 'NO WHITELISTED TEMPLATES AVAILABLE' }) => {
  return (
    <Box
      border="1px solid #333333"
      width="100%"
      height={200}
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius={5}
    >
      <Typography variant="h5">{label}</Typography>
    </Box>
  );
};

export default EmptyCard;
