/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Typography } from '@material-ui/core';
import swal from '@sweetalert/with-react';
import useStyles from './styles';
import ChannelVerificationSearchForm from '../../../../Components/Forms/SearchForms/ChannelVerificationSearchForm/ChannelVerificationSearchForm';
import Contexts from '../../../../Contexts';
import getChannelVerifications from '../../../../API/channelVerifications/getChannelVerifications';
import ApprovalsTable from '../../../../Components/ApprovalsComponents/ApprovalsTable';
import approveChannelVerification from '../../../../API/channelVerifications/approveChannelVerification';
import rejectChannelVerification from '../../../../API/channelVerifications/rejectChannelVerification';

export default function StreamSearch() {
  const classes = useStyles();
  const [listData, setListData] = useState([]);
  const { showLoadingSpinner } = useContext(Contexts);
  const { addToast } = useToasts();

  // for pagination
  const [count, setCount] = useState(0);

  // pageNo is also used in ref
  // this page no is for display purposes in the table pagination
  // the ref pageNo is to fetch new pages to avoid issue
  // of async setPage
  const [page, setPage] = useState(1);

  // setting the search params in ref
  // so that they can be re used in pagination
  const ref = useRef({
    only_approved: false,
    only_rejected: false,
    only_pending: false,
    page: 1,
  });

  const getData = async () => {
    try {
      showLoadingSpinner(true);
      // eslint-disable-next-line no-shadow
      const { only_approved, only_rejected, only_pending, page } = ref.current;

      const data = await getChannelVerifications({
        limit: 10,
        only_approved,
        only_pending,
        only_rejected,
        page,
        sort: 'updated_at:desc',
      });

      if (data) {
        setListData(data.channel_verifications);
        setCount(data.total_count);
      }
    } catch (error) {
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    } finally {
      showLoadingSpinner(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onSubmit = async (values, setSubmitting) => {
    const { only_approved, only_pending, only_rejected } = values;
    ref.current = {
      ...ref.current,
      only_approved,
      only_pending,
      only_rejected,
    };

    // reset pages
    setPage(1);
    ref.current.page = 1;

    await getData();
    setSubmitting(false);
  };

  const handleApprovedClicked = async row => {
    try {
      const isYesSelected = await swal({
        title: 'Are you sure? ',
        text: `APPROVE request for channel: ${
          row.channel_name
        } with mobile: ${row.mobile || ''}`,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      });

      if (!isYesSelected) return;

      showLoadingSpinner(true);
      const { id } = row;
      const updatedRecord = await approveChannelVerification(id);

      const newListData = [];

      listData.forEach(e => {
        const { id: currentId } = e;
        if (currentId === id) {
          newListData.push(updatedRecord);
        } else {
          newListData.push(e);
        }
      });

      setListData(newListData);
      addToast('Approved Request', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } catch (error) {
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    } finally {
      showLoadingSpinner(false);
    }
  };

  const handleRejectClicked = async row => {
    try {
      const isYesSelected = await swal({
        title: 'Are you sure? ',
        text: `REJECT request for channel: ${
          row.channel_name
        } with mobile: ${row.mobile || ''}`,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      });

      if (!isYesSelected) return;

      showLoadingSpinner(true);
      const { id } = row;
      const updatedRecord = await rejectChannelVerification(id);

      const newListData = [];

      listData.forEach(e => {
        const { id: currentId } = e;
        if (currentId === id) {
          newListData.push(updatedRecord);
        } else {
          newListData.push(e);
        }
      });

      setListData(newListData);
      addToast('Rejected Request', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } catch (error) {
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    } finally {
      showLoadingSpinner(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    // newPage + 1 adjustment because our pages are not 0 indexed
    // hence when passing to the table too we pasee page - 1 and
    // not page
    setPage(newPage + 1);
    ref.current.page = newPage + 1;
    getData();
  };

  return (
    <div className={classes.root}>
      <div className={classes.searchContainer}>
        <ChannelVerificationSearchForm onSubmit={onSubmit} />
      </div>

      {listData.length > 0 ? (
        <>
          <Typography style={{ paddingLeft: '5px' }} variant="caption">
            <b>S</b> - Create & Edit Streams, <b>E</b> - Create & Edit Events,{' '}
            <b>T</b> - Create & Edit Teams, <b>P</b> - Create & Edit Players
          </Typography>

          <ApprovalsTable
            count={count}
            rowsPerPage={10}
            // not the page - 1 here. Becuase we dont use zero indexing
            page={page - 1}
            onChangePage={handlePageChange}
            onRowApproveClicked={handleApprovedClicked}
            onRowRejectClicked={handleRejectClicked}
            data={listData}
          />
        </>
      ) : (
        <div />
      )}
    </div>
  );
}
