import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  Button,
  TableFooter,
  TablePagination,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';

const PERMISSIONS = {
  CAN_CREATE_STREAM: 'CAN_CREATE_STREAM',
  CAN_CREATE_EVENT: 'CAN_CREATE_EVENT',
  CAN_CREATE_TEAM: 'CAN_CREATE_TEAM',
  CAN_CREATE_PLAYER: 'CAN_CREATE_PLAYER',
};

const getPermissionsString = permissionsMap => {
  return (
    `${permissionsMap[PERMISSIONS.CAN_CREATE_STREAM] ? ' S ' : ' _ '}` +
    `${permissionsMap[PERMISSIONS.CAN_CREATE_EVENT] ? ' E ' : ' _ '}` +
    `${permissionsMap[PERMISSIONS.CAN_CREATE_TEAM] ? ' T ' : ' _ '}` +
    `${permissionsMap[PERMISSIONS.CAN_CREATE_PLAYER] ? ' P ' : ' _ '}`
  );
};

export default function ApprovalsTable({
  data = [],
  count = 10,
  rowsPerPage = 12,
  page = 1,
  // eslint-disable-next-line no-unused-vars
  onRowApproveClicked = rowData => console.log('approved clicked'),
  // eslint-disable-next-line no-unused-vars
  onRowRejectClicked = rowData => console.log('reject clicked'),

  onChangePage = () => console.log('page change clicked'),
}) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Sr No.</TableCell>
            <TableCell align="center">Channel Name</TableCell>
            <TableCell align="center">Mobile</TableCell>
            <TableCell align="center">Existing</TableCell>
            <TableCell align="center">Requested</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Approve</TableCell>
            <TableCell align="center">Reject</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data &&
            data.map((row, index) => {
              return (
                <TableRow key={row.id}>
                  <TableCell align="center">
                    {page * rowsPerPage + index + 1}
                  </TableCell>

                  <TableCell
                    align="center"
                    style={{ width: '350px', wordBreak: 'break-word' }}
                    component="th"
                    scope="row"
                  >
                    {`${row.channel_name}`}
                  </TableCell>

                  <TableCell align="center" component="th" scope="row">
                    {`${row.mobile || ''}`}
                  </TableCell>

                  <TableCell align="center" component="th" scope="row">
                    {getPermissionsString(row.existing_permissions)}
                  </TableCell>

                  <TableCell align="center" component="th" scope="row">
                    {getPermissionsString(row.requesting_permissions)}
                  </TableCell>

                  <TableCell component="th" scope="row" align="center">
                    {!row.is_processed && (
                      <Typography variant="body2" color="error">
                        PENDING
                      </Typography>
                    )}
                    {row.is_processed && !row.is_approved && (
                      <Typography variant="body2" color="textSecondary">
                        REJECTED
                      </Typography>
                    )}
                    {row.is_processed && row.is_approved && (
                      <Typography variant="body2" color="textPrimary">
                        APPROVED
                      </Typography>
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      disabled={row.is_processed}
                      variant="outlined"
                      color="primary"
                      onClick={() => onRowApproveClicked(row)}
                    >
                      APPROVE
                    </Button>
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      disabled={row.is_processed}
                      variant="outlined"
                      color="secondary"
                      onClick={() => onRowRejectClicked(row)}
                    >
                      REJECT
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rows
              colSpan={12}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={onChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
