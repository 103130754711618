import axiosInstance from '../../axiosInstance';

const updatePMSEnabledStatus = async ({
  sportKey,
  playerId,
  matchId,
  isEnabled,
}) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/stats/player-match/${sportKey}/player/${playerId}/match/${matchId}/is-enabled`,
    {
      is_enabled: isEnabled,
    },
  );

  const { data } = response.data;

  return data.player_match_stat;
};

export default updatePMSEnabledStatus;
