import React, { useContext } from 'react';
import { Formik } from 'formik';
import FormFields from './FormFields';
import Contexts from '../../../../Contexts';
import {
  generateStateDropDownList,
  generateSportDropDownList,
} from '../../../../Utils/dropDownListGenerators';

const TeamsSearchForm = ({
  name = '',
  state = '',
  sport = '',
  team_id = '',
  creator_id = '',
  enabled_only = false,
  disabled_only = false,
  onSubmit = () => console.log('teams search form clicked'),
}) => {
  const context = useContext(Contexts);
  const { formConfig } = context;

  return (
    <>
      <Formik
        initialValues={{
          name,
          state,
          sport,
          team_id,
          creator_id,
          enabled_only,
          disabled_only,
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields
          stateDropDownList={generateStateDropDownList(formConfig)}
          sportDropDownList={generateSportDropDownList(formConfig)}
        />
      </Formik>
    </>
  );
};

export default TeamsSearchForm;
