import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import {
  CATEGORY_KEY_VALUE_LIST_BY_FEED_TYPE,
  SPORT_KEY_VALUE_LIST,
} from './utils';
import SportCard from './SportCard';
import useManagedHomeFeedStore from '../../../Store/useManagedHomeFeedStore';
import { FeedType } from '../../../API/ManagedHomeFeed/types';

type SportsListProps = {
  feedType: FeedType;
};

const SportList: React.FC<SportsListProps> = ({ feedType }) => {
  const { selectedCategory, setSelectedCategory } = useManagedHomeFeedStore();
  return (
    <Grid container spacing={3}>
      {CATEGORY_KEY_VALUE_LIST_BY_FEED_TYPE[feedType].map(sport => {
        return (
          <SportCard
            sport={sport}
            selected={selectedCategory === sport.value}
            onClick={() => setSelectedCategory(sport.value)}
          />
        );
      })}
    </Grid>
  );
};

export default SportList;
