import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Typography, Button } from '@material-ui/core';
import useStyles from './styles';
import LineUpTable from '../../../../Components/StreamsComponent/LineupTable/LineUpTable';
import Contexts from '../../../../Contexts';
import getSingleStream from '../../../../API/streams/getSingleStream';
import updateTeamPlayerLineupStatus from '../../../../API/streams/updateTeamPlayerLineupStatus';
import startAllPlayersInLineup from '../../../../API/streams/startAllPlayersInLineup';

const convertObjToList = obj => {
  return Object.keys(obj).map(key => obj[key]);
};

export default function LineUps() {
  const classes = useStyles();
  const { streamId } = useParams();
  const { addToast } = useToasts();
  const { showLoadingSpinner } = useContext(Contexts);
  const [streamData, setStreamData] = useState(null);
  const [homeTeamPlayersList, setHomeTeamPlayersList] = useState([]);
  const [awayTeamPlayersList, setAwayTeamPlayersList] = useState([]);
  const [currHomeTeamLineUp, setCurrHomeTeamLineUp] = useState({});
  const [currAwayTeamLineUp, setCurrAwayTeamLineUp] = useState({});

  const getInitialData = async () => {
    const stream = await getSingleStream(streamId);

    if (stream.teams_info) {
      setCurrHomeTeamLineUp(stream.teams_info.home_team.lineup);
      setCurrAwayTeamLineUp(stream.teams_info.away_team.lineup);

      setHomeTeamPlayersList(
        convertObjToList(stream.teams_info.home_team.team_players),
      );

      setAwayTeamPlayersList(
        convertObjToList(stream.teams_info.away_team.team_players),
      );
    }

    setStreamData(stream);
  };

  useEffect(() => {
    try {
      showLoadingSpinner(true);
      getInitialData();
    } catch (err) {
      addToast('error in fetching stream data. reload', {
        appearance: 'error',
        autoDismiss: 500,
      });
    } finally {
      showLoadingSpinner(false);
    }
  }, []);

  const renderTeamsNotLinked = () => (
    <>
      <div className={classes.invalidMessage}>
        Teams not linked. This can be done in the manage teams section
      </div>
    </>
  );

  const renderInvalidMatchType = () => (
    <>
      <div className={classes.invalidMessage}>
        Match type of the stream needs to be set to team based.
        <br />
        This can be done in the stream details section.
        <br />
        <br />
        Add the updated teams in manage teams section (in the selected stream)
        then.
        <br />
        So that updated teams are linked to set lineups of.
      </div>
    </>
  );

  const updateHomePlayerStatus = async (playerId, status) => {
    try {
      showLoadingSpinner(false);

      const teamId = streamData.teams_info.home_team.team_id;
      await updateTeamPlayerLineupStatus({
        streamId,
        teamId,
        playerId,
        status,
      });

      const updatedLineup = { ...currHomeTeamLineUp, [playerId]: status };
      setCurrHomeTeamLineUp(updatedLineup);

      addToast('updated', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } catch (err) {
      addToast('error in updating player lineup status', {
        appearance: 'error',
        autoDismiss: 500,
      });
    } finally {
      showLoadingSpinner(false);
    }
  };

  const updateAwayPlayerStatus = async (playerId, status) => {
    try {
      showLoadingSpinner(false);

      const teamId = streamData.teams_info.away_team.team_id;
      await updateTeamPlayerLineupStatus({
        streamId,
        teamId,
        playerId,
        status,
      });

      const updatedLineup = { ...currAwayTeamLineUp, [playerId]: status };
      setCurrAwayTeamLineUp(updatedLineup);

      addToast('updated', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } catch (err) {
      addToast('error in updating player lineup status', {
        appearance: 'error',
        autoDismiss: 500,
      });
    } finally {
      showLoadingSpinner(false);
    }
  };

  const startAll = async () => {
    try {
      showLoadingSpinner(false);

      await startAllPlayersInLineup({ streamId });

      window.location.reload();
    } catch (err) {
      addToast('error ', {
        appearance: 'error',
        autoDismiss: 500,
      });
    } finally {
      showLoadingSpinner(false);
    }
  };

  const renderMain = () => (
    <div className={classes.root}>
      <div className={classes.tableContainer}>
        <Typography variant="h5">
          {`Home Team: ${streamData.teams_info.home_team.name}`}
        </Typography>
        <LineUpTable
          currentLineUp={currHomeTeamLineUp}
          teamPlayersList={homeTeamPlayersList}
          onPlayerStatusChange={updateHomePlayerStatus}
        />
      </div>

      <div style={{ marginTop: '20px' }}>
        <Button variant="contained" color="primary" onClick={startAll}>
          Start All
        </Button>
      </div>

      <div className={classes.tableContainer}>
        <Typography variant="h5">
          {`Away Team: ${streamData.teams_info.away_team.name}`}
        </Typography>
        <LineUpTable
          currentLineUp={currAwayTeamLineUp}
          teamPlayersList={awayTeamPlayersList}
          onPlayerStatusChange={updateAwayPlayerStatus}
        />
      </div>
    </div>
  );

  const renderWithTeamInfoAndMatchTypeCheck = () => (
    <>
      {!streamData.teams_info && renderTeamsNotLinked()}

      {streamData.teams_info &&
        streamData.match_type !== 'team' &&
        renderInvalidMatchType()}

      {streamData.teams_info &&
        streamData.match_type === 'team' &&
        renderMain(0)}
    </>
  );

  return streamData && renderWithTeamInfoAndMatchTypeCheck();
}
