import axiosInstance from '../../axiosInstance';

const setIsActive = async (stream_key = 'default', status = true) => {
  try {
    const postData = {
      status,
    };

    const response = await axiosInstance(
      `/api/admin/v2/streams/${stream_key}/is_active`,
      {
        method: 'patch',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(postData),
      },
    );

    const { data, error } = response.data;

    if (error) {
      throw new Error(error.errorMessage);
    }

    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default setIsActive;
