import React, { useState, useEffect, useContext } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import useStyles from './styles';
import TeamPlayerTable from '../../../../Components/TeamsComponent/TeamPlayerTable';
import getTeamPlayers from '../../../../API/teams/getTeamPlayers';
import deleteTeamPlayer from '../../../../API/teams/deleteTeamPlayer';
import changeJerseyNo from '../../../../API/teams/changeJerseyNo';
import Contexts from '../../../../Contexts';
import getSingleTeamDetails from '../../../../API/teams/getSingleTeamDetails';
import changePosition from '../../../../API/teams/changePosition';
import updateDummyPlayerData from '../../../../API/teams/updateDummyPlayerData';

export default function TeamPlayer() {
  const { showLoadingSpinner } = useContext(Contexts);
  const classes = useStyles();
  const [teamPlayers, setTeamPlayers] = useState([]);
  const { teamId } = useParams();
  const { addToast } = useToasts();
  const [sport, setSport] = useState('');

  const history = useHistory();

  const getPlayerByTeam = async () => {
    const data = await getTeamPlayers(teamId);
    setTeamPlayers(data.team_players);
  };

  const getTeamData = async id => {
    try {
      const data = await getSingleTeamDetails(id);
      setSport(data.team.sport);
    } catch (err) {
      addToast('failed to load team details', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  useEffect(() => {
    if (teamId) {
      getTeamData(teamId);
    }
  }, []);

  useEffect(() => {
    getPlayerByTeam();
  }, []);

  const deletePlayer = async playerId => {
    try {
      showLoadingSpinner(true);
      await deleteTeamPlayer(teamId, playerId);
      showLoadingSpinner(false);
      addToast('deleted player', {
        appearance: 'success',
        autoDismiss: 500,
      });
      return true;
    } catch (e) {
      showLoadingSpinner(false);
      addToast('error in deleting player', {
        appearance: 'error',
        autoDismiss: 500,
      });
      return false;
    }
  };

  const handleDeletePlayer = async playerId => {
    const isSuccessful = await deletePlayer(playerId);
    if (isSuccessful) {
      let updatedList = [];
      updatedList = teamPlayers.filter(e => e.player_id !== playerId);
      setTeamPlayers(updatedList);
    }
  };

  const handleJerseyNoChange = async (newNum, playerId) => {
    try {
      if (newNum) {
        await changeJerseyNo(newNum, teamId, playerId);
      }

      const temp = [];

      teamPlayers.forEach(el => {
        if (el.player_id === playerId) {
          el.jersey_num = newNum;
        }

        temp.push(el);
      });

      setTeamPlayers(temp);
      if (newNum) {
        addToast('jersey number updated', {
          appearance: 'success',
          autoDismiss: 500,
        });
      }
    } catch (err) {
      addToast('failed to update jersey number', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  const handlePositionChange = async (newPos, playerId) => {
    try {
      await changePosition(newPos, teamId, playerId);

      const temp = [];

      teamPlayers.forEach(el => {
        if (el.player_id === playerId) {
          el.position = newPos;
        }

        temp.push(el);
      });

      setTeamPlayers(temp);
      addToast('position updated', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } catch (err) {}
  };

  const handleDummyPlayerNameUpdate = async ({
    playerId,
    firstName,
    lastName,
    position,
    jerseyNum,
  }) => {
    await updateDummyPlayerData({
      teamId,
      playerId,
      firstName,
      lastName,
      position,
      jerseyNum,
    });

    const temp = [];

    teamPlayers.forEach(el => {
      if (el.player_id === playerId) {
        el.first_name = firstName;
        el.last_name = lastName;
        el.position = position;
        el.jersey_num = jerseyNum;
      }

      temp.push(el);
    });

    setTeamPlayers(temp);

    try {
      addToast('success', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } catch (error) {
      addToast('failed', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  const handleViewProfileClicked = athleteId => {
    history.push({
      pathname: `/player/${athleteId}`,
    });
  };

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex', textAlign: 'center' }}>
        <Typography variant="caption">
          NOTE: Only dummy player names can be edited. When a dummy player name
          is edited, the corresponding position is also updated in the Athlete's
          profile.
        </Typography>
      </div>

      <div style={{ display: 'flex' }}>
        <p style={{ marginRight: 10 }}>Add Players </p>
        <IconButton
          edge="start"
          className={classes.searchIcon}
          color="default"
          aria-label="menu"
          onClick={() => {
            history.push({
              pathname: `/teams/${teamId}/players/add`,
            });
          }}
        >
          <Add />
        </IconButton>
      </div>

      {teamPlayers.length > 0 && (
        <div className={classes.editContainer}>
          <TeamPlayerTable
            sport={sport}
            data={teamPlayers}
            onDeletePlayer={handleDeletePlayer}
            onJerseyNoChange={handleJerseyNoChange}
            onPositionChange={handlePositionChange}
            onDummyPlayerNameChange={handleDummyPlayerNameUpdate}
            onViewProfileClicked={handleViewProfileClicked}
          />
        </div>
      )}
    </div>
  );
}
