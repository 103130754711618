import { create } from 'zustand';
import { ManagedHomeFeed } from '../types/ManagedHomeFeed';

type TManagedHomeFeedStore = {
  managedHomeFeed: ManagedHomeFeed | null;
  setManagedHomeFeed: (managedHomeFeed: ManagedHomeFeed) => void;
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
  selectedSectionKey: string;
  setSelectedSectionKey: (sectionKey: string) => void;
  searchOpen: boolean;
  setSearchOpen: (open: boolean) => void;
  isLoading: boolean;
  setIsLoading: (open: boolean) => void;
};

const useManagedHomeFeedStore = create<TManagedHomeFeedStore>(set => ({
  managedHomeFeed: null,
  setManagedHomeFeed: managedHomeFeed => set(() => ({ managedHomeFeed })),
  selectedCategory: 'all_sports',
  setSelectedCategory: category =>
    set(() => ({ selectedCategory: category, selectedSectionKey: '' })),
  selectedSectionKey: '',
  setSelectedSectionKey: sectionKey =>
    set(() => ({ selectedSectionKey: sectionKey })),
  searchOpen: false,
  setSearchOpen: searchOpen => set(() => ({ searchOpen })),
  isLoading: false,
  setIsLoading: isLoading => set(() => ({ isLoading })),
}));

export default useManagedHomeFeedStore;
