import axiosInstance from '../../axiosInstance';

const passAuthenticatorPostAttendance = async (userId, eventId) => {
  const response = await axiosInstance.post(`/api/v2/event-attendances`, {
    user_id: userId,
    event_id: eventId,
  });
  console.log(response.data);
  return response.data.data.event_attendance;
};

export default passAuthenticatorPostAttendance;
