import axiosInstance from '../../axiosInstance';

const getChannelVerifications = async ({
  only_approved = false,
  only_rejected = false,
  only_pending = false,
  page = '1',
  limit = 10,
  sort = '_id:desc',
}) => {
  const response = await axiosInstance.get(
    `/api/admin/v2/verifications/channel`,
    {
      params: {
        page,
        limit,
        only_approved,
        only_pending,
        only_rejected,
        sort,
      },
    },
  );

  const { data } = response.data;

  return data;
};

export default getChannelVerifications;
