import React from 'react';
import { useFormikContext } from 'formik';
import { Button } from '@material-ui/core';

const FormikSubmitAutoHighlights = ({ submitText }) => {
  const { submitForm } = useFormikContext();

  const handleClick = () => submitForm();

  return (
    <Button variant="contained" onClick={handleClick}>
      {submitText}
    </Button>
  );
};

export default FormikSubmitAutoHighlights;
