export const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`,
  }));

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

export const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  margin: `0 0 ${grid}px 0`,
  ...draggableStyle,
});

export const SectionTypes = [
  {
    value: 'LONG_CONTENT',
    displayName: 'Videos',
    color: 'red',
  },
  {
    value: 'EVENT',
    displayName: 'Event',
    color: 'orange',
  },
  {
    value: 'PLAYER',
    displayName: 'Player',
    color: 'green',
  },
];

export const getSectionColorByType = (sectionType: string) => {
  const filteredSection = SectionTypes.find(
    section => section.value.toLowerCase() === sectionType.toLowerCase(),
  );
  return filteredSection?.color;
};
