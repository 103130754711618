import axiosInstance from '../../axiosInstance';

const updateFeaturedDataIsAvailable = async (
  userId = 'default',
  value = false,
) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/players/${userId}/is-featured-data-available`,
    { is_featured_data_available: value },
  );

  const { player } = response.data.data;

  return player;
};

export default updateFeaturedDataIsAvailable;
