import React from 'react';
import { Form } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikSubmit from '../MuiFormikComponents/FormikSubmit';
import FormikTextInput from '../MuiFormikComponents/FormikTextInput';

const FormFieldsFast = () => {
  const classes = useStyles();

  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Clip Settings</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput
              type="number"
              label="Seconds Before"
              name="window_seconds_before_scoring_event"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput
              type="number"
              label="Seconds After"
              name="window_seconds_after_scoring_event"
            />
          </Grid>

          <Grid item xs={12}>
            <FormikSubmit
              submitText="Generate / Re-Generate"
              name="submitButon"
            />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFieldsFast;
