import axiosInstance from '../../axiosInstance';

const updateEvent = async (
  event_id = 'default',
  name = '',
  sport = '',
  state = '',
  start_date = '',
  end_date = '',
  location = '',
  district = '',
  description = '',
  organised_by = '',
  is_sport = true,
  preferred_section = 'none',
  gender_category_ids = [],
  age_category_ids = [],
  event_league_config,
) => {
  const response = await axiosInstance.put(`/api/admin/v2/events/${event_id}`, {
    name,
    sport,
    state,
    start_date,
    end_date,
    location,
    district,
    description,
    organised_by,
    is_sport,
    preferred_section,
    gender_category_ids,
    age_category_ids,
    event_league_config,
  });

  return response.data;
};

export default updateEvent;
