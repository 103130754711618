import axiosInstance from '../../axiosInstance';

const updateArticleThumbnail = async (image, article_id = 'default') => {
  try {
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('image', image);

    const response = await axiosInstance.patch(
      `/api/admin/v2/articles/${article_id}/thumbnail`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    const responseData = response.data;

    if (responseData.error) {
      throw new Error(responseData.error.errorMessage);
    }

    return responseData.data.imageUrl;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default updateArticleThumbnail;
