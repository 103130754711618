import React from 'react';
import { useFormikContext } from 'formik';
import { Button } from '@material-ui/core';

const FormikSubmit = ({ submitText }) => {
  const { submitForm, isSubmitting } = useFormikContext();

  const handleClick = () => submitForm();

  return (
    <Button disabled={isSubmitting} variant="contained" onClick={handleClick}>
      {submitText}
    </Button>
  );
};

export default FormikSubmit;
