import React, { useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import useStyles from './style';
import createNewTeam from '../../../../API/teams/createNewTeam';
// eslint-disable-next-line max-len
import TeamBasicDetailsForm from '../../../../Components/Forms/TeamBasicDetailsForm/TeamBasicDetailsForm';
import Contexts from '../../../../Contexts';

export default function AddTeam() {
  const classes = useStyles();
  const history = useHistory();
  const { addToast } = useToasts();
  const context = useContext(Contexts);
  const { showLoadingSpinner } = context;

  const handleSubmit = async (values, setSubmitting) => {
    try {
      const {
        name,
        sport,
        state,
        district = '',
        association = '',
        addDummyPlayers = true,
      } = values;
      showLoadingSpinner(true);
      const data = await createNewTeam({
        name,
        sport,
        district,
        association,
        state,
        addDummyPlayers,
      });

      if (data) {
        setSubmitting(false);
        history.goBack();
      }
      showLoadingSpinner(false);
      addToast('Team Created Successfully', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } catch (error) {
      showLoadingSpinner(false);
      addToast('Error While Creating Team', {
        appearance: 'error',
        autoDismiss: 500,
      });
      setSubmitting(false);
    }
  };

  return (
    <div className={classes.container}>
      <TeamBasicDetailsForm
        enableAddDummyPlayersOption
        onSubmit={handleSubmit}
      />
    </div>
  );
}
