import axiosInstance from '../../axiosInstance';

const getTeamPlayers = async (teamId = '') => {
  const response = await axiosInstance.get(`/api/v2/teams/${teamId}/player`);

  const { data } = response.data;

  return data;
};

export default getTeamPlayers;
