import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormFields from './FormFields';

const TeamBasicDetailsForm = ({
  enableAddDummyPlayersOption = false,
  name = '',
  state = '',
  sport = '',
  association = '',
  district = '',
  addDummyPlayers = true,
  onSubmit,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          name,
          state,
          sport,

          // optional fields
          association,
          district,
          addDummyPlayers,
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Required'),
          state: Yup.string().required('Required'),
          sport: Yup.string().required('Required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields enableAddDummyPlayersOption={enableAddDummyPlayersOption} />
      </Formik>
    </>
  );
};

export default TeamBasicDetailsForm;
