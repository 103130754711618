import { BASE_URL } from '../constants';

const getStreamScore = async stream_key => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/v2/streams/${stream_key}/result`,
    );

    const json = await response.json();

    if (json.error) {
      throw new Error(json.error.errorMessage);
    }

    return json.data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default getStreamScore;
