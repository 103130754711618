import React from 'react';
import AthleteMatchStatsForm from '../../../../Components/Forms/AthleteMatchStatsForm';
import useStyles from './styles';

const AthleteMatchStats = () => {
  const classes = useStyles;
  return (
    <div className={classes.root}>
      <AthleteMatchStatsForm />
    </div>
  );
};

export default AthleteMatchStats;
