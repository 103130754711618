import axiosInstance from '../../axiosInstance';

const startAllPlayersInLineup = async ({ streamId }) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/streams/${streamId}/teams/lineup/start-all`,
  );
  const { data } = response.data;
  return data;
};

export default startAllPlayersInLineup;
