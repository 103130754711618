import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormFields from './FormFields';

const validationSchema = Yup.object({
  title: Yup.string().required('Required'),
  channel_id: Yup.string().required('Required'),
  category: Yup.string().required('Required'),
  orientation: Yup.string().required('Required'),
  article_type: Yup.string().required('Required'),
  language: Yup.string().required('Required'),

  embed_url: Yup.string().when('article_type', {
    is: 'youtube',
    then: Yup.string()
      .required('Required')
      .matches(
        /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/,
        'invalid youtube url',
      ),
  }),

  creation_source: Yup.string().required('Required'),

  match_id: Yup.string().when('creation_source', {
    is: value =>
      [
        'SHORT_CLIP',
        'AUTO_CLIP',
        'AUTO_CLIP_MERGED_PLAYER_INDIVIDUAL',
        'AUTO_CLIP_MERGED_FULL_MATCH',
        'PLAYER_MATCH_INDIVIDUAL_CLIP',
        'MERGED_MATCH_CLIP',
      ].includes(value),
    then: Yup.string().required('Required'),
  }),
});

const ArticleDetailsForm = ({
  title = '',
  state = '',
  content = '',
  district = '',
  pincode = '',
  orientation = 'landscape',
  channel_id = '',
  article_type = 'video',
  category = '',
  keywords = [],
  embed_url = '',
  event_id = '',
  country = '',
  match_id = '',
  language = 'hi',
  preferred_section,
  creation_source = 'USER',

  // jio stuff
  is_commentary = false,
  is_jio_stb = false,
  is_ppv = false,
  is_sub = false,
  is_ad = false,
  is_jio_store = false,
  is_jio_tv = false,
  is_all_jio = false,
  is_global = true,

  onSubmit,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          title,
          state,
          content,
          district,
          pincode,
          orientation,
          channel_id,
          article_type,
          category,
          keywords,
          embed_url,
          event_id,
          match_id,
          language,
          country,
          creation_source,

          is_commentary,
          is_jio_stb,
          is_ppv,
          is_sub,
          is_ad,
          is_jio_store,
          is_jio_tv,
          is_all_jio,
          is_global,
          preferred_section,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields />
      </Formik>
    </>
  );
};

export default ArticleDetailsForm;
