import axiosInstance from '../../axiosInstance';

const updateArticle = async (
  article_id = 'default',
  title = '',
  state = '',
  content = '',
  district = '',
  pincode = '',
  orientation = 'landscape',
  channel_id = '',
  article_type = 'video',
  category = '',
  keywords = [],
  embed_url = '',
  event_id = '',
  country = '',
  match_id = '',
  language = 'hi',
  creation_source,

  is_commentary = false,
  is_jio_stb = false,
  is_ppv = false,
  is_sub = false,
  is_ad = false,
  is_jio_store = false,
  is_jio_tv = false,
  is_all_jio = false,
  is_global = true,
) => {
  try {
    const postData = {
      title,
      state,
      content,
      district,
      pincode,
      orientation,
      channel_id,
      article_type,
      category,
      keywords,
      embed_url,
      event_id,
      match_id,
      language,
      country,
      creation_source,

      // jio stuff
      is_commentary,
      is_jio_stb,
      is_ppv,
      is_sub,
      is_ad,
      is_jio_store,
      is_jio_tv,
      is_all_jio,
      is_global,
    };

    const response = await axiosInstance(
      `/api/admin/v2/articles/${article_id}`,
      {
        method: 'patch',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(postData),
      },
    );

    const { data, error } = response;
    console.log('datassss', data);

    if (error) {
      throw new Error(error.errorMessage);
    }

    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default updateArticle;
