/* eslint-disable react/jsx-boolean-value */
import React, { useState, useRef, useContext } from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';
import { Button, Typography } from '@material-ui/core';
import SearchBox from '../../../../Components/SearchBox';
import getByMobile from '../../../../API/users/getByMobile';
import ChannelForm from '../../../../Components/Forms/ChannelForm';
import useStyles from './Styles';
import updateUserProfile from '../../../../API/users/updateUserProfile';
import UploadingDialog from '../../../../Components/UploadingDialog';
import uploadUserImage from '../../../../API/users/uploadUserImage';
import SuccessFailedDialog from '../../../../Components/SuccessFailedDialog.js';
import addUserProfile from '../../../../API/users/addUserProfile';
import ImageBlock from '../../../../Components/ImageBlock';
import uploadUserBgImage from '../../../../API/users/uploadUserBgImage';
import updateUserChannelName from '../../../../API/users/updateUserChannelName';
import Contexts from '../../../../Contexts';

const Edit = () => {
  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);
  const imageType = {
    PROFILE: 'profile',
    BACKGROUND: 'background',
  };

  // editImageType used to
  // determine which edit image button was clicked
  // so as to correctly upload image or background image
  const ref = useRef({
    userId: 'default',
    editImageType: imageType.PROFILE,
  });

  // visibililty control states
  const [formVisible, setFormVisible] = useState(false);
  const [addUserVisible, setAddUserVisible] = useState(false);
  const [addNewUserFormVisible, setAddNewUserFormVisible] = useState(false);
  const [disableSearchInput, setDisableSearchInput] = useState(false);

  // feedback dialog
  const [feedbackDialogOpen, setFeedBackdialogOpen] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState('');
  const [feedbackTitle, setFeedbackTitle] = useState('');
  const handleFeedBackDialogClose = () => setFeedBackdialogOpen(false);
  const showDialog = (title, message) => {
    setFeedbackTitle(title);
    setFeedbackMessage(message);
    setFeedBackdialogOpen(true);
  };

  const [mobile, setMobile] = useState('');
  const [formFields, setFormFields] = useState({});
  const [imageUploadOpen, setImageUploadOpen] = useState(false);
  const [uploadingDialogOpen, setUploadingDialogOpen] = useState(false);

  const [profileImage, setProfileImage] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');

  const classes = useStyles();

  const handleClear = () => {
    // reset form
    setMobile('');
    setFormVisible(false);
    setAddUserVisible(false);
    setAddNewUserFormVisible(false);
    setFormFields({});
    setProfileImage('');
    setBackgroundImage('');
    setDisableSearchInput(false);
  };

  const handleSearch = async () => {
    try {
      if (!mobile || mobile.length !== 10 || isNaN(mobile))
        throw new Error('Invalid mobile number');

      setDisableSearchInput(true);

      const data = await getByMobile(mobile);

      if (data) {
        ref.current.userId = data._id;
        setFormFields(data);
        setProfileImage(data.profileImage);
        setBackgroundImage(data.bgImage);
        setFormVisible(true);
      } else {
        setAddUserVisible(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = async (values, setSubmitting) => {
    try {
      if (addNewUserFormVisible) {
        const {
          first_name,
          last_name,
          description,
          channel_verified,
          can_upload,
          can_stream,
          display_streams,
          display_videos,
          channel_name,
        } = values;
        const id = await addUserProfile(mobile, {
          first_name,
          last_name,
          description,
          channel_verified,
          can_upload,
          can_stream,
          display_streams,
          display_videos,
        });
        showDialog(
          'SUCCESS',
          `User added successfully with id ${id}. Proceed to add images`,
        );
        const currentMobileNumber = mobile;
        handleClear();
        setMobile(currentMobileNumber);
        handleSearch();
      } else {
        const {
          first_name,
          last_name,
          description,
          channel_verified,
          can_upload,
          can_stream,
          display_streams,
          display_videos,
          channel_name,
        } = values;
        await updateUserProfile(ref.current.userId, {
          first_name,
          last_name,
          description,
          channel_verified,
          can_upload,
          can_stream,
          display_streams,
          display_videos,
        });

        if (channel_name) {
          await updateUserChannelName(ref.current.userId, channel_name);
        }

        showMessageDialog('SUCCESS', 'User updated successfully');
      }
    } catch (e) {
      showMessageDialog('ERROR', e.message);
    }

    setSubmitting(false);
  };

  const uploadImage = async file => {
    let resp;
    if (ref.current.editImageType === imageType.BACKGROUND) {
      resp = await uploadUserBgImage({ image: file, userId: formFields._id });
    } else {
      resp = await uploadUserImage({ image: file, userId: formFields._id });
    }

    if (resp) {
      if (ref.current.editImageType === imageType.BACKGROUND) {
        setBackgroundImage(resp);
      } else {
        setProfileImage(resp);
      }
      setUploadingDialogOpen(false);
    }
  };

  const handleImageDialogSave = files => {
    const file = files[0];

    setImageUploadOpen(false);
    setUploadingDialogOpen(true);
    uploadImage(file);
  };

  const handleImageDialogClose = () => {
    setImageUploadOpen(false);
  };

  const handleEditImage = type => {
    ref.current.editImageType = type;
    setImageUploadOpen(true);
  };

  const renderAddUser = () => (
    <div className={classes.addNewContainer}>
      <Typography variant="overline">
        {`No user found! Add user with mobile number ${mobile}?`}
      </Typography>
      <Button
        variant="outlined"
        onClick={() => {
          setAddUserVisible(false);
          setAddNewUserFormVisible(true);
          setFormVisible(true);
        }}
      >
        Add User
      </Button>
    </div>
  );

  const renderForm = () => (
    <>
      <div className={classes.addNewContainer}>
        {addNewUserFormVisible && (
          <Typography variant="overline">{`Adding user with mobile number ${mobile}`}</Typography>
        )}
      </div>
      <div className={classes.formContainer}>
        <ChannelForm
          displayChannelField={!addNewUserFormVisible}
          {...formFields}
          onSubmit={handleSubmit}
        />
      </div>
    </>
  );

  const renderProfileImageUploader = () => (
    <div className={classes.imageBlock}>
      <ImageBlock
        blockLabel="Profile Image"
        onButtonPress={() => handleEditImage(imageType.PROFILE)}
        imgSrc={profileImage}
      />
    </div>
  );

  const renderBgImageUploader = () => (
    <div className={classes.imageBlock}>
      <ImageBlock
        blockLabel="Background Image"
        onButtonPress={() => handleEditImage(imageType.BACKGROUND)}
        imgSrc={backgroundImage}
      />
    </div>
  );

  return (
    <>
      <SearchBox
        disableInput={disableSearchInput}
        type="number"
        label="Search Mobile"
        value={mobile}
        onChangeValue={setMobile}
        onSearch={handleSearch}
        onClear={handleClear}
      />

      {!formVisible && addUserVisible && renderAddUser()}

      {formVisible && renderForm()}

      {formVisible && !addNewUserFormVisible && renderProfileImageUploader()}

      {formVisible && !addNewUserFormVisible && renderBgImageUploader()}

      <DropzoneDialog
        open={imageUploadOpen}
        onSave={handleImageDialogSave}
        acceptedFiles={['image/*']}
        showPreviews={true}
        maxFileSize={5000000}
        filesLimit={1}
        onClose={handleImageDialogClose}
        showAlerts={false}
      />

      <UploadingDialog open={uploadingDialogOpen} />
    </>
  );
};

export default Edit;
