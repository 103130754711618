import { FORM_CONFIG_BASE_URL } from '../constants';

const getFormConfig = async () => {
  try {
    const response = await fetch(
      `${FORM_CONFIG_BASE_URL}/scoreCardFormConfig.json`,
    );

    const json = await response.json();

    return json;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default getFormConfig;
