import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { getFormFieldsBySport } from '../utils';
import FormikTextInput from '../../../../../Components/Forms/MuiFormikComponents/FormikTextInput';
import FormikDropDown from '../../../../../Components/Forms/MuiFormikComponents/FormikDropDown';
import FormikSubmit from '../../../../../Components/Forms/MuiFormikComponents/FormikSubmit';
import FormikSwitch from '../../../../../Components/Forms/MuiFormikComponents/FormikSwitch';

type FormFieldsProps = {
  sport: string;
  stream: any;
};

const FormFields: React.FC<FormFieldsProps> = ({ sport, stream }) => {
  return (
    <Box>
      {getFormFieldsBySport(sport).map(section => {
        let sectionName = section.name;

        if (section.type === 'home_team') {
          sectionName = stream.teams_info.home_team.name;
        }

        if (section.type === 'away_team') {
          sectionName = stream.teams_info.away_team.name;
        }

        return (
          <Box key={section.id} mb={3}>
            <Box mb={1.5}>
              <Typography
                variant="subtitle2"
                gutterBottom
                style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
              >
                {sectionName}
              </Typography>
            </Box>

            <Grid container spacing={3}>
              {section.fields.map(field => {
                if (field.name === 'winning_team_id') {
                  return (
                    <Grid item xs={12} md={6} lg={3}>
                      <FormikDropDown
                        name="winning_team_id"
                        label="Winning Team"
                        valueDisplayNameMap={[
                          {
                            value: stream.teams_info.home_team.team_id,
                            displayName: stream.teams_info.home_team.name,
                          },
                          {
                            value: stream.teams_info.away_team.team_id,
                            displayName: stream.teams_info.away_team.name,
                          },
                        ]}
                        variant="outlined"
                      />
                    </Grid>
                  );
                }

                if (field.type === 'number') {
                  return (
                    <Grid item xs={12} md={6} lg={3}>
                      <FormikTextInput
                        type={field.type}
                        name={field.name}
                        label={field.label}
                        multiline={false}
                        variant="outlined"
                      />
                    </Grid>
                  );
                }

                if (field.type === 'switch') {
                  return (
                    <Grid item xs={12} md={6} lg={3}>
                      <Box
                        border="1px solid #333333"
                        borderRadius={5}
                        width="100%"
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <FormikSwitch name={field.name} label={field.label} />
                      </Box>
                    </Grid>
                  );
                }

                if (field.type === 'dropdown') {
                  return (
                    <Grid item xs={12} md={6} lg={3}>
                      <FormikDropDown
                        type={field.type}
                        name={field.name}
                        label={field.label}
                        valueDisplayNameMap={field.dropdown_options}
                        variant="outlined"
                      />
                    </Grid>
                  );
                }
              })}
            </Grid>
          </Box>
        );
      })}

      <Box mt={2}>
        <FormikSubmit submitText="SAVE" />
      </Box>
    </Box>
  );
};

export default FormFields;
