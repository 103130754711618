import React, { useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import useStyles from './style';
import addTournament from '../../../../API/users/addTournament';
import AddTournamentForm from '../../../../Components/Forms/AddTournamentForm/AddTournamentForm';
import Contexts from '../../../../Contexts';

export default function AddTournament() {
  const classes = useStyles();
  const history = useHistory();
  const { addToast } = useToasts();
  const context = useContext(Contexts);
  const { showLoadingSpinner } = context;

  const handleSubmit = async (values, setSubmitting) => {
    try {
      showLoadingSpinner(true);
      const resp = await addTournament(values);

      if (resp.data) {
        showLoadingSpinner(false);
        setSubmitting(false);
        addToast('Successfully Created Tournament', {
          appearance: 'success',
          autoDismiss: 500,
        });
        history.goBack();
      }
    } catch (error) {
      showLoadingSpinner(false);
      setSubmitting(false);
      addToast('Error While Creating Tournament', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  return (
    <div className={classes.container}>
      <AddTournamentForm onSubmit={handleSubmit} />
    </div>
  );
}
