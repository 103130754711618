import React from 'react';

import { Switch, Route, useRouteMatch } from 'react-router-dom';
import StreamSearch from './StreamSearch';
import AddStream from './AddStream';
import StreamsDetails from './StreamsDetails';
import SelectedStream from './SelectedStream/SelectedStream';
import ManageTeams from './ManageTeams/ManageTeams';
import ResultManagment from './ResultManagment/ResultManagment';
import LineUps from './Lineups/LineUps';
import IndividualPlayer from './IndividualPlayer/IndividualPlayer';
import AddStreamLinkedPlayer from './AddStreamLinkedPlayer/AddStreamLinkedPlayer';
import StreamLinkedPlayers from './StreamLinkedPlayers/StreamLinkedPlayers';
import GenerateReplayMp4 from './GenerateReplayMp4/GenerateReplayMp4';
import GenerateAutoClips from './GenerateAutoClips/GenerateAutoClips';
import GenerateAutoClipsFastly from './GenerateAutoClipsFastly/GenerateAutoClipsFastly';
import SFAMapping from './SFAMapping';
import OverridePointsTable from './OverridePointsTable';
import StreamSearchPixellotHighlightsVas from './StreamSearchPixellotHighlightsVas/StreamSearchPixellotHighlightsVas';

export default function StreamsManagement() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <StreamSearch />
      </Route>
      <Route exact path={`${path}/pix-highlights-vas`}>
        <StreamSearchPixellotHighlightsVas />
      </Route>
      <Route exact path={`${path}/add`}>
        <AddStream />
      </Route>
      <Route exact path={`${path}/:streamId`}>
        <SelectedStream />
      </Route>
      <Route exact path={`${path}/:streamId/details`}>
        <StreamsDetails />
      </Route>
      <Route exact path={`${path}/:streamId/teams`}>
        <ManageTeams />
      </Route>
      <Route exact path={`${path}/:streamId/generate-replay-mp4`}>
        <GenerateReplayMp4 />
      </Route>
      <Route exact path={`${path}/:streamKey/individual`}>
        <IndividualPlayer />
      </Route>
      <Route exact path={`${path}/:streamKey/result`}>
        <ResultManagment />
      </Route>
      <Route exact path={`${path}/:streamId/lineups`}>
        <LineUps />
      </Route>
      <Route exact path={`${path}/:streamId/linked-players`}>
        <StreamLinkedPlayers />
      </Route>
      <Route exact path={`${path}/:streamId/generate-auto-clips`}>
        <GenerateAutoClips />
      </Route>
      <Route exact path={`${path}/:streamId/generate-auto-clips-fast`}>
        <GenerateAutoClipsFastly />
      </Route>
      <Route exact path={`${path}/:streamId/linked-players/add`}>
        <AddStreamLinkedPlayer />
      </Route>
      <Route exact path={`${path}/:streamId/sfa-mapping`}>
        <SFAMapping />
      </Route>
      <Route exact path={`${path}/:streamId/override-points-table`}>
        <OverridePointsTable />
      </Route>
    </Switch>
  );
}
