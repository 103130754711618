import axiosInstance from '../../axiosInstance';

// ref: https://docs.aws.amazon.com/AWSEC2/latest/APIReference/API_InstanceState.html

const updateStreamingServerState = async ({ isStarted = true }) => {
  const response = await axiosInstance.patch(
    `/api/admin-actions/v2/ec2-state/streaming-server/status`,
    {
      is_started: isStarted,
    },
  );

  const { data } = response.data;

  return data.instance_state;
};

export default updateStreamingServerState;
