import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormFields from './FormFields';

const AthleteDetailsForm = ({
  onSubmit = () => console.log('on submit clicked'),
  first_name = '',
  last_name = '',
  position = '',
  sport = '',
  state = '',
  isProAthlete = 'N',
  preferred_section = '',
  best_team_name = '',
  DOB = '',
  description = '',
  aadhar_num = '',
  email = '',
  cm_height = 0.0,
  kg_weight = 0.0,
  mobile = '',
}) => {
  return (
    <>
      <Formik
        initialValues={{
          first_name,
          last_name,
          position,
          state,
          sport,
          isProAthlete,

          // optional
          preferred_section,
          DOB,
          cm_height,
          aadhar_num,
          mobile,
          email,
          kg_weight,
          description,
          best_team_name,
        }}
        validationSchema={Yup.object({
          first_name: Yup.string().required('Required'),
          last_name: Yup.string().required('Required'),
          position: Yup.string().required('Required'),
          state: Yup.string().required('Required'),
          sport: Yup.string().required('Required'),
          isProAthlete: Yup.string().required('Required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields />
      </Formik>
    </>
  );
};

export default AthleteDetailsForm;
