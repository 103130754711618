/* eslint-disable react/jsx-boolean-value */
import React, { useState, useRef, useContext, useEffect } from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';
import { useParams } from 'react-router';
import ChannelForm from '../../../../Components/Forms/ChannelForm';
import useStyles from './Styles';
import updateUserProfile from '../../../../API/users/updateUserProfile';
import uploadUserImage from '../../../../API/users/uploadUserImage';
import ImageBlock from '../../../../Components/ImageBlock';
import uploadUserBgImage from '../../../../API/users/uploadUserBgImage';
import updateUserChannelName from '../../../../API/users/updateUserChannelName';
import Contexts from '../../../../Contexts';
import getUserByIdV2 from '../../../../API/users/getUserByIdV2';

const UserDetails = () => {
  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);
  const [userData, setUserData] = useState(null);

  const imageType = {
    PROFILE: 'profile',
    BACKGROUND: 'background',
  };

  const { userId } = useParams();

  // editImageType used to
  // determine which edit image button was clicked
  // so as to correctly upload image or background image
  const ref = useRef({
    editImageType: imageType.PROFILE,
  });

  const [imageUploadOpen, setImageUploadOpen] = useState(false);

  const [profileImage, setProfileImage] = useState('');
  const [backgroundImage, setBackgroundImage] = useState('');

  useEffect(() => {
    if (!userData) return;
    setProfileImage(userData.profileImage);
    setBackgroundImage(userData.bgImage);
  }, [userData]);

  const classes = useStyles();

  const handleSubmit = async (values, setSubmitting) => {
    try {
      const {
        first_name,
        last_name,
        description,
        channel_verified,
        can_upload,
        can_stream,
        display_streams,
        display_videos,
        channel_name,
        is_position_based_lineups_enabled,
      } = values;
      await updateUserProfile(userId, {
        first_name,
        last_name,
        description,
        channel_verified,
        can_upload,
        can_stream,
        display_streams,
        display_videos,
        is_position_based_lineups_enabled,
      });

      if (channel_name) {
        await updateUserChannelName(userId, channel_name);
      }

      showMessageDialog('SUCCESS', 'User updated successfully');
    } catch (e) {
      showMessageDialog('ERROR', e.message);
    }

    setSubmitting(false);
  };

  const loadUserData = async () => {
    try {
      showLoadingSpinner(true);
      const user = await getUserByIdV2({ userId });
      setUserData(user);
      showLoadingSpinner(false);
    } catch (error) {
      showLoadingSpinner(false);
      showMessageDialog('ERROR', error.message);
    }
  };

  useEffect(() => {
    loadUserData();
  }, []);

  const uploadImage = async file => {
    let resp;
    if (ref.current.editImageType === imageType.BACKGROUND) {
      resp = await uploadUserBgImage({ image: file, userId });
    } else {
      resp = await uploadUserImage({ image: file, userId });
    }

    if (resp) {
      if (ref.current.editImageType === imageType.BACKGROUND) {
        setBackgroundImage(resp);
      } else {
        setProfileImage(resp);
      }
      showLoadingSpinner(false);
    }
  };

  const handleImageDialogSave = files => {
    const file = files[0];

    setImageUploadOpen(false);
    showLoadingSpinner(true);
    uploadImage(file);
  };

  const handleImageDialogClose = () => {
    setImageUploadOpen(false);
  };

  const handleEditImage = type => {
    ref.current.editImageType = type;
    setImageUploadOpen(true);
  };

  const renderForm = () => (
    <div className={classes.formContainer}>
      <ChannelForm
        displayChannelField={true}
        {...userData}
        onSubmit={handleSubmit}
      />
    </div>
  );

  const renderProfileImageUploader = () => (
    <div className={classes.imageBlock}>
      <ImageBlock
        blockLabel="Profile Image"
        onButtonPress={() => handleEditImage(imageType.PROFILE)}
        imgSrc={profileImage}
      />
    </div>
  );

  const renderBgImageUploader = () => (
    <div className={classes.imageBlock}>
      <ImageBlock
        blockLabel="Background Image"
        onButtonPress={() => handleEditImage(imageType.BACKGROUND)}
        imgSrc={backgroundImage}
      />
    </div>
  );

  if (!userData) return <div>Loading..</div>;

  return (
    <>
      {renderForm()}

      {renderProfileImageUploader()}

      {renderBgImageUploader()}

      <DropzoneDialog
        open={imageUploadOpen}
        onSave={handleImageDialogSave}
        acceptedFiles={['image/*']}
        showPreviews={true}
        maxFileSize={5000000}
        filesLimit={1}
        onClose={handleImageDialogClose}
        showAlerts={false}
      />
    </>
  );
};

export default UserDetails;
