import axiosInstance from '../../axiosInstance';

const deleteTeamPlayer = async (team_id, player_id) => {
  const response = await axiosInstance.delete(
    `/api/admin/v2/teams/${team_id}/player/${player_id}`,
  );

  const { data } = response.data;

  return data;
};

export default deleteTeamPlayer;
