import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { withRouter, useRouteMatch } from 'react-router-dom';

const RequestListElem = ({
  mobile,
  isProcessed,
  isApproved,
  userId,
  email,
  canUpload,
  canStream,
  history,
  channelName,
}) => {
  const match = useRouteMatch();

  const getStatusColor = () => {
    if (!isProcessed) return 'textPrimary';
    if (isApproved) return 'primary';
    return 'error';
  };

  const getStatusText = () => {
    if (!isProcessed) return 'Pending';
    if (isApproved) return 'Approved';
    return 'Rejected';
  };

  const handleClick = () => {
    //uncomment this
    //if (isProcessed) return;
    history.push({
      pathname: `${match.path}/verify-request`,
      state: {
        canUpload,
        canStream,
        email,
        mobile,
        userId,
        isProcessed,
        channelName,
      },
    });
  };

  return (
    <ListItem button onClick={handleClick}>
      <ListItemText
        primary={`Channel Name: ${channelName} || Mobile: ${mobile}`}
        secondary={`Email: ${email}`}
      />

      <ListItemSecondaryAction>
        <Typography color={getStatusColor()}>{getStatusText()}</Typography>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

RequestListElem.propTypes = {
  mobile: PropTypes.string,
  isProcessed: PropTypes.bool,
  isApproved: PropTypes.bool,
  userId: PropTypes.string,
  email: PropTypes.string,
  canUpload: PropTypes.bool,
  canStream: PropTypes.bool,
  channelName: PropTypes.string,
};

RequestListElem.defaultProps = {
  mobile: '5435252',
  isProcessed: true,
  isApproved: true,
  userId: 'fdsf',
  email: 'gomes.er',
  canUpload: true,
  canStream: false,
  channelName: 'default',
};

export default withRouter(RequestListElem);
