import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import useStyles from './styles';

const STATUS_VALUES = {
  STARTING: 'STARTING',
  SUBSTITUTE: 'SUB',
  EXCLUDED: 'EXCLUDED',
};

export default function LineUpTable({
  teamPlayersList,
  currentLineUp,
  onPlayerStatusChange = (playerId, status) =>
    console.log('onPlayerStatusChange with ', { playerId, status }),
}) {
  const classes = useStyles();

  const getBgColorByStatus = status => {
    switch (status) {
      case STATUS_VALUES.STARTING:
        return '#abe39a';
      case STATUS_VALUES.SUBSTITUTE:
        return '#ede8a6';
      case STATUS_VALUES.EXCLUDED:
        return '#e3938d';
      default:
        return 'white';
    }
  };

  const getStatusByPlayerId = playerId => currentLineUp[playerId];

  const renderTable = () => (
    <div className={classes.tableContainer}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Sr no</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="center">Jersey No</TableCell>
              <TableCell align="center">STARTING</TableCell>
              <TableCell align="center">SUB</TableCell>
              <TableCell align="center">EXCLUDED</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {teamPlayersList &&
              teamPlayersList.map((row, index) => (
                <TableRow
                  key={row.player_id}
                  style={{
                    backgroundColor: getBgColorByStatus(
                      getStatusByPlayerId(row.player_id),
                    ),
                  }}
                >
                  <TableCell>{index + 1}</TableCell>

                  <TableCell>{`${row.first_name} ${row.last_name}`}</TableCell>

                  <TableCell align="center">{row.jersey_num}</TableCell>

                  <TableCell align="center">
                    <Button
                      variant={
                        getStatusByPlayerId(row.player_id) ===
                        STATUS_VALUES.STARTING
                          ? 'contained'
                          : 'outlined'
                      }
                      color={
                        getStatusByPlayerId(row.player_id) ===
                        STATUS_VALUES.STARTING
                          ? 'primary'
                          : 'primary'
                      }
                      onClick={() =>
                        onPlayerStatusChange(
                          row.player_id,
                          STATUS_VALUES.STARTING,
                        )
                      }
                    >
                      {STATUS_VALUES.STARTING}
                    </Button>
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      variant={
                        getStatusByPlayerId(row.player_id) ===
                        STATUS_VALUES.SUBSTITUTE
                          ? 'contained'
                          : 'outlined'
                      }
                      color={
                        getStatusByPlayerId(row.player_id) ===
                        STATUS_VALUES.SUBSTITUTE
                          ? 'primary'
                          : 'primary'
                      }
                      onClick={() =>
                        onPlayerStatusChange(
                          row.player_id,
                          STATUS_VALUES.SUBSTITUTE,
                        )
                      }
                    >
                      {STATUS_VALUES.SUBSTITUTE}
                    </Button>
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      variant={
                        getStatusByPlayerId(row.player_id) ===
                        STATUS_VALUES.EXCLUDED
                          ? 'contained'
                          : 'outlined'
                      }
                      color={
                        getStatusByPlayerId(row.player_id) ===
                        STATUS_VALUES.EXCLUDED
                          ? 'primary'
                          : 'primary'
                      }
                      onClick={() =>
                        onPlayerStatusChange(
                          row.player_id,
                          STATUS_VALUES.EXCLUDED,
                        )
                      }
                    >
                      {STATUS_VALUES.EXCLUDED}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

  const withEmptyLineUpCheck = () => (
    <>
      {!teamPlayersList.length && (
        <div
          style={{
            paddingTop: '20px',
            paddingLeft: '30px',
            paddingRight: '30px',
            textAlign: 'center',
          }}
        >
          No players in team! add players to team and re-link team to this
          stream.
        </div>
      )}

      {!!teamPlayersList.length && renderTable()}
    </>
  );

  return withEmptyLineUpCheck();
}
