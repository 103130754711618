import React from 'react';
import { Form } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikSubmit from '../../MuiFormikComponents/FormikSubmit';
import FormikTextInput from '../../MuiFormikComponents/FormikTextInput';
import ChannelSearchFormComponent from '../../MuiFormikComponents/ChannelSearchFormComponent/ChannelSearchFormComponent';
import FormikSwitch from '../../MuiFormikComponents/FormikSwitch';

const FormFields = () => {
  const classes = useStyles();

  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">User Search</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Mobile" name="mobile" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <ChannelSearchFormComponent
              label="Channel Name"
              name="user_id_from_channel_search"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="User ID" name="user_id" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="First Name" name="first_name" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Last Name" name="last_name" />
          </Grid>

          {/* <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch
              label="Go-Live Enabled?"
              name="has_go_live_permission"
            />
          </Grid> */}

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch label="Special Privilege?" name="spa_only" />
          </Grid>

          <Grid item xs={12}>
            <FormikSubmit submitText="Search" name="submitButon" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
