import { BASE_URL } from '../constants';

const getUgcRequests = async (page = 1) => {
  try {
    const postData = {
      page_no: page,
    };

    const response = await fetch(`${BASE_URL}/api/user/get_ugc_requests`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });

    const json = await response.json();

    return json.data;
  } catch (e) {
    return [];
  }
};

export default getUgcRequests;
