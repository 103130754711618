import React, { useState } from 'react';
import PageTabs from '../../../Components/PageTabs';
import ScoreCard from './ScoreCard/ScoreCard';
import AthleteMatchStats from './AthleteMatchStats';

const tabNameComponentMap = [
  {
    name: 'Score Card',
    component: <ScoreCard />,
  },
  {
    name: 'Highlights',
    component: <AthleteMatchStats />,
  },
];

const MatchManagement = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <PageTabs
      value={tabValue}
      onChange={handleTabChange}
      tabNameComponentMap={tabNameComponentMap}
    />
  );
};

export default MatchManagement;
