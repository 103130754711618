import { BASE_URL } from '../constants';

const getUserById = async (userId = '') => {
  try {
    const postData = {
      userId,
    };

    const response = await fetch(`${BASE_URL}/api/user/get_user_profile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });

    const json = await response.json();

    if (json.error) {
      throw new Error(json.error.errorMessage);
    }

    return json.data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default getUserById;
