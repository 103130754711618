import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import Contexts from '../../../../Contexts';
import getAutoClipsInfoForMatch from '../../../../API/autoClipGen/getAutoClipsInfoForMatch';
import AutoClipsGenForm from '../../../../Components/Forms/AutoClipsGenForm/AutoClipsGenForm';
import queueAutoClipGenForMatch from '../../../../API/autoClipGen/queueAutoClipGenForMatch';
import getSingleStream from '../../../../API/streams/getSingleStream';

export default function GenerateAutoClips() {
  const { showLoadingSpinner } = useContext(Contexts);
  const classes = useStyles();
  const { streamId } = useParams();
  const [isInitComplete, setIsInitComplete] = useState(false);
  const [stream, setStream] = useState({});

  const [currentStatus, setCurrentStatus] = useState({
    is_processing: false,
    is_queued: false,
    seconds_after: 4,
    seconds_before: 20,
    times_failed_processing: 0,
    times_successfully_processed: 0,
  });

  const getInitialData = async () => {
    showLoadingSpinner(true);
    const fetchedCurrentStatus = await getAutoClipsInfoForMatch({
      matchId: streamId,
    });
    const fetchedStream = await getSingleStream(streamId);
    setStream(fetchedStream);
    setCurrentStatus(fetchedCurrentStatus);
    setIsInitComplete(true);
    showLoadingSpinner(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const handleOnSubmit = async (values, setSubmitting) => {
    const { seconds_after, seconds_before } = values;

    const newStatus = await queueAutoClipGenForMatch({
      matchId: streamId,
      secondsAfter: seconds_after,
      secondsBefore: seconds_before,
    });

    setCurrentStatus(newStatus);
    setSubmitting(false);
  };

  const shouldShowForm =
    !currentStatus.is_processing && !currentStatus.is_queued;

  let currentStateText = '';

  if (currentStatus.is_processing) {
    currentStateText = 'PROCESSING';
  }

  if (currentStatus.is_queued) {
    currentStateText = 'QUEUED';
  }

  const { media_path = '', is_complete = false } = stream;

  console.log('fdafsfdsafdsfsdfsdfsdaf', media_path, is_complete);

  const isValidStream = media_path.includes('.m3u8') && is_complete;

  if (!isInitComplete) return <div>loading</div>;

  if (!isValidStream)
    return <div>Stream must be Non-YT and complete to generate auto clips</div>;

  return (
    <div className={classes.root}>
      <div
        style={{
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption">
          ABOUT: this will generate auto clips that create match highlights and
          links clips to player profiles too
        </Typography>

        <Typography variant="caption">
          NOTE 1: works for football,BB,crick,kabaddi ONLY if detailed scoring
          is done
        </Typography>

        <Typography variant="caption">
          NOTE 2: longer matches will take longer to generate
        </Typography>

        <Typography variant="caption">
          NOTE 3: backend can process max 4 at a time, the rest will be QUEUED
          and processed when others complete
        </Typography>

        <Typography variant="overline">
          Delayed scoring will cause clips to be missed in the generation
          window. Use Seconds before and after options accordingly if need to
          change default clip window size
        </Typography>

        {shouldShowForm && (
          <AutoClipsGenForm
            seconds_after={currentStatus.seconds_after}
            seconds_before={currentStatus.seconds_before}
            onSubmit={handleOnSubmit}
          />
        )}

        {!shouldShowForm && (
          <Typography variant="h5"> STATE: {currentStateText} </Typography>
        )}

        <Typography variant="caption">
          times_successfully_processed:{' '}
          {currentStatus.times_successfully_processed}{' '}
        </Typography>

        <Typography variant="caption">
          times_failed_processing: {currentStatus.times_failed_processing}{' '}
        </Typography>

        <Typography variant="caption">
          seconds_after: {currentStatus.seconds_after}{' '}
        </Typography>

        <Typography variant="caption">
          seconds_before: {currentStatus.seconds_before}{' '}
        </Typography>

        <Typography variant="caption">
          is_processing: {currentStatus.is_processing ? 'Y' : 'N'}{' '}
        </Typography>

        <Typography variant="caption">
          is_queued: {currentStatus.is_queued ? 'Y' : 'N'}{' '}
        </Typography>
      </div>
    </div>
  );
}
