import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormFields from './FormFields';

const validationSchema = Yup.object({
  title: Yup.string().required('Required'),

  // mathc type shoudl be either individual or team based if
  // is_tour_stream is true
  match_type: Yup.string()
    .when('is_tourn_stream', {
      is: val => val === 'false' || !val,
      then: Yup.string().required('Required'),
    })
    .when('is_tourn_stream', {
      is: val => val === 'true' || val,
      then: Yup.string()
        .required()
        .oneOf(
          ['team', 'indi'],
          'tournament stream must be team or inidvidual match type',
        ),
    }),

  channel_id: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  category: Yup.string().required('Required'),
  orientation: Yup.string().required('Required'),
  article_type: Yup.string().required('Required'),
  start_timestamp: Yup.string().required('Required'),

  // round is required when is_tourn_stream is true
  round: Yup.string().when('is_tourn_stream', {
    is: val => val === 'true' || val,
    then: Yup.string()
      .required('Required')
      .matches(
        /^(S|R){1}_\d+$/,
        'round must be either R_1, R_2... or S_1, S_2...',
      ),
  }),

  // round is required when is_tourn_stream is true
  group_id: Yup.string().when('is_tourn_stream', {
    is: val => val === 'true' || val,
    then: Yup.string().matches(
      /^[A-Z]{1}$/,
      'group id must be a single uppercase alphabet',
    ),
  }),

  event_id: Yup.string().when('is_tourn_stream', {
    is: val => val === 'true' || val,
    then: Yup.string().required('Required'),
  }),

  embed_url: Yup.string().when('article_type', {
    is: 'youtube',
    then: Yup.string().required('Required'),
  }),
});

const StreamDetailsForm = ({
  title = '',
  state = '',
  start_timestamp = '',
  content = '',
  location = '',
  district = '',
  pincode = '',
  orientation = 'landscape',
  channel_id = '',
  article_type = 'stream',
  is_tourn_stream = false,
  category = '',
  keywords = [],
  match_type = 'na',
  embed_url = '',
  event_id = '',
  round = '',
  country = '',
  group_id = '',
  num_of_entities = 2,
  team_A,
  team_B,
  onSubmit,
  preferred_section,

  // jio stuff
  is_commentary = false,
  is_jio_stb = false,
  is_ppv = false,
  is_sub = false,
  is_ad = false,
  is_jio_store = false,
  is_jio_tv = false,
  is_all_jio = false,
  is_global = true,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          title,
          state,
          start_timestamp,
          content,
          // optional fields
          district,
          location,
          pincode,
          orientation,
          channel_id,
          article_type,
          is_tourn_stream,
          category,
          keywords,
          match_type,
          embed_url,
          event_id,
          round,
          country,
          group_id,
          num_of_entities,
          team_A,
          team_B,
          preferred_section,

          // jio stuff
          is_commentary,
          is_jio_stb,
          is_ppv,
          is_sub,
          is_ad,
          is_jio_store,
          is_jio_tv,
          is_all_jio,
          is_global,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields />
      </Formik>
    </>
  );
};

export default StreamDetailsForm;
