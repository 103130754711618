import React from 'react';
import { Box } from '@material-ui/core';
import styles from './styles.module.css';
import { Formik } from 'formik';
import FormFields from './FormFields';
import useManagedHomeFeedStore from '../../../Store/useManagedHomeFeedStore';
import useManagedHomeFeedActions from '../../../customHooks/ManagedHomeFeeds/useManagedHomeFeedActions';

function SectionForm() {
  const { managedHomeFeed, selectedSectionKey } = useManagedHomeFeedStore();
  const { handleUpdateSectionNameInCategory } = useManagedHomeFeedActions();

  if (!selectedSectionKey) return null;

  return (
    <Box className={styles.form_container}>
      <Formik
        enableReinitialize
        initialValues={{
          section_name: managedHomeFeed?.section_data[selectedSectionKey].eng,
        }}
        onSubmit={values =>
          handleUpdateSectionNameInCategory({
            sectionKey: selectedSectionKey,
            sectionName: values.section_name || '',
          })
        }
      >
        <FormFields />
      </Formik>
    </Box>
  );
}

export default SectionForm;
