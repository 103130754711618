import React, { useState, useRef } from 'react';
import ScoreCardForm from '../../../../Components/Forms/ScoreCardForm';
import SearchBox from '../../../../Components/SearchBox';
import useStyles from './styles';
import getStreamByStreamKey from '../../../../API/scoreCard/getStreamByStreamKey';
import addScoreCard from '../../../../API/scoreCard/addScoreCard';
import SuccessFailedDialog from '../../../../Components/SuccessFailedDialog.js';

const ScoreCard = () => {
  const ref = useRef({
    streamStatsData: {},
    dialogMessage: null,
    dialogTitle: null,
  });

  const [streamKey, setStreamKey] = useState('');
  const [displayForm, setDisplayForm] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();

  const openDialog = (title, message) => {
    ref.current.dialogTitle = title;
    ref.current.dialogMessage = message;
    setDialogOpen(true);
  };

  const handleSearch = async () => {
    setDisplayForm(false);
    const data = await getStreamByStreamKey(streamKey);
    if (data) {
      ref.current.streamStatsData = data;
      setDisplayForm(true);
    } else {
      openDialog('Error', 'Invalid stream key');
    }
  };

  const handleClear = () => {
    setDisplayForm(false);
    setStreamKey('');
  };

  const handleSubmit = async (postData, setSubmitting) => {
    const resp = await addScoreCard(streamKey, postData);
    if (resp) {
      openDialog('Saved', 'Scorecard updated succesfully.');
    } else {
      openDialog('Error', 'Failed to update scorecard');
    }
    setSubmitting(false);
  };

  return (
    <div className={classes.root}>
      <SearchBox
        value={streamKey}
        onChangeValue={setStreamKey}
        label="Search Stream Key"
        onSearch={handleSearch}
        onClear={handleClear}
      />
      {displayForm && (
        <ScoreCardForm
          matchTitle={ref.current.streamStatsData.title}
          onSubmit={handleSubmit}
          statsData={ref.current.streamStatsData.stats}
        />
      )}
      <SuccessFailedDialog
        open={dialogOpen}
        message={ref.current.dialogMessage}
        title={ref.current.dialogTitle}
        onClose={() => setDialogOpen(false)}
      />
    </div>
  );
};

export default ScoreCard;
