import axiosInstance from '../../axiosInstance';

const updateSPA = async ({
  userId,
  is_spa_enabled,
  fixed_otp,
  studio_profile_type,
  valid_till,
  stream_create_limit,
  streaming_minutes_balance,
  max_concurrent_streams,
  is_trial,
}) => {
  const response = await axiosInstance.post(
    `/api/admin/v2/subscription-tracking/special-privileges/${userId}`,
    {
      is_spa_enabled,
      fixed_otp,
      studio_profile_type,
      valid_till,
      stream_create_limit,
      streaming_minutes_balance,
      max_concurrent_streams,
      is_trial,
    },
  );

  return response.data.data.subscription_tracking;
};

export default updateSPA;
