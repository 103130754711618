import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import FormikTextInput from '../MuiFormikComponents/FormikTextInput';

const TwoTeamFormField = ({
  label = 'label',
  homeKey = 'default_home_key',
  awayKey = 'default_away_key',
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6">{label}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <FormikTextInput label="Home Team" name={homeKey} />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <FormikTextInput label="Away Team" name={awayKey} />
      </Grid>
    </>
  );
};

export default TwoTeamFormField;
