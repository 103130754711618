import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormFields from './FormFields';

const AutoClipsGenForm = ({
  onSubmit = () => console.log('AutoClipsGenForm submit clicked'),
  seconds_before = 16,
  seconds_after = 4,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          seconds_before,
          seconds_after,
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
        validationSchema={Yup.object({
          seconds_before: Yup.number()
            .min(4, 'min 4')
            .max(60, 'max 60')
            .required('Required'),
          seconds_after: Yup.number()
            .min(4, 'min 4')
            .max(60, 'max 60')
            .required('Required'),
        })}
      >
        <FormFields />
      </Formik>
    </>
  );
};

export default AutoClipsGenForm;
