import { useEffect, useState } from 'react';
import getSingleStream from '../../API/streams/getSingleStream';

interface IUseStreamReturn {
  stream: any;
  loading: boolean;
  error: any;
  forceRefresh: () => void;
}

export default function useStream(id: string | undefined): IUseStreamReturn {
  const [stream, setStream] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  const fetchData = async () => {
    try {
      const streamRes = await getSingleStream(id);
      setStream(streamRes);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const forceRefresh = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return { stream, loading, error, forceRefresh };
}
