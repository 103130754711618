const DEFAULT_SPA_FORM_FIELDS = {
  is_spa_enabled: false,
  fixed_otp: '',
  studio_profile_type: '',
  valid_till: '',
  stream_create_limit: 0,
  streaming_minutes_balance: 0,
  max_concurrent_streams: 0,
  is_trial: false,
};

export const getSpaFormFieldsFromSubTracking = ({
  subTracking,
  studioProfileType,
  isSpaUser,
  fixedOtp,
}) => {
  if (!subTracking) {
    return {
      ...DEFAULT_SPA_FORM_FIELDS,
      is_spa_enabled: isSpaUser,
      fixed_otp: fixedOtp,
      studio_profile_type: studioProfileType,
      stream_create_limit: 9999,
    };
  }

  const { plan_valid_till, stream_tracking, plan_snapshot } = subTracking;
  const { max_concurrent_streams, streaming_minutes_balance } = stream_tracking;
  const { is_trial_plan } = plan_snapshot;

  return {
    is_spa_enabled: isSpaUser,
    fixed_otp: fixedOtp,
    studio_profile_type: studioProfileType,
    valid_till: plan_valid_till,
    stream_create_limit: 9999,
    streaming_minutes_balance,
    max_concurrent_streams,
    is_trial: is_trial_plan,
  };
};

export default null;
