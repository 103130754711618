import { useEffect, useState } from 'react';
import { getOrCreateControlSetByUserId } from '../../API/ControlSet';

const useControlSet = (userId = '') => {
  const [controlSet, setControlSet] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchControlSet = async () => {
    try {
      const controlSetRes = await getOrCreateControlSetByUserId(userId);
      setControlSet(controlSetRes);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchControlSet();
  }, [userId]);

  return {
    controlSet,
    isLoading,
    error,
    refetchControlSet: fetchControlSet,
  };
};

export default useControlSet;
