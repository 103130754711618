import axiosInstance from '../../axiosInstance';

const addNewPMS = async ({ sportKey, playerId, matchId, stats }) => {
  const response = await axiosInstance.post(
    `/api/admin/v2/stats/player-match/${sportKey}/player/${playerId}/match/${matchId}`,
    {
      stats,
    },
  );

  const { data } = response.data;

  return data.player_match_stat;
};

export default addNewPMS;
