import { BASE_URL } from '../constants';

const getTournamentSearch = async (
  name,
  sport,
  tournament_id,
  start_date,
  end_date,
) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/v2/tournaments/?name=${name}&sport=${sport}&start_date=${start_date}&end_date=${end_date}&tournament_id=${tournament_id}&page=1&limit=12`,
    );

    const json = await response.json();

    if (json.error) {
      throw new Error(json.error.errorMessage);
    }

    return json.data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default getTournamentSearch;
