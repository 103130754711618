import { BASE_URL } from '../constants';

const getTournamentTeams = async tourn_id => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/v2/tournaments/${tourn_id}/teams?is_active=&group_id=`,
    );

    const json = await response.json();

    if (json.error) {
      throw new Error(json.error.errorMessage);
    }

    return json.data.teams;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default getTournamentTeams;
