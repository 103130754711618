import axiosInstance from '../../axiosInstance';

const updateUserProfile = async (
  userId,
  {
    first_name,
    last_name,
    description,
    channel_verified,
    can_upload,
    can_stream,
    display_streams,
    display_videos,
    is_position_based_lineups_enabled = false,
  },
) => {
  const response = await axiosInstance.patch(`/api/admin/v2/users/${userId}`, {
    first_name,
    last_name,
    description,
    channel_verified,
    can_upload,
    can_stream,
    display_streams,
    display_videos,
    is_position_based_lineups_enabled,
  });

  const { user } = response.data.data;

  return user;
};

export default updateUserProfile;
