import React, { useState, useContext, useEffect } from 'react';
import { DropzoneDialog } from 'material-ui-dropzone';
import { Button, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import useStyles from './styles';
import Contexts from '../../../../Contexts';
import addArticleVideo from '../../../../API/articles/addArticleVideo';
import { BASE_URL } from '../../../../API/constants';
import UploadingDialogWithProgress from '../../../../Components/UploadingDialogWithProgress';
import axiosInstance from '../../../../axiosInstance';

const ArticleMedia = () => {
  const classes = useStyles();
  const [mediaUploadOpen, setMediaUploadOpen] = useState(false);
  const [loadComplete, setLoadComplete] = useState(false);
  const [articleData, setArticleData] = useState({});

  // for uploading progress
  const [progressValue, setProgressValue] = useState(0);
  const [uploadProgressOpen, setUploadPrgressOpen] = useState(false);

  // path params
  const { articleId } = useParams();

  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);

  const getArticleData = async id => {
    showLoadingSpinner(true);
    try {
      const response = await axiosInstance.get(`/api/v2/articles/${id}`);
      const { data, error } = response.data;

      if (error) {
        throw new Error(error.errorMessage);
      }

      const { article } = data;

      setArticleData(article);
      setLoadComplete(true);
    } catch (err) {
      showMessageDialog('ERROR', err.message);
    } finally {
      showLoadingSpinner(false);
    }
  };

  const uploadMedia = async file => {
    try {
      setUploadPrgressOpen(true);
      await addArticleVideo(file, articleId, progress =>
        setProgressValue(progress * 100),
      );
      setUploadPrgressOpen(false);

      // update the article data
      await getArticleData(articleId);
    } catch (e) {
      showMessageDialog('ERROR', e.message);
    } finally {
      setProgressValue(0);
    }
  };

  const handleMediaSave = files => {
    const file = files[0];

    setMediaUploadOpen(false);
    uploadMedia(file);
  };

  useEffect(() => {
    if (articleId) {
      getArticleData(articleId);
    }
  }, []);

  const renderUploader = () => (
    <>
      <Button variant="outlined" onClick={() => setMediaUploadOpen(true)}>
        Upload Video (max size 100MB)
      </Button>

      <DropzoneDialog
        open={mediaUploadOpen}
        onSave={handleMediaSave}
        acceptedFiles={['video/mp4']}
        showPreviews
        maxFileSize={100000000}
        filesLimit={1}
        onClose={() => setMediaUploadOpen(false)}
        showAlerts={false}
      />
    </>
  );

  const renderProcessing = () => (
    <div className={classes.procesingContainer}>
      <Typography variant="h3">Processing</Typography>
      <img
        alt="SV"
        src="https://i.pinimg.com/originals/eb/24/ac/eb24ac9ceb8b614128ed5945a385206a.gif"
        height="200px"
        className={classes.processingImage}
      />

      <Typography variant="caption" align="center">
        Video has been submitted and is being processed. Refresh this page later
        to find the processed video
      </Typography>
    </div>
  );

  const renderVideoLink = () => (
    <>
      <div className={classes.procesingContainer}>
        <Typography variant="h3">Video Link</Typography>

        <a href={`https://sportvot.com/video/${articleId}`}>
          <Typography variant="caption" align="center">
            Open
          </Typography>
        </a>
      </div>
    </>
  );

  const renderPageItems = () => (
    <>
      {!articleData.media_uploaded && renderUploader()}

      {articleData.media_uploaded &&
        !articleData.media_path &&
        renderProcessing()}

      {articleData.media_uploaded &&
        articleData.media_path &&
        renderVideoLink()}

      <UploadingDialogWithProgress
        textUploading="Uploading"
        textUploadComplete="Processing"
        progressValue={progressValue}
        open={uploadProgressOpen}
      />
    </>
  );

  return (
    <div className={classes.container}>{loadComplete && renderPageItems()}</div>
  );
};

export default ArticleMedia;
