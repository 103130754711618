import { useState } from 'react';
import {
  addSectionInCategory,
  addEntityInSection,
  deleteEntityFromSection,
  reorderSectionsInCategory,
  reorderEntitiesInSection,
  toggleSectionVisibilityInCategory,
  updateSectionNameInCategory,
  deleteSectionInCategory,
} from '../../API/ManagedHomeFeed';
import {
  AddOrDeleteEntityInSectionParams,
  AddEntitiesInSectionParams,
  ReorderSectionsInCategory,
  ReorderEntitiesInSectionParams,
  ToggleSectionVisibilityInCategoryParams,
  UpdateSectionInCategoryParams,
} from '../../API/ManagedHomeFeed/types';
import useManagedHomeFeedStore from '../../Store/useManagedHomeFeedStore';

const useManagedHomeFeedActions = () => {
  const [error, setError] = useState<any>(null);
  const {
    setManagedHomeFeed,
    selectedCategory,
    managedHomeFeed,
    setSelectedSectionKey,
    setSearchOpen,
    isLoading,
    setIsLoading,
  } = useManagedHomeFeedStore();

  const handleAddSectionInCategory = async (
    sectionName: string,
    sectionType: string,
  ) => {
    setIsLoading(true);
    try {
      const { managed_home_feed } = await addSectionInCategory({
        sectionName,
        categoryCode: selectedCategory,
        sectionType,
      });
      setManagedHomeFeed(managed_home_feed);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateSectionNameInCategory = async (
    params: Omit<UpdateSectionInCategoryParams, 'categoryCode'>,
  ) => {
    if (!managedHomeFeed) return;
    setIsLoading(true);
    try {
      const { sectionName, sectionKey } = params;
      const { managed_home_feed } = await updateSectionNameInCategory({
        sectionName,
        categoryCode: managedHomeFeed._id,
        sectionKey,
      });
      setManagedHomeFeed(managed_home_feed);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleToggleSectionVisibilityInCategory = async (
    params: Omit<ToggleSectionVisibilityInCategoryParams, 'categoryCode'>,
  ) => {
    if (!managedHomeFeed) return;
    setIsLoading(true);
    try {
      const { enabled, sectionKey } = params;
      const { managed_home_feed } = await toggleSectionVisibilityInCategory({
        enabled,
        categoryCode: managedHomeFeed._id,
        sectionKey,
      });
      setManagedHomeFeed(managed_home_feed);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteSectionInCategory = async (sectionKey: string) => {
    if (!managedHomeFeed) return;
    setIsLoading(true);
    try {
      const { managed_home_feed } = await deleteSectionInCategory({
        categoryCode: managedHomeFeed._id,
        sectionKey,
      });
      setSelectedSectionKey('');
      setManagedHomeFeed(managed_home_feed);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReorderSectionsInCategory = async (
    params: Omit<ReorderSectionsInCategory, 'categoryCode'>,
  ) => {
    if (!managedHomeFeed) return;
    setIsLoading(true);
    try {
      const { sectionKeys } = params;
      const { managed_home_feed } = await reorderSectionsInCategory({
        sectionKeys,
        categoryCode: managedHomeFeed._id,
      });
      setManagedHomeFeed(managed_home_feed);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddEntityInSection = async (
    params: Omit<AddEntitiesInSectionParams, 'categoryCode'>,
  ) => {
    if (!managedHomeFeed) return;
    setIsLoading(true);
    try {
      const { entityIds, sectionKey } = params;
      const { managed_home_feed } = await addEntityInSection({
        entityIds,
        categoryCode: managedHomeFeed._id,
        sectionKey,
      });
      setManagedHomeFeed(managed_home_feed);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setIsLoading(false);
      setSearchOpen(false);
    }
  };

  const handleDeleteEntityInSection = async (
    params: Omit<AddOrDeleteEntityInSectionParams, 'categoryCode'>,
  ) => {
    if (!managedHomeFeed) return;
    setIsLoading(true);
    try {
      const { entityId, sectionKey } = params;
      const { managed_home_feed } = await deleteEntityFromSection({
        entityId,
        categoryCode: managedHomeFeed._id,
        sectionKey,
      });
      setManagedHomeFeed(managed_home_feed);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReorderEntitiesInSection = async (
    params: Omit<ReorderEntitiesInSectionParams, 'categoryCode'>,
  ) => {
    if (!managedHomeFeed) return;
    setIsLoading(true);
    try {
      const { entityIds, sectionKey } = params;
      const { managed_home_feed } = await reorderEntitiesInSection({
        entityIds,
        categoryCode: managedHomeFeed._id,
        sectionKey,
      });
      setManagedHomeFeed(managed_home_feed);
    } catch (err) {
      console.error(err);
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    setError,
    handleAddSectionInCategory,
    handleUpdateSectionNameInCategory,
    handleToggleSectionVisibilityInCategory,
    handleReorderSectionsInCategory,
    handleAddEntityInSection,
    handleDeleteEntityInSection,
    handleReorderEntitiesInSection,
    handleDeleteSectionInCategory,
  };
};

export default useManagedHomeFeedActions;
