import axiosInstance from '../../axiosInstance';

const getTeamAllSquad = async (team_id = '') => {
  const response = await axiosInstance(`/api/v2/teams/${team_id}/player`);

  const { data } = response.data;

  return data;
};

export default getTeamAllSquad;
