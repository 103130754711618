import React, { useEffect } from 'react';
import { Form, useFormikContext } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikSubmit from '../../MuiFormikComponents/FormikSubmit';
import FormikSwitch from '../../MuiFormikComponents/FormikSwitch';

const FormFields = () => {
  const classes = useStyles();
  const {
    values: { only_rejected, only_approved, only_pending },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (only_approved) {
      setFieldValue('only_pending', false);
      setFieldValue('only_rejected', false);
    }
  }, [only_approved]);

  useEffect(() => {
    if (only_rejected) {
      setFieldValue('only_pending', false);
      setFieldValue('only_approved', false);
    }
  }, [only_rejected]);

  useEffect(() => {
    if (only_pending) {
      setFieldValue('only_rejected', false);
      setFieldValue('only_approved', false);
    }
  }, [only_pending]);

  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Approvals</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch name="only_pending" label="Pending Only" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch name="only_approved" label="Approved Only" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch name="only_rejected" label="Rejected Only" />
          </Grid>

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="Search" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
