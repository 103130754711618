import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormFields from './FormFields';

const LeaugeKnockoutForm = ({
  NoT = 0,
  NoG = 0,
  TpG = 0,
  QpG = 0,
  NoR = 0,
  NoS = 0,
  PPW = 0,
  PPD = 0,
  onSubmit,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          NoT,
          NoG,
          TpG,
          QpG,
          NoR,
          NoS,
          PPW,
          PPD,
        }}
        validationSchema={Yup.object({
          NoT: Yup.number().required('Required'),
          NoG: Yup.number().required('Required'),
          TpG: Yup.number().required('Required'),
          QpG: Yup.number().required('Required'),
          NoR: Yup.number().required('Required'),
          NoS: Yup.number().required('Required'),
          PPW: Yup.number().required('Required'),
          PPD: Yup.number().required('Required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields />
      </Formik>
    </>
  );
};

export default LeaugeKnockoutForm;
