import axiosInstance from '../../axiosInstance';

const getByMobile = async (mobile = '') => {
  const response = await axiosInstance.get(`/api/v2/users/`, {
    params: {
      page: 1,
      limit: 1,
      mobile,
    },
  });

  const { data } = response.data;

  return data.users.length ? data.users[0] : null;
};

export default getByMobile;
