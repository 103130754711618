import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  Button,
  TableFooter,
  TablePagination,
  Typography,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import CheckIcon from '@material-ui/icons/Check';
import useStyles from './styles';

export default function ArticlesTable({
  data = [],
  onRowViewClicked = () => console.log('event row clicked'),
  onRowStatusClicked = () => console.log('row button clicked'),
  onChangePage = () => console.log('page change clicked'),
  onCloneStreamClicked = () => console.log('clone clicked'),
  count = 10,
  rowsPerPage = 12,
  page = 1,
}) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Sr No.</TableCell>
            <TableCell align="left">Title</TableCell>
            <TableCell align="left">Category</TableCell>
            <TableCell align="left">Channel</TableCell>
            <TableCell align="center">Created On</TableCell>
            <TableCell align="center">Uploaded</TableCell>
            <TableCell align="center">Processed</TableCell>
            <TableCell align="center">Action</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Clone</TableCell>
            <TableCell align="center">Article Id</TableCell>
            <TableCell align="center">Linked Match</TableCell>
            <TableCell align="center">Link</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data &&
            data.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="center">
                    {page * rowsPerPage + index + 1}
                  </TableCell>

                  <TableCell align="left">{`${row.title}`}</TableCell>

                  <TableCell component="th" scope="row" align="left">
                    {`${row.category}`}
                  </TableCell>

                  <TableCell align="left">{`${row.channel}`}</TableCell>

                  <TableCell component="th" scope="row" align="left">
                    {`${row.created_on &&
                      new Date(row.created_on).toLocaleString()}`}
                  </TableCell>

                  <TableCell align="center">
                    {row.media_uploaded ? <CheckIcon /> : <CancelIcon />}
                  </TableCell>

                  <TableCell align="center">
                    {row.media_uploaded && row.media_path ? (
                      <CheckIcon />
                    ) : (
                      <CancelIcon />
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onRowViewClicked(row)}
                    >
                      View
                    </Button>
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      color={row.is_active === 'Y' ? 'primary' : 'secondary'}
                      onClick={() => onRowStatusClicked(row)}
                    >
                      {row.is_active === 'Y' ? 'ENABLED' : 'DISABLED'}
                    </Button>
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onCloneStreamClicked(row)}
                    >
                      Clone
                    </Button>
                  </TableCell>

                  <TableCell component="th" scope="row" align="left">
                    {`${row._id}`}
                  </TableCell>

                  <TableCell component="th" scope="row" align="left">
                    {`${row.match_id}`}
                  </TableCell>

                  <TableCell component="th" scope="row" align="left">
                    <a
                      target="_blank"
                      href={`https://sportvot.com/video/${row._id}`}
                    >
                      <Typography variant="h6">Link</Typography>
                    </a>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rows
              colSpan={12}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={onChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
