import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormFields from './FormFields';

const KnockoutForm = ({ NoT = 0, NoS = 0, onSubmit }) => {
  return (
    <>
      <Formik
        initialValues={{
          NoT,
          NoS,
        }}
        validationSchema={Yup.object({
          NoT: Yup.number().required('Required'),
          NoS: Yup.number().required('Required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields />
      </Formik>
    </>
  );
};

export default KnockoutForm;
