import React from 'react';
import { Paper, Typography, Button } from '@material-ui/core';
import useStyles from './styles';

const StatusControllerAndId = ({
  isActive = 'Y',
  onClick = () => 'status controller button clicked',
  eventId = 'event id',
}) => {
  const classes = useStyles();
  return (
    <Paper elevation={3}>
      <div className={classes.statusControllerContainer}>
        <Typography variant="h6">Status</Typography>
        <Button
          variant="outlined"
          color={isActive === 'Y' ? 'primary' : 'secondary'}
          onClick={onClick}
        >
          {isActive === 'Y' ? 'ENABLED' : 'DISABLED'}
        </Button>
      </div>
      <div className={classes.statusControllerContainer}>
        <Typography variant="h6">Event id</Typography>
        <Typography variant="h6">{eventId}</Typography>
      </div>
    </Paper>
  );
};

export default StatusControllerAndId;
