import axiosInstance from '../../axiosInstance';
import { AUTO_CLIPS_BASE_URL } from '../constants';

const getAutoClipsInfoForMatch = async ({ matchId }) => {
  try {
    const response = await axiosInstance.get(
      `${AUTO_CLIPS_BASE_URL}/clipping-queue/${matchId}`,
    );

    const {
      is_processing,
      is_queued,
      seconds_after,
      seconds_before,
      times_failed_processing,
      times_successfully_processed,
    } = response.data.data.status;

    return {
      is_processing,
      is_queued,
      seconds_after,
      seconds_before,
      times_failed_processing,
      times_successfully_processed,
    };
  } catch (error) {
    return {
      is_processing: false,
      is_queued: false,
      seconds_after: 4,
      seconds_before: 20,
      times_failed_processing: 0,
      times_successfully_processed: 0,
    };
  }
};

export default getAutoClipsInfoForMatch;
