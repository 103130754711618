import React from 'react';
import { Form } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import FormikTextInput from '../../MuiFormikComponents/FormikTextInput';
import FormikSubmit from '../../MuiFormikComponents/FormikSubmit';
import useStyles from './styles';
import FormikDropDown from '../../MuiFormikComponents/FormikDropDown';
import FormikSwitch from '../../MuiFormikComponents/FormikSwitch';

const SPECIAL_CRICKET_OUT_TYPE_DROPDOWN = [
  {
    value: 'NOT_GIVEN_STRIKE',
    displayName: 'Not Given Strike',
  },
  {
    value: 'NOT_OUT',
    displayName: 'Not Out',
  },
  {
    value: 'BOWLED',
    displayName: 'Bowled',
  },
  {
    value: 'CAUGHT',
    displayName: 'Caught',
  },
  {
    value: 'LBW',
    displayName: 'LBW',
  },
  {
    value: 'STUMPED',
    displayName: 'Stumped',
  },
  {
    value: 'RUN_OUT',
    displayName: 'Run Out',
  },
  {
    value: 'HIT_WICKET',
    displayName: 'Hit Wicket',
  },
];

const SPECIAL_HANDLING_FIELDS = ['out_type', 'is_clean_sheet'];

/**
 *
 * formConfig is of the an array of the following the following objects
 *
 * {
 *  key: <String>,
 *  displayName: <String>,
 *  isEditable: <Boolean>,
 *  initialValue: <Number>
 * }
 */
const FormFields = ({ formConfig = [] }) => {
  const classes = useStyles();
  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Stats</Typography>
          </Grid>

          {formConfig.map(elem => (
            <Grid item xs={12} sm={6} md={6} key={elem.key}>
              {/* special cricket handling */}
              {!SPECIAL_HANDLING_FIELDS.includes(elem.key) && (
                <FormikTextInput
                  disabled={!elem.isEditable}
                  label={elem.displayName}
                  name={elem.key}
                  type="number"
                />
              )}
              {/* special cricket handling */}
              {elem.key === 'out_type' && (
                <FormikDropDown
                  disabled={!elem.isEditable}
                  label={elem.displayName}
                  name={elem.key}
                  valueDisplayNameMap={SPECIAL_CRICKET_OUT_TYPE_DROPDOWN}
                />
              )}
              {/* special football handling  */}
              {elem.key === 'is_clean_sheet' && (
                <FormikSwitch
                  disabled={!elem.isEditable}
                  label={elem.displayName}
                  name={elem.key}
                />
              )}
            </Grid>
          ))}

          <Grid item xs={12}>
            <FormikSubmit name="submitButton" submitText="save" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
