import { BASE_URL } from '../constants';

const deleteTournamentTeam = async (tourn_id, team_id) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/v2/tournaments/${tourn_id}/teams/${team_id}`,
      {
        method: 'DELETE',
      },
    );

    const json = await response.json();
    console.log('response', response);
    console.log('json', json);

    if (json.error) {
      throw new Error(json.error.errorMessage);
    }

    return json;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default deleteTournamentTeam;
