import axiosInstance from '../../axiosInstance';

const setHeaderContent = async ({
  isHomeFeed = true,
  sport = '',
  type,
  content_id,
}) => {
  let endpoint;

  if (isHomeFeed) {
    endpoint = '/api/admin/v2/header-content/non-sport-specific';
  } else {
    endpoint = `/api/admin/v2/header-content/sport-specific/${sport}`;
  }

  const response = await axiosInstance.put(endpoint, {
    type,
    content_id,
  });

  const { data } = response.data;

  return data.header_content;
};

export default setHeaderContent;
