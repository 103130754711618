import axiosInstance from '../../axiosInstance';

const removeArticleLinkedPlayer = async (articleId, athleteId) => {
  const response = await axiosInstance.delete(
    `/api/admin/v2/articles/${articleId}/linked-athletes/${athleteId}`,
  );

  const { data } = response.data;

  return data.article;
};

export default removeArticleLinkedPlayer;
