import React from 'react';
import { TextField } from '@material-ui/core';
import { useField } from 'formik';

const FormikTextInput = ({
  fullWidth = true,
  type = 'text',
  label,
  multiline,
  variant = 'standard',
  ...props
}) => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <TextField
      type={type}
      fullWidth={fullWidth}
      multiline={multiline}
      {...field}
      {...props}
      error={!!(meta.touched && meta.error)}
      label={label}
      helperText={`${meta.touched && meta.error ? meta.error : ''}`}
      variant={variant}
    />
  );
};

export default FormikTextInput;
