import {
  Ec2RegionCosts,
  RegionAndTypeBasedEc2Costs,
  RegionBasedS3Costs,
} from './types';

export const Ec2RegionCostsConfig: Ec2RegionCosts = {
  'us-east-1': {
    dataOutCostsPerGbToInternet: 0.09,

    dataOutCostsPerGbToRegion: {
      'us-east-1': 0.01,
      'eu-central-1': 0.02,
      'ap-south-1': 0.02,
      'us-west-1': 0.02,
    },
    gp2StoragePerGbMonth: 0.1,
    publicIpv4PerHour: 0.005,
  },

  'eu-central-1': {
    dataOutCostsPerGbToInternet: 0.09,

    dataOutCostsPerGbToRegion: {
      'us-east-1': 0.02,
      'eu-central-1': 0.01,
      'ap-south-1': 0.02,
      'us-west-1': 0.02,
    },
    gp2StoragePerGbMonth: 0.119,
    publicIpv4PerHour: 0.005,
  },

  'ap-south-1': {
    dataOutCostsPerGbToInternet: 0.1093,

    dataOutCostsPerGbToRegion: {
      'us-east-1': 0.086,
      'eu-central-1': 0.086,
      'ap-south-1': 0.01,
      'us-west-1': 0.086,
    },
    gp2StoragePerGbMonth: 0.114,
    publicIpv4PerHour: 0.005,
  },

  'us-west-1': {
    dataOutCostsPerGbToInternet: 0.09,

    dataOutCostsPerGbToRegion: {
      'us-east-1': 0.02,
      'eu-central-1': 0.02,
      'ap-south-1': 0.02,
      'us-west-1': 0.01,
    },
    gp2StoragePerGbMonth: 0.12,
    publicIpv4PerHour: 0.005,
  },
};

export const regionAndTypeBasedEc2CostsConfig: RegionAndTypeBasedEc2Costs = {
  'us-east-1': {
    'g4dn.x_large': {
      computePerHour: 0.526,
      regionCosts: Ec2RegionCostsConfig['us-east-1'],
    },

    'g4dn.2x_large': {
      computePerHour: 0.752,
      regionCosts: Ec2RegionCostsConfig['us-east-1'],
    },
  },

  'eu-central-1': {
    'g4dn.x_large': {
      computePerHour: 0.658,
      regionCosts: Ec2RegionCostsConfig['eu-central-1'],
    },

    'g4dn.2x_large': {
      computePerHour: 0.94,
      regionCosts: Ec2RegionCostsConfig['eu-central-1'],
    },
  },

  'ap-south-1': {
    'g4dn.x_large': {
      computePerHour: 0.579,
      regionCosts: Ec2RegionCostsConfig['ap-south-1'],
    },

    'g4dn.2x_large': {
      computePerHour: 0.828,
      regionCosts: Ec2RegionCostsConfig['ap-south-1'],
    },
  },

  'us-west-1': {
    'g4dn.x_large': {
      computePerHour: 0.631,
      regionCosts: Ec2RegionCostsConfig['us-west-1'],
    },

    'g4dn.2x_large': {
      computePerHour: 0.902,
      regionCosts: Ec2RegionCostsConfig['us-west-1'],
    },
  },
};

export const RegionBasedS3CostsConfig: RegionBasedS3Costs = {
  'us-east-1': {
    INT_FA_GBMonth: 0.023,
    INT_IFA_GBMonth: 0.0125,
    INT_AIA_GBMonth: 0.004,
    INT_monitoringPer1000Objects: 0.0025,
  },

  'eu-central-1': {
    INT_FA_GBMonth: 0.0245,
    INT_IFA_GBMonth: 0.0135,
    INT_AIA_GBMonth: 0.005,
    INT_monitoringPer1000Objects: 0.0025,
  },

  'ap-south-1': {
    INT_FA_GBMonth: 0.025,
    INT_IFA_GBMonth: 0.0138,
    INT_AIA_GBMonth: 0.005,
    INT_monitoringPer1000Objects: 0.0025,
  },

  'us-west-1': {
    INT_FA_GBMonth: 0.026,
    INT_IFA_GBMonth: 0.0144,
    INT_AIA_GBMonth: 0.005,
    INT_monitoringPer1000Objects: 0.0025,
  },
};

export const SERVER_GP2_STORAGE_GB = 530;

export const CDN_MUMBAI_DATA_OUT_EGRESS_COST_PER_GB = 0.055;

export const TAX_MULTIPLIER = 1.18; // 18% tax
