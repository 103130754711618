import React, { useContext } from 'react';
import { Form, useFormikContext } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikTextInput from '../MuiFormikComponents/FormikTextInput';
import FormikSubmit from '../MuiFormikComponents/FormikSubmit';
import Context from '../../../Contexts';
import {
  generateStateDropDownList,
  generateHomeSectionsListFromCategory,
} from '../../../Utils/dropDownListGenerators';
import FormikDropDown from '../MuiFormikComponents/FormikDropDown';
import ReactDatePicker from '../MuiFormikComponents/ReactDatePicker';
import FormikSwitch from '../MuiFormikComponents/FormikSwitch';

const isLeagueConfigSupportedSport = sportKey => {
  if (!sportKey) return false;
  return [
    'cricket',
    'kab',
    'basketball',
    'football',
    'hockey',
    'volleyball',
  ].includes(sportKey);
};

const FormFields = () => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const context = useContext(Context);
  const { formConfig, categoriesDropDownList, sectionDetails } = context;

  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Event Details</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Mandatory Fields</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Event Name *" name="name" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Organised by *" name="organised_by" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikDropDown
              valueDisplayNameMap={generateStateDropDownList(formConfig)}
              label="State *"
              name="state"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikDropDown
              valueDisplayNameMap={categoriesDropDownList}
              label="Sport *"
              name="sport"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch label="Sport Event *" name="is_sport" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Location *" name="location" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <ReactDatePicker
              disableTime
              label="Start Date *"
              name="start_date"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <ReactDatePicker disableTime label="End Date *" name="end_date" />
          </Grid>

          {isLeagueConfigSupportedSport(values.sport) && (
            <Grid item xs={12}>
              <Typography variant="h6">League Config</Typography>
            </Grid>
          )}

          {isLeagueConfigSupportedSport(values.sport) && (
            <Grid item xs={12} sm={6} md={6}>
              <FormikSwitch
                label="Enable League? (determines if league table is visible)"
                name="event_league_config_is_enabled"
              />
            </Grid>
          )}

          {isLeagueConfigSupportedSport(values.sport) && (
            <Grid item xs={12} sm={6} md={6}>
              <FormikTextInput
                label="Points Per Win"
                name="event_league_config_points_per_win"
                type="number"
              />
            </Grid>
          )}

          {isLeagueConfigSupportedSport(values.sport) && (
            <Grid item xs={12} sm={6} md={6}>
              <FormikTextInput
                label="Points Per Draw"
                name="event_league_config_points_per_draw"
                type="number"
              />
            </Grid>
          )}

          {values.sport && isLeagueConfigSupportedSport(values.sport) && (
            <Grid item xs={12} sm={6} md={6}>
              <FormikTextInput
                label="Points Per Loss"
                name="event_league_config_points_per_loss"
                type="number"
              />
            </Grid>
          )}

          {values.sport && (
            <Grid item xs={12}>
              <Typography variant="h6">Home Section</Typography>
            </Grid>
          )}

          {values.sport && (
            <Grid item xs={12} sm={6} md={6}>
              <FormikDropDown
                valueDisplayNameMap={generateHomeSectionsListFromCategory(
                  sectionDetails,
                  values.sport,
                  ['event'],
                )}
                label="Home Section"
                name="preferred_section"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography variant="h6">Optional Fields</Typography>
          </Grid>

          <Grid item xs={12}>
            <FormikTextInput multiline label="Description" name="description" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="District" name="district" />
          </Grid>

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="save" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
