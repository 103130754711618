import React, { useContext, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import Contexts from '../../../Contexts';
import { generateHomeSectionCategoriesDropDown } from '../../../Utils/dropDownListGenerators';
import getHeaderContent from '../../../API/HeaderContent/getHeaderContent';
import HeaderContentForm from '../../../Components/Forms/HeaderContentForm';
import setHeaderContent from '../../../API/HeaderContent/setHeaderContent';
import EnabledController from './EnabledController';
import updateHeaderContentIsEnabled from '../../../API/HeaderContent/updateHeaderContentIsEnabled';

export default function HeaderContent() {
  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);
  const [headerContentData, setHeaderContentData] = useState({});
  const [homeCategoriesDropdown, setHomeCategoriesDropdown] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('home_feed');
  const [isCategoriesLoaded, setIsCategoriesLoaded] = useState(false);
  const [isLoadingHeaderContentData, setIsLoadingHeaderContentData] = useState(
    true,
  );
  const { addToast } = useToasts();
  const classes = useStyles();

  const setHomeCategories = async () => {
    try {
      const data = await generateHomeSectionCategoriesDropDown();
      setHomeCategoriesDropdown(data);
      setIsCategoriesLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchHeaderContent = async category => {
    try {
      setIsLoadingHeaderContentData(true);
      const headerContent = await getHeaderContent({
        isHomeFeed: category === 'home_feed',
        sport: category,
      });

      setHeaderContentData(headerContent || {});

      console.log('header content for ', category, headerContent);
      setIsLoadingHeaderContentData(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setHomeCategories();
  }, []);

  useEffect(() => {
    if (isCategoriesLoaded) {
      fetchHeaderContent(selectedCategory);
    }
  }, [isCategoriesLoaded, selectedCategory]);

  const onSubmit = async (values, setSubmitting) => {
    try {
      showLoadingSpinner(true);
      setIsLoadingHeaderContentData(true);
      const { type, content_id } = values;
      const newHeaderContentData = await setHeaderContent({
        type,
        isHomeFeed: selectedCategory === 'home_feed',
        sport: selectedCategory,
        content_id,
      });
      setHeaderContentData(newHeaderContentData || {});
      addToast('Updated', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } catch (error) {
      console.log(error);
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
    setSubmitting(false);
    showLoadingSpinner(false);
    setIsLoadingHeaderContentData(false);
  };

  const onIsEnabledUpdate = async () => {
    try {
      showLoadingSpinner(true);
      setIsLoadingHeaderContentData(true);

      const newHeaderContentData = await updateHeaderContentIsEnabled({
        isHomeFeed: selectedCategory === 'home_feed',
        sport: selectedCategory,
        is_enabled: !headerContentData.is_enabled,
      });
      setHeaderContentData(newHeaderContentData || {});
      addToast('Updated', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } catch (error) {
      console.log(error);
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
    showLoadingSpinner(false);
    setIsLoadingHeaderContentData(false);
  };

  if (!isCategoriesLoaded)
    return (
      <div className={classes.container}>
        <Typography variant="caption">Loading categories</Typography>
      </div>
    );

  if (isLoadingHeaderContentData)
    return (
      <div className={classes.container}>
        <Typography variant="caption">Loading Header Content</Typography>
      </div>
    );

  return (
    <div className={classes.container}>
      <Typography variant="caption">
        This is the header (large) video / stream that comes on the web home
        page or sport specific page
      </Typography>
      <div style={{ paddingTop: '20px' }}>
        {Object.keys(headerContentData).length > 0 && (
          <EnabledController
            isEnabled={!!headerContentData.is_enabled}
            onClick={onIsEnabledUpdate}
          />
        )}
      </div>
      <div style={{ paddingTop: '20px' }}>
        <FormControl fullWidth>
          <InputLabel>Section</InputLabel>
          <Select
            value={selectedCategory}
            onChange={e => setSelectedCategory(e.target.value)}
          >
            <MenuItem value="home_feed">Home Page</MenuItem>

            {homeCategoriesDropdown.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <HeaderContentForm onSubmit={onSubmit} {...headerContentData} />
    </div>
  );
}
