export const AUTHORITIES = {
  ADMIN_USER: 'ADMIN_USER',
  PASS_AUTHENTICATOR: 'PASS_AUTHENTICATOR',

  ARTICLE: {
    READ: 'ARTICLE_READ',
    WRITE: 'ARTICLE_WRITE',
  },

  PLAYER: {
    READ: 'PLAYER_READ',
    WRITE: 'PLAYER_WRITE',
  },

  TEAM: {
    READ: 'TEAM_READ',
    WRITE: 'TEAM_WRITE',

    PLAYER: {
      READ: 'TEAM_PLAYER_READ',
      WRITE: 'TEAM_PLAYER_WRITE',
    },
  },

  USER: {
    READ: 'USER_READ',
    WRITE: 'USER_WRITE',

    AUTHORITIES: {
      READ: 'USER_AUTHORITIES_READ',
      WRITE: 'USER_AUTHORITIES_WRITE',
    },
  },
};

// this should be an exhaustive list of all the
// permissions above
// should be changed whenever permissions are changed
export const authoritiesList = [
  AUTHORITIES.ARTICLE.READ,
  AUTHORITIES.ARTICLE.WRITE,

  AUTHORITIES.PLAYER.READ,
  AUTHORITIES.PLAYER.WRITE,

  AUTHORITIES.USER.READ,
  AUTHORITIES.USER.WRITE,
  AUTHORITIES.USER.AUTHORITIES.READ,
  AUTHORITIES.USER.AUTHORITIES.WRITE,

  AUTHORITIES.TEAM.READ,
  AUTHORITIES.TEAM.WRITE,
  AUTHORITIES.TEAM.PLAYER.READ,
  AUTHORITIES.TEAM.PLAYER.WRITE,
];
