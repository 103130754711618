import React from 'react';
import { Button } from '@material-ui/core';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';

export default function FixtureLKOG() {
  const { teamId, type } = useParams();
  const history = useHistory();
  const { url } = useRouteMatch();

  const onMangeGroupStageBTN = async () => {
    history.push({
      pathname: `${url}/group_stage`,
    });
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Button
        style={{ marginTop: 30 }}
        variant="contained"
        onClick={onMangeGroupStageBTN}
      >
        Group Stage
      </Button>
      <Button style={{ marginTop: 30 }} variant="contained">
        Knockout Stage
      </Button>
      <Button style={{ marginTop: 30 }} variant="contained">
        Evaluate Group Qualifiers{' '}
      </Button>
    </div>
  );
}
