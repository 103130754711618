import React, { useContext } from 'react';
import { Form, useFormikContext } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikSubmit from '../../MuiFormikComponents/FormikSubmit';
import FormikTextInput from '../../MuiFormikComponents/FormikTextInput';
import FormikDropDown from '../../MuiFormikComponents/FormikDropDown';
import Contexts from '../../../../Contexts';
import {
  generateHomeSectionsListFromCategory,
  generateSportDropDownList,
} from '../../../../Utils/dropDownListGenerators';
import ChannelSearchFormComponent from '../../MuiFormikComponents/ChannelSearchFormComponent/ChannelSearchFormComponent';
import FormikSwitch from '../../MuiFormikComponents/FormikSwitch';
import ReactDatePicker from '../../MuiFormikComponents/ReactDatePicker';

const FormFields = ({ isForManagedHomeFeed = false }) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  const context = useContext(Contexts);
  const { formConfig, sectionDetails } = context;

  return (
    <Form>
      <Paper
        elevation={3}
        className={classes.formContainer}
        style={{
          maxWidth: !isForManagedHomeFeed ? '600px' : '100%',
          marginTop: isForManagedHomeFeed && 0,
        }}
      >
        <Grid container spacing={3}>
          {!isForManagedHomeFeed && (
            <Grid item xs={12}>
              <Typography variant="h4">Stream Search</Typography>
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={6} lg={!isForManagedHomeFeed ? 4 : 3}>
            <FormikTextInput label="Stream Key" name="stream_key" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={!isForManagedHomeFeed ? 4 : 3}>
            <FormikTextInput label="Title" name="title" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={!isForManagedHomeFeed ? 4 : 3}>
            <FormikDropDown
              valueDisplayNameMap={[
                { value: 'youtube', displayName: 'Youtube' },
                { value: 'stream', displayName: 'Stream' },
              ]}
              label="Type"
              name="article_type"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={!isForManagedHomeFeed ? 4 : 3}>
            <FormikDropDown
              valueDisplayNameMap={generateSportDropDownList(formConfig)}
              label="Category"
              name="category"
            />
          </Grid>

          {values.category && !isForManagedHomeFeed && (
            <Grid item xs={12} sm={6} md={6} lg={!isForManagedHomeFeed ? 4 : 3}>
              <FormikDropDown
                valueDisplayNameMap={generateHomeSectionsListFromCategory(
                  sectionDetails,
                  values.category,
                  ['short_content', 'long_content', 'story'],
                )}
                label="Home Section"
                name="preferred_section"
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={6} lg={!isForManagedHomeFeed ? 4 : 3}>
            <ChannelSearchFormComponent name="channel_id" label="Channel" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={!isForManagedHomeFeed ? 4 : 3}>
            <FormikTextInput label="Match Id" name="_id" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={!isForManagedHomeFeed ? 4 : 3}>
            <FormikTextInput label="Event Id" name="event_id" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={!isForManagedHomeFeed ? 4 : 3}>
            <FormikSwitch name="is_live" label="Live Only" />
          </Grid>

          {isForManagedHomeFeed && (
            <>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <ReactDatePicker
                  disableTime
                  label="Start Date"
                  name="start_date"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <ReactDatePicker disableTime label="End Date" name="end_date" />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={!isForManagedHomeFeed ? 4 : 3}
              >
                <FormikSwitch
                  name="should_sort_by_views"
                  label="Sort By Views"
                />
              </Grid>
            </>
          )}

          {!isForManagedHomeFeed && (
            <>
              <Grid item xs={12} sm={6} md={6}>
                <FormikSwitch
                  label="Include Demo & Trial Streams?"
                  name="include_demo_and_trial_streams"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Jio Related Fields</Typography>
              </Grid>

              {/* <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch label="Has Commentary" name="is_commentary" />
          </Grid> */}

              <Grid item xs={12} sm={6} md={6}>
                <FormikSwitch label="STB" name="is_jio_stb" />
              </Grid>

              {/* <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch label="Pay Per View" name="is_ppv" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch label="Has Subtitles" name="is_sub" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch label="Ad Enabled" name="is_ad" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch label="Store" name="is_jio_store" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch label="TV" name="is_jio_tv" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch label="All" name="is_all_jio" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch label="Global" name="is_global" />
          </Grid> */}
            </>
          )}

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="Search" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
