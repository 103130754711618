import axiosInstance from '../../axiosInstance';

export const getOrCreateControlSetByUserId = async userId => {
  const response = await axiosInstance.get(
    `/api/admin/v2/control-set/${userId}`,
  );
  return response.data.data;
};

export const createControlSetByUserId = async userId => {
  const response = await axiosInstance.post(
    `/api/admin/v2/control-set/${userId}`,
  );
  return response.data.data;
};

export const whitelistTemplateForUser = async ({ userId, templateId }) => {
  const response = await axiosInstance.post(
    `/api/admin/v2/control-set/${userId}/templates`,
    {
      template_id: templateId,
    },
  );
  return response.data.data;
};

export const unWhitelistTemplateForUser = async ({ userId, templateId }) => {
  const response = await axiosInstance.delete(
    `/api/admin/v2/control-set/${userId}/templates/${templateId}`,
  );
  return response.data.data;
};

export const toggleEditorAllowed = async ({ userId, editorAllowed }) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/control-set/${userId}/editor-allowed`,
    {
      editor_allowed: editorAllowed,
    },
  );
  return response.data.data;
};
