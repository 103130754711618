import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import swal from '@sweetalert/with-react';
import { useToasts } from 'react-toast-notifications';
import useStyles from './styles';
import logoutFromAllDevices from '../../../../API/users/logoutFromAllDevices';
import Contexts from '../../../../Contexts';

export default function SelectedUser() {
  const classes = useStyles();
  const history = useHistory();
  const { userId } = useParams();
  const { showLoadingSpinner } = useContext(Contexts);
  const { addToast } = useToasts();

  const navigateToUserDetails = () => {
    history.push({
      pathname: `/users/${userId}/details`,
    });
  };

  const navigateToSPA = () => {
    history.push({
      pathname: `/users/${userId}/SPA`,
    });
  };

  const navigateToSharingTo = () => {
    history.push({
      pathname: `/users/${userId}/sharing-to`,
    });
  };

  const navigateToUserTemplates = () => {
    history.push({
      pathname: `/users/${userId}/templates`,
    });
  };

  const handleLogout = async () => {
    try {
      const isYesSelected = await swal({
        title: 'Are you sure?',
        text:
          'This will logout the user from all devices. Logout could take upto one hour.',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      });

      if (!isYesSelected) return;

      showLoadingSpinner(true);
      await logoutFromAllDevices({ userId });

      showLoadingSpinner(false);

      addToast('Success', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } catch (error) {
      showLoadingSpinner(false);
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  const renderOptions = () => (
    <>
      <Button
        className={classes.buttons}
        variant="contained"
        onClick={navigateToUserDetails}
      >
        Manage Details
      </Button>

      <Button
        className={classes.buttons}
        variant="contained"
        onClick={navigateToUserTemplates}
      >
        Manage Templates
      </Button>

      <Button
        className={classes.buttons}
        variant="contained"
        onClick={navigateToSPA}
      >
        Special Privilege Access
      </Button>

      <Button
        className={classes.buttons}
        variant="contained"
        onClick={navigateToSharingTo}
      >
        Data Sharing
      </Button>

      <Button
        className={classes.buttons}
        variant="contained"
        onClick={handleLogout}
      >
        LOGOUT From All Devices
      </Button>
      <Typography variant="caption">
        NOTE: LOGOUT can take upto one hour on some devices
      </Typography>
    </>
  );

  return <div className={classes.container}>{renderOptions()}</div>;
}
