import axiosInstance from '../../axiosInstance';

const addArticleLinkedPlayer = async (articleId, athleteId) => {
  const response = await axiosInstance.post(
    `/api/admin/v2/articles/${articleId}/linked-athletes`,
    {
      athlete_id: athleteId,
    },
  );

  const { data } = response.data;

  return data.article;
};

export default addArticleLinkedPlayer;
