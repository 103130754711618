import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import useStyles from './styles';

export default function LinkedAthleteTable({
  data,
  onDeletePlayer = playerId => console.log(playerId),
}) {
  const classes = useStyles();

  return (
    <div className={classes.tableContainer}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Sr no</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell>Sport</TableCell>
              <TableCell align="center">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => {
                return (
                  <TableRow key={row.player_id}>
                    <TableCell align="center">{index + 1}</TableCell>

                    <TableCell component="th" scope="row">
                      {`${row.first_name}`}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {`${row.last_name}`}
                    </TableCell>

                    <TableCell component="th" scope="row">
                      {`${row.sport}`}
                    </TableCell>

                    <TableCell align="center">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => onDeletePlayer(row._id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
