import React from 'react';
import Button from '@material-ui/core/Button';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import useStyles from './styles';

export default function SelectedTeam() {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { teamId } = useParams();

  const onMangeDetailsBTN = () => {
    history.push({
      pathname: `${url}/details`,
    });
  };

  return (
    <div className={classes.container}>
      <Button
        className={classes.buttons}
        variant="contained"
        onClick={onMangeDetailsBTN}
      >
        Manage Details
      </Button>
      <Button
        className={classes.buttons}
        variant="contained"
        onClick={() => {
          history.push({
            pathname: `/teams/${teamId}/players`,
          });
        }}
      >
        Manage Players
      </Button>
    </div>
  );
}
