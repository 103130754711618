import axiosInstance from '../../axiosInstance';

const getSpaDetails = async ({ userId }) => {
  const response = await axiosInstance.get(
    `/api/admin/v2/subscription-tracking/special-privileges/${userId}`,
  );

  const { subscription_tracking, user, fixed_otp } = response.data.data;

  return { subscription_tracking, user, fixed_otp };
};

export default getSpaDetails;
