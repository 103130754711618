import React, { useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, List } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';
import RequestListElem from './RequestListElem';

import getUgcRequests from '../../../../API/ugcRequests/getUgcRequests';

const RequestList = () => {
  const ref = useRef({
    pageNo: 1,
    canLoad: true,
  });

  const [listData, setListData] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const fetchData = async () => {
    ref.current.canLoad = false;

    const data = await getUgcRequests(ref.current.pageNo);
    if (!data.length) return setHasMore(false);

    setListData(listData.concat(data));
    ref.current.canLoad = true;
  };

  useEffect(() => {
    fetchData();
  }, []);

  const renderList = () => {
    return (
      <Grid item xs={12} md={6}>
        <List component="nav">
          {listData.map(data => {
            const {
              mobile,
              can_upload,
              can_stream,
              email,
              approved,
              request_processed,
              userId,
              channel_name,
            } = data;
            return (
              <RequestListElem
                key={mobile}
                mobile={mobile}
                canUpload={can_upload}
                canStream={can_stream}
                email={email}
                isApproved={approved}
                isProcessed={request_processed}
                userId={userId}
                channelName={channel_name}
              />
            );
          })}
        </List>
      </Grid>
    );
  };

  const fetchMore = () => {
    if (ref.current.canLoad) {
      ref.current.pageNo += 1;
      fetchData();
    }
  };

  return (
    <InfiniteScroll
      dataLength={listData.length}
      next={fetchMore}
      hasMore={hasMore}
    >
      {renderList()}
    </InfiniteScroll>
  );
};

export default withRouter(RequestList);
