import axiosInstance from '../../axiosInstance';
import {
  AddOrDeleteEntityInSectionParams,
  AddSectionInCategoryParams,
  AddEntitiesInSectionParams,
  ReorderSectionsInCategory,
  ReorderEntitiesInSectionParams,
  ToggleSectionVisibilityInCategoryParams,
  UpdateSectionInCategoryParams,
  GetSectionVideosParams,
  FeedType,
} from './types';

export const getManagedHomeFeedByCategoryCode = async (
  categoryCode: string,
  feedType: FeedType,
) => {
  console.log('categoryCode', categoryCode);
  const resp = await axiosInstance.get(
    `/api/admin/v2/managed-home-feed/${categoryCode}`,
    {
      params: {
        feed_type: feedType,
      },
    },
  );

  return resp.data.data;
};

export const addSectionInCategory = async (
  params: AddSectionInCategoryParams,
) => {
  const { categoryCode, sectionName, sectionType } = params;
  const resp = await axiosInstance.post(
    `/api/admin/v2/managed-home-feed/${categoryCode}`,
    {
      section_name: sectionName,
      section_type: sectionType,
    },
  );

  return resp.data.data;
};

export const updateSectionNameInCategory = async (
  params: UpdateSectionInCategoryParams,
) => {
  const { categoryCode, sectionName, sectionKey } = params;
  const resp = await axiosInstance.patch(
    `/api/admin/v2/managed-home-feed/${categoryCode}/section/${sectionKey}`,
    {
      section_name: sectionName,
    },
  );

  return resp.data.data;
};

export const deleteSectionInCategory = async (
  params: Omit<UpdateSectionInCategoryParams, 'sectionName'>,
) => {
  const { categoryCode, sectionKey } = params;
  const resp = await axiosInstance.delete(
    `/api/admin/v2/managed-home-feed/${categoryCode}/section/${sectionKey}`,
  );

  return resp.data.data;
};

export const toggleSectionVisibilityInCategory = async (
  params: ToggleSectionVisibilityInCategoryParams,
) => {
  const { categoryCode, enabled, sectionKey } = params;
  const resp = await axiosInstance.patch(
    `/api/admin/v2/managed-home-feed/${categoryCode}/section/${sectionKey}/enabled`,
    {
      enabled,
    },
  );

  return resp.data.data;
};

export const reorderSectionsInCategory = async (
  params: ReorderSectionsInCategory,
) => {
  const { categoryCode, sectionKeys } = params;
  const resp = await axiosInstance.patch(
    `/api/admin/v2/managed-home-feed/${categoryCode}/section-order`,
    {
      section_keys: sectionKeys,
    },
  );

  return resp.data.data;
};

export const addEntityInSection = async (
  params: AddEntitiesInSectionParams,
) => {
  const { categoryCode, sectionKey, entityIds } = params;
  const resp = await axiosInstance.patch(
    `/api/admin/v2/managed-home-feed/${categoryCode}/section/${sectionKey}/entity`,
    {
      entity_ids: entityIds,
    },
  );

  return resp.data.data;
};

export const deleteEntityFromSection = async (
  params: AddOrDeleteEntityInSectionParams,
) => {
  const { categoryCode, sectionKey, entityId } = params;
  const resp = await axiosInstance.delete(
    `/api/admin/v2/managed-home-feed/${categoryCode}/section/${sectionKey}/entity/${entityId}`,
  );

  return resp.data.data;
};

export const reorderEntitiesInSection = async (
  params: ReorderEntitiesInSectionParams,
) => {
  const { categoryCode, sectionKey, entityIds } = params;
  const resp = await axiosInstance.patch(
    `/api/admin/v2/managed-home-feed/${categoryCode}/section/${sectionKey}/entity-order`,
    {
      entity_ids: entityIds,
    },
  );

  return resp.data.data;
};

export const getSectionVideos = async (params: GetSectionVideosParams) => {
  const { categoryCode, sectionKey } = params;
  const resp = await axiosInstance.get(
    `/api/admin/v2/managed-home-feed/${categoryCode}/section/${sectionKey}/videos`,
  );

  return resp.data.data;
};

export const getSectionPlayers = async (params: GetSectionVideosParams) => {
  const { categoryCode, sectionKey } = params;
  const resp = await axiosInstance.get(
    `/api/admin/v2/managed-home-feed/${categoryCode}/section/${sectionKey}/players`,
  );

  return resp.data.data;
};

export const getSectionEvents = async (params: GetSectionVideosParams) => {
  const { categoryCode, sectionKey } = params;
  const resp = await axiosInstance.get(
    `/api/admin/v2/managed-home-feed/${categoryCode}/section/${sectionKey}/events`,
  );

  return resp.data.data;
};
