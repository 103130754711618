import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './Styles';

export default function InfoLablel({ caption, value }) {
  const classes = useStyles();

  return (
    <div className={classes.InfoLabelContainer}>
      <Typography variant="caption" display="block">
        {caption}
      </Typography>
      <Typography variant="overline" display="block">
        {value}
      </Typography>
    </div>
  );
}

InfoLablel.propTypes = {
  caption: PropTypes.string,
  value: PropTypes.string,
};

InfoLablel.defaultProps = {
  caption: 'caption',
  value: 'longer text here',
};
