import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  title: {
    fontSize: 22,
  },
  pos: {
    marginBottom: 12,
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
    padding: 0,
    maxWidth: 500,
    flexDirection: 'column',
    marginTop: theme.spacing(4),
  },
  TextField: {
    margin: 20,
  },
  imageBlock: {
    marginTop: theme.spacing(4),
    marginBottom: 50,
  },
  statusControllerContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));
export default useStyles;
