import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TableFooter, TablePagination } from '@material-ui/core';
import moment from 'moment';
import useStyles from './styles';

export default function StreamsTableForPixHighlightsVas({
  data = [],
  // eslint-disable-next-line no-console
  onChangePage = () => console.log('page change clicked'),
  count = 10,
  rowsPerPage = 12,
  page = 1,
}) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Sr No.</TableCell>
            <TableCell>Title</TableCell>
            <TableCell align="center">Stream Key</TableCell>
            <TableCell align="center">HL Types</TableCell>
            <TableCell align="center">Start Time</TableCell>
            <TableCell align="center">End Time</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Mobile</TableCell>
            <TableCell align="center">otp</TableCell>
            <TableCell align="center">MP4 Link</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data &&
            data.map((row, index) => {
              let highlightTypesString = '';
              const highlightTypes = row.highlight_types || [];

              highlightTypes.forEach(ht => {
                if (ht.type === 'game') {
                  highlightTypesString += `${ht.subtype} `;
                } else {
                  highlightTypesString += `${ht.type} `;
                }
              });

              return (
                <TableRow key={row._id}>
                  <TableCell align="center">
                    {page * rowsPerPage + index + 1}
                  </TableCell>
                  <TableCell
                    style={{ width: '350px', wordBreak: 'break-word' }}
                    component="th"
                    scope="row"
                  >
                    {`${row.title}`}
                  </TableCell>
                  <TableCell
                    style={{ width: '350px', wordBreak: 'break-word' }}
                    align="center"
                  >
                    {row.stream_key || ''}
                  </TableCell>
                  <TableCell
                    style={{ width: '350px', wordBreak: 'break-word' }}
                    align="center"
                  >
                    {highlightTypesString}
                  </TableCell>
                  <TableCell
                    style={{ width: '350px', wordBreak: 'break-word' }}
                    align="center"
                  >
                    {moment(new Date(row.start_timestamp))
                      .format('LLL')
                      .replace(',', '')}
                  </TableCell>
                  <TableCell
                    style={{ width: '350px', wordBreak: 'break-word' }}
                    align="center"
                  >
                    {moment(new Date(row.end_timestamp))
                      .format('LLL')
                      .replace(',', '')}
                  </TableCell>
                  <TableCell
                    style={{ width: '350px', wordBreak: 'break-word' }}
                    align="center"
                  >
                    {`${row.highlight_mixer_vas_status}`}
                  </TableCell>
                  <TableCell
                    style={{ width: '350px', wordBreak: 'break-word' }}
                    align="center"
                  >
                    {`${row.login_mobile}`}
                  </TableCell>
                  <TableCell
                    style={{ width: '350px', wordBreak: 'break-word' }}
                    align="center"
                  >
                    {`${row.login_otp}`}
                  </TableCell>
                  <TableCell
                    style={{ width: '350px', wordBreak: 'break-word' }}
                    align="center"
                  >
                    {row.mp4_link ? (
                      <a
                        href={row.mp4_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Link
                      </a>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rows
              colSpan={12}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={onChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
