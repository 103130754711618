import { useEffect, useState } from 'react';
import useManagedHomeFeedStore from '../../Store/useManagedHomeFeedStore';
import { getSectionVideos } from '../../API/ManagedHomeFeed';

const useSectionVideos = () => {
  const [videos, setVideos] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const { managedHomeFeed, selectedSectionKey } = useManagedHomeFeedStore();

  useEffect(() => {
    if (!managedHomeFeed || !selectedSectionKey) {
      setVideos([]);
      return;
    }
    const fetchVideos = async () => {
      try {
        const { videos: videoList } = await getSectionVideos({
          sectionKey: selectedSectionKey,
          categoryCode: managedHomeFeed?._id,
        });

        setVideos(videoList);
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchVideos();
  }, [managedHomeFeed, selectedSectionKey]);

  return {
    videos,
    loading,
    error,
  };
};

export default useSectionVideos;
