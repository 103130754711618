import React from 'react';

import { Switch, Route, useRouteMatch } from 'react-router-dom';
import UserSearch from './UserSearch';
import UserPermission from './UserPermission';

export default function Permission() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <UserSearch />
      </Route>
      <Route exact path={`${path}/:user_id`}>
        <UserPermission />
      </Route>
    </Switch>
  );
}
