import React, { useState, useEffect, useRef, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';

import { useParams } from 'react-router-dom';
import { DropzoneDialog } from 'material-ui-dropzone';
import swal from '@sweetalert/with-react';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import AthleteDetailsForm from '../../../../Components/Forms/AthletDetailsForm';
import UploadingDialog from '../../../../Components/UploadingDialog';
import updateAthleteProfile from '../../../../API/users/updateAthleteProfile';
import ImageBlock from '../../../../Components/ImageBlock';
import Contexts from '../../../../Contexts';
import getSingleAthlete from '../../../../API/athletes/getSingleAthlete';
import uploadUserImage from '../../../../API/users/uploadUserImage';
import setAthletePreferredSection from '../../../../API/athletes/setAthletePreferredSection';
import DummyPlayerControls from './DummyPlayerControls';
import upgradeToNonDummy from '../../../../API/athletes/upgradeToNonDummy';

export default function AthleteDetails() {
  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);
  const { addToast } = useToasts();

  const imageType = {
    PROFILE: 'profile',
    BACKGROUND: 'background',
  };
  const ref = useRef({
    userId: 'default',
    editImageType: imageType.PROFILE,
  });

  const classes = useStyles();
  const [formFields, setFormFields] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [imageUploadOpen, setImageUploadOpen] = useState(false);
  const [uploadingDialogOpen, setUploadingDialogOpen] = useState(false);
  // path params
  const { playerId } = useParams();

  const getUserData = async id => {
    showLoadingSpinner(true);
    try {
      const data = await getSingleAthlete(id);
      setFormFields(data);
      setDataFetched(true);
      setProfileImage(data.profileImage);
    } catch (err) {
      showMessageDialog('ERROR', err.message);
    } finally {
      showLoadingSpinner(false);
    }
  };

  useEffect(() => {
    if (playerId) {
      getUserData(playerId);
    }
  }, []);

  const uploadImage = async file => {
    const resp = await uploadUserImage({ userId: formFields._id, image: file });

    if (resp) {
      if (ref.current.editImageType === imageType.PROFILE) {
        setProfileImage(resp);
        addToast('Image Saved Successfully', {
          appearance: 'success',
          autoDismiss: 500,
        });
      }
      setUploadingDialogOpen(false);
    }
  };

  const handleImageDialogSave = files => {
    const file = files[0];

    setImageUploadOpen(false);
    setUploadingDialogOpen(true);
    uploadImage(file);
  };

  const handleImageDialogClose = () => {
    setImageUploadOpen(false);
  };

  const handleEditImage = type => {
    ref.current.editImageType = type;
    setImageUploadOpen(true);
  };

  const handleSubmit = async (values, setSubmitting) => {
    try {
      showLoadingSpinner(true);
      const data = await updateAthleteProfile(formFields._id, values);
      const resp = await setAthletePreferredSection({
        playerId,
        preferredHomeSection: values.preferred_section,
      });
      if (data) {
        showLoadingSpinner(false);

        setSubmitting(false);
        addToast('Saved Successfully', {
          appearance: 'success',
          autoDismiss: 500,
        });
      }
    } catch (error) {
      showLoadingSpinner(false);
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
      setSubmitting(false);
    }
  };

  const handleDummyPlayerUpgrade = async () => {
    try {
      const isFirstYesSelected = await swal({
        title: 'UPGRADE: Add Mobile Number',
        text:
          'Mobile number can be set only if it does not already belong to an athlete on the platform. ',
        icon: 'info',
        buttons: true,
      });

      if (!isFirstYesSelected) return;

      let mobile;

      do {
        // eslint-disable-next-line no-await-in-loop
        mobile = await swal({
          title: 'Mobile Number',
          icon: 'info',
          content: {
            element: 'input',
            attributes: {
              placeholder: '10 digit indian mobile number',
              type: 'number',
            },
          },
          buttons: true,
        });
      } while (mobile !== null && !isNaN(mobile) && mobile.length !== 10);

      if (!mobile) return;

      const isSecondYesSelected = await swal({
        title: 'Are You Sure?',
        text: `Setting ${mobile} for this dummy player. This player will not be a dummy anymore.`,
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      });

      if (!isSecondYesSelected) return;

      showLoadingSpinner(true);
      await upgradeToNonDummy({ playerId, mobile });
      window.location.reload();
    } catch (error) {
      addToast(error.message, {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
    showLoadingSpinner(false);
  };

  return (
    <div className={classes.container}>
      {dataFetched && (
        <>
          <Typography variant="caption">
            OLD URL: https://sportvot.com/athlete/id=
            {encodeURIComponent(formFields._id)}
          </Typography>
          <DummyPlayerControls
            isDummyPlayer={formFields.is_dummy_plr}
            onUpgradeClick={handleDummyPlayerUpgrade}
          />
          <AthleteDetailsForm {...formFields} onSubmit={handleSubmit} />
        </>
      )}

      <div className={classes.imageBlock}>
        <ImageBlock
          buttonLabel="Edit"
          blockLabel="Profile Image"
          onButtonPress={() => handleEditImage(imageType.PROFILE)}
          imgSrc={profileImage}
        />
      </div>

      <DropzoneDialog
        open={imageUploadOpen}
        onSave={handleImageDialogSave}
        acceptedFiles={['image/*']}
        showPreviews={true}
        maxFileSize={5000000}
        filesLimit={1}
        onClose={handleImageDialogClose}
        showAlerts={false}
      />
      <UploadingDialog open={uploadingDialogOpen} />
    </div>
  );
}
