import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Paper, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from '../Styles';
import InfoLablel from './InfoLablel';
import BottomButtons from './BottomButtons';

const VerificationCardContainer = ({
  canUpload,
  canStream,
  mobile,
  email,
  onSubmit,
  setCanUpload,
  setCanStream,
  isProcessed,
  channelName,
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={3}>
      <div className={classes.root}>
        <div className={classes.topLabels}>
          <InfoLablel caption="Email" value={email} />
          <InfoLablel caption="Channel Name" value={channelName} />
          <InfoLablel caption="Mobile" value={mobile} />
        </div>
        <Divider />
        <FormGroup row className={classes.switchContainer}>
          <FormControlLabel
            control={
              <Switch
                checked={canUpload}
                onChange={e => setCanUpload(e.target.checked)}
                value="canUploadLocal"
                color="primary"
              />
            }
            label="Can Upload"
          />
          <FormControlLabel
            control={
              <Switch
                checked={canStream}
                onChange={e => setCanStream(e.target.checked)}
                value="canUploadLocal"
                color="primary"
              />
            }
            label="Can Stream"
          />
        </FormGroup>
        <Divider />
        <BottomButtons onSubmit={onSubmit} disabled={isProcessed} />
      </div>
    </Paper>
  );
};

VerificationCardContainer.propTypes = {
  canUpload: PropTypes.bool,
  canStream: PropTypes.bool,
  email: PropTypes.string,
  mobile: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  setCanStream: PropTypes.func.isRequired,
  setCanUpload: PropTypes.func.isRequired,
  isProcessed: PropTypes.bool,
  channelName: PropTypes.string,
};

VerificationCardContainer.defaultProps = {
  canUpload: false,
  canStream: false,
  email: 'gomes.luis479@gmail.com',
  mobile: '785412368',
  isProcessed: true,
  channelName: 'my channel',
};

export default VerificationCardContainer;
