import React from 'react';
import CostCalcForm from '../../../Components/Forms/CostCalcForm';
import { calculateCosts } from '../../../Utils/cost-calculator/calculateCosts';
import swal from 'sweetalert';
import { CostRequest } from '../../../Utils/cost-calculator/types';

export default function ServerCosts() {
  const onSubmit = values => {
    console.log('submitted ', values);

    let valuesDeepCopy = JSON.parse(JSON.stringify(values));

    let {
      isUsingAutoHighlights,
      isUsingManualClipping,
      isDistributedOnSvOttNonYT,
    } = valuesDeepCopy;

    const request: CostRequest = valuesDeepCopy;

    // modify some things based on flags

    let isUsingHls =
      isDistributedOnSvOttNonYT ||
      (isUsingAutoHighlights && !isUsingManualClipping) ||
      isUsingAutoHighlights ||
      isUsingManualClipping;

    if (!isUsingAutoHighlights && !isUsingManualClipping) {
      request.minsOfHighlightsPerHour = 0;
      request.numberOfHighlightClippersAndQA = 0;
      request.numberOfHighlightDownloads = 0;
    }

    if (isUsingAutoHighlights && !isUsingManualClipping) {
      request.numberOfHighlightClippersAndQA = 0;
      request.numberOfHighlightDownloads = 0;
    }

    if (!isDistributedOnSvOttNonYT) {
      request.avgNumberOfUsersWhoWillWatchFullStream = 0;
    }

    if (!isUsingHls) {
      request.hlsQualityLevels = [];
    }

    const outputString = calculateCosts(request);
    swal({ text: outputString });
  };

  return <CostCalcForm onSubmit={onSubmit} />;
}
