import axiosInstance from '../../axiosInstance';

const getSingleAthlete = async (id = '') => {
  const response = await axiosInstance(`/api/admin/v2/players/${id}`);

  const { player } = response.data.data;

  return player;
};

export default getSingleAthlete;
