import { BASE_URL } from '../constants';

const updateLeaugeData = async (postBody, tourn_id = '') => {
  try {
    const postData = {
      ...postBody,
    };

    const response = await fetch(
      `${BASE_URL}/api/v2/tournaments/${tourn_id}/L/rules`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      },
    );

    const json = await response.json();

    if (json.error) {
      throw new Error(JSON.stringify(json.error));
    }

    return json;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default updateLeaugeData;
