import React from 'react';
import { Form, useFormikContext } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';
import FormikSubmit from '../MuiFormikComponents/FormikSubmit';
import FormikDropDown from '../MuiFormikComponents/FormikDropDown';
import TwoTeamFormField from './StatsField';

const FormFields = ({ sportList, matchId = 'default', sportKeyMap }) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const { sport } = values;

  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{matchId}</Typography>
          </Grid>

          <Grid item xs={12}>
            <FormikDropDown
              label="Sport"
              valueDisplayNameMap={sportList}
              name="sport"
            />
          </Grid>

          {sport &&
            sportKeyMap[sport].map(item => (
              <TwoTeamFormField
                key={item.label}
                label={item.label}
                name={item.name}
              />
            ))}

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="save" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

FormFields.propTypes = {
  sportKeyMap: PropTypes.object,
  sportList: PropTypes.array,
};

// for testing this format is required from parent
FormFields.defaultValues = {
  sportKeyMap: {
    s1: [
      { label: 'hi', name: 'hk_1' },
      { label: 'yo', name: 'hk_2' },
    ],
    s2: [
      { label: 'ih', homeKey: 'hk_3' },
      { label: 'oy', homeKey: 'hk_4' },
    ],
  },

  sportList: [
    { value: 's1', displayName: 'S1 label' },
    { value: 's2', displayName: 'S2 label' },
  ],
};

export default FormFields;
