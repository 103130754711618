import React, { useState } from 'react';
import { Button, Paper, TextField, Typography } from '@material-ui/core';
import useStyles from './styles';

export default function SearchOrAddNewPMS({ onSearch, onAddNew }) {
  const classes = useStyles();
  const [matchId, setMatchId] = useState('');

  return (
    <div className={classes.root}>
      <Paper className={classes.root} elevation={3}>
        <Typography variant="h6">Search / Add-New by Match ID</Typography>
        <Typography variant="caption">
          Match id can be found in STREAM details
        </Typography>
        <TextField
          style={{ paddingTop: '10px', paddingBottom: '10px' }}
          placeholder="match id"
          variant="outlined"
          value={matchId}
          onChange={e => setMatchId(e.target.value)}
        />
        <div className={classes.buttonsContainer}>
          <Button
            style={{ marginLeft: '5px', marginRight: '5px' }}
            variant="contained"
            color="primary"
            onClick={() => onSearch(matchId)}
          >
            Search
          </Button>

          <Button
            style={{ marginLeft: '5px', marginRight: '5px' }}
            variant="outlined"
            color="primary"
            onClick={() => onAddNew(matchId)}
          >
            + Add New
          </Button>
        </div>
      </Paper>
    </div>
  );
}
