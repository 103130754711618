import axiosInstance from '../../axiosInstance';

const setEventLogo = async (image, event_id = 'default') => {
  // eslint-disable-next-line no-undef
  const formData = new FormData();
  formData.append('image', image);

  const response = await axiosInstance.patch(
    `/api/admin/v2/events/${event_id}/logo`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  const { data } = response.data;

  return data.imageUrl;
};

export default setEventLogo;
