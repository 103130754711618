import React from 'react';
import { Form } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikSubmit from '../MuiFormikComponents/FormikSubmit';
import FormikSwitch from '../MuiFormikComponents/FormikSwitch';
import { AUTHORITIES } from '../../../Utils/authorities';

const FormFields = () => {
  const classes = useStyles();

  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">User Permissions</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch
              label="Article Read"
              name={AUTHORITIES.ARTICLE.READ}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch
              label="Article Write"
              name={AUTHORITIES.ARTICLE.WRITE}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch label="Player Read" name={AUTHORITIES.PLAYER.READ} />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch
              label="Player Write"
              name={AUTHORITIES.PLAYER.WRITE}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch label="Team Read" name={AUTHORITIES.TEAM.READ} />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch label="Team Write" name={AUTHORITIES.TEAM.WRITE} />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch
              label="Team Player Read"
              name={AUTHORITIES.TEAM.PLAYER.READ}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch
              label="Team Player Write"
              name={AUTHORITIES.TEAM.PLAYER.WRITE}
            />
          </Grid>

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="SUBMIT" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
