import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormFields from './FormFields';

const AddTournamentForm = ({
  name = '',
  state = '',
  sport = '',
  start_date = '',
  end_date = '',
  type = '',
  is_team_based = false,
  onSubmit,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          name,
          state,
          sport,
          start_date,
          end_date,
          type,
          is_team_based,

          // optional fields
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Required'),
          state: Yup.string().required('Required'),
          sport: Yup.string().required('Required'),
          type: Yup.string().required('Required'),
          is_team_based: Yup.bool().required('Required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields />
      </Formik>
    </>
  );
};

export default AddTournamentForm;
