import axiosInstance from '../../axiosInstance';

const addArticleVideo = async (
  media,
  article_id = 'default',
  onUploadProgress = progress => console.log('progress', progress),
) => {
  try {
    const handleUploadProgress = progressEvent => {
      const uploadPercentage = progressEvent.loaded / progressEvent.total;
      onUploadProgress(uploadPercentage);
    };

    // eslint-disable-next-line no-undef
    const formData = new FormData();

    formData.append('media', media);

    await axiosInstance.post(
      `/api/admin/v2/articles/${article_id}/media`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: handleUploadProgress,
      },
    );
  } catch (err) {
    throw new Error(err.message);
  }
};

export default addArticleVideo;
