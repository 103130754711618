import React from 'react';
import { Box, Typography } from '@material-ui/core';

function CustomChip({ label = '', bgcolor = '#000', color = '#fff' }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      bgcolor={bgcolor}
      borderRadius={5}
      px={0.75}
      py={0.1}
      width="fit-content"
    >
      <Typography
        variant="caption"
        style={{
          color,
          fontSize: 9,
          padding: 0,
          margin: 0,
          textTransform: 'uppercase',
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}

export default CustomChip;
