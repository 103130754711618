import React from 'react';
import useStyles from './styles';
import { Button } from '@material-ui/core';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';

export default function TournamentRules() {
  const history = useHistory();
  const classes = useStyles();
  const { type, teamId } = useParams();
  console.log('type', type);

  const onMangeLBTN = () => {
    history.push({
      pathname: `/tournament/${teamId}/${type}/rule/Lform`,
    });
  };
  const onMangeKOBTN = () => {
    history.push({
      pathname: `/tournament/${teamId}/${type}/rule/KOform`,
    });
  };
  const onMangeLKOGBTN = () => {
    history.push({
      pathname: `/tournament/${teamId}/${type}/rule/LKOGform`,
    });
  };

  return (
    <div className={classes.root}>
      {type === 'L' ? (
        <Button
          className={classes.buttons}
          variant="contained"
          onClick={onMangeLBTN}
        >
          League Details
        </Button>
      ) : type === 'KO' ? (
        <Button
          className={classes.buttons}
          variant="contained"
          onClick={onMangeKOBTN}
        >
          Knockout Details
        </Button>
      ) : type === 'LKOG' ? (
        <Button
          className={classes.buttons}
          variant="contained"
          onClick={onMangeLKOGBTN}
        >
          League + Knockout Details
        </Button>
      ) : (
        <Button className={classes.buttons} variant="contained">
          No Details
        </Button>
      )}
    </div>
  );
}
