import React from 'react';
import { Formik } from 'formik';
import FormFields from './FormFields';

const getInitialValuesMap = (config = []) => {
  const retVal = {};
  for (let i = 0; i < config.length; i += 1) {
    const element = config[i];
    const { key, initialValue = 0 } = element;

    if (key === 'out_type') {
      // special cricket handling,
      // out_type is an enum with values
      // NOT_GIVEN_STRIKE, NOT_OUT, BOWLED, CAUGHT, LBW, STUMPED, RUN_OUT, HIT_WICKET
      retVal[key] = initialValue || '';
    } else if (key === 'is_clean_sheet') {
      // special football handling
      // this is a boolean field
      retVal[key] = !!initialValue;
    } else {
      retVal[key] = initialValue;
    }
  }
  return retVal;
};

/**
 *
 * formConfigAndInitialValues is of the an array of the following the following objects
 *
 * {
 *  key: <String>,
 *  displayName: <String>,
 *  isEditable: <Boolean>,
 *  initialValue: <Number>
 * }
 */
const AthleteMatchStatsForm = ({
  formConfigAndInitialValues = [],
  onSubmit,
}) => {
  return (
    <>
      <Formik
        initialValues={getInitialValuesMap(formConfigAndInitialValues)}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields formConfig={formConfigAndInitialValues} />
      </Formik>
    </>
  );
};

export default AthleteMatchStatsForm;
