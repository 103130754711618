import React, { useContext } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { Form, useFormikContext } from 'formik';
import FormikDropDown from '../../MuiFormikComponents/FormikDropDown';
import FormikTextInput from '../../MuiFormikComponents/FormikTextInput';
import Contexts from '../../../../Contexts';
import TemplateSearchFormComponent from '../../MuiFormikComponents/TemplateSearchFormComponent';
import ChannelSearchFormComponent from '../../MuiFormikComponents/ChannelSearchFormComponent';
import {
  SCORING_MODE,
  PUBLISHED_STATUS,
} from '../../../../Utils/templateEngine';
import FormikSubmit from '../../MuiFormikComponents/FormikSubmit';

const FormFields = () => {
  const { values } = useFormikContext();
  const { categoriesDropDownList } = useContext(Contexts);

  return (
    <Form>
      <Typography variant="caption" gutterBottom>
        {values.is_thumbnail_template
          ? 'Thumbnail Filters'
          : 'Template Filters'}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6} lg={3}>
          <FormikTextInput
            label="TEMPLATE ID"
            name="t_id"
            fullWidth
            size="small"
          />
        </Grid>
        <Grid item xs={12} md={6} lg={2}>
          <FormikDropDown
            label="SPORT"
            valueDisplayNameMap={categoriesDropDownList}
            name="sport"
            fullWidth
            size="small"
          />
        </Grid>

        <Grid item xs={12} md={6} lg={2}>
          <FormikDropDown
            valueDisplayNameMap={SCORING_MODE}
            label="SCORING MODE"
            name="scoring_mode"
            noneAllowed={false}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={2}>
          <FormikDropDown
            valueDisplayNameMap={PUBLISHED_STATUS}
            label="STATUS"
            name="is_ready"
            noneAllowed={false}
          />
        </Grid>

        <Grid item xs={12} md={6} lg={3}>
          <ChannelSearchFormComponent label="Created By" name="creator_id" />
        </Grid>
      </Grid>

      <Box mt={1}>
        <Typography variant="caption" gutterBottom>
          {values.is_thumbnail_template
            ? 'Filtered Thumbnails'
            : 'Filtered Templates'}
        </Typography>
        <TemplateSearchFormComponent
          label={values.is_thumbnail_template ? 'Thumbnail' : 'Template'}
          name="template_id"
          sport={values.sport}
          creator_id={values.creator_id}
          scoring_mode={values.scoring_mode}
          is_ready={values.is_ready}
          template_id={values.t_id}
          is_thumbnail_template={values.is_thumbnail_template}
        />
      </Box>

      <Grid item xs={12} style={{ marginTop: 10 }}>
        <FormikSubmit
          submitText={
            values.is_thumbnail_template
              ? 'WHITELIST THUMBNAIL'
              : 'WHITELIST TEMPLATE'
          }
          name="submitButon"
        />
      </Grid>
    </Form>
  );
};

export default FormFields;
