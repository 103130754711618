import axiosInstance from '../../axiosInstance';

const addUserProfile = async (
  mobile = '',
  {
    first_name,
    last_name,
    description,
    channel_verified,
    can_upload,
    can_stream,
    display_streams,
    display_videos,
  },
) => {
  const response = await axiosInstance.post(`/api/admin/v2/users/`, {
    first_name,
    last_name,
    description,
    channel_verified,
    can_upload,
    can_stream,
    display_streams,
    display_videos,
    mobile,
  });

  const { user_id } = response.data.data;
  return user_id;
};

export default addUserProfile;
