import axiosInstance from '../../axiosInstance';

const getUserByIdV2 = async ({ userId }) => {
  const response = await axiosInstance.get(`/api/v2/users/${userId}`);

  const { data } = response.data;

  return data.user;
};

export default getUserByIdV2;
