import React from 'react';
import { Formik } from 'formik';
import FormFields from './FormFields';

const StreamSearchForm = ({
  stream_key = '',
  title = '',
  channel_id = '',
  preferred_section = '',
  category = '',
  onSubmit = () => console.log('events search form clicked'),
  is_live = false,
  event_id,
  _id = '',
  article_type = '',

  // jio stuff
  is_commentary = false,
  is_jio_stb = false,
  is_ppv = false,
  is_sub = false,
  is_ad = false,
  is_jio_store = false,
  is_jio_tv = false,
  is_all_jio = false,
  is_global = false,
  isForManagedHomeFeed = false,
  start_date = '',
  end_date = '',
  include_demo_and_trial_streams = false,
  should_sort_by_views = false,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          stream_key,
          title,
          channel_id,
          preferred_section,
          category,
          is_live,
          event_id,
          _id,
          article_type,

          // jio stuff
          is_commentary,
          is_jio_stb,
          is_ppv,
          is_sub,
          is_ad,
          is_jio_store,
          is_jio_tv,
          is_all_jio,
          is_global,
          start_date,
          end_date,
          include_demo_and_trial_streams:
            include_demo_and_trial_streams || isForManagedHomeFeed,
          should_sort_by_views: isForManagedHomeFeed,
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields isForManagedHomeFeed={isForManagedHomeFeed} />
      </Formik>
    </>
  );
};

export default StreamSearchForm;
