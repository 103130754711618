import axiosInstance from '../../axiosInstance';

const getEventById = async eventId => {
  const response = await axiosInstance(`/api/v2/events/${eventId}`);

  const { data } = response.data;

  return data.event;
};

export default getEventById;
