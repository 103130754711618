import axiosInstance from '../../axiosInstance';

const getArticles = async (
  article_id = '',
  category = '',
  channel_id = '',
  title = '',
  preferred_section = '',
  article_type = '',

  creation_source,
  stream_key,

  // jio stuff
  is_commentary = false,
  is_jio_stb = false,
  is_ppv = false,
  is_sub = false,
  is_ad = false,
  is_jio_store = false,
  is_jio_tv = false,
  is_all_jio = false,
  is_global = false,

  page = '1',
  limit = 10,
  sort = '_id:desc',
) => {
  const response = await axiosInstance.get(`/api/admin/v2/articles/`, {
    params: {
      article_id,
      category,
      channel_id,
      title,
      preferred_section,
      article_type,

      creation_source,
      stream_key,

      page,
      limit,

      cache: 'false',

      // jio stuff
      is_commentary: is_commentary || null,
      is_jio_stb: is_jio_stb || null,
      is_ppv: is_ppv || null,
      is_sub: is_sub || null,
      is_ad: is_ad || null,
      is_jio_store: is_jio_store || null,
      is_jio_tv: is_jio_tv || null,
      is_all_jio: is_all_jio || null,
      is_global: is_global || null,
      sort,
    },
  });

  const { data } = response.data;

  return data;
};

export default getArticles;
