import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, TableFooter, TablePagination } from '@material-ui/core';
import useStyles from './styles';

export default function UsersTable({
  data = [],
  // eslint-disable-next-line no-console
  onRowViewClicked = () => console.log('view row clicked'),
  onRowGoLivePermissionToggled = () =>
    // eslint-disable-next-line no-console
    console.log(' row  onRowGoLivePermissionToggled clicked'),
  // eslint-disable-next-line no-console
  onChangePage = () => console.log('page change clicked'),
  count = 10,
  rowsPerPage = 12,
  page = 1,
}) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Sr No.</TableCell>
            <TableCell>Channel Name</TableCell>
            <TableCell align="center">Mobile</TableCell>
            <TableCell align="center">User Id</TableCell>
            <TableCell align="center">Full Name</TableCell>
            <TableCell align="center">Special Privilege?</TableCell>
            <TableCell align="center">Action</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data &&
            data.map((row, index) => {
              const hasGoLiveAuthority = row.authorities.includes(
                'CAN_GO_LIVE',
              );

              return (
                <TableRow key={row._id}>
                  <TableCell align="center">
                    {page * rowsPerPage + index + 1}
                  </TableCell>

                  <TableCell
                    style={{ width: '350px', wordBreak: 'break-word' }}
                    component="th"
                    scope="row"
                    index
                  >
                    {`${row.channel_name}`}
                  </TableCell>

                  <TableCell align="center">{row.mobile}</TableCell>

                  <TableCell align="center">{row._id}</TableCell>

                  <TableCell
                    style={{ width: '350px', wordBreak: 'break-word' }}
                    align="center"
                  >
                    {`${row.first_name} ${row.last_name}`}
                  </TableCell>

                  <TableCell align="center">{row.is_spa ? 'Y' : 'N'}</TableCell>

                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onRowViewClicked(row)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rows
              colSpan={12}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={onChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
