import React from 'react';
import { Formik } from 'formik';
import FormFields from './FormFields';

const EventSearchForm = ({
  name = '',
  state = '',
  sport = '',
  event_id = '',
  start_date = '',
  end_date = '',
  creator_id = '',
  onSubmit = () => console.log('events search form clicked'),
  isForManagedHomeFeed = false,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          name,
          state,
          sport,
          event_id,
          start_date,
          end_date,
          creator_id,
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields isForManagedHomeFeed={isForManagedHomeFeed} />
      </Formik>
    </>
  );
};

export default EventSearchForm;
