import React from 'react';

import { Switch, Route, useRouteMatch } from 'react-router-dom';

import AthleteSearch from './AthleteSearch/AthleteSearch';
import SelectedPlayer from './SelectedPlayer/SelectedPlayer';
import AthleteDetails from './AthleteDetails/AthleteDetails';
import AddPlayer from './AddPlayer/AddPlayer';
import AddPlayerForm from './AddPlayerForm/AddPlayerForm';
import RegularToAthleteForm from './RegularToAthleteForm/RegularToAthleteForm';
import FeaturedAthlete from './FeaturedAthlete';
import ListAthleteMatchStats from './AthleteStats/ListAthleteMatchStats/ListAthleteMatchStats';
import AthleteMatchStatsDetails from './AthleteStats/AthleteMatchStatsDetails/AthleteMatchStatsDetails';
import AddNewPMSPage from './AthleteStats/AddNewPMSPage/AddNewPMSPage';
import AthleteAggPMSDetails from './AthleteStats/AthleteAggPMSDetails/AthleteAggPMSDetails';
import AddNewAggPMS from './AthleteStats/AddNewAggPMS/AddNewAggPMS';

export default function AthleteManagment() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <AthleteSearch />
      </Route>

      <Route exact path={`${path}/add`}>
        <AddPlayer />
      </Route>
      <Route exact path={`${path}/add/newAthlete/:mobile`}>
        <AddPlayerForm />
      </Route>
      <Route exact path={`${path}/add/regularToAthlete/:mobile`}>
        <RegularToAthleteForm />
      </Route>

      <Route exact path={`${path}/:playerId`}>
        <SelectedPlayer />
      </Route>
      <Route exact path={`${path}/:playerId/details`}>
        <AthleteDetails />
      </Route>
      <Route exact path={`${path}/:playerId/featured`}>
        <FeaturedAthlete />
      </Route>

      {/* ------ Stats - Match Level --------- */}
      <Route exact path={`${path}/:playerId/match-stats`}>
        <ListAthleteMatchStats />
      </Route>

      <Route exact path={`${path}/:playerId/match-stats/:matchId`}>
        <AthleteMatchStatsDetails />
      </Route>

      <Route exact path={`${path}/:playerId/match-stats/:matchId/create`}>
        <AddNewPMSPage />
      </Route>

      {/* ------ Stats - Aggregated --------- */}
      <Route exact path={`${path}/:playerId/agg-match-stats`}>
        <AthleteAggPMSDetails />
      </Route>

      <Route exact path={`${path}/:playerId/agg-match-stats/create`}>
        <AddNewAggPMS />
      </Route>
    </Switch>
  );
}
