import React from 'react';

import Drawer from '@material-ui/core/Drawer';
import PropTypes from 'prop-types';
import SideList from './SideList';

const AppDrawer = ({
  open,
  setOpen,
  nameIconArray,
  handleSideBarItemClick,
}) => {
  return (
    <Drawer open={open} onClose={() => setOpen(false)}>
      <SideList
        setOpen={setOpen}
        nameIconArray={nameIconArray}
        handleSideBarItemClick={handleSideBarItemClick}
      />
    </Drawer>
  );
};

AppDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default AppDrawer;
