import React from 'react';
import { Form, useFormikContext } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikTextInput from '../MuiFormikComponents/FormikTextInput';
import FormikSubmit from '../MuiFormikComponents/FormikSubmit';

import FormikDropDown from '../MuiFormikComponents/FormikDropDown';
import ReactDatePicker from '../MuiFormikComponents/ReactDatePicker';
import FormikSwitch from '../MuiFormikComponents/FormikSwitch';

const FormFields = () => {
  const classes = useStyles();
  const { values } = useFormikContext();

  const showStreamingFields = values.studio_profile_type === 'PRODUCTION';
  const showFields = !!values.is_spa_enabled;

  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Special Privilege Account</Typography>
          </Grid>

          <Grid item xs={12}>
            <FormikSwitch
              label="Enable Special Privilege"
              name="is_spa_enabled"
            />
          </Grid>

          {showFields && (
            <Grid item xs={12}>
              <Typography variant="caption">
                TRIAL MODE: If enabled, the usage limits will be set in trial
                mode. In this mode any matches created will be tagged under
                TRIAL and will not show up on sportVot feeds if they go live.
                They can still view the stream using dedicated match links.
              </Typography>
            </Grid>
          )}

          {showFields && (
            <Grid item xs={12}>
              <Typography variant="caption">
                This matters more only in case you choose the SPORTING ORG
                profile type, since these will be allowed live streaming access
              </Typography>
            </Grid>
          )}

          {showFields && (
            <Grid item xs={12}>
              <FormikSwitch label="Trial Mode?" name="is_trial" />
            </Grid>
          )}

          {showFields && (
            <Grid item xs={12}>
              <FormikDropDown
                valueDisplayNameMap={[
                  { value: 'GRAPHICAL', displayName: 'Broadcaster' },
                  { value: 'PRODUCTION', displayName: 'Sporting Org' },
                ]}
                label="Profile Type"
                name="studio_profile_type"
              />
            </Grid>
          )}

          {showFields && (
            <Grid item xs={12}>
              <FormikTextInput
                type="number"
                label="Fixed OTP (6 DIGITS)"
                name="fixed_otp"
              />
            </Grid>
          )}

          {showFields && showStreamingFields && (
            <Grid item xs={12}>
              <FormikTextInput
                type="number"
                label="Steaming Minutes Balance"
                name="streaming_minutes_balance"
              />
            </Grid>
          )}

          {showFields && showStreamingFields && (
            <Grid item xs={12}>
              <FormikTextInput
                type="number"
                label="Max Concurrent Live Streams"
                name="max_concurrent_streams"
              />
            </Grid>
          )}

          {showFields && (
            <Grid item xs={12} sm={6} md={6}>
              <ReactDatePicker label="Valid Till" name="valid_till" />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="save" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
