import axiosInstance from '../../axiosInstance';

const setStreamTeams = async ({ streamId, homeTeamId, awayTeamId }) => {
  const response = await axiosInstance.post(
    `/api/admin/v2/streams/${streamId}/teams`,
    {
      home_team_id: homeTeamId,
      away_team_id: awayTeamId,
    },
  );

  const { data } = response.data;

  return data;
};

export default setStreamTeams;
