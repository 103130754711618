import axiosInstance from '../../axiosInstance';

const rejectChannelVerification = async id => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/verifications/channel/${id}/reject`,
  );

  const { data } = response.data;

  return data.channel_verification;
};

export default rejectChannelVerification;
