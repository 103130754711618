import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import useStyles from './Styles';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const PageTabs = ({ tabNameComponentMap, value, onChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={onChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {tabNameComponentMap.map((data, index) => (
            <Tab key={data.name} label={data.name} {...a11yProps(index)} />
          ))}
        </Tabs>
      </AppBar>
      {tabNameComponentMap.map((data, index) => (
        <TabPanel key={data.name} value={value} index={index}>
          {data.component}
        </TabPanel>
      ))}
    </div>
  );
};

PageTabs.propTypes = {
  tabNameComponentMap: PropTypes.array,
};

PageTabs.defaultProps = {
  tabNameComponentMap: [
    {
      name: 'tab1',
      component: <div>tab1</div>,
    },
    {
      name: 'tab2',
      component: <div>tab2</div>,
    },
  ],
};

export default PageTabs;
