import React, { useContext } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Contexts from '../../Contexts/Contexts';
import useStyles from './styles';
import { Button } from '@material-ui/core';

export default function TournamentTable({ data, TeamNavigate }) {
  const classes = useStyles();
  const context = useContext(Contexts);
  const { formConfig } = context;
  return (
    <div className={classes.tableContainer}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Sr no</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="center">Sport</TableCell>
              <TableCell align="center">State</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell component="th" scope="row">
                    {`${row.name}`}
                  </TableCell>
                  <TableCell align="center">
                    {(row.sport &&
                      formConfig['sport_data'][row.sport].displayName) ||
                      ''}
                  </TableCell>
                  <TableCell align="center">
                    {(formConfig['state_data'][row.state] &&
                      formConfig['state_data'][row.state].displayName) ||
                      ''}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => TeamNavigate(row.event_id)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
