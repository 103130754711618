import React from 'react';
import { Paper, Typography, Button } from '@material-ui/core';
import useStyles from './styles';

const FeaturedController = ({
  isFeatured = false,
  dataAvailable = false,
  onIsFeaturedToggle = () => console.log('status controller button clicked'),
  onDataAvailableToggle = () => console.log('status controller button clicked'),
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={3}>
      <div className={classes.statusControllerContainer}>
        <Typography variant="h6">Featured Athlete</Typography>

        <Button
          variant="outlined"
          color={isFeatured ? 'primary' : 'secondary'}
          onClick={onIsFeaturedToggle}
        >
          {isFeatured ? 'Yes' : 'No'}
        </Button>
      </div>
      <div className={classes.statusControllerContainer}>
        <Typography variant="h6">Data Available</Typography>

        <Button
          variant="outlined"
          color={dataAvailable ? 'primary' : 'secondary'}
          onClick={onDataAvailableToggle}
        >
          {dataAvailable ? 'Yes' : 'No'}
        </Button>
      </div>
    </Paper>
  );
};

export default FeaturedController;
