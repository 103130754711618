import React, { useState, useRef, useEffect, useContext } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import Add from '@material-ui/icons/Add';
import { IconButton, Typography } from '@material-ui/core';
import useStyles from './styles';
import EventSearchForm from '../../../../Components/Forms/SearchForms/EventSearchForm/EventSearchForm';
import getEvents from '../../../../API/events/getEvents';
import EventsTable from '../../../../Components/EventComponents/EventsTable';
import setIsActive from '../../../../API/events/setIsActive';
import Contexts from '../../../../Contexts';
import setIsOngoing from '../../../../API/events/setIsOngoing';
import setIsListed from '../../../../API/events/setisListed';
// eslint-disable-next-line import/extensions
import EventsTableFormHomeFeed from '../../../../Components/ManagedHomeFeed/AddEventDialog';

export default function EventsSearch({
  isForManagedHomeFeed = false,
  categoryCode = '',
}) {
  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);

  const classes = useStyles();
  const [eventsData, setEventsData] = useState([]);

  // for pagination
  const [count, setCount] = useState(0);

  // pageNo is also used in ref
  // this page no is for display purposes in the table pagination
  // the ref pageNo is to fetch new pages to avoid issue
  // of async setPage
  const [page, setPage] = useState(1);

  // setting the search params in ref
  // so that they can be resused in pagination
  const ref = useRef({
    name: '',
    sport: categoryCode,
    state: '',
    event_id: '',
    preferred_section: '',
    pageNo: 1,
    start_date: '',
    end_date: '',
    creator_id: '',
  });

  const { path } = useRouteMatch();

  const history = useHistory();

  const getData = async () => {
    showLoadingSpinner(true);
    try {
      const {
        name,
        sport,
        state,
        event_id,
        pageNo,
        preferred_section,
        start_date,
        end_date,
        creator_id,
      } = ref.current;
      const data = await getEvents(
        name,
        sport,
        state,
        event_id,
        preferred_section,
        pageNo,
        start_date,
        end_date,
        creator_id,
      );

      if (data) {
        setEventsData(data.events);
        setCount(data.total_count);
        showLoadingSpinner(false);
      }
    } catch (err) {
      showMessageDialog('ERROR', err.message);
      showLoadingSpinner(false);
    }
  };

  const onSubmit = async (values, setSubmitting) => {
    const {
      name,
      sport,
      state,
      event_id,
      preferred_section,
      start_date,
      end_date,
      creator_id,
    } = values;
    ref.current = {
      ...ref.current,
      name,
      sport,
      state,
      event_id,
      preferred_section,
      start_date,
      end_date,
      creator_id,
    };

    // reset pages
    setPage(1);
    ref.current.pageNo = 1;

    await getData();
    setSubmitting(false);
  };

  const handleRowViewClicked = row => {
    history.push({
      pathname: `${path}${row.event_id}`,
    });
  };

  // toggle status (is_active)
  const handleRowStatusClicked = async row => {
    const { event_id } = row;
    const temp = [];
    let foundMatch = false;
    let newStatus = null;

    if (event_id) {
      eventsData.forEach(elem => {
        if (elem.event_id && elem.event_id === event_id) {
          const { is_active } = elem;

          elem.is_active = is_active === 'Y' ? 'N' : 'Y';
          newStatus = elem.is_active;

          foundMatch = true;
          temp.push(elem);
        } else {
          temp.push(elem);
        }
      });
    }

    if (foundMatch) {
      showLoadingSpinner(true);

      const resp = await setIsActive(event_id, newStatus === 'Y');

      if (resp) {
        setEventsData(temp);
        showLoadingSpinner(false);
      }
    }
  };

  // toggle ongoing (is_ongoing)
  const handleRowOngoingClicked = async row => {
    const { event_id } = row;
    const temp = [];
    let foundMatch = false;
    let newStatus = null;

    if (event_id) {
      eventsData.forEach(elem => {
        if (elem.event_id && elem.event_id === event_id) {
          const { is_ongoing } = elem;

          elem.is_ongoing = is_ongoing === 'Y' ? 'N' : 'Y';
          newStatus = elem.is_ongoing;

          foundMatch = true;
          temp.push(elem);
        } else {
          temp.push(elem);
        }
      });
    }

    if (foundMatch) {
      showLoadingSpinner(true);

      const resp = await setIsOngoing(event_id, newStatus === 'Y');

      if (resp) {
        setEventsData(temp);
        showLoadingSpinner(false);
      }
    }
  };

  // toggle listed (is_listed)
  const handleRowListedClicked = async row => {
    const { event_id } = row;
    const temp = [];
    let foundMatch = false;
    let newStatus = null;

    if (event_id) {
      eventsData.forEach(elem => {
        if (elem.event_id && elem.event_id === event_id) {
          const { is_listed } = elem;

          elem.is_listed = !is_listed;
          newStatus = elem.is_listed;

          foundMatch = true;
          temp.push(elem);
        } else {
          temp.push(elem);
        }
      });
    }

    if (foundMatch) {
      showLoadingSpinner(true);

      const resp = await setIsListed(event_id, newStatus);

      if (resp) {
        setEventsData(temp);
        showLoadingSpinner(false);
      }
    }
  };

  const handlePageChange = (event, newPage) => {
    // newPage + 1 adjustment because our pages are not 0 indexed
    // hence when passing to the table too we pasee page - 1 and
    // not page
    setPage(newPage + 1);
    ref.current.pageNo = newPage + 1;
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.searchContainer}>
        <EventSearchForm
          onSubmit={onSubmit}
          isForManagedHomeFeed={isForManagedHomeFeed}
          sport={categoryCode}
        />
        {!isForManagedHomeFeed && (
          <div>
            <Typography variant="overline">Create New Event</Typography>
            <IconButton
              edge="start"
              className={classes.searchIcon}
              color="default"
              aria-label="menu"
              onClick={() => {
                history.push({
                  pathname: `${path}add`,
                });
              }}
            >
              <Add />
            </IconButton>
          </div>
        )}
      </div>

      {!isForManagedHomeFeed && eventsData.length > 0 ? (
        <EventsTable
          count={count}
          rowsPerPage={10}
          // not the page - 1 here. Becuase we dont use zero indexing
          page={page - 1}
          onChangePage={handlePageChange}
          data={eventsData}
          onRowViewClicked={row => handleRowViewClicked(row)}
          onRowStatusClicked={row => handleRowStatusClicked(row)}
          onRowOngoinglicked={row => handleRowOngoingClicked(row)}
          onRowListedClicked={row => handleRowListedClicked(row)}
        />
      ) : (
        <div />
      )}

      {isForManagedHomeFeed && eventsData.length > 0 && (
        <EventsTableFormHomeFeed
          totalCount={count}
          rowsPerPage={10}
          page={page - 1}
          onChangePage={handlePageChange}
          data={eventsData}
        />
      )}
    </div>
  );
}
