import React, { useState, useEffect, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import LeaugeKnockoutForm from '../../../../Components/Forms/LeaugeKnockoutForm';
import useStyles from './styles';
import Contexts from '../../../../Contexts';
import getTournamnetRules from '../../../../API/tournament/getTournamnetRules';
import { useParams, useHistory } from 'react-router-dom';
import updateLKOGData from '../../../../API/tournament/updateLKOGData';

export default function LeagueKnockoutDetails() {
  const classes = useStyles();
  const [formFields, setFormFields] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);
  const { teamId } = useParams();
  const { addToast } = useToasts();
  const history = useHistory();

  const getLeagueData = async () => {
    showLoadingSpinner(true);
    try {
      const data = await getTournamnetRules(teamId, 'LKOG');
      setFormFields(data);
      setDataFetched(true);
    } catch (err) {
      showMessageDialog('ERROR', err.message);
    } finally {
      showLoadingSpinner(false);
    }
  };

  useEffect(() => {
    if (teamId) {
      getLeagueData();
    }
  }, []);

  const handleSubmit = async (values, setSubmitting) => {
    showLoadingSpinner(true);

    try {
      const resp = await updateLKOGData(values, teamId);

      if (resp.data) {
        showLoadingSpinner(false);
        setSubmitting(false);
        addToast('Successfully Saved', {
          appearance: 'success',
          autoDismiss: 500,
        });
        history.goBack();
      }
    } catch (error) {
      showLoadingSpinner(false);
      setSubmitting(false);
      addToast('Error While Saving', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  return (
    <div className={classes.container}>
      {dataFetched && (
        <LeaugeKnockoutForm {...formFields} onSubmit={handleSubmit} />
      )}
    </div>
  );
}
