import axiosInstance from '../../axiosInstance';

const updateFeaturedImage = async (image, userId, imageKey, lang = 'eng') => {
  // eslint-disable-next-line no-undef
  const formData = new FormData();
  formData.append('image', image);

  const response = await axiosInstance.patch(
    `/api/admin/v2/players/${userId}/featured-images/${imageKey}`,
    formData,
    {
      headers: { 'Content-Type': 'application/json' },
      params: {
        lang,
      },
    },
  );

  const { data } = response.data;

  return data;
};

export default updateFeaturedImage;
