import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-around',
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 30,
  },
  selectedTeamContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  invalidMatchType: {
    marginTop: '100px',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
