import axiosInstance from '../../axiosInstance';

const updateTeamLogo = async (image, teamId) => {
  // eslint-disable-next-line no-undef
  const formData = new FormData();
  formData.append('image', image);

  const response = await axiosInstance.patch(
    `/api/admin/v2/teams/${teamId}/logo`,
    formData,
  );
  const { data } = response.data;

  return data;
};

export default updateTeamLogo;
