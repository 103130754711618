import React, { useContext } from 'react';
import { Form, useFormikContext } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikSubmit from '../../MuiFormikComponents/FormikSubmit';
import FormikTextInput from '../../MuiFormikComponents/FormikTextInput';
import FormikDropDown from '../../MuiFormikComponents/FormikDropDown';
import Contexts from '../../../../Contexts';
import {
  generateSportBasedPositionDropdown,
  generateStateDropDownList,
  generateSportDropDownList,
  generateHomeSectionsListFromCategory,
} from '../../../../Utils/dropDownListGenerators';
import FormikSwitch from '../../MuiFormikComponents/FormikSwitch';

const FormFields = ({ isForManagedHomeFeed = false }) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const context = useContext(Contexts);
  const { formConfig, sectionDetails } = context;

  return (
    <Form>
      <Paper
        elevation={3}
        className={classes.formContainer}
        style={{
          maxWidth: !isForManagedHomeFeed ? '600px' : '100%',
          marginTop: !isForManagedHomeFeed && 0,
        }}
      >
        <Grid container spacing={3}>
          {!isForManagedHomeFeed && (
            <Grid item xs={12}>
              <Typography variant="h4">Search Athletes</Typography>
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={6} lg={isForManagedHomeFeed ? 3 : 6}>
            <FormikTextInput label="First Name" name="first_name" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={isForManagedHomeFeed ? 3 : 6}>
            <FormikTextInput label="Last Name" name="last_name" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={isForManagedHomeFeed ? 3 : 6}>
            <FormikTextInput label="Mobile" name="mobile" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={isForManagedHomeFeed ? 3 : 6}>
            <FormikTextInput label="Player Id" name="playerId" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={isForManagedHomeFeed ? 3 : 6}>
            <FormikDropDown
              valueDisplayNameMap={generateStateDropDownList(formConfig)}
              label="State"
              name="state"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={isForManagedHomeFeed ? 3 : 6}>
            <FormikDropDown
              valueDisplayNameMap={generateSportDropDownList(formConfig)}
              label="Sport"
              name="sport"
            />
          </Grid>

          {values.sport && !isForManagedHomeFeed && (
            <Grid item xs={12} sm={6} md={6} lg={isForManagedHomeFeed ? 3 : 6}>
              <FormikDropDown
                valueDisplayNameMap={generateHomeSectionsListFromCategory(
                  sectionDetails,
                  values.sport,
                  ['player', 'story'],
                )}
                label="Home Section"
                name="preferred_section"
              />
            </Grid>
          )}

          {values.sport && (
            <Grid item xs={12} sm={6} md={6} lg={isForManagedHomeFeed ? 3 : 6}>
              <FormikDropDown
                valueDisplayNameMap={generateSportBasedPositionDropdown(
                  formConfig,
                  values.sport,
                )}
                label="Position"
                name="position"
              />
            </Grid>
          )}

          {!isForManagedHomeFeed && (
            <Grid item xs={12} sm={6} md={6} lg={isForManagedHomeFeed ? 3 : 6}>
              <FormikSwitch label="Featured" name="is_featured" />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="Search" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
