import React, { useEffect, useContext, useState } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import useStyles from './styles';
import Contexts from '../../../../Contexts';
import LinkedAthleteTable from '../../../../Components/CommonTables/LInkedAtheteTable/LinkedAthleteTable';
import getStreamByStreamKey from '../../../../API/scoreCard/getStreamByStreamKey';
import getAthletesByIds from '../../../../API/athletes/getAthletesByIds';
import removeStreamLinkedPlayer from '../../../../API/streams/removeStreamLinkedPlayer';

export default function StreamLinkedPlayers() {
  const [athletesData, setAthletesData] = useState([]);
  const [streamData, setStreamData] = useState(null);
  const { showLoadingSpinner } = useContext(Contexts);
  const classes = useStyles();
  const { streamId } = useParams();
  const { addToast } = useToasts();

  const history = useHistory();

  const getStream = async () => {
    showLoadingSpinner(true);
    const fetchedStream = await getStreamByStreamKey(streamId);
    setStreamData(fetchedStream);
    showLoadingSpinner(false);
  };

  const setLinkedAthletesDataFromStream = async stream => {
    showLoadingSpinner(true);
    try {
      const athletes = await getAthletesByIds(stream.linked_athletes);
      setAthletesData(athletes);
    } catch (error) {
      console.error(error);
    }
    showLoadingSpinner(false);
  };

  useEffect(() => {
    getStream();
  }, []);

  useEffect(() => {
    if (streamData) {
      setLinkedAthletesDataFromStream(streamData);
    }
  }, [streamData]);

  const handleDeletePlayer = async playerId => {
    showLoadingSpinner(true);
    try {
      const updatedStream = await removeStreamLinkedPlayer(streamId, playerId);
      setStreamData(updatedStream);
      showLoadingSpinner(false);

      addToast('deleted player', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } catch (e) {
      showLoadingSpinner(false);
      addToast('error in deleting player', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex', textAlign: 'center' }}>
        <Typography variant="caption">
          This stream will show up in the videos tab of these linked players
        </Typography>
      </div>

      <div style={{ display: 'flex' }}>
        <p style={{ marginRight: 10 }}>Add Players</p>
        <IconButton
          edge="start"
          className={classes.searchIcon}
          color="default"
          aria-label="menu"
          onClick={() => {
            history.push({
              pathname: `/streams/${streamId}/linked-players/add`,
            });
          }}
        >
          <Add />
        </IconButton>
      </div>

      {athletesData.length > 0 && (
        <div className={classes.editContainer}>
          <LinkedAthleteTable
            data={athletesData}
            onDeletePlayer={handleDeletePlayer}
          />
        </div>
      )}
    </div>
  );
}
