import {
  CLOUDFRONT_BASE_URL,
  DEFAULT_PROFILE_IMAGE_CHANNELS,
} from '../API/constants';

export const getImageUrl = (
  img,
  defaultImage = DEFAULT_PROFILE_IMAGE_CHANNELS,
) => {
  if (!img) return defaultImage;

  if (!img.includes || !img.includes('http'))
    return `${CLOUDFRONT_BASE_URL}/${img}`;

  return img;
};

export const getViewCountInDifferentFormat = count => {
  const viewCount = count;
  if (viewCount > 1000000) {
    return `${(viewCount / 1000000).toFixed(1)}M`;
  }
  if (viewCount > 1000) {
    return `${(viewCount / 1000).toFixed(1)}K`;
  }
  return viewCount;
};

export default null;
