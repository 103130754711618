import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import useStyles from './Styles';

export default function BottomButtons({ disabled, onSubmit }) {
  const classes = useStyles();

  return (
    <div className={classes.ButtonsContainer}>
      <Button
        color="primary"
        disabled={disabled}
        onClick={() => onSubmit(true)}
      >
        Approve
      </Button>
      <Button
        color="secondary"
        disabled={disabled}
        onClick={() => onSubmit(false)}
      >
        Reject
      </Button>
    </div>
  );
}

BottomButtons.propTypes = {
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

BottomButtons.defaultProps = {
  disabled: false,
};
