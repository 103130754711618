import React, { useState, useEffect, useRef, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';

import { useParams } from 'react-router-dom';
import { DropzoneDialog } from 'material-ui-dropzone';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import ImageBlock from '../../../../Components/ImageBlock';
import Contexts from '../../../../Contexts';
import getSingleAthlete from '../../../../API/athletes/getSingleAthlete';
import updateFeaturedImage from '../../../../API/athletes/updateFeaturedImage';
import LargeTextInputBlock from '../../../../Components/LargeTextInputBlock';
import updateFeaturedTextSection from '../../../../API/athletes/updateFeaturedTextSection';
import FeaturedController from './FeaturedController';
import updateAthleteIsFeatured from '../../../../API/athletes/updateAthleteIsFeatured';
import LanguageSelector from './LanguageSelector';
import updateFeaturedAthleteVideosList from '../../../../API/athletes/updateFeaturedAthleteVideosList';
import updateFeaturedDataIsAvailable from '../../../../API/athletes/updateFeaturedDataIsAvailable';
import updateFeaturedVideo from '../../../../API/athletes/updateFeaturedVideo';

const FeaturedAthlete = () => {
  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);
  const { addToast } = useToasts();

  const classes = useStyles();
  const [formFields, setFormFields] = useState({});
  const [dataFetched, setDataFetched] = useState(false);

  const [featuredImages, setFeaturedImages] = useState({});

  const [imageUploadOpen, setImageUploadOpen] = useState(false);
  // path params
  const { playerId } = useParams();

  // for language based fields
  const [language, setLanguage] = useState('eng');
  // used to force a render of language fields
  const [showLangFields, setShowLangFields] = useState(true);

  const IMAGE_KEYS = {
    MAIN_IMAGE: 'MAIN_IMAGE',
  };

  const TEXT_KEYS = {
    NAME_MAIN: 'NAME_MAIN',
    NAME_SUBTITLE: 'NAME_SUBTITLE',

    SHORT_DESC: 'SHORT_DESC',
    LONG_DESC: 'LONG_DESC',
  };

  const ref = useRef({
    currentImageKey: IMAGE_KEYS.IMAGE_TYPE_2,
    userData: {},
    selectedLanguage: 'eng',
  });

  const getUserData = async id => {
    showLoadingSpinner(true);
    try {
      const data = await getSingleAthlete(id);
      setFormFields(data);

      // for use in text sections
      ref.current.userData = data;
      setDataFetched(true);

      // initialize featured images
      const temp1 = {};
      Object.keys(IMAGE_KEYS).forEach(key => {
        const tempImageKey = IMAGE_KEYS[key];
        temp1[tempImageKey] = data[
          `featured_data_${ref.current.selectedLanguage}`
        ]
          ? data[`featured_data_${ref.current.selectedLanguage}`][tempImageKey]
            ? data[`featured_data_${ref.current.selectedLanguage}`][
                tempImageKey
              ].value
            : ''
          : '';
      });

      setFeaturedImages(temp1);
    } catch (err) {
      showMessageDialog('ERROR', err.message);
    } finally {
      showLoadingSpinner(false);
    }
  };

  useEffect(() => {
    if (playerId) {
      getUserData(playerId);
    }
  }, []);

  const uploadImage = async file => {
    try {
      const data = await updateFeaturedImage(
        file,
        formFields._id,
        ref.current.currentImageKey,
        ref.current.selectedLanguage,
      );

      const imagePath = data.image_path;

      setFeaturedImages({
        ...featuredImages,
        [ref.current.currentImageKey]: imagePath,
      });

      addToast('Image Saved Successfully', {
        appearance: 'success',
        autoDismiss: 500,
      });

      showLoadingSpinner(false);
    } catch (err) {
      showLoadingSpinner(false);
      showMessageDialog('ERROR', err.message);
    }
  };

  const handleImageDialogSave = files => {
    const file = files[0];

    setImageUploadOpen(false);
    showLoadingSpinner(true);
    uploadImage(file);
  };

  const handleTextSectionSave = async (updatedText, sectionKey) => {
    try {
      showLoadingSpinner(true);

      await updateFeaturedTextSection(
        updatedText,
        formFields._id,
        sectionKey,
        ref.current.selectedLanguage,
      );
      showLoadingSpinner(false);

      addToast('Saved', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } catch (err) {
      showLoadingSpinner(false);
      showMessageDialog('ERROR', err.message);
    }
  };

  const onMainFeaturedVideoSave = async articleId => {
    try {
      showLoadingSpinner(true);

      const updatedUserDoc = await updateFeaturedVideo(
        formFields._id,
        articleId,
      );

      setFormFields(updatedUserDoc);

      showLoadingSpinner(false);

      addToast('Saved', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } catch (err) {
      showLoadingSpinner(false);
      showMessageDialog('ERROR', err.message);
    }
  };

  const handleImageDialogClose = () => {
    setImageUploadOpen(false);
  };

  const handleEditImage = type => {
    ref.current.currentImageKey = type;
    setImageUploadOpen(true);
  };

  const onIsFeaturedToggle = async () => {
    try {
      showLoadingSpinner(true);
      const updatedUserDoc = await updateAthleteIsFeatured(
        formFields._id,
        !formFields.is_featured,
      );

      setFormFields(updatedUserDoc);
      showLoadingSpinner(false);
    } catch (error) {
      showLoadingSpinner(false);
      showMessageDialog('ERROR', error.message);
    }
  };

  const onDataAvailableToggle = async () => {
    try {
      showLoadingSpinner(true);
      const updatedUserDoc = await updateFeaturedDataIsAvailable(
        formFields._id,
        !formFields.is_featured_data_available,
      );

      setFormFields(updatedUserDoc);
      showLoadingSpinner(false);
    } catch (error) {
      showLoadingSpinner(false);
      showMessageDialog('ERROR', error.message);
    }
  };

  const getInitalTextValue = key => {
    const featuredData =
      ref.current.userData[`featured_data_${ref.current.selectedLanguage}`];
    if (!featuredData) return '';

    const keyDoc = featuredData[key];
    if (!keyDoc) return '';

    const { value } = keyDoc;
    if (!value) return '';

    return value;
  };

  const renderLanguageDependentFields = () => (
    <>
      <div className={classes.imageBlock}>
        <LargeTextInputBlock
          blockLabel="Short Description"
          buttonLabel="save"
          initialText={getInitalTextValue(TEXT_KEYS.SHORT_DESC)}
          onButtonPress={text =>
            handleTextSectionSave(text, TEXT_KEYS.SHORT_DESC)
          }
        />
      </div>

      <div className={classes.imageBlock}>
        <ImageBlock
          buttonLabel="Edit"
          blockLabel="Main Image"
          onButtonPress={() => handleEditImage(IMAGE_KEYS.MAIN_IMAGE)}
          imgSrc={featuredImages[IMAGE_KEYS.MAIN_IMAGE]}
        />
      </div>

      <div className={classes.imageBlock}>
        <LargeTextInputBlock
          blockLabel="Long Description"
          buttonLabel="save"
          initialText={getInitalTextValue(TEXT_KEYS.LONG_DESC)}
          onButtonPress={text =>
            handleTextSectionSave(text, TEXT_KEYS.LONG_DESC)
          }
        />
      </div>
    </>
  );

  const onChangeLanguage = async lang => {
    if (lang !== ref.current.selectedLanguage) {
      setShowLangFields(false);
      ref.current.selectedLanguage = lang;
      await getUserData(playerId);
      setLanguage(lang);
      setShowLangFields(true);
    }
  };
  const renderScreen = () => (
    <div className={classes.container}>
      <div style={{ paddingBottom: '15px' }}>
        <a href="https://docs.google.com/document/d/1F6kAm21SPtAR6NqIb5pDXWXSiof4hV0_kp9hz0bBR-c/edit?usp=sharing">
          <Typography variant="h6">Reference Doc</Typography>
        </a>
      </div>

      <FeaturedController
        isFeatured={!!formFields.is_featured}
        dataAvailable={!!formFields.is_featured_data_available}
        onDataAvailableToggle={onDataAvailableToggle}
        onIsFeaturedToggle={onIsFeaturedToggle}
      />

      <div className={classes.imageBlock}>
        <LargeTextInputBlock
          blockLabel="Main Featured Video Article Id"
          buttonLabel="save"
          initialText={
            formFields.featured_video ? formFields.featured_video._id : ''
          }
          onButtonPress={text => onMainFeaturedVideoSave(text)}
        />
      </div>

      <div className={classes.imageBlock}>
        <LanguageSelector
          selectedLanguage={language}
          onLanguageSelect={onChangeLanguage}
        />
      </div>

      {showLangFields && renderLanguageDependentFields()}

      <DropzoneDialog
        open={imageUploadOpen}
        onSave={handleImageDialogSave}
        acceptedFiles={['image/*']}
        showPreviews
        maxFileSize={5000000}
        filesLimit={1}
        onClose={handleImageDialogClose}
        showAlerts={false}
      />
    </div>
  );

  return dataFetched && renderScreen();
};

export default FeaturedAthlete;
