import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
  switchContainer: {
    margin: theme.spacing(3),
  },
  topLabels: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  InfoLabelContainer: {
    padding: theme.spacing(1),
  },
  ButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: theme.spacing(1),
  },
}));

export default useStyles;
