import React, { useState, useEffect, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useParams, useHistory } from 'react-router-dom';
import { BASE_URL } from '../../../../API/constants';
import TournamentTeamPlayerTable from '../../../../Components/TournamentComponenet/TournamentTeamPlayerTable';
import useStyles from './styles';
import { Typography, Button } from '@material-ui/core';
import addPlayerToTeam from '../../../../API/tournament/addPlayerToTeam';
import Contexts from '../../../../Contexts';

export default function TournamentTeamsPlayer() {
  const { tournamentId, teamId } = useParams();
  const [player, setPlayer] = useState([]);
  const [inSquad, setInSquad] = useState([]);
  const classes = useStyles();
  const { addToast } = useToasts();
  const context = useContext(Contexts);
  const { showLoadingSpinner } = context;
  const history = useHistory();

  const getTeamAllPlayer = async () => {
    const response = await fetch(`${BASE_URL}/api/teams/${teamId}/players`);
    const { data } = await response.json();
    setPlayer(data);
  };

  const getTeamSquad = async () => {
    const response = await fetch(
      `${BASE_URL}/api/v2/tournaments/${tournamentId}/teams/${teamId}/squad`,
    );
    const { data } = await response.json();
    if (data) {
      const newData = [];
      data.map(el => {
        newData.push(el.player_id);
      });
      setInSquad(newData);
    } else {
      setInSquad([]);
    }
  };

  useEffect(() => {
    getTeamAllPlayer();
    getTeamSquad();
  }, []);

  const setSelectPlayer = id => {
    if (inSquad.includes(id)) {
      const newInSquad = [...inSquad];
      const index = newInSquad.indexOf(id);
      newInSquad.splice(index, 1);

      setInSquad(newInSquad);
    } else {
      setInSquad([...inSquad, id]);
    }
  };

  const addTeamSquad = async () => {
    showLoadingSpinner(true);

    const postData = {
      data: inSquad,
    };
    try {
      const resp = await addPlayerToTeam(tournamentId, teamId, postData);
      if (resp.data) {
        showLoadingSpinner(false);
        addToast('Successfully Saved ', {
          appearance: 'success',
          autoDismiss: 500,
        });
        history.goBack();
      }
    } catch (error) {
      showLoadingSpinner(false);
      addToast('Error While Saving', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };
  return (
    <div className={classes.root}>
      <Typography variant="h4">Teams Players</Typography>

      {player && (
        <TournamentTeamPlayerTable
          data={player}
          setSelectPlayer={setSelectPlayer}
          inSquad={inSquad}
        />
      )}
      <Button
        variant="contained"
        style={{ marginTop: 20 }}
        onClick={addTeamSquad}
      >
        save
      </Button>
    </div>
  );
}
