import axiosInstance from '../../axiosInstance';

const removeStreamLinkedPlayer = async (streamId, athleteId) => {
  const response = await axiosInstance.delete(
    `/api/admin/v2/streams/${streamId}/linked-athletes/${athleteId}`,
  );

  const { data } = response.data;

  return data.stream;
};

export default removeStreamLinkedPlayer;
