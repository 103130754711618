import axiosInstance from '../../axiosInstance';
import {
  GetGpuTaskBySvTaskTypeAndLinkedEntityIdRequest,
  GpuTask,
} from './types';

const getGpuTaskBySvTaskTypeAndLinkedEntityId = async (
  params: GetGpuTaskBySvTaskTypeAndLinkedEntityIdRequest,
) => {
  const { linkedEntityId, svTaskType } = params;
  const resp = await axiosInstance.get(
    `/api/admin/v2/gpu-tasks/sv-task-type/${svTaskType}/linked-entity-id/${linkedEntityId}`,
  );

  return resp.data.data.gpu_task as GpuTask | null;
};

const addStreamDownloadAttempt = async (matchId: string) => {
  await axiosInstance.post(`/api/admin/v2/gpu-tasks/stream-mp4-generation`, {
    matchId,
  });
};

export const GpuTaskApis = {
  getGpuTaskBySvTaskTypeAndLinkedEntityId,
  addStreamDownloadAttempt,
};
