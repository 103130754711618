import React, { useState, useEffect, useContext, useRef } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import Add from '@material-ui/icons/Add';
import { IconButton, Typography } from '@material-ui/core';
import swal from '@sweetalert/with-react';
import { useToasts } from 'react-toast-notifications';
import useStyles from './styles';
import TeamsTable from '../../../../Components/TeamsComponent/TeamsTable';
import TeamsSearchForm from '../../../../Components/Forms/SearchForms/TeamsSearchForm/TeamsSearchForm';
import Contexts from '../../../../Contexts';
import getTeams from '../../../../API/teams/getTeams';
import updateTeamIsEnabled from '../../../../API/teams/updateTeamIsEnabled';

/**
 * actionLabel is the CTA label in the rendered tabled
 * navigates to team details by default but calls onActionClick instead
 * if passed like onActionCLick(row)
 *
 */
export default function TeamsSearch({
  actionLabel = 'View',
  onActionClick = null,
}) {
  const classes = useStyles();

  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);

  const [teamsData, setTeamsData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const { path } = useRouteMatch();
  const history = useHistory();

  const { addToast } = useToasts();

  const ref = useRef({
    name: '',
    sport: '',
    state: '',
    team_id: '',
    creator_id: '',
    pageNo: 1,
    enabled_only: false,
    disabled_only: false,
  });

  const getData = async () => {
    showLoadingSpinner(true);
    try {
      const {
        name,
        sport,
        state,
        team_id,
        pageNo,
        creator_id,
        enabled_only,
        disabled_only,
      } = ref.current;

      const data = await getTeams(
        name,
        sport,
        state,
        team_id,
        creator_id,
        pageNo,
        10,
        enabled_only,
        disabled_only,
      );

      if (data) {
        setTeamsData(data.teams);
        setCount(data.total_count);
        showLoadingSpinner(false);
      }
    } catch (err) {
      showMessageDialog('ERROR', err.message);
      showLoadingSpinner(false);
    }
  };

  const onSubmit = async (values, setSubmitting) => {
    const {
      name,
      sport,
      state,
      team_id,
      creator_id,
      enabled_only,
      disabled_only,
    } = values;
    ref.current = {
      ...ref.current,
      name,
      sport,
      state,
      team_id,
      creator_id,
      enabled_only,
      disabled_only,
    };

    // reset pages
    setPage(1);
    ref.current.pageNo = 1;

    await getData();
    setSubmitting(false);
  };

  const handleRowActionClick = team => {
    if (onActionClick) {
      onActionClick(team);
      return;
    }

    // default behaviour
    history.push({
      pathname: `${path}${team.team_id}`,
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
    ref.current.pageNo = newPage + 1;
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const handleRowStatusClicked = async row => {
    try {
      if (row.is_enabled) {
        const isYesSelected = await swal({
          title: 'Are you sure? ',
          text: `WARNING: Disabling a team will REMOVE all it's players too`,
          icon: 'warning',
          buttons: true,
          dangerMode: true,
        });

        if (!isYesSelected) return;
      }

      showLoadingSpinner(true);
      const { team_id, is_enabled } = row;

      const { team: updateTeam } = await updateTeamIsEnabled(
        team_id,
        !is_enabled,
      );

      let newTeamsData = [];

      newTeamsData = teamsData.map(e => {
        const currentTeamId = e.team_id;
        if (team_id === currentTeamId) {
          return updateTeam;
        }

        return e;
      });

      setTeamsData(newTeamsData);
      addToast('Success', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } catch (error) {
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    } finally {
      showLoadingSpinner(false);
    }
  };

  return (
    <div className={classes.root}>
      <TeamsSearchForm onSubmit={onSubmit} />

      <div>
        <Typography variant="overline">Add Team</Typography>
        <IconButton
          edge="start"
          className={classes.searchIcon}
          color="default"
          aria-label="menu"
          onClick={() => {
            history.push({
              pathname: `/teams/add`,
            });
          }}
        >
          <Add />
        </IconButton>
      </div>

      <div className={classes.addContainer}>
        <TeamsTable
          actionLabel={actionLabel}
          data={teamsData}
          onRowActionClicked={handleRowActionClick}
          count={count}
          page={page - 1}
          onChangePage={handlePageChange}
          onRowStatusClicked={handleRowStatusClicked}
          rowsPerPage={10}
        />
      </div>
    </div>
  );
}
