import React from 'react';
import { Form } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikTextInput from '../MuiFormikComponents/FormikTextInput';
import FormikSubmit from '../MuiFormikComponents/FormikSubmit';

const FormFields = () => {
  const classes = useStyles();

  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Leauge Details</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="No Of Teams *" name="NoT" type="number" />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="No Of Rounds *" name="NoR" type="number" />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput
              label="Points Per Win *"
              name="PPW"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput
              label="Points Per Draw *"
              name="PPD"
              type="number"
            />
          </Grid>

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="save" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
