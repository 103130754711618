import axiosInstance from '../../axiosInstance';

const createEvent = async (
  name = '',
  sport = '',
  state = '',
  start_date = '',
  end_date = '',
  location = '',
  district = '',
  description = '',
  organised_by = '',
  is_sport = false,
  preferred_section = 'none',
  event_league_config,
  is_auto_highlights_required = false,
  window_seconds_before_scoring_event = 0,
  window_seconds_after_scoring_event = 0,
) => {
  const response = await axiosInstance.post(`/api/admin/v2/events/`, {
    name,
    sport,
    state,
    start_date,
    end_date,
    location,
    district,
    description,
    organised_by,
    is_sport,
    preferred_section,
    event_league_config,
    is_auto_highlights_required,
    window_seconds_before_scoring_event,
    window_seconds_after_scoring_event,
  });

  return response.data;
};

export default createEvent;
