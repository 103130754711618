import axiosInstance from '../../axiosInstance';

const getUserDoc = async () => {
  try {
    const response = await axiosInstance(`/api/v2/users/me`);

    return response.data.data.user;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default getUserDoc;
