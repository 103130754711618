import React from 'react';
import { Form, useFormikContext } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './Styles';
import FormikTextInput from '../MuiFormikComponents/FormikTextInput';
import FormikSwitch from '../MuiFormikComponents/FormikSwitch';
import FormikSubmit from '../MuiFormikComponents/FormikSubmit';
import FormikDropDown from '../MuiFormikComponents/FormikDropDown';
import CommonUserFields from './CommonUserFields';
import AthleteFields from './AthleteFields';

const FormFields = ({ displayChannelField }) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4"> User Details</Typography>
          </Grid>

          <CommonUserFields displayChannelField={displayChannelField} />

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="save" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
