import axiosInstance from '../../axiosInstance';

const grantGoLivePermission = async ({ userId }) => {
  const response = await axiosInstance.post(
    `/api/admin-actions/v2/special-user-permissions/can-go-live/${userId}`,
  );

  const { data } = response.data;

  return data;
};

export default grantGoLivePermission;
