import { useEffect, useState } from 'react';
import { getPixellotTenants } from '../../API/tenants';

export type Tenant = {
  user_id: string;
  tenant: string;
  mobile: string;
  fixed_otp: number;
  is_ignored_in_all_tenants_billing: boolean;
  joined_stream_enhancer_vas: string[];
  joined_overlay_provider_vas: string[];
};

const usePixellotTenants = () => {
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const data = await getPixellotTenants();
        setTenants(data.tenants);
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTenants();
  }, []);

  return {
    tenants,
    loading,
    error,
  };
};

export default usePixellotTenants;
