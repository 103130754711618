import React, { useEffect, useState } from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import FormikTextInput from '../Forms/MuiFormikComponents/FormikTextInput';
import FormikSubmitAutoHighlights from './FormikSubmitAutoHighlights';
import FormikSwitch from '../Forms/MuiFormikComponents/FormikSwitch';
import useStyles from './styles';
import setAutoHighlightsConfig from '../../API/events/setAutoHighlightsConfig';
import getEventById from '../../API/events/getEventById';

const AutoHighlightBlock = ({ eventId }) => {
  const handleSubmitAutoHighLights = values => {
    console.log(values);
    const respAutoHighlightConfig = setAutoHighlightsConfig(
      eventId,
      values.is_auto_highlights_required,
      values.is_auto_highlights_required_live_stream,
      parseInt(values.window_seconds_before_scoring_event, 10),
      parseInt(values.window_seconds_after_scoring_event, 10),
    );
    console.log(respAutoHighlightConfig);
  };

  const classes = useStyles();

  const [
    isPostAutoHighlightsRequired,
    setIsPostAutoHighlightsRequired,
  ] = useState(true);

  const [
    isLiveAutoHighlightsRequired,
    setIsLiveAutoHighlightsRequired,
  ] = useState(true);

  const [
    windowSecondsBeforeScoringEvent,
    setWindowSecondsBeforeScoringEvent,
  ] = useState(16);

  const [
    windowSecondsAfterScoringEvent,
    setWindowSecondsAfterScoringEvent,
  ] = useState(4);

  const [dataFetched, setDataFetched] = useState(false);

  useEffect(async () => {
    // const respEventData = getEventById(eventId);
    if (!eventId) return;
    const event = await getEventById(eventId);
    if (!event) return;
    setIsPostAutoHighlightsRequired(event.is_auto_highlights_required);
    setIsLiveAutoHighlightsRequired(
      event.is_auto_highlights_required_live_stream,
    );
    setWindowSecondsBeforeScoringEvent(
      event.window_seconds_before_scoring_event,
    );
    setWindowSecondsAfterScoringEvent(event.window_seconds_after_scoring_event);
    setDataFetched(true);
    console.log(event);
  }, []);

  if (dataFetched) {
    return (
      <Paper elevation={3} className={classes.formContainer}>
        <Formik
          initialValues={{
            is_auto_highlights_required: isPostAutoHighlightsRequired,
            is_auto_highlights_required_live_stream: isLiveAutoHighlightsRequired,
            window_seconds_before_scoring_event: windowSecondsBeforeScoringEvent,
            window_seconds_after_scoring_event: windowSecondsAfterScoringEvent,
          }}
          onSubmit={values => {
            handleSubmitAutoHighLights(values);
          }}
          validationSchema={Yup.object({
            is_auto_highlights_required: Yup.boolean().required('Required'),
            is_auto_highlights_required_live_stream: Yup.boolean().required(
              'Required',
            ),
            window_seconds_before_scoring_event: Yup.number()
              .required()
              .positive()
              .integer()
              .required('Required'),
            window_seconds_after_scoring_event: Yup.number()
              .required()
              .positive()
              .integer()
              .required('Required'),
          })}
        >
          <Form onChange={Formik.handleSubmitAutoHighLights}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h4">Auto Highlights Config</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormikTextInput
                  label="Window Seconds Before Scoring Event *"
                  name="window_seconds_before_scoring_event"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormikTextInput
                  label="Window Seconds After Scoring Event *"
                  name="window_seconds_after_scoring_event"
                  type="number"
                />
              </Grid>

              {/* hidden coz its not relevant anymore after highlights september 2023 updates */}
              <Grid hidden item xs={12} sm={6} md={6}>
                <FormikSwitch
                  label="Is Post Match Auto Highlights Required *"
                  name="is_auto_highlights_required"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikSwitch
                  label="Is Live Match Auto Highlights Required *"
                  name="is_auto_highlights_required_live_stream"
                />
              </Grid>
              <Grid item xs={12}>
                <FormikSubmitAutoHighlights
                  name="submitButon"
                  submitText="save"
                />
                {/* <Button
                  variant="contained"
                  onClick={handleSubmitAutoHighLights}
                >
                  save
                </Button> */}
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </Paper>
    );
  }
  return <></>;
};

export default AutoHighlightBlock;
