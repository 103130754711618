import { useEffect, useState } from 'react';
import { GameResult } from '../../API/OverrideGameResult/types';
import { getOverrideGameResult } from '../../API/OverrideGameResult';

const useOverrideGameResult = (matchId: string) => {
  const [gameResult, setGameResult] = useState<GameResult>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<any>(null);

  const fetchGameResult = async () => {
    try {
      const { game_result } = await getOverrideGameResult(matchId);
      setGameResult(game_result);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchGameResult();
  }, [matchId]);

  return {
    gameResult,
    isLoading,
    error,
    refetchGameResult: fetchGameResult,
  };
};

export default useOverrideGameResult;
