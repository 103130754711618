import { FORM_CONFIG_BASE_URL } from '../constants';

const getAthleteMatchStatsConfig = async () => {
  try {
    const response = await fetch(
      `${FORM_CONFIG_BASE_URL}/athleteMatchStatsConfig.json`,
    );

    const json = await response.json();

    return json;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default getAthleteMatchStatsConfig;
