import React from 'react';
import { Formik } from 'formik';
import FormFields from './FormFields';

const CostCalcForm = ({ onSubmit = values => {} }) => {
  return (
    <>
      <Formik
        initialValues={{
          // -------- server controlled --------------------

          previewAudioBitrateKbps: 192,
          previewVideoBitrateKbps: 2000, // 480p previews for video inputs

          hlsQualityLevels: [
            { bitrateKbps: 5000, name: '1080p' },
            { bitrateKbps: 2800, name: '720p' },
            { bitrateKbps: 1400, name: '480p' },
          ],

          s3Region: 'us-east-1',

          streamS3StorageFAMonths: 1,
          streamS3StorageIFAMonths: 3,
          streamS3StorageAIAMonths: 8,

          highlightS3StorageFAMonths: 1,
          highlightS3StorageIFAMonths: 3,
          highlightS3StorageAIAMonths: 8,

          hlsSegmentDurationSeconds: 6,

          // ------ user determined --------------------------

          serverRegion: 'ap-south-1',
          audioCommentators: 1,
          producers: 1,
          videoInputs: 2,

          outputDestinations: [{ bitrateKbps: 5000, region: 'internet' }],

          // ------- manual clipping related ------------
          // set to zero if not using manual clipping

          numberOfHighlightClippersAndQA: 1,

          numberOfHighlightDownloads: 1,

          minsOfHighlightsPerHour: 10,

          // --------- cdn related ---------------

          avgNumberOfUsersWhoWillWatchFullStream: 200,

          // visibility toggles

          isUsingAutoHighlights: false,
          isUsingManualClipping: false,
          isDistributedOnSvOttNonYT: false,

          isBackendSettingsEnabled: false,
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
          setSubmitting(false);
        }}
      >
        <FormFields />
      </Formik>
    </>
  );
};

export default CostCalcForm;
