import React from 'react';
import { Form, useFormikContext } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikTextInput from '../MuiFormikComponents/FormikTextInput';
import FormikSubmit from '../MuiFormikComponents/FormikSubmit';
import FormikDropDown from '../MuiFormikComponents/FormikDropDown';

const getTextInputLabel = type => {
  switch (type) {
    case 'STREAM':
      return 'Match ID';

    case 'ARTICLE':
      return 'Article ID';

    default:
      return 'invalid type';
  }
};

const FormFields = () => {
  const classes = useStyles();

  const { values } = useFormikContext();

  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4"> Details</Typography>
          </Grid>

          <Grid item xs={12}>
            <FormikDropDown
              valueDisplayNameMap={[
                { value: 'STREAM', displayName: 'Stream' },
                { value: 'ARTICLE', displayName: 'Article' },
              ]}
              label="Type"
              name="type"
            />
          </Grid>

          {values.type && values.type === 'STREAM' && (
            <Grid item xs={12}>
              <Typography variant="caption">
                Match ID can be found in details page of Streams (search streams
                -&gt; view -&gt; manage details)
              </Typography>
            </Grid>
          )}

          {values.type && values.type === 'ARTICLE' && (
            <Grid item xs={12}>
              <Typography variant="caption">
                Article ID can be found in details page of Articles (search
                articles -&gt; view -&gt; manage details)
              </Typography>
            </Grid>
          )}

          {values.type && (
            <Grid item xs={12}>
              <FormikTextInput
                label={getTextInputLabel(values.type)}
                name="content_id"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="save" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
