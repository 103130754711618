import React, { useState, useEffect, useContext, useRef } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';
import Add from '@material-ui/icons/Add';
import { IconButton, Typography } from '@material-ui/core';
import useStyles from './styles';
import TeamsTable from '../../../../Components/TeamsComponent/TeamsTable';
import TeamsSearchForm from '../../../../Components/Forms/SearchForms/TeamsSearchForm/TeamsSearchForm';
import Contexts from '../../../../Contexts';
import getTeams from '../../../../API/teams/getTeams';
import getTournamentTeams from '../../../../API/tournament/getTournamentTeams';
import addSingleTouramentTeams from '../../../../API/tournament/addSingleTouramentTeams';
import deleteTournamentTeam from '../../../../API/tournament/deleteTournamentTeam';

export default function AddTeams() {
  const classes = useStyles();
  const { teamId } = useParams();
  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);
  const [currentSquad, setCurrentSquad] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const { addToast } = useToasts();

  const { path } = useRouteMatch();
  const history = useHistory();

  const ref = useRef({
    name: '',
    sport: '',
    state: '',
    team_id: '',
    pageNo: 1,
  });

  const getData = async () => {
    showLoadingSpinner(true);
    try {
      const { name, sport, state, team_id, pageNo } = ref.current;

      const data = await getTeams(name, sport, state, team_id, pageNo, 10);

      if (data) {
        setTeamsData(data.teams);
        setCount(data.total_count);
        showLoadingSpinner(false);
      }
    } catch (err) {
      showMessageDialog('ERROR', err.message);
      showLoadingSpinner(false);
    }
  };

  const onSubmit = async (values, setSubmitting) => {
    const { name, sport, state, team_id } = values;
    ref.current = {
      ...ref.current,
      name,
      sport,
      state,
      team_id,
    };

    // reset pages
    setPage(1);
    ref.current.pageNo = 1;

    await getData();
    setSubmitting(false);
  };

  const handleRowActionClick = async team => {
    if (currentSquad.includes(team.team_id)) {
      try {
        const res = await deleteTournamentTeam(teamId, team.team_id);
        if (res.data) {
          addToast('Successfully Removed Player', {
            appearance: 'success',
            autoDismiss: 500,
          });
          getAllTeamSquadData();
        }
      } catch (error) {
        addToast('Error While Removing Player', {
          appearance: 'error',
          autoDismiss: 500,
        });
      }
    } else {
      try {
        const selectedPlayer = {
          team_id: team.team_id,
        };
        const res = await addSingleTouramentTeams(teamId, selectedPlayer);
        if (res.data) {
          addToast('Successfully Added', {
            appearance: 'success',
            autoDismiss: 500,
          });
          getAllTeamSquadData();
        }
      } catch (error) {
        addToast('Error while adding player', {
          appearance: 'error',
          autoDismiss: 500,
        });
      }
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
    ref.current.pageNo = newPage + 1;
    getData();
  };

  const getAllTeamSquadData = async () => {
    const res = await getTournamentTeams(teamId);
    if (res) {
      const data = [];
      res.map(el => {
        data.push(el.team_id);
      });
      setCurrentSquad(data);
    }
  };

  useEffect(() => {
    getData();
    getAllTeamSquadData();
  }, []);

  return (
    <div className={classes.root}>
      <TeamsSearchForm onSubmit={onSubmit} />

      <div>
        <Typography variant="overline">Add Team</Typography>
        <IconButton
          edge="start"
          className={classes.searchIcon}
          color="default"
          aria-label="menu"
          onClick={() => {
            history.push({
              pathname: `/teams/add`,
            });
          }}
        >
          <Add />
        </IconButton>
      </div>

      <div className={classes.addContainer}>
        <TeamsTable
          inSquad={currentSquad}
          data={teamsData}
          onRowActionClicked={handleRowActionClick}
          count={count}
          page={page - 1}
          onChangePage={handlePageChange}
          rowsPerPage={10}
        />
      </div>
    </div>
  );
}
