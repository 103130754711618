import axiosInstance from '../../axiosInstance';

const getUsers = async ({
  page = 1,
  limit = 10,
  firstName = '',
  lastName = '',
  includesAuthority,
  id,
  mobile = '',
  spa_only,
}) => {
  const response = await axiosInstance.get(`/api/admin/v2/users/`, {
    params: {
      page,
      limit,
      includes_authority: includesAuthority || null,
      ids: id || null,
      mobile,
      first_name: firstName,
      last_name: lastName,
      spa_only,
    },
  });

  const { data } = response.data;

  return data;
};

export default getUsers;
