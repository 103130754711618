import React from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputLabel,
} from '@material-ui/core';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import useStyles from './styles';

const FormikDropDown = ({
  label,
  valueDisplayNameMap,
  variant = 'standard',
  ...props
}) => {
  const [field, meta] = useField({ ...props });
  const classes = useStyles();

  return (
    <FormControl
      fullWidth
      className={classes.formControl}
      error={!!(meta.touched && meta.error)}
      variant={variant}
    >
      <InputLabel>{label}</InputLabel>
      <Select {...field} {...props} className={classes.selectEmpty}>
        <MenuItem value="">None</MenuItem>

        {valueDisplayNameMap.map(item => (
          <MenuItem key={item.value} value={item.value}>
            {item.displayName}
          </MenuItem>
        ))}
      </Select>
      {meta.touched && meta.error && (
        <FormHelperText>{`${
          meta.touched && meta.error ? meta.error : ''
        }`}</FormHelperText>
      )}
    </FormControl>
  );
};

FormikDropDown.propTypes = {
  valueDisplayNameMap: PropTypes.array,
  label: PropTypes.string,
};

FormikDropDown.defaultProps = {
  valueDisplayNameMap: [
    { value: 'hi', displayName: 'HI' },
    { value: 'yo', displayName: 'YO' },
  ],
  label: 'default',
};

export default FormikDropDown;
