import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { useField } from 'formik';

const FormikSwitch = ({ label, ...props }) => {
  const [field] = useField({ ...props, type: 'checkbox' });
  return (
    <FormControlLabel
      control={<Switch {...field} {...props} color="primary" />}
      label={label}
    />
  );
};

export default FormikSwitch;
