import React, { useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';
import { SportObj } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  }),
);

type SportCardProps = {
  sport: SportObj;
  selected: boolean;
  onClick: () => void;
};

const SportCard = (props: SportCardProps) => {
  const { sport, selected = false, onClick } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Chip
        avatar={<Avatar>{sport.displayName.charAt(0)}</Avatar>}
        label={sport.displayName}
        clickable
        color={selected ? 'primary' : 'default'}
        onClick={onClick}
      />
    </div>
  );
};

export default SportCard;
