import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import FormFields from './FormFields';

const SignupSchema = Yup.object().shape({
  first_name: Yup.string().required('Required'),
  channel_name: Yup.string().when('channel_verified', {
    is: true,
    then: Yup.string().required('Required'),
  }),
});

const ChannelForm = ({
  displayChannelField = true,
  first_name = '',
  last_name = '',
  channel_name = '',
  description = '',
  profile_type = 'regular',
  channel_verified = false,
  onSubmit,
  display_streams = false,
  display_videos = false,
  can_upload = false,
  is_position_based_lineups_enabled = false,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          first_name,
          last_name,
          description,
          channel_name,
          profile_type,
          channel_verified,
          display_streams,
          display_videos,
          can_upload,
          is_position_based_lineups_enabled,
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields displayChannelField={displayChannelField} />
      </Formik>
    </>
  );
};

ChannelForm.propTypes = {
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  channel_name: PropTypes.string,
  description: PropTypes.string,
  profile_type: PropTypes.string,
  can_stream: PropTypes.bool,
  can_upload: PropTypes.bool,
  channel_verified: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  display_videos: PropTypes.string,
  display_streams: PropTypes.string,
};

ChannelForm.defaultProps = {
  first_name: '',
  last_name: '',
  channel_name: '',
  description: '',
  profile_type: 'regular',
  can_stream: false,
  can_upload: false,
  channel_verified: false,
  display_videos: false,
  display_streams: false,
};

export default ChannelForm;
