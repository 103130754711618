import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useField, useFormikContext } from 'formik';
import { BASE_URL } from '../../../../API/constants';
import getUserById from '../../../../API/users/getUserById';

/**
 * used to set the channel id in a form
 */
const ChannelSearchFormComponent = ({
  label = 'default label',
  variant = 'standard',
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({ ...props });

  const [options, setOptions] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState({});

  const updateOptions = async channelSearhTerm => {
    const resp = await fetch(
      `${BASE_URL}/api/search/solr?value=${channelSearhTerm}&page=1&limit=50&athletes=N&articles=N&live_streams=N&channels=Y&replays=N`,
    );

    const json = await resp.json();

    if (!json.data) return;

    setOptions(json.data.channels);
  };

  const setIntitialValue = async id => {
    try {
      const userData = await getUserById(id);
      if (userData) {
        setOptions([userData]);
        if (userData.channel_name) {
          setSelectedChannel(userData);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (field.value) {
      setIntitialValue(field.value);
    }
  }, []);

  const onAutocompleteInputChange = (event, value) => {
    updateOptions(value);
  };

  const onAutocompleteChange = (event, value, reason) => {
    if (reason === 'select-option') {
      if (value.channel_name) {
        setSelectedChannel(value);

        // set the chanenl id for formik form
        setFieldValue(field.name, value._id);
      }
    }

    if (reason === 'clear') {
      setSelectedChannel('');
      // set the chanenl id for formik form
      setFieldValue(field.name, '');
    }
  };

  return (
    <Autocomplete
      onInputChange={onAutocompleteInputChange}
      onChange={onAutocompleteChange}
      freeSolo
      id="free-solo-2-demo"
      getOptionLabel={option => option.channel_name || ''}
      getOptionSelected={(option, value) =>
        option.channel_name && option.channel_name === value.channel_name
      }
      options={options}
      value={selectedChannel}
      renderInput={params => (
        <TextField
          {...params}
          variant={variant}
          InputProps={{ ...params.InputProps, type: 'search' }}
          error={!!(meta.touched && meta.error)}
          label={label}
          helperText={`${meta.touched && meta.error ? meta.error : ''}`}
        />
      )}
    />
  );
};

export default ChannelSearchFormComponent;
