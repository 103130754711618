import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormFields from './FormFields';

const validationSchema = Yup.object({
  fixed_otp: Yup.number()
    .min(100000)
    .max(999999)
    .required('Required'),
  studio_profile_type: Yup.string().required('Required'),
  valid_till: Yup.string().required('Required'),
  stream_create_limit: Yup.number().required('Required'),
  streaming_minutes_balance: Yup.number().required('Required'),
  max_concurrent_streams: Yup.number().required('Required'),
});

const SpaForm = ({
  is_spa_enabled,
  fixed_otp,
  studio_profile_type,
  valid_till,
  stream_create_limit,
  streaming_minutes_balance,
  max_concurrent_streams,
  is_trial,
  onSubmit,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          is_spa_enabled,
          fixed_otp,
          studio_profile_type,
          valid_till,
          stream_create_limit,
          streaming_minutes_balance,
          max_concurrent_streams,
          is_trial,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields />
      </Formik>
    </>
  );
};

export default SpaForm;
