import axiosInstance from '../../axiosInstance';

const getAthletesByIds = async (ids = []) => {
  if (ids.length === 0) return [];

  const response = await axiosInstance.get(`/api/admin/v2/players/`, {
    params: {
      ids: ids.join(','),
    },
  });

  const { data } = response.data;

  return data.players;
};

export default getAthletesByIds;
