import { Box, InputAdornment, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useField, useFormikContext } from 'formik';
import React, { useState, useEffect } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import DebouncedTextField from '../../../CustomComponents/DeboucedTextField';
import { getTemplateById, getTemplates } from '../../../../API/Templates';
import CustomChip from '../../../Common/CustomChip';

const TemplateSearchFormComponent = ({
  label = 'Template',
  isDisabled = false,
  setSelectedMatchId = () => {},
  name = 'template',
  sport = '',
  creator_id = '',
  scoring_mode = '',
  is_ready = true,
  template_id,
  is_thumbnail_template = false,
  ...props
}) => {
  const { setFieldValue, values } = useFormikContext();
  const [field, meta] = useField({ name, ...props });
  const [options, setOptions] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState({});

  const [shrink, setShrink] = useState(false);

  const onFocus = () => {
    setShrink(true);
  };

  const onBlur = event => {
    if (event.target.value.length === 0) {
      setShrink(false);
    }
  };

  useEffect(() => {
    if (values[field.name] === '') {
      setSelectedMatch({});
      setSelectedMatchId('');
      setFieldValue(field.name, '');
    }
  }, [values]);

  useEffect(() => {
    if (field.value) {
      setShrink(true);
    }
  }, []);

  const updateOptions = async (matchName = '') => {
    const resp = await getTemplates({
      creator_id,
      name: matchName,
      sport,
      scoring_mode,
      is_ready,
      template_id,
      is_thumbnail_template,
    });
    const { templates } = resp;

    if (!templates) return;

    setOptions(templates);
  };

  const onAutocompleteInputChange = value => {
    updateOptions(value);
  };

  const onAutocompleteChange = (event, value, reason) => {
    if (reason === 'select-option') {
      if (value.name) {
        setSelectedMatch(value);
        setSelectedMatchId(value._id);
        setFieldValue(field.name, value._id);
      }
    }

    if (reason === 'clear') {
      setSelectedMatch({});
      setSelectedMatchId('');
      setFieldValue(field.name, '');
    }
  };

  const setIntitialValue = async id => {
    try {
      const data = await getTemplateById(id);
      const { template } = data;
      if (template) {
        if (template.name) {
          setSelectedMatch(template);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    updateOptions(field.value);
  }, [sport, creator_id, scoring_mode, is_ready, template_id]);

  useEffect(() => {
    if (field.value) {
      setIntitialValue(field.value);
    }
  }, []);

  return (
    <Autocomplete
      onChange={onAutocompleteChange}
      disabled={isDisabled}
      id="free-solo-2-demo"
      disableClearable={false}
      forcePopupIcon
      getOptionLabel={option => option.name || ''}
      getOptionSelected={(option, value) =>
        option.name && option.name === value.name
      }
      options={options}
      value={selectedMatch}
      renderOption={option => {
        return (
          <Box mb={1} style={{ width: '100%' }}>
            <Typography
              variant="subtitle2"
              style={{ textTransform: 'capitalize' }}
            >
              {option.name}
            </Typography>
            <CustomChip
              bgcolor={option.is_ready ? 'green' : 'red'}
              label={option.is_ready ? 'PUBLISHED' : 'NOT PUBLISHED'}
            />
          </Box>
        );
      }}
      renderInput={params => (
        <DebouncedTextField
          {...params}
          variant="outlined"
          disabled={isDisabled}
          onFocus={onFocus}
          onBlur={onBlur}
          InputLabelProps={{ shrink }}
          onDebouncedChange={value => onAutocompleteInputChange(value)}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            endAdornment: !shrink && (
              <InputAdornment position="end">
                <SearchIcon
                  style={{
                    fontSize: 24,
                    color: 'rgb(255,255,255,0.7)',
                  }}
                />
              </InputAdornment>
            ),
          }}
          error={!!(meta.touched && meta.error)}
          label={label}
          helperText={`${meta.touched && meta.error ? meta.error : ''}`}
        />
      )}
    />
  );
};

export default TemplateSearchFormComponent;
