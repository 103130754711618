import React, { useContext } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TableFooter, TablePagination, Button } from '@material-ui/core';
import Contexts from '../../Contexts/Contexts';
import useStyles from './styles';

export default function TeamsTable({
  actionLabel,
  data = [],
  onRowActionClicked = () => console.log('event row clicked'),
  onRowStatusClicked = row => console.log('event status clicked'),
  onChangePage = () => console.log('page change clicked'),
  count = 10,
  rowsPerPage = 12,
  page = 1,
  inSquad = [],
}) {
  const classes = useStyles();
  const context = useContext(Contexts);
  const { formConfig } = context;
  return (
    <div className={classes.tableContainer}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Sr. No</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Team id</TableCell>
              <TableCell align="left">Sport</TableCell>
              <TableCell align="left">State</TableCell>
              <TableCell align="center">Action</TableCell>
              <TableCell align="center">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    {page * rowsPerPage + index + 1}
                  </TableCell>

                  <TableCell align="left" component="th" scope="row">
                    {`${row.name}`}
                  </TableCell>

                  <TableCell align="left" component="th" scope="row">
                    {`${row.team_id}`}
                  </TableCell>

                  <TableCell align="left">{row.sport}</TableCell>

                  <TableCell align="left">
                    {(formConfig.state_data[row.state] &&
                      formConfig.state_data[row.state].displayName) ||
                      ''}
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onRowActionClicked(row)}
                    >
                      {actionLabel}
                    </Button>
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      color={row.is_enabled ? 'primary' : 'secondary'}
                      onClick={() => onRowStatusClicked(row)}
                    >
                      {row.is_enabled ? 'ENABLED' : 'DISABLED'}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rows
                colSpan={12}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={onChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
