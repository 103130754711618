import axiosInstance from '../../axiosInstance';

const getGameState = async (match_id: string, sport: string) => {
  const response = await axiosInstance.get(
    `/api/v2/scoring/game-state/${sport}/${match_id}?lang=eng`,
  );

  const { data } = response.data;

  return data;
};
export default getGameState;
