import { createMuiTheme } from '@material-ui/core/styles';
import { pageScrollBar } from './GetStyles';

const themeDark = createMuiTheme({
  background: {
    default: 'rgba(0,0,0,0)',
    paper: 'linear-gradient(to top right, #3A0029,#0B062F )',
    component: 'rgba(0,0,0,0)',
  },
  palette: {
    type: 'dark',
  },
  typography: {},
});

themeDark.overrides = {
  MuiCssBaseline: {
    '@global': {
      body: {
        backgroundColor: '#1a1a1a',
        ...pageScrollBar(true),
      },
    },
  },
};

export default themeDark;
