import { BASE_URL } from '../constants';

const setIndividualPlayer = async (stream_key, playerData) => {
  try {
    const postData = {
      player_ids: playerData,
    };

    const response = await fetch(
      `${BASE_URL}/api/v2/streams/${stream_key}/players`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      },
    );

    const json = await response.json();
    console.log('postData', postData);
    console.log('response', response);
    console.log('json', json);

    if (json.error) {
      throw new Error(json.error.errorMessage);
    }

    return json;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default setIndividualPlayer;
