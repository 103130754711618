import React, { useState, useRef, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormFields from './FormFields';
import getFormConfig from '../../../API/scoreCard/getFormConfig';

// t1 -> home team t2 -> away team
// fb -> football
// kb -> kabaddi

const getSportValues = mapping => {
  const sportValues = [];

  const { sports } = mapping;
  sports.forEach(val => {
    sportValues.push({
      displayName: mapping[val].displayName,
      value: val,
    });
  });

  return sportValues;
};

const getAllKeys = mapping => {
  const allKeys = {
    t1: '',
    t2: '',
    t1_score: '',
    t2_score: '',
    sport: '',
    enabled: false,
    is_auto_managed: false,
  };

  const { sports } = mapping;

  sports.forEach(sport => {
    const { stats } = mapping[sport];

    stats.forEach(stat => {
      allKeys[`t1_${sport}_${stat}`] = '';
      allKeys[`t2_${sport}_${stat}`] = '';
    });
  });

  return allKeys;
};

const getSportKeyMap = mapping => {
  const retVal = {};

  const { sports } = mapping;

  sports.forEach(sport => {
    retVal[sport] = [];
    const { stats } = mapping[sport];

    stats.forEach(stat => {
      const temp = {
        label: mapping[sport][stat].displayName,
        homeKey: `t1_${sport}_${stat}`,
        awayKey: `t2_${sport}_${stat}`,
      };
      retVal[sport].push(temp);
    });
  });

  return retVal;
};

const ScoreCardForm = ({ matchTitle, statsData = null, onSubmit }) => {
  const ref = useRef({
    formConfig: {},
  });
  const [fetchedConfig, setFetchedConfig] = useState(false);

  const getConfig = async () => {
    try {
      const config = await getFormConfig();
      ref.current.formConfig = config;

      if (!config) throw new Error('Failed to get scorecard form config');

      setFetchedConfig(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getConfig();
  }, []);

  const constructData = values => {
    const retValue = {};

    const {
      enabled,
      sport,
      t1,
      t2,
      t2_score,
      t1_score,
      is_auto_managed,
    } = values;

    if (!sport && !is_auto_managed) {
      console.error('Select a sport');
    }

    retValue.enabled = enabled;
    retValue.home_team = t1;
    retValue.away_team = t2;
    retValue.sport = sport;
    retValue.is_auto_managed = is_auto_managed;
    retValue.score = {
      home_team: t1_score,
      away_team: t2_score,
    };

    retValue.data = [];

    if (is_auto_managed) return retValue;

    const { stats } = ref.current.formConfig[sport];

    stats.forEach(stat => {
      const temp = {
        key: stat,
        home_team: values[`t1_${sport}_${stat}`],
        away_team: values[`t2_${sport}_${stat}`],
      };

      if (temp.home_team && temp.away_team) retValue.data.push(temp);
    });

    return retValue;
  };

  const getInitialValues = mapping => {
    try {
      const allKeys = getAllKeys(mapping);
      if (!statsData) return allKeys;

      // modify allKeys to take initial values form statsData
      const {
        enabled,
        home_team,
        away_team,
        sport,
        score,
        data,
        is_auto_managed = false,
      } = statsData;
      allKeys.enabled = enabled;
      allKeys.t1 = home_team;
      allKeys.t2 = away_team;
      allKeys.sport = sport;
      allKeys.t1_score = score.home_team;
      allKeys.t2_score = score.away_team;
      allKeys.is_auto_managed = is_auto_managed;
      data.forEach(elem => {
        allKeys[`t1_${sport}_${elem.key}`] = elem.home_team;
        allKeys[`t2_${sport}_${elem.key}`] = elem.away_team;
      });

      return allKeys;
    } catch (e) {
      console.error(e);
    }
  };

  const renderForm = () => {
    return (
      <>
        <Formik
          initialValues={getInitialValues(ref.current.formConfig)}
          onSubmit={(values, { setSubmitting }) => {
            const postData = constructData(values);
            onSubmit(postData, setSubmitting);
          }}
          validationSchema={Yup.object({
            sport: Yup.string().when('is_auto_managed', {
              is: false,
              then: Yup.string().required('Required'),
            }),
            t1: Yup.string().when('is_auto_managed', {
              is: false,
              then: Yup.string().required('Required'),
            }),
            t2: Yup.string().when('is_auto_managed', {
              is: false,
              then: Yup.string().required('Required'),
            }),
            t1_score: Yup.string().when('is_auto_managed', {
              is: false,
              then: Yup.string().required('Required'),
            }),
            t2_score: Yup.string().when('is_auto_managed', {
              is: false,
              then: Yup.string().required('Required'),
            }),
          })}
        >
          <FormFields
            matchTitle={matchTitle}
            sportList={getSportValues(ref.current.formConfig)}
            sportKeyMap={getSportKeyMap(ref.current.formConfig)}
          />
        </Formik>
      </>
    );
  };

  return <>{fetchedConfig && renderForm()}</>;
};

ScoreCardForm.propTypes = {};

ScoreCardForm.defaultProps = {};

export default ScoreCardForm;
