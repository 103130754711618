import React, { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import SearchUserForm from '../../../../Components/Forms/SearchForms/SearchUserForm';
import useStyles from './styles';
import getUserByMobile from '../../../../API/permission/getUserByMobile';
import Contexts from '../../../../Contexts';

export default function UserSearch() {
  const { showMessageDialog } = useContext(Contexts);
  const classes = useStyles();
  const history = useHistory();
  const { path } = useRouteMatch();

  const onSubmit = async (values, setSubmitting) => {
    const { mobile } = values;
    try {
      const user = await getUserByMobile(mobile);
      history.push({
        pathname: `${path}${user._id}`,
      });
    } catch (error) {
      showMessageDialog('ERROR', error.message);
    }
    setSubmitting(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.searchContainer}>
        <SearchUserForm onSubmit={onSubmit} />
      </div>
    </div>
  );
}
