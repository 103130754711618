import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import useStyles from './styles';
import getSingleTournament from '../../../../API/tournament/getSingleTournament';

export default function SelectedTournament() {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { teamId } = useParams();
  const [tourData, setTourData] = useState({});
  const [loading, setLoading] = useState(true);

  const onMangeDetailsBTN = () => {
    history.push({
      pathname: `${url}/details`,
    });
  };
  const onMangeIndividualBTN = () => {
    history.push({
      pathname: `${url}/individual`,
    });
  };

  const onMangeRuleBTN = () => {
    history.push({
      pathname: `${url}/${tourData.type}/rule`,
    });
  };
  const getSingleTournamentData = async () => {
    const res = await getSingleTournament(teamId);
    console.log(res);
    setTourData(res);
    setLoading(false);
  };

  const onMangeFixturesBTN = async () => {
    history.push({
      pathname: `${url}/${tourData.type}/fixtures`,
    });
  };

  useEffect(() => {
    getSingleTournamentData();
  }, []);

  return (
    !loading && (
      <div className={classes.container}>
        <Button
          className={classes.buttons}
          variant="contained"
          onClick={onMangeDetailsBTN}
        >
          Manage Details
        </Button>

        <Button
          className={classes.buttons}
          variant="contained"
          onClick={onMangeRuleBTN}
        >
          Tournament Rules
        </Button>

        {tourData.is_team_based ? (
          <Button
            className={classes.buttons}
            variant="contained"
            onClick={() => {
              history.push({
                pathname: `/tournament/${teamId}/teams`,
              });
            }}
          >
            Manage Teams (For team Based)
          </Button>
        ) : (
          <div />
        )}

        {!tourData.is_team_based ? (
          <Button
            className={classes.buttons}
            variant="contained"
            onClick={onMangeIndividualBTN}
          >
            Manage Player (For Individual Based)
          </Button>
        ) : (
          <div />
        )}

        <Button
          className={classes.buttons}
          variant="contained"
          onClick={onMangeFixturesBTN}
        >
          Fixtures
        </Button>
      </div>
    )
  );
}
