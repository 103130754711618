import React from 'react';
import { Paper, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';
import { S3_BUCKET_BASE_URL } from '../../API/constants';

const ImageBlock = ({ buttonLabel, blockLabel, imgSrc, onButtonPress }) => {
  const classes = useStyles();

  const getImageUrl = () => {
    if (!imgSrc.includes('https')) {
      return `${S3_BUCKET_BASE_URL}/${imgSrc}`;
    }

    return imgSrc;
  };

  return (
    <Paper elevation={3} className={classes.imageBlockContainer}>
      <Typography variant="h6">{`${blockLabel}`}</Typography>
      <Typography variant="caption">
        ENSURE IMAGE FILE NAME DOES NOT HAVE SPACES
      </Typography>

      {imgSrc && (
        <img style={{ width: '300px' }} src={getImageUrl()} alt="SportVot" />
      )}

      <Button variant="contained" onClick={onButtonPress}>
        {buttonLabel}
      </Button>
    </Paper>
  );
};

ImageBlock.propTypes = {
  blockLabel: PropTypes.string,
  imgSrc: PropTypes.string,
  buttonLabel: PropTypes.string,
  onButtonPress: PropTypes.func,
};

ImageBlock.defaultProps = {
  blockLabel: 'Profile Image',
  imgSrc: '',
  buttonLabel: 'Edit',
  onButtonPress: () => console.log('button pressed in ImageBlock'),
};

export default ImageBlock;
