import axiosInstance from '../../axiosInstance';

const getAthletes = async (
  first_name = '',
  last_name = '',
  mobile = '',
  state = '',
  sport = '',
  position = '',
  preferred_section = '',
  is_featured = false,
  page = '1',
  limit = '10',
  playerId,
) => {
  const queryObj = {
    first_name,
    last_name,
    mobile,
    state,
    sport,
    position,
    page,
    preferred_section,
    limit,
  };

  if (playerId) {
    queryObj.ids = playerId;
  }

  // only add the is_featured filter if set to true
  if (is_featured) queryObj.is_featured = !!is_featured;

  const response = await axiosInstance.get(`/api/admin/v2/players/`, {
    params: queryObj,
  });

  const { data } = response.data;

  return data;
};

export default getAthletes;
