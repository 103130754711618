import React, { useContext } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Contexts from '../../Contexts/Contexts';
import useStyles from './styles';
import {
  generateSportPositionDisplayName,
  generateSportDisplayName,
} from '../../Utils/genrateDisplayNames';
import { Button } from '@material-ui/core';

export default function TournamentTeamPlayerTable({
  data,
  setSelectPlayer,
  inSquad,
}) {
  const classes = useStyles();
  const context = useContext(Contexts);
  const { formConfig } = context;

  return (
    <div className={classes.tableContainer}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Sr no</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell align="center">Sport</TableCell>
              <TableCell align="center">Position</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow
                  key={row.player.user_id}
                  style={{
                    backgroundColor: inSquad.includes(row.player.user_id)
                      ? '#a5d6a7'
                      : 'white',
                  }}
                >
                  <TableCell>{index + 1}</TableCell>

                  <TableCell component="th" scope="row">
                    {`${row.player.first_name} ${row.player.last_name}`}
                  </TableCell>

                  <TableCell align="center">
                    {generateSportDisplayName(formConfig, row.player.sport)}
                  </TableCell>

                  <TableCell align="center">
                    {generateSportPositionDisplayName(
                      formConfig,
                      row.player.sport,
                      row.player.position,
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setSelectPlayer(row.player.user_id)}
                    >
                      Select
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
