import axiosInstance from '../../axiosInstance';

const setIsOngoing = async (event_id = 'default', status = true) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/events/${event_id}/is_ongoing`,
    {
      status,
    },
  );

  const { data } = response.data;

  return data;
};

export default setIsOngoing;
