import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import PassChecker from './PassChecker/PassChecker';

export default function PassCheck() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/event/:eventId/user/:userId`}>
        <PassChecker />
      </Route>
    </Switch>
  );
}
