import React, { useState, useEffect, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useParams, useHistory } from 'react-router-dom';
import { Typography, Button } from '@material-ui/core';
import useStyles from './styles';
import TeamsSearch from '../../Teams/TeamsSearch/TeamsSearch';
import setStreamTeams from '../../../../API/streams/setStreamTeams';
import getSingleStream from '../../../../API/streams/getSingleStream';
// eslint-disable-next-line max-len
import Contexts from '../../../../Contexts';

export default function ManageTeams() {
  const [streamData, setStreamData] = useState(null);

  const [selectedHomeTeam, setSelectedHomeTeam] = useState({
    teamId: '',
    name: '',
  });
  const [selectedAwayTeam, setSelectedAwayTeam] = useState({
    teamId: '',
    name: '',
  });

  const classes = useStyles();
  const { streamId } = useParams();
  const { addToast } = useToasts();
  const { showLoadingSpinner } = useContext(Contexts);
  const history = useHistory();

  const getInitialData = async () => {
    const stream = await getSingleStream(streamId);
    if (stream.teams_info) {
      const teamsInfo = stream.teams_info;
      setSelectedHomeTeam({
        teamId: teamsInfo.home_team.team_id,
        name: teamsInfo.home_team.name,
      });
      setSelectedAwayTeam({
        teamId: teamsInfo.away_team.team_id,
        name: teamsInfo.away_team.name,
      });
    }
    setStreamData(stream);
  };

  useEffect(() => {
    try {
      showLoadingSpinner(true);
      getInitialData();
    } catch (err) {
      addToast('error in fetching stream data. reload', {
        appearance: 'error',
        autoDismiss: 500,
      });
    } finally {
      showLoadingSpinner(false);
    }
  }, []);

  const setSelectedHomeTeamData = data => {
    addToast(
      `Selected ${data.name} as home team. Hit save on selecting both teams!`,
      {
        appearance: 'success',
        autoDismiss: 500,
      },
    );
    setSelectedHomeTeam({
      teamId: data.team_id,
      name: data.name,
    });
  };

  const setSelectedAwayTeamData = data => {
    addToast(
      `Selected ${data.name} as away team. Hit save on selecting both teams!`,
      {
        appearance: 'success',
        autoDismiss: 500,
      },
    );
    setSelectedAwayTeam({
      teamId: data.team_id,
      name: data.name,
    });
  };

  const isValidSelection = () => {
    return (
      selectedHomeTeam &&
      selectedHomeTeam.teamId &&
      selectedAwayTeam &&
      selectedAwayTeam.teamId &&
      selectedHomeTeam.teamId !== selectedAwayTeam.teamId
    );
  };

  const handleSave = async () => {
    try {
      if (!isValidSelection()) {
        addToast('error saving teams', {
          appearance: 'error',
          autoDismiss: 500,
        });
        return;
      }

      showLoadingSpinner(true);

      await setStreamTeams({
        streamId: streamData._id,
        homeTeamId: selectedHomeTeam.teamId,
        awayTeamId: selectedAwayTeam.teamId,
      });

      showLoadingSpinner(false);

      addToast('teams saved', {
        appearance: 'success',
        autoDismiss: 500,
      });

      showLoadingSpinner(false);
      history.goBack();
    } catch (error) {
      showLoadingSpinner(false);
      addToast('error saving teams', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  const getTeamNameAndId = selectedTeam => {
    return `${selectedTeam.name} ( id : ${selectedTeam.teamId} )`;
  };

  const renderMain = () => (
    <div className={classes.root}>
      <div>
        <div className={classes.selectedTeamContainer}>
          <Typography variant="h3">Home Team</Typography>
          <Typography variant="h6">
            {selectedHomeTeam.teamId
              ? getTeamNameAndId(selectedHomeTeam)
              : 'home team not selected'}
          </Typography>
        </div>
        <TeamsSearch
          actionLabel="Select"
          onActionClick={setSelectedHomeTeamData}
        />
      </div>
      <div
        style={{
          marginTop: 30,
        }}
      >
        <Button variant="contained" onClick={handleSave}>
          Save / Update
        </Button>
      </div>
      <div>
        <div className={classes.selectedTeamContainer}>
          <Typography variant="h3">Away Team</Typography>
          <Typography variant="h6">
            {selectedAwayTeam.teamId
              ? getTeamNameAndId(selectedAwayTeam)
              : 'away team not selected'}
          </Typography>
        </div>
        <TeamsSearch
          actionLabel="Select"
          onActionClick={setSelectedAwayTeamData}
        />
      </div>
    </div>
  );

  const renderChangeMatchTypeMessage = () => (
    <>
      <div className={classes.invalidMatchType}>
        Match type needs to be set to team based to set teams.
        <br />
        <br />
        This can be set in stream details section.
      </div>
    </>
  );

  const renderMatchTypCheckedView = () => (
    <>
      {streamData.match_type === 'team' && renderMain()}
      {streamData.match_type !== 'team' && renderChangeMatchTypeMessage()}
    </>
  );

  return streamData && renderMatchTypCheckedView();
}
