import React, { useContext } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, TableFooter, TablePagination } from '@material-ui/core';
import useStyles from './styles';
import {
  generateSportDisplayName,
  generateStateDisplayName,
} from '../../Utils/genrateDisplayNames';
import Context from '../../Contexts';
import TablePaginationActions from '../TablePaginationActions';

export default function EventsTable({
  data = [],
  onRowViewClicked = () => console.log('event row clicked'),
  onRowStatusClicked = () => console.log('row status clicked'),
  onRowOngoinglicked = () => console.log('row ongoing clicked'),
  onRowListedClicked = () => console.log('row listed clicked'),
  onChangePage = () => console.log('page change clicked'),
  count = 10,
  rowsPerPage = 12,
  page = 1,
}) {
  const classes = useStyles();

  const context = useContext(Context);
  const { formConfig, categoriesDropDownList } = context;

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Sr No.</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="center">Sport</TableCell>
            <TableCell align="center">State</TableCell>
            <TableCell align="center">Event id</TableCell>
            <TableCell align="center">Action</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Ongoing</TableCell>
            <TableCell align="center">OTT Listed?</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {data &&
            data.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="center">
                    {page * rowsPerPage + index + 1}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {`${row.name}`}
                  </TableCell>

                  <TableCell align="center">{row.sport || ''}</TableCell>

                  <TableCell align="center">
                    {generateStateDisplayName(formConfig, row.state)}
                  </TableCell>

                  <TableCell align="center">{row.event_id || ''}</TableCell>

                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onRowViewClicked(row)}
                    >
                      View
                    </Button>
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      color={row.is_active === 'Y' ? 'primary' : 'secondary'}
                      onClick={() => onRowStatusClicked(row)}
                    >
                      {row.is_active === 'Y' ? 'ENABLED' : 'DISABLED'}
                    </Button>
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      color={row.is_ongoing === 'Y' ? 'primary' : 'secondary'}
                      onClick={() => onRowOngoinglicked(row)}
                    >
                      {row.is_ongoing === 'Y' ? 'Y' : 'N'}
                    </Button>
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      variant="outlined"
                      color={row.is_listed ? 'primary' : 'secondary'}
                      onClick={() => onRowListedClicked(row)}
                    >
                      {row.is_listed ? 'Y' : 'N'}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rows
              colSpan={12}
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
              onChangePage={onChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
