import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import swal from '@sweetalert/with-react';
import AthleteMatchStatsForm from '../../../../../Components/Forms/AthleteStatsForms/AthleteMatchStatsForm/AthleteMatchStatsForm';
import Contexts from '../../../../../Contexts';
import { useAthleteData } from '../../../../../customHooks/useAthleteData';
import { useSportConfig } from '../../../../../customHooks/useSportConfig';
import AggAthleteMatchStatDetailsControls from './controls/AggAthleteMatchStatDetailsControls';
import useStyles from './styles';
import getAggPMSByPlayerId from '../../../../../API/aggPMS/getAggPMSByPlayerId';
import updateAggPMS from '../../../../../API/aggPMS/updateAggPMS';
import setAggPMSAutoCalc from '../../../../../API/aggPMS/setAggPMSAutoCalc';
import { Button, Typography } from '@material-ui/core';

const constructConfig = (existingStats, sportConfig) => {
  const {
    agg_editable_plr_match_stats = [],
    agg_plr_match_stats = {},
  } = sportConfig;

  const retVal = [];

  agg_editable_plr_match_stats.forEach(elem => {
    retVal.push({
      displayName: agg_plr_match_stats[elem]
        ? agg_plr_match_stats[elem].eng
        : '',
      isEditable: true,
      key: elem,
      initialValue:
        (existingStats && existingStats.stats && existingStats.stats[elem]) ||
        0,
    });
  });

  const remainingKeys = Object.keys(agg_plr_match_stats || {}).filter(
    elem => !agg_editable_plr_match_stats.includes(elem),
  );

  remainingKeys.forEach(elem => {
    retVal.push({
      displayName: agg_plr_match_stats[elem]
        ? agg_plr_match_stats[elem].eng
        : '',
      isEditable: false,
      key: elem,
      initialValue:
        (existingStats && existingStats.stats && existingStats.stats[elem]) ||
        0,
    });
  });

  return retVal;
};

function AthleteAggPMSDetails() {
  const { showLoadingSpinner } = useContext(Contexts);
  const { addToast } = useToasts();
  const classes = useStyles();

  const [sportKey, setSportKey] = useState(null);
  const { playerId } = useParams();
  const history = useHistory();

  const athlete = useAthleteData(playerId);
  const sportConfig = useSportConfig(sportKey);
  const [aggMatchStat, setAggMatchStat] = useState({
    isLoaded: false,
    data: null,
    isUpdating: false,
  });

  const geAggStatData = async () => {
    try {
      const data = await getAggPMSByPlayerId({
        sportKey,
        playerId,
      });
      setAggMatchStat({ isLoaded: true, data });
    } catch (error) {
      setAggMatchStat({ isLoaded: true, data: null });
    }
  };

  useEffect(() => {
    if (athlete) {
      setSportKey(athlete.sport);
    }
  }, [athlete]);

  useEffect(() => {
    if (sportKey) {
      geAggStatData(sportKey);
    }
  }, [sportKey]);

  const onSubmit = async (values, setSubmitting) => {
    try {
      showLoadingSpinner(true);
      setAggMatchStat({ ...aggMatchStat, isUpdating: true });
      const updatedStat = await updateAggPMS({
        playerId,
        sportKey,
        stats: values,
      });

      if (updatedStat) {
        showLoadingSpinner(false);
        setAggMatchStat({
          ...aggMatchStat,
          isUpdating: false,
          data: updatedStat,
        });
        setSubmitting(false);
        addToast('Updated', {
          appearance: 'success',
          autoDismiss: 500,
        });
      }
    } catch (error) {
      showLoadingSpinner(false);
      setAggMatchStat({ ...aggMatchStat, isUpdating: false });
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
      setSubmitting(false);
    }
  };

  const handleAutoCalcClicked = async () => {
    const isYesSelected = await swal({
      title: 'Are you sure? ',
      text:
        'This will reset the stats for this player to the ones calculated from individual match stats.',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    });

    if (!isYesSelected) return;

    try {
      showLoadingSpinner(true);
      setAggMatchStat({ ...aggMatchStat, isUpdating: true });
      const updatedStat = await setAggPMSAutoCalc({
        playerId,
        sportKey,
      });

      if (updatedStat) {
        showLoadingSpinner(false);
        setAggMatchStat({
          ...aggMatchStat,
          isUpdating: false,
          data: updatedStat,
        });
        addToast('Updated', {
          appearance: 'success',
          autoDismiss: 500,
        });
      }
    } catch (error) {
      showLoadingSpinner(false);
      setAggMatchStat({ ...aggMatchStat, isUpdating: false });
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  if (!athlete) {
    return (
      <div className={classes.container}>
        <div>Loading Athlete Data</div>
      </div>
    );
  }

  if (!sportConfig) {
    return (
      <div className={classes.container}>
        <div>Loading Config</div>
      </div>
    );
  }

  if (!aggMatchStat.isLoaded) {
    return (
      <div className={classes.container}>
        <div>Loading Existing Match Stats</div>
      </div>
    );
  }

  if (!aggMatchStat.data) {
    return (
      <div className={classes.container}>
        <Typography
          style={{ textAlign: 'center', marginBottom: '10px' }}
          variant="caption"
        >
          Aggregated Stats don't exist for this player yet.
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            history.push({
              pathname: `/player/${playerId}/agg-match-stats/create`,
            });
          }}
        >
          + Create New
        </Button>
      </div>
    );
  }

  const renderFormOrWaitingForCalc = () => (
    <>
      <AggAthleteMatchStatDetailsControls
        isCalcRequired={aggMatchStat.data.is_calc_required}
        isUserEdited={aggMatchStat.data.is_user_edited}
        onSetForAutoCalcClick={handleAutoCalcClicked}
      />
      {aggMatchStat.data.is_calc_required && (
        <div
          style={{
            textAlign: 'center',
            paddingTop: '25px',
          }}
        >
          Stats are being re-calculated. Refresh page after a few minutes
        </div>
      )}
      {!aggMatchStat.data.is_calc_required && (
        <AthleteMatchStatsForm
          onSubmit={onSubmit}
          formConfigAndInitialValues={constructConfig(
            aggMatchStat.data,
            sportConfig,
          )}
        />
      )}
    </>
  );

  return (
    <div className={classes.container}>
      {!aggMatchStat.isUpdating && renderFormOrWaitingForCalc()}
      {aggMatchStat.isUpdating && <div>U P D A T I N G . . .</div>}
    </div>
  );
}

export default AthleteAggPMSDetails;
