import { useEffect, useState } from 'react';
import useManagedHomeFeedStore from '../../Store/useManagedHomeFeedStore';
import { getSectionEvents } from '../../API/ManagedHomeFeed';

const useSectionEvents = () => {
  const [events, setEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const { managedHomeFeed, selectedSectionKey } = useManagedHomeFeedStore();

  useEffect(() => {
    if (!managedHomeFeed || !selectedSectionKey) return;
    const fetchEvents = async () => {
      try {
        const { events: eventList } = await getSectionEvents({
          sectionKey: selectedSectionKey,
          categoryCode: managedHomeFeed?._id,
        });

        setEvents(eventList);
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchEvents();
  }, [managedHomeFeed, selectedSectionKey]);

  return {
    events,
    loading,
    error,
  };
};

export default useSectionEvents;
