import React from 'react';
import { Formik } from 'formik';
import FormFields from './FormFields';

const ChannelVerificationSearchForm = ({
  only_rejected = false,
  only_approved = false,
  only_pending = false,
  onSubmit = () => console.log('events search form clicked'),
}) => {
  return (
    <>
      <Formik
        initialValues={{
          only_rejected,
          only_approved,
          only_pending,
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields />
      </Formik>
    </>
  );
};

export default ChannelVerificationSearchForm;
