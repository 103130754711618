import getCategoryCodes from '../API/categories/getCategoryCodes';

export const generateStateDropDownList = configJSON => {
  if (!configJSON) {
    return [];
  }

  try {
    const { states } = configJSON;
    const { state_data } = configJSON;

    const retVal = [];

    states.forEach(elem => {
      const temp = {
        value: elem,
        displayName: state_data[elem].displayName,
      };

      retVal.push(temp);
    });

    return retVal;
  } catch (err) {
    return [];
  }
};

export const generateSportDropDownList = configJSON => {
  if (!configJSON) {
    return [];
  }

  try {
    const { sports } = configJSON;
    const { sport_data } = configJSON;

    const retVal = [];

    sports.forEach(elem => {
      const temp = {
        value: elem,
        displayName: sport_data[elem].displayName,
      };

      retVal.push(temp);
    });
    return retVal;
  } catch (err) {
    return [];
  }
};

export const generateSportBasedPositionDropdown = (configJSON, sportKey) => {
  if (!configJSON || !sportKey) {
    return [];
  }

  try {
    const { sport_data } = configJSON;
    const { positions } = sport_data[sportKey];

    const retVal = [];

    positions.forEach(elem => {
      const temp = {
        value: elem,
        displayName: sport_data[sportKey][elem].displayName,
      };

      retVal.push(temp);
    });

    return retVal;
  } catch (err) {
    return [];
  }
};

export const generateCategoriesDropDown = async () => {
  try {
    const data = await getCategoryCodes();

    // exclude the for_app key object
    if (data.for_app) delete data.for_app;

    // exclude the section_details key object
    if (data.section_details) delete data.section_details;

    const retVal = [];

    Object.keys(data).forEach(key => {
      const temp = {
        value: key,
        displayName: data[key].eng,
      };

      retVal.push(temp);
    });

    return retVal;
  } catch (err) {
    return [];
  }
};

export const generateHomeSectionCategoriesDropDown = async () => {
  try {
    const data = await getCategoryCodes();

    // exclude the for_app key object
    if (data.for_app) delete data.for_app;

    // exclude the section_details key object
    if (data.section_details) delete data.section_details;

    const retVal = [];

    Object.keys(data).forEach(key => {
      if (data[key].home_section) {
        const temp = {
          value: key,
          displayName: data[key].eng,
        };

        retVal.push(temp);
      }
    });

    return retVal;
  } catch (err) {
    return [];
  }
};

export const generateHomeSectionsListFromCategory = (
  sectionDetailsFromContext,
  category,
  validSectionTypesList = [],
) => {
  console.log(sectionDetailsFromContext);
  if (!sectionDetailsFromContext || !category) {
    return [];
  }

  try {
    const validSportsList = Object.keys(sectionDetailsFromContext).filter(
      sportKey => sportKey !== 'other_sports',
    );

    const isValidCategorySport = validSportsList.includes(category);

    const sectionDetailsKey = isValidCategorySport ? category : 'other_sports';

    const sectionDetails = sectionDetailsFromContext[sectionDetailsKey];
    if (!sectionDetails) return [];

    const sectionData = sectionDetails.section_data;
    if (!sectionData) return [];

    const retVal = [];

    Object.keys(sectionData).forEach(key => {
      const { section_type } = sectionData[key];
      if (section_type && validSectionTypesList.includes(section_type)) {
        const temp = {
          value: key,
          displayName: sectionData[key].eng || 'failed',
        };

        retVal.push(temp);
      }
    });

    return retVal;
  } catch (err) {
    return [];
  }
};
