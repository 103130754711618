import axiosInstance from '../../axiosInstance';

const setUserPermissions = async (id, authorities) => {
  try {
    const response = await axiosInstance(
      `/api/admin/v2/users/${id}/authorities`,
      {
        method: 'patch',
        data: { authorities },
      },
    );

    const { data, error } = response.data;

    if (error) {
      throw new Error(error.errorMessage);
    }

    return data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default setUserPermissions;
