import React from 'react';
import { Form } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikSubmit from '../../MuiFormikComponents/FormikSubmit';
import FormikTextInput from '../../MuiFormikComponents/FormikTextInput';
import FormikDropDown from '../../MuiFormikComponents/FormikDropDown';
import ReactDatePicker from '../../MuiFormikComponents/ReactDatePicker';

const FormFields = ({ stateDropDownList, sportDropDownList }) => {
  const classes = useStyles();

  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Tournament Search</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Tournament Name" name="name" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Tournament id" name="tournament_id" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikDropDown
              valueDisplayNameMap={stateDropDownList}
              label="State"
              name="state"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikDropDown
              valueDisplayNameMap={sportDropDownList}
              label="Sport"
              name="sport"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ReactDatePicker disableTime label="Start Date" name="start_date" />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ReactDatePicker disableTime label="End Date" name="end_date" />
          </Grid>

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="Search" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
