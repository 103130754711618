export default null;

export const getAbsoluteMediaPath = mediaPath => {
  try {
    if (!mediaPath) return 'no media path found';

    if (mediaPath.startsWith('https')) return mediaPath;

    if (mediaPath.startsWith('YT_')) return 'youtube link';

    return `${'https://d3s84sk2607r1u.cloudfront.net'}/${mediaPath}`;
  } catch (error) {
    return 'error';
  }
};
