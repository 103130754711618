import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormFields from './FormFields';

const EventDetailsForm = ({
  name = '',
  state = '',
  sport = '',
  start_date = '',

  location = '',
  district = '',
  end_date = '',
  description = '',
  organised_by = '',
  is_sport = true,
  preferred_section = '',

  is_auto_highlights_required = false,
  window_seconds_before_scoring_event = 16,
  window_seconds_after_scoring_event = 4,

  event_league_config = {
    is_enabled: false,
    points_per_win: 1,
    points_per_draw: 0,
    points_per_loss: 0,
  },

  onSubmit,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          name,
          state,
          sport,
          start_date,

          // optional fields
          location,
          end_date,
          description,
          district,
          is_sport,
          organised_by,

          preferred_section,

          event_league_config_is_enabled: event_league_config.is_enabled,

          event_league_config_points_per_win:
            event_league_config.points_per_win,

          event_league_config_points_per_draw:
            event_league_config.points_per_draw,

          event_league_config_points_per_loss:
            event_league_config.points_per_loss,

          is_auto_highlights_required,
          window_seconds_before_scoring_event,
          window_seconds_after_scoring_event,
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Required'),
          state: Yup.string().required('Required'),
          sport: Yup.string().required('Required'),
          start_date: Yup.string().required('Required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          const {
            event_league_config_is_enabled,
            event_league_config_points_per_win,
            event_league_config_points_per_draw,
            event_league_config_points_per_loss,
          } = values;

          // eslint-disable-next-line no-param-reassign
          values.event_league_config = {
            is_enabled: event_league_config_is_enabled,
            points_per_win: event_league_config_points_per_win,
            points_per_draw: event_league_config_points_per_draw,
            points_per_loss: event_league_config_points_per_loss,
          };

          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields />
      </Formik>
    </>
  );
};

export default EventDetailsForm;
