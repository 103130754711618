import React from 'react';

import { Switch, Route, useRouteMatch } from 'react-router-dom';
import ArticleSearch from './ArticleSearch';
import AddArticle from './AddArticle';
import ArticleDetails from './ArticleDetails';
import ArticleMedia from './ArticleMedia/ArticleMedia';
import SelectedArticle from './SelectedArticle';
import AddArticleLinkedPlayer from './AddArticleLinkedPlayer/AddArticleLinkedPlayer';
import ArticleLinkedPlayers from './ArticleLinkedPlayers/ArticleLinkedPlayers';

export default function Articles() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <ArticleSearch />
      </Route>
      <Route exact path={`${path}/add`}>
        <AddArticle />
      </Route>
      <Route exact path={`${path}/:articleId`}>
        <SelectedArticle />
      </Route>
      <Route exact path={`${path}/:articleId/details`}>
        <ArticleDetails />
      </Route>
      <Route exact path={`${path}/:articleId/media`}>
        <ArticleMedia />
      </Route>
      <Route exact path={`${path}/:articleId/linked-players`}>
        <ArticleLinkedPlayers />
      </Route>
      <Route exact path={`${path}/:articleId/linked-players/add`}>
        <AddArticleLinkedPlayer />
      </Route>
    </Switch>
  );
}
