import React, { useEffect, useContext, useState } from 'react';
import { Button, Link, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import useStyles from './styles';
import Contexts from '../../../../Contexts';
import initiateToMp4ConversionForStream from '../../../../API/toMP4Conversions/initiateToMp4ConversionForStream';
import { GpuTask } from '../../../../API/GpuTask/types';
import { GpuTaskApis } from '../../../../API/GpuTask';
import getStreamByStreamKey from '../../../../API/scoreCard/getStreamByStreamKey';

export default function GenerateReplayMp4() {
  const [gpuTask, setGpuTask] = useState<GpuTask | null>(null);
  const [stream, setStream] = useState<any>(null);
  const { showLoadingSpinner } = useContext(Contexts);
  const classes = useStyles();
  const { streamId } = useParams();
  const { addToast } = useToasts();

  const getInitialData = async () => {
    showLoadingSpinner(true);
    const fetchedGpuTask = await GpuTaskApis.getGpuTaskBySvTaskTypeAndLinkedEntityId(
      {
        linkedEntityId: streamId,
        svTaskType: 'STREAM_DOWNLOAD',
      },
    );
    const fetchedStream = await getStreamByStreamKey(streamId);
    setGpuTask(fetchedGpuTask);
    setStream(fetchedStream);
    showLoadingSpinner(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const handleConvertClicked = async () => {
    try {
      showLoadingSpinner(true);
      await GpuTaskApis.addStreamDownloadAttempt(streamId);
      const fetchedGpuTask = await GpuTaskApis.getGpuTaskBySvTaskTypeAndLinkedEntityId(
        {
          linkedEntityId: streamId,
          svTaskType: 'STREAM_DOWNLOAD',
        },
      );
      setGpuTask(fetchedGpuTask);
    } catch (error) {
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
    showLoadingSpinner(false);
  };

  if (!gpuTask)
    return (
      <div className={classes.root}>
        <div style={{ display: 'flex', textAlign: 'center' }}>
          <Button variant="contained" onClick={handleConvertClicked}>
            Start Generation
          </Button>
        </div>
      </div>
    );

  const { successful_attempts } = gpuTask;

  if (successful_attempts === 0)
    return (
      <div className={classes.root}>
        <div style={{ display: 'flex', textAlign: 'center' }}>
          <Typography variant="caption">
            MP4 is being generated. Longer streams will take longer to convert.
            Refresh this page after a while to get the generated MP4.
          </Typography>
        </div>
      </div>
    );

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex', textAlign: 'center' }}>
        <Link href={stream?.mp4_link}>Generated MP4</Link>
      </div>
    </div>
  );
}
