import { GameResult } from '../../../../API/OverrideGameResult/types';
import {
  CRICKET_FROM_FIELDS,
  CRICKET_INITIAL_VALUES,
} from './FormFieldsBySport';

export const generateInitialValuesFromGameResultBySport = (
  gameResult: GameResult | undefined,
  sport: string,
  gs: any,
) => {
  if (!gameResult && !gs) {
    if (sport === 'cricket') return CRICKET_INITIAL_VALUES;
  }

  if (!gameResult && gs) {
    if (sport === 'cricket')
      return {
        ...CRICKET_INITIAL_VALUES,
        max_overs: gs.config_data.overs,
        max_wickets: gs.config_data.max_wickets,
      };
  }

  if (gameResult && sport === 'cricket') return gameResult.cricket_game_result;

  return {};
};

export const getFormFieldsBySport = (sport: string) => {
  if (sport === 'cricket') return CRICKET_FROM_FIELDS;

  return [];
};
