import React, { useEffect, useState, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  Paper,
  Typography,
  Switch,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import useStyles from './styles';
import getStreamScore from '../../../../API/streams/getStreamScore';
import setTeamResult from '../../../../API/streams/setTeamResult';
import Contexts from '../../../../Contexts';

export default function ResultManagment() {
  const classes = useStyles();
  const { streamKey } = useParams();
  const [data, setData] = useState({});
  const [draw, setDraw] = useState(false);
  const [winner, setWinner] = useState('');
  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);
  const [alphabets, setAlphabets] = useState([]);
  const { addToast } = useToasts();
  const history = useHistory();

  const generateAlphabets = () => {
    const alphabets = [];
    const start = 'A'.charCodeAt(0);
    const last = 'Z'.charCodeAt(0);
    for (let i = start; i <= last; i += 1) {
      alphabets.push(String.fromCharCode(i));
    }

    return alphabets;
  };
  const alphabetsData = generateAlphabets();

  useEffect(() => {
    const getStreamScoreData = async () => {
      const data = await getStreamScore(streamKey);
      setData(data);

      const newGroup = alphabetsData.splice(0, data.num_of_entities);
      setAlphabets(newGroup);

      setWinner(!data.winner ? 'none' : data.winner);
      if (!data.is_draw) {
        setDraw(false);
      } else {
        setDraw(data.is_draw);
      }
    };
    getStreamScoreData();
  }, []);

  const onSubmit = async () => {
    showLoadingSpinner(true);
    if (draw === true) {
      setWinner('');
    }
    const entity_scores = [];
    alphabets.map(el => {
      entity_scores.push(data[`entity_${el}_score`]);
    });

    try {
      const res = await setTeamResult(streamKey, entity_scores, draw, winner);
      if (res.data) {
        showLoadingSpinner(false);
        addToast('Successfully Saved Result', {
          appearance: 'success',
          autoDismiss: 500,
        });
        history.goBack();
      }
    } catch (err) {
      showLoadingSpinner(false);

      showMessageDialog('ERROR', err.message);
      addToast('Error While Saving Result', {
        appearance: 'error',
        autoDismiss: 500,
      });
    } finally {
      showLoadingSpinner(false);
    }
  };

  const setTeamScore = (value, alphabet) => {
    setData({ ...data, [`entity_${alphabet}_score`]: value });
  };

  return (
    <div className={classes.root}>
      <Paper elevation={3} className={classes.container}>
        <div className={classes.scoreContainer}>
          <div className={classes.scorePointsContainer}>
            {alphabets &&
              alphabets.map(el => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 15,
                  }}
                >
                  <Typography variant="h5">
                    {data[`entity_${el}_name`] || ''}
                  </Typography>
                  <p style={{ margin: '0 20px' }}>-</p>

                  <TextField
                    label="score"
                    variant="outlined"
                    style={{ width: 100 }}
                    value={data[`entity_${el}_score`] || 0}
                    onChange={event => setTeamScore(event.target.value, el)}
                    type="number"
                  />
                </div>
              ))}
          </div>
        </div>
        <div className={classes.drawContainer}>
          <Typography variant="p">Draw</Typography>
          <Switch
            checked={draw}
            onChange={e => setDraw(e.target.checked)}
            name="draw"
            inputProps={{ 'aria-label': 'secondary checkbox' }}
          />
        </div>
        {!draw ? (
          <div>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Winners
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={winner}
                onChange={e => setWinner(e.target.value)}
                label="Winners"
              >
                {alphabets &&
                  alphabets.map(el => (
                    <MenuItem value={`${el}`}>
                      {data[`entity_${el}_name`]}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        ) : (
          <div />
        )}
        <Button style={{ margin: 20 }} variant="contained" onClick={onSubmit}>
          Save
        </Button>
      </Paper>
    </div>
  );
}
