import React, { useState, useRef, useEffect, useContext } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import Add from '@material-ui/icons/Add';
import { IconButton, Typography } from '@material-ui/core';
import useStyles from './styles';
import Contexts from '../../../../Contexts';
import getArticles from '../../../../API/articles/getArticles';
import setArticleIsActive from '../../../../API/articles/setArticleIsActive';
import ArticleSearchForm from '../../../../Components/Forms/SearchForms/ArticleSearchForm';
import ArticlesTable from '../../../../Components/ArticleComponents/ArticlesTable';
import cloneArticle from '../../../../API/articles/cloneArticle';
// eslint-disable-next-line import/extensions
import ArticlesTableFormHomeFeed from '../../../../Components/ManagedHomeFeed/AddVideoDialog/ArticlesTable';

export default function ArticleSearch({
  isForManagedHomeFeed = false,
  categoryCode = '',
}) {
  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);

  const classes = useStyles();
  const [articlesData, setArticlesData] = useState([]);

  // for pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const ref = useRef({
    title: '',
    article_id: '',
    category: categoryCode,
    channel_id: '',
    pageNo: 1,
    preferred_section: '',
    article_type: '',

    creation_source: 'USER',

    // linked match id
    stream_key: '',

    is_commentary: false,
    is_jio_stb: false,
    is_ppv: false,
    is_sub: false,
    is_ad: false,
    is_jio_store: false,
    is_jio_tv: false,
    is_all_jio: false,
    is_global: false,
    should_sort_by_views: isForManagedHomeFeed,
  });

  const { path } = useRouteMatch();

  const history = useHistory();

  const getData = async () => {
    showLoadingSpinner(true);
    try {
      const {
        title,
        article_id,
        category,
        channel_id,
        pageNo,
        preferred_section,
        article_type,

        creation_source,
        stream_key,

        // jio stuff
        is_commentary,
        is_jio_stb,
        is_ppv,
        is_sub,
        is_ad,
        is_jio_store,
        is_jio_tv,
        is_all_jio,
        is_global,
        should_sort_by_views,
      } = ref.current;

      const data = await getArticles(
        article_id,
        category,
        channel_id,
        title,
        preferred_section,
        article_type,

        creation_source,
        stream_key,

        // jio stuff
        is_commentary,
        is_jio_stb,
        is_ppv,
        is_sub,
        is_ad,
        is_jio_store,
        is_jio_tv,
        is_all_jio,
        is_global,
        pageNo,
        10,
        should_sort_by_views ? 'views:desc' : '_id:desc',
      );

      if (data) {
        setArticlesData(data.articles);
        setCount(data.total_count);
        showLoadingSpinner(false);
      }
    } catch (err) {
      showMessageDialog('ERROR', err.message);
      showLoadingSpinner(false);
    }
  };

  const handleClone = async articleId => {
    try {
      // reset pages
      setPage(1);
      ref.current.page = 1;
      await cloneArticle(articleId);
      getData();
    } catch (error) {}
  };

  const onSubmit = async (values, setSubmitting) => {
    const {
      title,
      article_id,
      category,
      channel_id,
      preferred_section,
      article_type,

      creation_source,
      stream_key,

      // jio stuff
      is_commentary,
      is_jio_stb,
      is_ppv,
      is_sub,
      is_ad,
      is_jio_store,
      is_jio_tv,
      is_all_jio,
      is_global,
      should_sort_by_views,
    } = values;

    ref.current = {
      ...ref.current,
      title,
      article_id,
      category,
      channel_id,
      preferred_section,
      article_type,

      creation_source,
      stream_key,

      // jio stuff
      is_commentary,
      is_jio_stb,
      is_ppv,
      is_sub,
      is_ad,
      is_jio_store,
      is_jio_tv,
      is_all_jio,
      is_global,
      should_sort_by_views,
    };

    // reset pages
    setPage(1);
    ref.current.pageNo = 1;

    await getData();
    setSubmitting(false);
  };

  const handleRowViewClicked = row => {
    window.open(`/articles/${row._id}`);
    // history.push({
    //   pathname: `${path}${row._id}`,
    // });
  };

  // toggle status (is_active)
  const handleRowStatusClicked = async row => {
    const { _id } = row;
    const temp = [];
    let foundMatch = false;
    let newStatus = null;

    if (_id) {
      articlesData.forEach(elem => {
        if (elem._id && elem._id === _id) {
          const { is_active } = elem;

          elem.is_active = is_active === 'Y' ? 'N' : 'Y';
          newStatus = elem.is_active;

          foundMatch = true;
          temp.push(elem);
        } else {
          temp.push(elem);
        }
      });
    }

    if (foundMatch && _id) {
      showLoadingSpinner(true);

      const resp = await setArticleIsActive(_id, newStatus === 'Y');

      if (resp) {
        setArticlesData(temp);
        showLoadingSpinner(false);
      }
    }
  };

  const handlePageChange = (event, newPage) => {
    // newPage + 1 adjustment because our pages are not 0 indexed
    // hence when passing to the table too we pasee page - 1 and
    // not page
    setPage(newPage + 1);
    ref.current.pageNo = newPage + 1;
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.searchContainer}>
        <ArticleSearchForm
          isForManagedHomeFeed={isForManagedHomeFeed}
          onSubmit={onSubmit}
          category={categoryCode}
        />
        {!isForManagedHomeFeed && (
          <div>
            <Typography variant="overline">Add New</Typography>
            <IconButton
              edge="start"
              className={classes.searchIcon}
              color="default"
              aria-label="menu"
              onClick={() => {
                history.push({
                  pathname: `${path}add`,
                });
              }}
            >
              <Add />
            </IconButton>
          </div>
        )}
      </div>

      {!isForManagedHomeFeed && articlesData.length > 0 ? (
        <ArticlesTable
          count={count}
          rowsPerPage={10}
          // not the page - 1 here. Becuase we dont use zero indexing
          page={page - 1}
          onChangePage={handlePageChange}
          data={articlesData}
          onRowViewClicked={row => handleRowViewClicked(row)}
          onRowStatusClicked={row => handleRowStatusClicked(row)}
          onCloneStreamClicked={row => handleClone(row._id)}
        />
      ) : (
        <div />
      )}

      {isForManagedHomeFeed && articlesData.length > 0 && (
        <ArticlesTableFormHomeFeed
          totalCount={count}
          rowsPerPage={10}
          page={page - 1}
          onChangePage={handlePageChange}
          data={articlesData}
        />
      )}
    </div>
  );
}
