import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormFields from './FormFields';

const validationSchema = Yup.object({
  content_id: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
});

const HeaderContentForm = ({ type = '', content_id = '', onSubmit }) => {
  return (
    <>
      <Formik
        initialValues={{
          type,
          content_id,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields />
      </Formik>
    </>
  );
};

export default HeaderContentForm;
