import React from 'react';
import { FieldArray, useField, useFormikContext } from 'formik';
import {
  Button,
  IconButton,
  TextField,
  Container,
  List,
  ListItem,
  ListItemSecondaryAction,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './styles'; // Assuming you have a separate file for JSS styles

// Modify the regions array to include labels
const regions = [
  { value: 'internet', label: 'Other' },
  { value: 'us-west-1', label: '(AWS) N. California' },
  { value: 'us-east-1', label: '(AWS) N. Virginia' },
  { value: 'eu-central-1', label: '(AWS) Frankfurt' },
  { value: 'ap-south-1', label: '(AWS) Mumbai' },
];

const FormikFieldArrayForCostCalcOutputDestinations = ({ name }) => {
  const { values, setFieldValue } = useFormikContext();
  const [, meta] = useField(name);
  const classes = useStyles();
  const items = values[name];

  const handleRegionChange = (index, newValue) => {
    const newItems = items.map((item, i) =>
      i === index ? { ...item, region: newValue } : item,
    );
    setFieldValue(name, newItems);
  };

  const handleBitrateChange = (index, newValue) => {
    const newItems = items.map((item, i) =>
      i === index ? { ...item, bitrateKbps: newValue } : item,
    );
    setFieldValue(name, newItems);
  };

  return (
    <Container>
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <div>
            <List>
              {items.length > 0 ? (
                items.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem key={index} className={classes.listItem}>
                    <TextField
                      label="Bitrate (Kbps)"
                      type="number"
                      value={item.bitrateKbps}
                      onChange={e =>
                        handleBitrateChange(index, parseInt(e.target.value, 10))
                      }
                      style={{ marginRight: 8 }}
                    />
                    <FormControl style={{ minWidth: 220 }}>
                      <InputLabel>Region</InputLabel>
                      <Select
                        value={item.region}
                        onChange={e =>
                          handleRegionChange(index, e.target.value)
                        }
                      >
                        {regions.map(region => (
                          <MenuItem key={region.value} value={region.value}>
                            {region.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              ) : (
                <Button
                  type="button"
                  onClick={() =>
                    arrayHelpers.push({
                      bitrateKbps: 5000,
                      region: regions[0].value,
                    })
                  }
                >
                  Add Destination
                </Button>
              )}
            </List>
            {items.length > 0 && (
              <Button
                startIcon={<AddIcon />}
                onClick={() =>
                  arrayHelpers.push({
                    bitrateKbps: 5000,
                    region: regions[0].value,
                  })
                }
                className={classes.addButton}
              >
                Add Another Destination
              </Button>
            )}
            {meta.touched && meta.error && (
              <div className={classes.error}>{meta.error}</div>
            )}
          </div>
        )}
      />
    </Container>
  );
};

export default FormikFieldArrayForCostCalcOutputDestinations;
