import axiosInstance from '../../axiosInstance';

const updateFeaturedVideo = async (userId, articleId) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/players/${userId}/featured-video`,
    { article_id: articleId },
  );

  const { player } = response.data.data;

  return player;
};

export default updateFeaturedVideo;
