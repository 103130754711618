import React from 'react';
import styles from './styles.module.css';
import { Box, Typography } from '@material-ui/core';

type AddSectionCardProps = {
  onClick: () => void;
};

function AddSectionCard(props: AddSectionCardProps) {
  const { onClick } = props;
  return (
    <Box
      onClick={onClick}
      p={1}
      borderRadius={5}
      className={styles.add_section_card}
    >
      <Typography variant="caption">+</Typography>
      <Typography variant="subtitle2">Add New Section</Typography>
    </Box>
  );
}

export default AddSectionCard;
