import axiosInstance from '../../axiosInstance';

const addStreamLinkedPlayer = async (streamId, athleteId) => {
  const response = await axiosInstance.post(
    `/api/admin/v2/streams/${streamId}/linked-athletes`,
    {
      athlete_id: athleteId,
    },
  );

  const { data } = response.data;

  return data.stream;
};

export default addStreamLinkedPlayer;
