import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import passAuthenticatorPostAttendance from '../../../../API/eventAttendance/postEventAttendance';
import getEventById from '../../../../API/events/getEventById';
import getUserById from '../../../../API/users/getUserById';

function PassChecker() {
  const { eventId, userId } = useParams();
  const [isAttendanceAdded, setisAttendanceAdded] = useState(false);
  const [isProcessing, setisProcessing] = useState(true);
  const [errorMsg, seterrorMsg] = useState('');
  const [userName, setuserName] = useState('');
  const [eventName, seteventName] = useState('');

  useEffect(() => {
    const postEventAttendance = async () => {
      try {
        const user = await getUserById(userId);
        setuserName(`${user.first_name} ${user.last_name}`);
        const event = await getEventById(eventId);
        seteventName(event.name);
        await passAuthenticatorPostAttendance(userId, eventId);
        setisAttendanceAdded(true);
        setisProcessing(false);
      } catch (e) {
        setisProcessing(false);
        seterrorMsg(e.message);
      }
    };
    postEventAttendance();
  }, [eventId, userId]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {isProcessing && <Typography>Processing attendance request</Typography>}
      <Typography style={{ margin: '2vh' }}>
        {userName} @ {eventName}
      </Typography>
      {!isProcessing && isAttendanceAdded && (
        <Typography style={{ color: 'limegreen' }}>
          Attendance added successfully
        </Typography>
      )}
      {!isProcessing && !isAttendanceAdded && (
        <Typography style={{ color: '#ff2626' }}>ERROR: {errorMsg}</Typography>
      )}
    </div>
  );
}

export default PassChecker;
