import React, { useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useParams, useHistory } from 'react-router-dom';
import useStyles from './style';
import convertRegularToAthlete from '../../../../API/users/convertRegularToAthlete';
import AthleteDetailsForm from '../../../../Components/Forms/AthletDetailsForm';
import getByMobile from '../../../../API/users/getByMobile';

export default function RegularToAthleteForm() {
  const { mobile } = useParams();
  const classes = useStyles();
  const [formFields, setFormFields] = useState({ mobile });
  const history = useHistory();
  const [dataFetched, setDataFetched] = useState(false);
  const [userId, setUserId] = useState('');
  const { addToast } = useToasts();

  const getUserData = async mobNum => {
    const data = await getByMobile(mobNum);

    setFormFields({
      mobile,
      first_name: data.first_name,
      last_name: data.last_name,
    });
    setUserId(data._id);
    setDataFetched(true);
  };

  const handleSubmit = async (values, setSubmitting) => {
    try {
      const id = await convertRegularToAthlete(userId, values);

      if (id) {
        setSubmitting(false);
        history.push({
          pathname: `/player/${id}`,
        });
        addToast('Data Saved Successfully', {
          appearance: 'success',
          autoDismiss: 500,
        });
      }
    } catch (error) {
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };
  useEffect(() => {
    getUserData(mobile);
  }, []);

  return (
    <div className={classes.container}>
      {dataFetched && (
        <AthleteDetailsForm {...formFields} onSubmit={handleSubmit} />
      )}
    </div>
  );
}
