import { BASE_URL } from '../constants';

const deleteTeamPlayer = async (tourn_id, player_id) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/v2/tournaments/${tourn_id}/players/${player_id}`,
      {
        method: 'DELETE',
      },
    );

    const json = await response.json();

    if (json.error) {
      throw new Error(json.error.errorMessage);
    }

    return json;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default deleteTeamPlayer;
