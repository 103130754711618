import axiosInstance from '../../axiosInstance';

const updateTeamIsEnabled = async (teamId, isEnabled = true) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/teams/${teamId}/is-enabled`,
    {
      is_enabled: isEnabled,
    },
  );
  const { data } = response.data;

  return data;
};

export default updateTeamIsEnabled;
