import axiosInstance from '../../axiosInstance';

const getSportConfigBySportKey = async sportKey => {
  const response = await axiosInstance.get(
    `/api/v2/configs/sport-configs/${sportKey}`,
  );

  const { data } = response.data;

  return data.sport_config;
};

export default getSportConfigBySportKey;
