import axiosInstance from '../../axiosInstance';

const createNewTeam = async ({
  name,
  sport,
  state,
  district = '',
  association = '',
  addDummyPlayers = false,
}) => {
  const response = await axiosInstance.post(
    `/api/v2/teams/`,
    {
      name,
      sport,
      state,
      district,
      association,
    },
    {
      params: {
        add_dummy_players: addDummyPlayers,
      },
    },
  );

  const { data } = response.data;

  return data;
};

export default createNewTeam;
