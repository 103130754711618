import React, { useState, useRef, useContext, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useParams, useHistory } from 'react-router-dom';
import { IconButton, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import useStyles from './styles';
import Contexts from '../../../../Contexts';
import AddAthleteTable from '../../../../Components/TeamsComponent/AddAthleteTable/AddAthleteTable';
import AthletesSearchForm from '../../../../Components/Forms/SearchForms/AthletesSearchForm';
import getAthletes from '../../../../API/athletes/getAthletes';
import deleteTeamPlayer from '../../../../API/tournament/deleteTeamPlayer';
import getIndividualPlayer from '../../../../API/tournament/getIndividualPlayer';
import addSinglePlayer from '../../../../API/tournament/addSinglePlayer';

export default function IndividualPlayer() {
  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);
  const classes = useStyles();
  const [playersData, setPlayersData] = useState([]);
  const { teamId } = useParams();
  const history = useHistory();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [currentSquad, setCurrentSquad] = useState([]);
  const { addToast } = useToasts();

  const ref = useRef({
    first_name: '',
    last_name: '',
    mobile: '',
    sport: '',
    position: '',
    state: '',
    pageNo: 1,
  });

  const getData = async () => {
    showLoadingSpinner(true);
    try {
      const {
        first_name,
        last_name,
        mobile,
        sport,
        position,
        state,
        pageNo,
      } = ref.current;

      const data = await getAthletes(
        first_name,
        last_name,
        mobile,
        state,
        sport,
        position,
        pageNo,
        10,
      );

      if (data) {
        setPlayersData(data.players);
        setCount(data.total_count);
        showLoadingSpinner(false);
      }
    } catch (err) {
      showMessageDialog('ERROR', err.message);
      showLoadingSpinner(false);
    }
  };

  const onSubmit = async (values, setSubmitting) => {
    const { first_name, last_name, mobile, sport, position, state } = values;
    ref.current = {
      ...ref.current,
      first_name,
      last_name,
      mobile,
      sport,
      position,
      state,
    };

    // reset pages
    setPage(1);
    ref.current.pageNo = 1;

    await getData();
    setSubmitting(false);
  };

  const handleRowActionClick = async player => {
    console.log('player', player);
    if (currentSquad.includes(player._id)) {
      try {
        const res = await deleteTeamPlayer(teamId, player._id);
        if (res.data.player_id) {
          addToast('Successfully Removed Player', {
            appearance: 'success',
            autoDismiss: 500,
          });
          getTeamAllSquadData();
        }
      } catch (error) {
        addToast('Error While Removing Player', {
          appearance: 'error',
          autoDismiss: 500,
        });
      }
    } else {
      try {
        const selectedPlayer = {
          player_id: player._id,
        };
        const res = await addSinglePlayer(teamId, selectedPlayer);
        if (res.data.player_id) {
          addToast('Successfully Added', {
            appearance: 'success',
            autoDismiss: 500,
          });
          getTeamAllSquadData();
        }
      } catch (error) {
        addToast('Error while adding player', {
          appearance: 'error',
          autoDismiss: 500,
        });
      }
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
    ref.current.pageNo = newPage + 1;
    getData();
  };

  const getTeamAllSquadData = async () => {
    const res = await getIndividualPlayer(teamId);
    if (res.players) {
      const data = [];
      res.players.map(el => {
        data.push(el.player_id);
      });
      setCurrentSquad(data);
    }
  };

  useEffect(() => {
    getData();
    getTeamAllSquadData();
  }, []);

  return (
    <div className={classes.searchContainer}>
      <div className={classes.root}>
        <AthletesSearchForm onSubmit={onSubmit} />

        <div>
          <Typography variant="overline">Add Player</Typography>
          <IconButton
            edge="start"
            className={classes.searchIcon}
            color="default"
            aria-label="menu"
            onClick={() => {
              history.push({
                pathname: `/player/add`,
              });
            }}
          >
            <Add />
          </IconButton>
        </div>

        <div className={classes.addContainer}>
          <AddAthleteTable
            data={playersData}
            inSquad={currentSquad}
            onRowActionClicked={handleRowActionClick}
            count={count}
            page={page - 1}
            onChangePage={handlePageChange}
            rowsPerPage={10}
          />
        </div>
      </div>
    </div>
  );
}
