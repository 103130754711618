import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import swal from '@sweetalert/with-react';
import getSinglePlayerMatchStat from '../../../../../API/playerMatchStats/getSinglePlayerMatchStat';
import updateSinglePlayerMatchStat from '../../../../../API/playerMatchStats/updateSinglePlayerMatchStat';
import AthleteMatchStatsForm from '../../../../../Components/Forms/AthleteStatsForms/AthleteMatchStatsForm/AthleteMatchStatsForm';
import Contexts from '../../../../../Contexts';
import { useAthleteData } from '../../../../../customHooks/useAthleteData';
import { useSportConfig } from '../../../../../customHooks/useSportConfig';
import AthleteMatchStatDetailsControls from './controls/AthleteMatchStatDetailsControls';
import useStyles from './styles';
import updatePMSEnabledStatus from '../../../../../API/playerMatchStats/updatePMSEnabledStatus';
import setPMSForAutoCalc from '../../../../../API/playerMatchStats/setPMSForAutoCalc';

const constructConfig = (existingStats, sportConfig) => {
  const { editable_plr_match_stats = [], plr_match_stats = {} } = sportConfig;

  const retVal = [];

  editable_plr_match_stats.forEach(elem => {
    retVal.push({
      displayName: plr_match_stats[elem] ? plr_match_stats[elem].eng : '',
      isEditable: true,
      key: elem,
      initialValue:
        (existingStats && existingStats.stats && existingStats.stats[elem]) ||
        0,
    });
  });

  const remainingKeys = Object.keys(plr_match_stats || {}).filter(
    elem => !editable_plr_match_stats.includes(elem),
  );

  remainingKeys.forEach(elem => {
    retVal.push({
      displayName: plr_match_stats[elem] ? plr_match_stats[elem].eng : '',
      isEditable: false,
      key: elem,
      initialValue:
        (existingStats && existingStats.stats && existingStats.stats[elem]) ||
        0,
    });
  });

  return retVal;
};

function AthleteMatchStatsDetails() {
  const { showLoadingSpinner } = useContext(Contexts);
  const { addToast } = useToasts();
  const classes = useStyles();

  const [sportKey, setSportKey] = useState(null);
  const { matchId, playerId } = useParams();

  const athlete = useAthleteData(playerId);
  const sportConfig = useSportConfig(sportKey);
  const [matchStat, setMatchStat] = useState({
    isLoaded: false,
    data: null,
    isUpdating: false,
  });

  const getMatchStatData = async () => {
    try {
      const data = await getSinglePlayerMatchStat({
        sportKey,
        playerId,
        matchId,
      });
      setMatchStat({ isLoaded: true, data });
    } catch (error) {
      setMatchStat({ isLoaded: true, data: null });
    }
  };

  useEffect(() => {
    if (athlete) {
      setSportKey(athlete.sport);
    }
  }, [athlete]);

  useEffect(() => {
    if (sportKey) {
      getMatchStatData(sportKey);
    }
  }, [sportKey]);

  const onSubmit = async (values, setSubmitting) => {
    try {
      showLoadingSpinner(true);
      setMatchStat({ ...matchStat, isUpdating: true });
      const updatedStat = await updateSinglePlayerMatchStat({
        matchId,
        playerId,
        sportKey,
        stats: values,
      });

      if (updatedStat) {
        showLoadingSpinner(false);
        setMatchStat({ ...matchStat, isUpdating: false, data: updatedStat });
        setSubmitting(false);
        addToast('Updated', {
          appearance: 'success',
          autoDismiss: 500,
        });
      }
    } catch (error) {
      showLoadingSpinner(false);
      setMatchStat({ ...matchStat, isUpdating: false });
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
      setSubmitting(false);
    }
  };

  const handleStatusToggleClicked = async () => {
    try {
      showLoadingSpinner(true);
      setMatchStat({ ...matchStat, isUpdating: true });
      const updatedStat = await updatePMSEnabledStatus({
        matchId,
        playerId,
        sportKey,
        isEnabled: !matchStat.data.is_enabled,
      });

      if (updatedStat) {
        showLoadingSpinner(false);
        setMatchStat({ ...matchStat, isUpdating: false, data: updatedStat });
        addToast('Updated', {
          appearance: 'success',
          autoDismiss: 500,
        });
      }
    } catch (error) {
      showLoadingSpinner(false);
      setMatchStat({ ...matchStat, isUpdating: false });
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  const handleAutoCalcClicked = async () => {
    const isYesSelected = await swal({
      title: 'Are you sure? ',
      text:
        'This will reset the stats for this player to the ones calculated from scoring events',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    });

    if (!isYesSelected) return;

    try {
      showLoadingSpinner(true);
      setMatchStat({ ...matchStat, isUpdating: true });
      const updatedStat = await setPMSForAutoCalc({
        matchId,
        playerId,
        sportKey,
      });

      if (updatedStat) {
        showLoadingSpinner(false);
        setMatchStat({ ...matchStat, isUpdating: false, data: updatedStat });
        addToast('Updated', {
          appearance: 'success',
          autoDismiss: 500,
        });
      }
    } catch (error) {
      showLoadingSpinner(false);
      setMatchStat({ ...matchStat, isUpdating: false });
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  if (!athlete) {
    return (
      <div className={classes.container}>
        <div>Loading Athlete Data</div>
      </div>
    );
  }

  if (!sportConfig) {
    return (
      <div className={classes.container}>
        <div>Loading Config</div>
      </div>
    );
  }

  if (!matchStat.isLoaded) {
    return (
      <div className={classes.container}>
        <div>Loading Existing Match Stats</div>
      </div>
    );
  }

  if (!matchStat.data) {
    return (
      <div className={classes.container}>
        <div>No existing match stats for match id {matchId}</div>
      </div>
    );
  }

  const renderFormOrWaitingForCalc = () => (
    <>
      <AthleteMatchStatDetailsControls
        isEnabled={matchStat.data.is_enabled}
        isCalcRequired={matchStat.data.is_calc_required}
        isAutoGenerateSupported={matchStat.data.is_auto_generate_supported}
        isUserEdited={matchStat.data.is_user_edited}
        onSetForAutoCalcClick={handleAutoCalcClicked}
        onStatusClick={handleStatusToggleClicked}
      />
      {matchStat.data.is_calc_required && (
        <div
          style={{
            textAlign: 'center',
            paddingTop: '25px',
          }}
        >
          Stats are being re-calculated. Refresh page after a few minutes
        </div>
      )}
      {!matchStat.data.is_calc_required && (
        <AthleteMatchStatsForm
          onSubmit={onSubmit}
          formConfigAndInitialValues={constructConfig(
            matchStat.data,
            sportConfig,
          )}
        />
      )}
    </>
  );

  return (
    <div className={classes.container}>
      {!matchStat.isUpdating && renderFormOrWaitingForCalc()}
      {matchStat.isUpdating && <div>U P D A T I N G . . .</div>}
    </div>
  );
}

export default AthleteMatchStatsDetails;
