import axiosInstance from '../../axiosInstance';

const getArticlesData = async (id = '') => {
  try {
    const response = await axiosInstance(`/api/v2/articles/${id}`);

    const { data, error } = response;

    if (error) {
      throw new Error(error.errorMessage);
    }

    return data.data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default getArticlesData;
