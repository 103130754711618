import { BASE_URL } from '../constants';

const verifyOTP = async (mobile, otp) => {
  try {
    const postData = {
      mobile,
      otp,
    };

    const response = await fetch(`${BASE_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });

    const json = await response.json();

    if (json.error) {
      throw new Error(json.error.errorMessage);
    }

    return json;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default verifyOTP;
