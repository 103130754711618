import React, { useState } from 'react';
import DatePicker from 'react-date-picker';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';
import { Typography } from '@material-ui/core';

/**
 * a form component to be used for ISO date strings as the form value.
 * renders a date and time picker that sets the form value
 * to the ISO string of the selected date
 */
const ReactDatePicker = ({
  label = 'date-time picker',
  disableDate,
  disableTime,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({ ...props });

  const [selectedTime, setSelectedTime] = useState(
    (field.value && new Date(field.value)) || null,
  );

  const [selectedDate, setSelectedDate] = useState(
    (field.value && new Date(field.value)) || null,
  );

  const updateFormDateTimeValue = (date, time) => {
    const dateCopy = moment({
      year: date.getFullYear(),
      month: date.getMonth(),
      date: date.getDate(),
    });
    const timeCopy = new Date(time);

    const hours = timeCopy.getHours();
    const minutes = timeCopy.getMinutes();

    const a = disableTime
      ? moment(dateCopy)
      : moment(dateCopy)
          .add(hours, 'h')
          .add(minutes, 'm');

    const val = a.toDate();

    setFieldValue(field.name, val.toJSON());
  };

  const renderDatePicker = () => (
    <>
      <Typography variant="caption" component="h2">
        Date
      </Typography>

      <DatePicker
        value={selectedDate}
        selected={selectedDate}
        onChange={val => {
          setSelectedDate(val);
          updateFormDateTimeValue(val, selectedTime);
        }}
      />
    </>
  );

  const renderTimePicker = () => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        margin="normal"
        id="time-picker"
        label="Time"
        value={selectedTime}
        onChange={val => {
          setSelectedTime(val);
          updateFormDateTimeValue(selectedDate, val);
        }}
        KeyboardButtonProps={{
          'aria-label': 'change time',
        }}
      />
    </MuiPickersUtilsProvider>
  );

  const renderErrorMessage = () => (
    <Typography variant="caption" color="error">
      {`${meta.touched && meta.error ? meta.error : ''}`}
    </Typography>
  );

  return (
    <>
      <div>
        <Typography variant="overline" component="h2">
          {label}
        </Typography>
      </div>
      <div>
        {!disableDate && renderDatePicker()}
        {!disableTime && renderTimePicker()}
      </div>
      <div>{!!(meta.touched && meta.error) && renderErrorMessage()}</div>
    </>
  );
};

export default ReactDatePicker;
