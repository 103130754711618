import React from 'react';
import { Form, useFormikContext } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikTextInput from '../MuiFormikComponents/FormikTextInput';
import FormikSubmit from '../MuiFormikComponents/FormikSubmit';
import FormikDropDown from '../MuiFormikComponents/FormikDropDown';
import FormikFieldArrayForCostCalcHlsQualityLevels from '../MuiFormikComponents/FormikFieldArrayForCostCalcHlsQualityLevels';
import FormikFieldArrayForCostCalcOutputDestinations from '../MuiFormikComponents/FormikFieldArrayForCostCalcOutputDestinations';
import FormikSwitch from '../MuiFormikComponents/FormikSwitch';

const regionsDisplayMap = [
  { value: 'us-west-1', displayName: 'N. California' },
  { value: 'us-east-1', displayName: 'N. Virginia' },
  { value: 'eu-central-1', displayName: 'Frankfurt' },
  { value: 'ap-south-1', displayName: 'Mumbai' },
];

const FormFields = () => {
  const classes = useStyles();

  const { values } = useFormikContext();

  const {
    isDistributedOnSvOttNonYT,
    isUsingManualClipping,
    isUsingAutoHighlights,
  } = values;

  const isUsingHls =
    isDistributedOnSvOttNonYT ||
    (isUsingAutoHighlights && !isUsingManualClipping) ||
    isUsingAutoHighlights ||
    isUsingManualClipping;

  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4"> Cost Calculator</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" color="secondary">
              Evaluates a per hour cost in USD $.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Cloud Production</Typography>
          </Grid>

          <Grid item xs={12}>
            <FormikDropDown
              valueDisplayNameMap={regionsDisplayMap}
              label="Server Region"
              name="serverRegion"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput
              type="number"
              label="video inputs"
              name="videoInputs"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput
              type="number"
              label="audio Commentators"
              name="audioCommentators"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput
              type="number"
              label="producers / stream monitors"
              name="producers"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">
              RTMP/ SRT Output Destinations
            </Typography>
            <br />
            <Typography variant="caption" color="secondary">
              * for better cost, use AWS region options if you are sending
              output to another server thats on AWS
            </Typography>
            <FormikFieldArrayForCostCalcOutputDestinations name="outputDestinations" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Clipping / Highlights</Typography>
          </Grid>

          {!values.isUsingManualClipping && (
            <Grid item xs={12}>
              <FormikSwitch
                label="Auto Highlights?"
                name="isUsingAutoHighlights"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormikSwitch
              label="Manual Clipping?"
              name="isUsingManualClipping"
            />
          </Grid>

          {(values.isUsingManualClipping || values.isUsingAutoHighlights) && (
            <Grid item xs={12} sm={6} md={6}>
              <FormikTextInput
                type="number"
                label="highlights duration per hour (mins)"
                name="minsOfHighlightsPerHour"
              />
            </Grid>
          )}

          {values.isUsingManualClipping && (
            <Grid item xs={12} sm={6} md={6}>
              <FormikTextInput
                type="number"
                label="number of clippers + QA person"
                name="numberOfHighlightClippersAndQA"
              />
            </Grid>
          )}

          {values.isUsingManualClipping && (
            <Grid item xs={12} sm={6} md={6}>
              <FormikTextInput
                type="number"
                label="Highlight Downloads"
                name="numberOfHighlightDownloads"
              />
              <Typography variant="caption" color="secondary">
                NOTE: if duration of all highlights is 10mins, downloading
                footage of 10mins is = 1 downlaod{' '}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography variant="h6">Distribution</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch
              label="Video on OTT (non-YT)?"
              name="isDistributedOnSvOttNonYT"
            />
          </Grid>

          {values.isDistributedOnSvOttNonYT && (
            <Grid item xs={12} sm={6} md={6}>
              <FormikTextInput
                type="number"
                label="avg viewers of full stream"
                name="avgNumberOfUsersWhoWillWatchFullStream"
              />
            </Grid>
          )}

          {isUsingHls && (
            <Grid item xs={12}>
              <Typography variant="h5">Quality Levels</Typography>
              <br />{' '}
              <Typography variant="caption" color="secondary">
                NOTE 1: If using only for clipping(i.e OTT distribution does not
                matter), select only the quality level needed for clips.
              </Typography>
              <br />{' '}
              <Typography variant="caption" color="secondary">
                NOTE 2: If there are multiple quality levels, the highest
                Quality level is used by clips.
              </Typography>
              <FormikFieldArrayForCostCalcHlsQualityLevels name="hlsQualityLevels" />
            </Grid>
          )}

          {/* advanced settings */}

          <Grid item xs={12}>
            <FormikSwitch
              label="Show Backend Settings?"
              name="isBackendSettingsEnabled"
            />
          </Grid>

          {values.isBackendSettingsEnabled && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5">Backend Settings</Typography>
                <Typography variant="body1" color="secondary">
                  ** FOR TECH TEAM USE ONLY
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Cloud Production</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikTextInput
                  type="number"
                  label="Audio Bitrate (Kbps)"
                  name="previewAudioBitrateKbps"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikTextInput
                  type="number"
                  label="Video Bitrate (Kbps)"
                  name="previewVideoBitrateKbps"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikDropDown
                  valueDisplayNameMap={regionsDisplayMap}
                  label="S3 Region"
                  name="s3Region"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">S3 Retention Policy Notes</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="caption" color="secondary">
                  FA - Frequent Access Tier - cost: $$$
                </Typography>
                <br />
                <Typography variant="caption" color="secondary">
                  IFA - In-Frequent Access Tier- cost: $$
                </Typography>
                <br />

                <Typography variant="caption" color="secondary">
                  AIA - Archive Instant Access Tier - cost: $
                </Typography>

                <br />
                <br />

                <Typography variant="caption" color="secondary">
                  A policy of 1 FA, 3 IFA, 8 AIA, means the entity will be
                  stored for ONE YEAR. 1, 3, 8 is arrived at based on how S3
                  INTELLIGENT TIERING WORKS.
                  <a
                    // eslint-disable-next-line react/jsx-no-target-blank
                    target="_blank"
                    href="https://aws.amazon.com/s3/storage-classes/intelligent-tiering/"
                  >
                    see reference
                  </a>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">Stream S3 Retention Policy</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikTextInput
                  type="number"
                  label="stream S3 Storage FA Months"
                  name="streamS3StorageFAMonths"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikTextInput
                  type="number"
                  label="stream S3 Storage IFA Months"
                  name="streamS3StorageIFAMonths"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikTextInput
                  type="number"
                  label="stream S3 Storage AIA Months"
                  name="streamS3StorageAIAMonths"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">
                  Highlights S3 Retention Policy
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikTextInput
                  type="number"
                  label="highlight S3 Storage FA Months"
                  name="highlightS3StorageFAMonths"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikTextInput
                  type="number"
                  label="highlight S3 Storage IFA Months"
                  name="highlightS3StorageIFAMonths"
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikTextInput
                  type="number"
                  label="highlight S3 Storage AIA Months"
                  name="highlightS3StorageAIAMonths"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">HLS Config</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikTextInput
                  type="number"
                  label="hls segment length (seconds)"
                  name="hlsSegmentDurationSeconds"
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="Calculate" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
