import { BASE_URL } from '../constants';

const getCategoryCodes = async () => {
  try {
    const response = await fetch(`${BASE_URL}/api/category_codes/?cache=false`);

    const json = await response.json();

    if (json.error) {
      throw new Error(json.error.errorMessage);
    }

    return json.data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default getCategoryCodes;
