import React, { useState, useRef } from 'react';
import { useFormikContext, useField } from 'formik';
import { TextField } from '@material-ui/core';

/**
 * component to be used in formik with
 * list of strings field.
 *
 * takes the list of strings and displays as a
 * comma separated string. Also refects the edits in
 * the list of string value of the form
 */
const ArrayInputCommaString = ({
  fullwidth,
  multiline,
  label = 'default label',
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField({ ...props });

  // initial values
  const ref = useRef({
    listVal: field.value || [],
  });

  const [textVal, setTextVal] = useState(ref.current.listVal.join(','));

  const handleTextChange = value => {
    const temp = value ? value.split(',') : [];

    ref.current.listVal = temp;
    setFieldValue(field.name, ref.current.listVal);
    setTextVal(value);
  };

  return (
    <TextField
      fullWidth={fullwidth}
      multiline={multiline}
      value={textVal}
      onChange={e => handleTextChange(e.target.value)}
      error={!!(meta.touched && meta.error)}
      label={label}
      helperText={`${meta.touched && meta.error ? meta.error : ''}`}
    />
  );
};

export default ArrayInputCommaString;
