import getUserByIdV2 from './getUserByIdV2';
import getUsersByIds from './getUsersByIds';

const getSharingToUsersForUser = async ({ userId }) => {
  const user = await getUserByIdV2({ userId });

  const { sharing_to_accounts } = user;

  const users = await getUsersByIds({ userIds: sharing_to_accounts });

  return users;
};

export default getSharingToUsersForUser;
