import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import ArticleSearch from '../../../Pages/Main/Articles/ArticleSearch';
import StreamSearch from '../../../Pages/Main/StreamsManagement/StreamSearch';
import PageTabs from '../../PageTabs';
import useManagedHomeFeedStore from '../../../Store/useManagedHomeFeedStore';

function AddVideoDialogPage() {
  const [tabValue, setTabValue] = useState(0);
  const { managedHomeFeed } = useManagedHomeFeedStore();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <PageTabs
        value={tabValue}
        onChange={handleTabChange}
        tabNameComponentMap={[
          {
            name: 'Articles',
            component: (
              <ArticleSearch
                isForManagedHomeFeed
                categoryCode={managedHomeFeed?.category_code}
              />
            ),
          },
          {
            name: 'Streams',
            component: (
              <StreamSearch
                isForManagedHomeFeed
                categoryCode={managedHomeFeed?.category_code}
              />
            ),
          },
        ]}
      />
    </Box>
  );
}

export default AddVideoDialogPage;
