import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  formContainer: {
    maxWidth: '600px',
    marginTop: theme.spacing(4),
    padding: theme.spacing(2),
  },
}));

export default useStyles;
