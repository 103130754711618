import axiosInstance from '../../axiosInstance';

const addAthleteProfileData = async (
  mobile = '',
  {
    first_name = '',
    last_name = '',
    position = '',
    sport = '',
    state = '',
    isProAthlete = 'N',
    preferred_section = '',
    best_team_name = '',
    DOB = '',
    description = '',
    aadhar_num = '',
    email = '',
    cm_height = 0.0,
    kg_weight = 0.0,
  },
) => {
  const response = await axiosInstance.post(`/api/admin/v2/players/`, {
    mobile,
    first_name,
    last_name,
    position,
    sport,
    state,
    isProAthlete,
    preferred_section,
    best_team_name,
    DOB,
    description,
    aadhar_num,
    email,
    cm_height,
    kg_weight,
  });

  const { id } = response.data.data;

  return id;
};

export default addAthleteProfileData;
