import { Formik } from 'formik';
import React from 'react';
import {
  GameResult,
  GameResultBySport,
} from '../../../../../API/OverrideGameResult/types';
import FormFields from './FormFields';
import { generateInitialValuesFromGameResultBySport } from '../utils';

type OverrideFormProps = {
  onSubmit: (
    values: GameResultBySport,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => void;
  gameResult: GameResult | undefined;
  sport: string;
  gs: any;
  stream: any;
};

const OverrideForm: React.FC<OverrideFormProps> = ({
  onSubmit,
  gameResult,
  sport,
  gs,
  stream,
}) => {
  return (
    <Formik
      initialValues={generateInitialValuesFromGameResultBySport(
        gameResult,
        sport,
        gs,
      )}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values as GameResultBySport, setSubmitting);
      }}
    >
      <FormFields sport={sport} stream={stream} />
    </Formik>
  );
};

export default OverrideForm;
