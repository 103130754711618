import React, { useState, useEffect, useRef, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router-dom';
import { DropzoneDialog } from 'material-ui-dropzone';
import useStyles from './styles';
import updateTeamDetails from '../../../../API/teams/updateTeamDetails';
import ImageBlock from '../../../../Components/ImageBlock';
import UploadingDialog from '../../../../Components/UploadingDialog';
import updateTeamLogo from '../../../../API/teams/updateTeamLogo';
import TeamBasicDetailsForm from '../../../../Components/Forms/TeamBasicDetailsForm';
import Contexts from '../../../../Contexts';
import getSingleTeamDetails from '../../../../API/teams/getSingleTeamDetails';

export default function TeamDetails() {
  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);
  const { addToast } = useToasts();
  const imageType = {
    PROFILE: 'profile',
    BACKGROUND: 'background',
  };
  const ref = useRef({
    userId: 'default',
    editImageType: imageType.PROFILE,
  });

  const classes = useStyles();
  const [formFields, setFormFields] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const [uploadingDialogOpen, setUploadingDialogOpen] = useState(false);
  const [imageUploadOpen, setImageUploadOpen] = useState(false);
  const [teamLogo, setTeamLogo] = useState('');

  // path params
  const { teamId } = useParams();

  const getTeamData = async id => {
    showLoadingSpinner(true);
    try {
      const data = await getSingleTeamDetails(id);
      setFormFields(data.team);
      setDataFetched(true);
      setTeamLogo(data.team.logo);
    } catch (err) {
      showMessageDialog('ERROR', err.message);
    } finally {
      showLoadingSpinner(false);
    }
  };

  useEffect(() => {
    if (teamId) {
      getTeamData(teamId);
    }
  }, []);

  const handleSubmit = async (values, setSubmitting) => {
    try {
      showLoadingSpinner(true);
      const { name, sport, state, district = '', association = '' } = values;

      const data = await updateTeamDetails(teamId, {
        name,
        sport,
        district,
        state,
        association,
        gender_category_ids: formFields.gender_category_ids,
        age_category_ids: formFields.age_category_ids,
      });

      if (data) {
        setSubmitting(false);
        showLoadingSpinner(false);
        addToast('Saved Successfully', {
          appearance: 'success',
          autoDismiss: 500,
        });
      }
    } catch (error) {
      showLoadingSpinner(false);
      addToast('Error While Saving Team Details', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  const uploadImage = async file => {
    const resp = await updateTeamLogo(file, teamId);

    if (resp) {
      if (ref.current.editImageType === imageType.PROFILE) {
        setTeamLogo(resp.imageUrl);
        addToast('Image Saved Successfully', {
          appearance: 'success',
          autoDismiss: 500,
        });
      }
      setUploadingDialogOpen(false);
    }
  };

  const handleImageDialogSave = files => {
    const file = files[0];

    setImageUploadOpen(false);
    setUploadingDialogOpen(true);
    uploadImage(file);
  };

  const handleImageDialogClose = () => {
    setImageUploadOpen(false);
  };

  const handleEditImage = type => {
    ref.current.editImageType = type;
    setImageUploadOpen(true);
  };

  return (
    <div className={classes.container}>
      {dataFetched && (
        <TeamBasicDetailsForm {...formFields} onSubmit={handleSubmit} />
      )}
      <div className={classes.imageBlock}>
        <ImageBlock
          buttonLabel="Edit"
          blockLabel="Team Logo"
          onButtonPress={() => handleEditImage(imageType.PROFILE)}
          imgSrc={teamLogo}
        />
      </div>

      <DropzoneDialog
        open={imageUploadOpen}
        onSave={handleImageDialogSave}
        acceptedFiles={['image/*']}
        showPreviews
        maxFileSize={5000000}
        filesLimit={1}
        onClose={handleImageDialogClose}
        showAlerts={false}
      />
      <UploadingDialog open={uploadingDialogOpen} />
    </div>
  );
}
