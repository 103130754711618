/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect } from 'react';
import useStyles from './styles';
import UploadingDialog from '../../../../Components/UploadingDialog';
// eslint-disable-next-line import/extensions
import getStreamsForPixHighlightsVas from '../../../../API/streams/getStreamsForPixHighlightsVas';
import StreamsSearchFormForPixHighlightsVas from '../../../../Components/Forms/SearchForms/StreamsSearchFormForPixHighlightsVas/StreamsSearchFormForPixHighlightsVas';
import StreamsTableForPixHighlightsVas from '../../../../Components/StreamsComponent/StreamsTableForPixHighlightsVas/StreamsTableForPixHighlightsVas';

export default function StreamSearchPixellotHighlightsVas() {
  const classes = useStyles();
  const [streamsData, setStreamsData] = useState([]);
  const [isWaitSpinnerOpen, setIsWaitSpinnerOpen] = useState(false);

  // for pagination
  const [count, setCount] = useState(0);

  // pageNo is also used in ref
  // this page no is for display purposes in the table pagination
  // the ref pageNo is to fetch new pages to avoid issue
  // of async setPage
  const [page, setPage] = useState(1);

  // setting the search params in ref
  // so that they can be resused in pagination
  const ref = useRef({
    page,
    stream_key: '',
    title: '',
    channel_id: '',
    category: '',

    highlight_mixer_vas_status: null,
    include_demo_and_trial_streams: true,
    start_date: '',
    end_date: '',
  });

  const getData = async () => {
    setIsWaitSpinnerOpen(true);
    const {
      page,
      stream_key,
      title,
      channel_id,
      category,
      _id,

      highlight_mixer_vas_status,
      include_demo_and_trial_streams,
      start_date,
      end_date,
    } = ref.current;

    const data = await getStreamsForPixHighlightsVas(
      stream_key,
      title,
      channel_id,
      category,
      '',
      page,
      null,
      '',
      _id,
      null,

      // jio stuff
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      include_demo_and_trial_streams,
      start_date,
      end_date,
      'start_timestamp:desc',
      highlight_mixer_vas_status,
    );
    if (data) {
      setStreamsData(data.streams);
      setCount(data.total_count);
      setIsWaitSpinnerOpen(false);
    }
  };

  const onSubmit = async (values, setSubmitting) => {
    const {
      stream_key,
      title,
      channel_id,
      category,
      _id,

      start_date,
      end_date,
      highlight_mixer_vas_status,
      include_demo_and_trial_streams,
    } = values;
    ref.current = {
      ...ref.current,
      stream_key,
      title,
      channel_id,
      category,
      _id,

      start_date,
      end_date,
      highlight_mixer_vas_status,
      include_demo_and_trial_streams,
    };

    // reset pages
    setPage(1);
    ref.current.page = 1;

    await getData();
    setSubmitting(false);
  };

  const handlePageChange = (event, newPage) => {
    // newPage + 1 adjustment because our pages are not 0 indexed
    // hence when passing to the table too we pasee page - 1 and
    // not page
    setPage(newPage + 1);
    ref.current.page = newPage + 1;
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.searchContainer}>
        <StreamsSearchFormForPixHighlightsVas onSubmit={onSubmit} />
      </div>

      {streamsData.length > 0 ? (
        <StreamsTableForPixHighlightsVas
          count={count}
          rowsPerPage={10}
          // not the page - 1 here. Becuase we dont use zero indexing
          page={page - 1}
          onChangePage={handlePageChange}
          data={streamsData}
        />
      ) : (
        <div />
      )}

      <UploadingDialog text="Please wait..." open={isWaitSpinnerOpen} />
    </div>
  );
}
