import React from 'react';

import { Switch, Route, useRouteMatch } from 'react-router-dom';
import UserSearch from './UserSearch/UserSearch';
import UserDetails from './UserDetails/UserDetails';
import SPA from './SPA/SPA';
import SelectedUser from './SelectedUser/SelectedUser';
import SharingTo from './SharingTo/SharingTo';
import AddSharingTo from './AddSharingTo/AddSharingTo';
import UserTemplates from './UserTemplates';

export default function Users() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <UserSearch />
      </Route>
      <Route exact path={`${path}/:userId`}>
        <SelectedUser />
      </Route>
      <Route exact path={`${path}/:userId/details`}>
        <UserDetails />
      </Route>
      <Route exact path={`${path}/:userId/SPA`}>
        <SPA />
      </Route>
      <Route exact path={`${path}/:userId/sharing-to`}>
        <SharingTo />
      </Route>

      <Route exact path={`${path}/:userId/sharing-to/add`}>
        <AddSharingTo />
      </Route>
      <Route exact path={`${path}/:userId/templates`}>
        <UserTemplates />
      </Route>
    </Switch>
  );
}
