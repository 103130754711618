import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, Grid, Switch } from '@material-ui/core';
import useControlSet from '../../../../customHooks/ControlSet/useControlSet';
import UserTemplatesFilter from '../../../../Components/Forms/UserTemplates/Filter/UserTemplatesFilter';
import TemplateCard from '../../../../Components/UsersComponents/UserTemplates/TemplateCard';
import useUserWhitelistedTemplates from '../../../../customHooks/Templates/useUserWhitelistedTemplates';
import {
  unWhitelistTemplateForUser,
  whitelistTemplateForUser,
  toggleEditorAllowed,
} from '../../../../API/ControlSet';
import useUserData from '../../../../customHooks/useUserData';
import UserCard from '../../../../Components/UsersComponents/UserDetails/UserCard';
import updateUserProfile from '../../../../API/users/updateUserProfile';
import PageTabs from '../../../../Components/PageTabs';

const EmptyCard = ({ label = 'NO WHITELISTED TEMPLATES AVAILABLE' }) => {
  return (
    <Box
      border="1px solid #333333"
      width="100%"
      height={200}
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius={5}
    >
      <Typography variant="h5">{label}</Typography>
    </Box>
  );
};

function UserTemplates() {
  const { userId = '' } = useParams();
  const { controlSet, isLoading, refetchControlSet } = useControlSet(userId);
  const { templates, loading, forceRefresh } = useUserWhitelistedTemplates(
    userId,
  );
  const {
    templates: thumbnails,
    loading: thumbnailsLoading,
    forceRefresh: forceRefreshThumbnails,
  } = useUserWhitelistedTemplates(userId, true);
  const { user, loading: userLoading, refetchUser } = useUserData(userId);
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onAddTemplate = async values => {
    try {
      await whitelistTemplateForUser({
        userId,
        templateId: values.template_id,
      });
      forceRefresh();
      forceRefreshThumbnails();
    } catch (err) {
      console.error(err);
    }
  };

  const onDeleteTemplate = async templateId => {
    try {
      await unWhitelistTemplateForUser({
        userId,
        templateId,
      });
      forceRefresh();
      forceRefreshThumbnails();
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditorAllowed = async editorAllowed => {
    try {
      await toggleEditorAllowed({
        userId,
        editorAllowed,
      });
      forceRefresh();
      forceRefreshThumbnails();
      refetchControlSet();
    } catch (err) {
      console.error(err);
    }
  };

  const handlePositionBasedLineupToggle = async enabled => {
    try {
      await updateUserProfile(userId, {
        ...user,
        is_position_based_lineups_enabled: enabled,
      });
      refetchUser();
    } catch (err) {
      console.error(err);
    }
  };

  const templateComponent = ({ is_thumbnail_template = false }) => {
    const newTemplates = is_thumbnail_template ? thumbnails : templates;
    return (
      <>
        <Box
          p={2}
          pt={0}
          borderRadius={5}
          bgcolor="rgba(0,0,0,0.05)"
          boxShadow={4}
        >
          <UserTemplatesFilter
            handleFilter={onAddTemplate}
            is_thumbnail_template={is_thumbnail_template}
          />
        </Box>

        <Box mt={3}>
          <Typography variant="subtitle1">Whitelisted Templates</Typography>
          {newTemplates.length > 0 && (
            <Box border="1px solid #333333" borderRadius={5} p={2}>
              {newTemplates.map(template => {
                return (
                  <TemplateCard
                    template={template}
                    key={template._id}
                    handleDelete={() => onDeleteTemplate(template._id)}
                  />
                );
              })}
            </Box>
          )}

          {newTemplates.length === 0 && <EmptyCard />}
        </Box>
      </>
    );
  };

  if (isLoading || loading || userLoading || thumbnailsLoading)
    return <div>Loading...</div>;

  return (
    <Box p={5}>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={3}>
          <Box p={2} borderRadius={5} bgcolor="rgba(0,0,0,0.2)" boxShadow={4}>
            <UserCard user={user} />
          </Box>

          <Box
            mt={2}
            p={2}
            borderRadius={5}
            border="1px solid #3333333a"
            boxShadow={4}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              bgcolor="rgba(0,0,0,0.1)"
              pl={1}
              borderRadius={5}
            >
              <Typography variant="subtitle2">Allow Editor</Typography>
              <Switch
                checked={controlSet.template_rules.editor_allowed}
                onChange={e => handleEditorAllowed(e.target.checked)}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              bgcolor="rgba(0,0,0,0.1)"
              pl={1}
              borderRadius={5}
              mt={1}
            >
              <Typography variant="subtitle2">
                Can Use Position Based Lineups
              </Typography>
              <Switch
                checked={user.is_position_based_lineups_enabled}
                onChange={e =>
                  handlePositionBasedLineupToggle(e.target.checked)
                }
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={9}>
          <PageTabs
            value={tabValue}
            onChange={handleTabChange}
            tabNameComponentMap={[
              {
                name: 'Templates',
                component: templateComponent({ is_thumbnail_template: false }),
              },
              {
                name: 'Thumbnails',
                component: templateComponent({ is_thumbnail_template: true }),
              },
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default UserTemplates;
