import React, { useState, useEffect, useContext } from 'react';
import { IconButton, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import useStyles from './styles';
import Contexts from '../../../../Contexts';
import SharingToUsersTable from '../../../../Components/UsersComponents/SharingToUsersTable/SharingToUsersTable';
import removeSharingToForUser from '../../../../API/users/removeSharingToForUser';
import getSharingToUsersForUser from '../../../../API/users/getSharingToUsersForUser';

export default function SharingTo() {
  const { showLoadingSpinner } = useContext(Contexts);
  const classes = useStyles();
  const [sharingToUsers, setSharingToUsers] = useState([]);
  const { userId } = useParams();
  const { addToast } = useToasts();

  const history = useHistory();

  const initialize = async () => {
    showLoadingSpinner(true);
    const users = await getSharingToUsersForUser({ userId });
    setSharingToUsers(users);
    showLoadingSpinner(false);
  };

  useEffect(() => {
    initialize();
  }, []);

  const handleDelete = async user => {
    try {
      showLoadingSpinner(true);
      await removeSharingToForUser({ sharingToUserId: user._id, userId });
      initialize();
      showLoadingSpinner(false);
      addToast('deleted', {
        appearance: 'success',
        autoDismiss: 500,
      });
      return true;
    } catch (e) {
      showLoadingSpinner(false);
      addToast('error in deleting', {
        appearance: 'error',
        autoDismiss: 500,
      });
      return false;
    }
  };

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex', textAlign: 'center' }}>
        <Typography variant="h5">
          IMPORTANT: These are accounts that that will have access to TEAMS,
          PLAYERS of this account.
        </Typography>
      </div>

      <div style={{ display: 'flex' }}>
        <p style={{ marginRight: 10 }}>Add Account</p>
        <IconButton
          edge="start"
          className={classes.searchIcon}
          color="default"
          aria-label="menu"
          onClick={() => {
            history.push({
              pathname: `/users/${userId}/sharing-to/add`,
            });
          }}
        >
          <Add />
        </IconButton>
      </div>

      {sharingToUsers.length > 0 && (
        <div className={classes.editContainer}>
          <SharingToUsersTable
            data={sharingToUsers}
            targetUserId={userId}
            count={1000}
            page={0}
            rowsPerPage={1000}
            onChangePage={() => {}}
            onSharingToggle={user => handleDelete(user)}
          />
        </div>
      )}
    </div>
  );
}
