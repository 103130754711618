import { FeedType } from '../../../API/ManagedHomeFeed/types';

export type ValueDisplayNameMap = {
  value: string;
  displayName: string;
};

export const SPORT_KEY_VALUE_LIST: ValueDisplayNameMap[] = [
  {
    value: 'all_sports',
    displayName: 'All Sports',
  },
  {
    value: 'kab',
    displayName: 'Kabaddi',
  },
  {
    value: 'football',
    displayName: 'Football',
  },
  {
    value: 'cricket',
    displayName: 'Cricket',
  },
  {
    value: 'basketball',
    displayName: 'Basketball',
  },
  {
    value: 'volleyball',
    displayName: 'Volleyball',
  },
];

export const JIO_KEY_VALUE_LIST: ValueDisplayNameMap[] = [
  {
    value: 'all_sports',
    displayName: 'All Sports',
  },
];

export const CATEGORY_KEY_VALUE_LIST_BY_FEED_TYPE: Record<
  FeedType,
  ValueDisplayNameMap[]
> = {
  HOME_FEED: SPORT_KEY_VALUE_LIST,
  JIO_SPORTS_HUB: JIO_KEY_VALUE_LIST,
  PLAY_FEED: [],
};
