import React from 'react';
import { Formik } from 'formik';
import FormFields from './FormFields';

const ArticleSearchForm = ({
  title = '',
  category = '',
  channel_id = '',
  article_id = '',
  preferred_section = '',
  article_type = '',

  creation_source = 'USER',

  // linked match id
  stream_key = '',

  // jio stuff
  is_commentary = false,
  is_jio_stb = false,
  is_ppv = false,
  is_sub = false,
  is_ad = false,
  is_jio_store = false,
  is_jio_tv = false,
  is_all_jio = false,
  is_global = false,
  isForManagedHomeFeed = true,
  should_sort_by_views = false,
  onSubmit = () => console.log('events search form clicked'),
}) => {
  return (
    <>
      <Formik
        initialValues={{
          title,
          category,
          channel_id,
          article_id,
          preferred_section,
          article_type,

          // jio stuff
          is_commentary,
          is_jio_stb,
          is_ppv,
          is_sub,
          is_ad,
          is_jio_store,
          is_jio_tv,
          is_all_jio,
          is_global,

          creation_source,

          // linked match id
          stream_key,
          should_sort_by_views: isForManagedHomeFeed,
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields isForManagedHomeFeed={isForManagedHomeFeed} />
      </Formik>
    </>
  );
};

export default ArticleSearchForm;
