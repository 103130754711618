import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import TeamsSearch from './TeamsSearch/TeamsSearch';
import SelectedTeam from './SelectedTeam/SelectedTeam';
import TeamDetails from './TeamDetails/TeamDetails';
import AddTeam from './AddTeam/AddTeam';
import TeamPlayer from './TeamPlayers/TeamPlayers';
import AddTeamPlayer from './AddTeamPlayer/AddTeamPlayer';

export default function TeamsManagement() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <TeamsSearch from="addTeamPlayer" />
      </Route>
      <Route exact path={`${path}/add`}>
        <AddTeam />
      </Route>
      <Route exact path={`${path}/:teamId`}>
        <SelectedTeam />
      </Route>
      <Route exact path={`${path}/:teamId/details`}>
        <TeamDetails />
      </Route>
      <Route exact path={`${path}/:teamId/players`}>
        <TeamPlayer />
      </Route>
      <Route exact path={`${path}/:teamId/players/add`}>
        <AddTeamPlayer />
      </Route>
    </Switch>
  );
}
