import React from 'react';
import { Paper, Typography, Button } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import CheckIcon from '@material-ui/icons/Check';
import useStyles from './styles';

const StatusControllerAndId = ({
  isActive = 'Y',
  onClick = () => 'status controller button clicked',
  articleId = 'event id',
  media_path = '',
  media_uploaded = false,
}) => {
  const classes = useStyles();

  const renderVideoLink = () => (
    <div className={classes.statusControllerContainer}>
      <a href={`https://sportvot.com/video/${articleId}`}>
        <Typography variant="h6">Video Link</Typography>
      </a>
    </div>
  );

  return (
    <Paper elevation={3}>
      <div className={classes.statusControllerContainer}>
        <Typography variant="h6">Status</Typography>

        <Button
          variant="outlined"
          color={isActive === 'Y' ? 'primary' : 'secondary'}
          onClick={onClick}
        >
          {isActive === 'Y' ? 'ENABLED' : 'DISABLED'}
        </Button>
      </div>

      <div className={classes.statusControllerContainer}>
        <Typography variant="h6">Article id</Typography>
        <Typography variant="h6">{articleId}</Typography>
      </div>

      <div className={classes.statusControllerContainer}>
        <Typography variant="h6">Media Uploaded</Typography>
        {media_uploaded ? <CheckIcon /> : <CancelIcon />}
      </div>

      <div className={classes.statusControllerContainer}>
        <Typography variant="h6">Media Processed</Typography>
        {media_uploaded && media_path ? <CheckIcon /> : <CancelIcon />}
      </div>

      {media_uploaded && media_path && renderVideoLink()}
    </Paper>
  );
};

export default StatusControllerAndId;
