import axiosInstance from '../../axiosInstance';

const updateUserChannelName = async (userId, channelName) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/users/${userId}/channel-name`,
    {
      channel_name: channelName,
    },
  );

  const { user } = response.data.data;

  return user;
};

export default updateUserChannelName;
