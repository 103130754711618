import React, { useContext } from 'react';
import { Formik } from 'formik';
import FormFields from './FormFields';
import Contexts from '../../../../Contexts';
import {
  generateStateDropDownList,
  generateSportDropDownList,
} from '../../../../Utils/dropDownListGenerators';

const TournamentSearchForm = ({
  name = '',
  state = '',
  sport = '',
  tournament_id = '',
  start_date = '',
  end_date = '',
  onSubmit = () => console.log('teams search form clicked'),
}) => {
  const context = useContext(Contexts);
  const { formConfig } = context;

  return (
    <>
      <Formik
        initialValues={{
          name,
          state,
          sport,
          tournament_id,
          start_date,
          end_date,
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields
          stateDropDownList={generateStateDropDownList(formConfig)}
          sportDropDownList={generateSportDropDownList(formConfig)}
        />
      </Formik>
    </>
  );
};

export default TournamentSearchForm;
