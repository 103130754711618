import React from 'react';

// setting some default contexts
// this is primarily for intellisense

// the actual values are defined in the
// app storage

const Contexts = React.createContext({
  formConfig: {},
  setFormConfig: data => console.log('in setFormConfig with data', data),

  // loading
  loadingSpinnerEnabled: false,
  showLoadingSpinner: enabled =>
    console.log('in showLoadingSpinner with state', enabled),

  // message dialog
  messageDialogState: {
    title: 'default title',
    message: 'default message',
    enabled: false,
  },

  showMessageDialog: (title, message) =>
    console.log(
      'in showMessageDialog with state title, message as: ',
      title,
      message,
    ),

  hideMessageDialog: () =>
    console.log('in hideMessageDialog with state title, message as: '),

  categoriesDropDownList: [],
  setCategoriesDropDownList: category =>
    console.log('in setCategoriesDropDownList '),

  loggedIn: false,
  setLoginStatusAndToken: (status, token = null) => {
    console.log('in here');
  },

  userDoc: {},
  setUserDoc: (val = {}) => console.log('setting user doc with ' + val),

  sectionDetails: {},
  setSectionDetails: (val = {}) =>
    console.log('setting section details with ' + val),

  isDarkMode: true,
  setIsDarkMode: val => console.log(`current theme${val}`),
});

export default Contexts;
