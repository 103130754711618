import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  container: {
    minWidth: '600px',
    marginTop: theme.spacing(4),
  },
  scoreContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 20,
    flexDirection: 'column',
  },
  drawContainer: {
    paddingLeft: 15,
  },
  scorePointsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '90%',
    flexDirection: 'column',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default useStyles;
