import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Grid, Typography } from '@material-ui/core';
import { SectionTypes, getSectionColorByType } from '../utils';
import SectionTypeCard from './SectionTypeCard';

type AddSectionFormProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (sectionName: string, sectionType: string) => void;
};

export default function AddSectionForm(props: AddSectionFormProps) {
  const { open, setOpen, onSubmit } = props;
  const [sectionName, setSectionName] = useState('');
  const [sectionType, setSectionType] = useState('LONG_CONTENT');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add New Section</DialogTitle>
      <DialogContent>
        <DialogContentText>
          This new section will be added as the last section but can be
          re-ordered later.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Section Name"
          type="text"
          fullWidth
          value={sectionName}
          onChange={e => setSectionName(e.target.value)}
        />

        <Box mt={1}>
          <Typography variant="subtitle2" gutterBottom>
            Section Type
          </Typography>
          <Grid container spacing={2}>
            {SectionTypes.map(section => {
              return (
                <Grid item xs={4}>
                  <SectionTypeCard
                    onClick={() => setSectionType(section.value)}
                    sectionType={section.displayName}
                    selected={section.value === sectionType}
                    color={
                      getSectionColorByType(section.value) || 'transparent'
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => onSubmit(sectionName, sectionType)}
          color="primary"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}
