import axiosInstance from '../../axiosInstance';

const getStreamsForPixHighlightsVas = async (
  stream_key = '',
  title = '',
  channel_id = '',
  category = '',
  preferred_section = '',
  page = '1',
  is_live = false,
  event_id = '',
  _id,
  article_type = '',

  // jio stuff
  is_commentary,
  is_jio_stb,
  is_ppv,
  is_sub,
  is_ad,
  is_jio_store,
  is_jio_tv,
  is_all_jio,
  is_global,

  include_demo_and_trial_streams,
  start_date,
  end_date,
  sort = '_id:desc',
  highlight_mixer_vas_status,
) => {
  const queryParams = {
    title,
    channel_id,
    category,
    preferred_section,

    page,
    stream_key,
    cache: 'false',
    stream_id: _id,
    event_id,
    article_type,

    // jio stuff
    is_commentary: is_commentary || null,
    is_jio_stb: is_jio_stb || null,
    is_ppv: is_ppv || null,
    is_sub: is_sub || null,
    is_ad: is_ad || null,
    is_jio_store: is_jio_store || null,
    is_jio_tv: is_jio_tv || null,
    is_all_jio: is_all_jio || null,
    is_global: is_global || null,
    include_demo_and_trial_streams: include_demo_and_trial_streams || null,
    from_timestamp: start_date,
    to_timestamp: end_date,
    highlight_mixer_vas_status: highlight_mixer_vas_status || null,
    sort,
  };

  if (is_live) {
    queryParams.is_live = true;
  }

  const response = await axiosInstance.get(`/api/admin/v2/streams-new/`, {
    params: queryParams,
  });

  const { data } = response.data;

  return data;
};

export default getStreamsForPixHighlightsVas;
