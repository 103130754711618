import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import useStyles from './styles';
import SearchBox from '../../../../Components/SearchBox';
import Contexts from '../../../../Contexts';
import getByMobile from '../../../../API/users/getByMobile';

export default function AddPlayer() {
  const { showMessageDialog } = useContext(Contexts);

  const classes = useStyles();

  const [mobile, setMobile] = useState('');
  const history = useHistory();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [fullName, setFullName] = useState('');
  const [mobileNo, setMobileNo] = useState('');

  const handleYesClicked = () => {
    history.push({
      pathname: `/player/add/regularToAthlete/${mobile}`,
    });
  };

  const handleClear = () => {
    setMobile('');
  };

  const handleSearch = async () => {
    try {
      if (!mobile || mobile.length !== 10 || isNaN(mobile))
        throw new Error('Invalid mobile number');

      const data = await getByMobile(mobile);

      if (data) {
        if (data.profile_type === 'athlete') {
          history.push({
            pathname: `/player/${data._id}`,
          });
        }

        if (data.profile_type === 'regular') {
          setFullName(`${data.first_name} ${data.last_name}`);
          setMobileNo(data.mobile);
          setDialogOpen(true);
        }
      } else {
        history.push({
          pathname: `/player/add/newAthlete/${mobile}`,
        });
      }
    } catch (err) {
      showMessageDialog('ERROR', err.message);
    }
  };

  return (
    <div className={classes.container}>
      <SearchBox
        type="number"
        label="Search Mobile"
        value={mobile}
        onChangeValue={setMobile}
        onSearch={handleSearch}
        onClear={handleClear}
      />

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="draggable-dialog-title">Alert!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`User ${fullName} with  Mobile number ${mobileNo} is not an athlete.
            Do you want to convert this profile to athlete?
          `}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleYesClicked} color="primary">
            Yes
          </Button>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
