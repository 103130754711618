import React, { useState, useRef, useContext, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useParams } from 'react-router-dom';
import useStyles from './styles';
import Contexts from '../../../../Contexts';
import AddAthleteTable from '../../../../Components/TeamsComponent/AddAthleteTable/AddAthleteTable';
import AthletesSearchForm from '../../../../Components/Forms/SearchForms/AthletesSearchForm';
import getAthletes from '../../../../API/athletes/getAthletes';
import getStreamByStreamKey from '../../../../API/scoreCard/getStreamByStreamKey';
import addStreamLinkedPlayer from '../../../../API/streams/addStreamLinkedPlayer';
import removeStreamLinkedPlayer from '../../../../API/streams/removeStreamLinkedPlayer';

export default function AddStreamLinkedPlayer() {
  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);
  const classes = useStyles();
  const [playersData, setPlayersData] = useState([]);
  const { streamId } = useParams();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [currentLinkedPlayers, setCurrentLinkedPlayers] = useState([]);
  const { addToast } = useToasts();

  const ref = useRef({
    first_name: '',
    last_name: '',
    mobile: '',
    sport: '',
    position: '',
    state: '',
    pageNo: 1,
  });

  const getData = async () => {
    showLoadingSpinner(true);
    try {
      const {
        first_name,
        last_name,
        mobile,
        sport,
        position,
        state,
        pageNo,
      } = ref.current;

      const data = await getAthletes(
        first_name,
        last_name,
        mobile,
        state,
        sport,
        position,
        '',
        false,
        pageNo,
        '10',
      );

      if (data) {
        setPlayersData(data.players);
        setCount(data.total_count);
        showLoadingSpinner(false);
      }
    } catch (err) {
      showMessageDialog('ERROR', err.message);
      showLoadingSpinner(false);
    }
  };

  const onSubmit = async (values, setSubmitting) => {
    const { first_name, last_name, mobile, sport, position, state } = values;
    ref.current = {
      ...ref.current,
      first_name,
      last_name,
      mobile,
      sport,
      position,
      state,
    };

    // reset pages
    setPage(1);
    ref.current.pageNo = 1;

    await getData();
    setSubmitting(false);
  };

  const setCurrentLinkedPlayersFromStream = stream => {
    if (!stream) return;
    setCurrentLinkedPlayers(stream.linked_athletes);
  };

  const getStream = async () => {
    const fetchedStream = await getStreamByStreamKey(streamId);
    setCurrentLinkedPlayersFromStream(fetchedStream);
  };

  const removePlayer = async playerId => {
    showLoadingSpinner(true);
    try {
      const updatedStream = await removeStreamLinkedPlayer(streamId, playerId);
      setCurrentLinkedPlayersFromStream(updatedStream);

      addToast('Successfully Removed Player', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } catch (error) {
      addToast('Error While Unlinking Player', {
        appearance: 'error',
        autoDismiss: 500,
      });
    } finally {
      showLoadingSpinner(false);
    }
  };

  const addPlayer = async playerId => {
    showLoadingSpinner(true);
    try {
      const updatedStream = await addStreamLinkedPlayer(streamId, playerId);
      setCurrentLinkedPlayersFromStream(updatedStream);
      addToast('Successfully Added', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } catch (error) {
      addToast('Error while linking player', {
        appearance: 'error',
        autoDismiss: 500,
      });
    } finally {
      showLoadingSpinner(false);
    }
  };

  const handleRowActionClick = async player => {
    const selectedPlayerId = player._id;
    if (currentLinkedPlayers.includes(selectedPlayerId)) {
      removePlayer(selectedPlayerId);
    } else {
      addPlayer(selectedPlayerId);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
    ref.current.pageNo = newPage + 1;
    getData();
  };

  useEffect(() => {
    getData();
    getStream();
  }, []);

  return (
    <div className={classes.searchContainer}>
      <div className={classes.root}>
        <AthletesSearchForm onSubmit={onSubmit} />
        <div className={classes.addContainer}>
          <AddAthleteTable
            data={playersData}
            inSquad={currentLinkedPlayers}
            onRowActionClicked={handleRowActionClick}
            count={count}
            page={page - 1}
            onChangePage={handlePageChange}
            rowsPerPage={10}
          />
        </div>
      </div>
    </div>
  );
}
