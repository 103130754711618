/* eslint-disable react/prop-types */
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useStyles from './styles';

const SideList = ({ setOpen, nameIconArray, handleSideBarItemClick }) => {
  const classes = useStyles();

  return (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => setOpen(false)}
      onKeyDown={() => setOpen(false)}
    >
      <List>
        {nameIconArray.map(data => (
          <ListItem
            button
            key={data.name}
            onClick={() => handleSideBarItemClick(data.name, data.routeName)}
          >
            <ListItemIcon>{data.icon}</ListItemIcon>
            <ListItemText primary={data.name} />
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default SideList;
