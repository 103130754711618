import React, { useState, useEffect, useContext } from 'react';
import './App.css';
import moment from 'moment';
import JwtDecode from 'jwt-decode';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Login from './Pages/Login';
import Main from './Pages/Main';
import Contexts from './Contexts/Contexts';
import { generateCategoriesDropDown } from './Utils/dropDownListGenerators';
import axiosInstance from './axiosInstance';
import SuccessFailedDialog from './Components/SuccessFailedDialog.js';
import UploadingDialog from './Components/UploadingDialog';
import getUserDoc from './API/permission/getUserDoc';
import getCategoryCodes from './API/categories/getCategoryCodes';
import { AUTHORITIES } from './Utils/authorities';
import getNewAccessToken from './API/login/getNewAccessToken';
import {
  THEME_PREFERENCE,
  TOKEN_CHECK_INTERVAL,
  TOKEN_EXPIRY_PROXIMITY_LIMIT,
} from './API/constants';
import themeDark from './UI/Themes/ThemeDark';
import themeLight from './UI/Themes/ThemeLight.js';

function App() {
  const [formConfigLoaded, setFormConfigLoaded] = useState(false);

  const {
    setFormConfig,
    setCategoriesDropDownList,
    showMessageDialog,
    loggedIn,
    messageDialogState,
    loadingSpinnerEnabled,
    hideMessageDialog,
    setUserDoc,
    setLoginStatusAndToken,
    setSectionDetails,
    isDarkMode,
    setIsDarkMode,
  } = useContext(Contexts);

  // set FormConfig in conext
  const setFormConfigInContext = async () => {
    const response = await fetch('/FormConfig.json');
    const data = await response.json();
    if (data) {
      setFormConfig(data);
      setFormConfigLoaded(true);
    }
  };

  // set categoriesDropDownList
  const setCategoriesDropDown = async () => {
    try {
      const data = await generateCategoriesDropDown();
      setCategoriesDropDownList(data);
    } catch (err) {
      showMessageDialog('ERROR', err.message);
    }
  };

  // set sectionDetails in context
  const setSectionDetailsInContext = async () => {
    try {
      const { section_details = {} } = await getCategoryCodes();

      setSectionDetails(section_details);
    } catch (err) {
      showMessageDialog('ERROR', err.message);
    }
  };

  const setupAxiosInterceptors = () => {
    // =================== auth / request  interceptors =============================

    // Add a request interceptor to add the jwt token
    axiosInstance.interceptors.request.use(
      config => {
        // eslint-disable-next-line no-param-reassign
        config.headers = {
          ...config.headers,
          Authorization: localStorage.getItem('jwtToken')
            ? `Bearer ${localStorage.getItem('jwtToken')}`
            : '',
        };
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );

    // Add a response interceptor to handdle unauthorized requests
    axiosInstance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        if (error.response || loggedIn) {
          const { status } = error.response;
          if (status === 500) {
            showMessageDialog('ERROR', error.response.data.error.errorMessage);
          }

          if (status === 403) {
            showMessageDialog('ERROR', 'Forbidden');
          }
          if (status === 401) {
            showMessageDialog('ERROR', 'Access Denied');
            setLoginStatusAndToken(false);
          }
          console.log(error.response.data.error.errorMessage);
        }

        let rejectError;
        try {
          rejectError = new Error(error.response.data.error.errorMessage);
        } catch (err) {
          rejectError = error;
        }
        return Promise.reject(rejectError);
      },
    );
  };

  useEffect(() => {
    setupAxiosInterceptors();
    setFormConfigInContext();
    setCategoriesDropDown();
    setSectionDetailsInContext();

    // to set the login status on first load
    if (localStorage.getItem('loggedIn') === 'Y') {
      setLoginStatusAndToken(true);
    }
  }, []);

  const runAuthoritiesValidations = userDoc => {
    if (!userDoc.authorities) throw Error('Authorities not present');
    if (
      userDoc.authorities.includes(AUTHORITIES.ADMIN_USER) ||
      userDoc.authorities.includes(AUTHORITIES.PASS_AUTHENTICATOR)
    )
      return;
    console.log('not an admin user or authenticator so logging out');
    alert('Access Denied. You are not an admin or pass authenticator');
    setLoginStatusAndToken(false);
  };

  // save user doc to context if a jwt token is stored in local storage
  const getUserData = async () => {
    const jwtToken = localStorage.getItem('jwtToken');
    try {
      console.log('attempting to fetch user doc with token', jwtToken);
      const userDoc = await getUserDoc();
      runAuthoritiesValidations(userDoc);
      console.log('setting user doc with ', userDoc);
      setUserDoc(userDoc);
    } catch (error) {
      console.error(error.message);
    }
  };

  const setNewAccessToken = async () => {
    const refreshToken = localStorage.getItem('jwtRefreshToken');
    const refreshId = localStorage.getItem('jwtRefreshId');

    const newAccessToken = await getNewAccessToken({
      refreshToken,
      refreshId,
    });

    console.log('got new access token', newAccessToken);

    if (newAccessToken) localStorage.setItem('jwtToken', newAccessToken);
  };

  const executeLoggedInActions = async () => {
    await setNewAccessToken();
    getUserData();
  };

  const checkAccessTokenExpiry = async () => {
    const jwtToken = localStorage.getItem('jwtToken');
    if (!jwtToken) return;

    const token = JwtDecode(jwtToken);
    const { exp } = token;
    const currentDateTime = moment().format('DD/MM/YYYY HH:mm:ss');
    const expiryDateTime = moment.unix(exp).format('DD/MM/YYYY HH:mm:ss');

    const diffSeonds = moment(expiryDateTime, 'DD/MM/YYYY HH:mm:ss').diff(
      moment(currentDateTime, 'DD/MM/YYYY HH:mm:ss'),
      'seconds',
    );

    const diffSeondsInt = parseInt(diffSeonds, 10);

    // jsut for logs ----------
    const diffMiutes = moment(expiryDateTime, 'DD/MM/YYYY HH:mm:ss').diff(
      moment(currentDateTime, 'DD/MM/YYYY HH:mm:ss'),
      'minutes',
    );
    console.log('access token expires in', diffMiutes, 'minutes');

    if (diffSeondsInt < TOKEN_EXPIRY_PROXIMITY_LIMIT) {
      console.log('token about to expire, setting a new one');
      setNewAccessToken();
    }
  };

  // useffect to userDoc in context when loggedIn
  // and to clear it from context when logged out
  useEffect(() => {
    if (loggedIn) {
      executeLoggedInActions();
    } else {
      // clear the user doc
      setUserDoc({});
    }
  }, [loggedIn]);

  // loop to check jwt token expiry
  useEffect(() => {
    const tokenCheckInterval = setInterval(() => {
      checkAccessTokenExpiry();
    }, TOKEN_CHECK_INTERVAL * 1000);
    return () => {
      if (tokenCheckInterval) {
        clearInterval(tokenCheckInterval);
      }
    };
  }, []);

  useEffect(() => {
    const theme = localStorage.getItem(THEME_PREFERENCE);

    if (theme === null) {
      setIsDarkMode(true);
      localStorage.setItem(THEME_PREFERENCE, 'DARK');
    } else {
      setIsDarkMode(theme === 'DARK');
    }
  }, []);

  return (
    <>
      <ThemeProvider theme={isDarkMode ? themeDark : themeLight}>
        <CssBaseline />
        {!loggedIn && <Login />}

        {loggedIn && formConfigLoaded && <Main />}

        <SuccessFailedDialog
          title={messageDialogState.title}
          message={messageDialogState.message}
          open={messageDialogState.enabled}
          onClose={hideMessageDialog}
        />

        <UploadingDialog text="" open={loadingSpinnerEnabled} />
      </ThemeProvider>
    </>
  );
}

export default App;
