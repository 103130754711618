/// api/admin/v2/players/dummy-player/606ece66e3a0d863eaa09797/upgrade-to-non-dummy

import axiosInstance from '../../axiosInstance';

const upgradeToNonDummy = async ({ playerId = 'none', mobile = '' }) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/players/dummy-player/${playerId}/upgrade-to-non-dummy`,
    {
      mobile,
    },
  );

  const { data } = response.data;
  return data.player;
};

export default upgradeToNonDummy;
