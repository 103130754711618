import { useEffect, useState } from 'react';
import useManagedHomeFeedStore from '../../Store/useManagedHomeFeedStore';
import { getSectionPlayers } from '../../API/ManagedHomeFeed';

const useSectionPlayers = () => {
  const [players, setPlayers] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const { managedHomeFeed, selectedSectionKey } = useManagedHomeFeedStore();

  useEffect(() => {
    if (!managedHomeFeed || !selectedSectionKey) return;
    const fetchPlayers = async () => {
      try {
        const { players: playersList } = await getSectionPlayers({
          sectionKey: selectedSectionKey,
          categoryCode: managedHomeFeed?._id,
        });

        setPlayers(playersList);
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchPlayers();
  }, [managedHomeFeed, selectedSectionKey]);

  return {
    players,
    loading,
    error,
  };
};

export default useSectionPlayers;
