export const SCORING_MODE = [
  {
    value: 'BASIC',
    displayName: 'Basic',
  },
  {
    value: 'DETAILED',
    displayName: 'Detailed',
  },
];

export const PUBLISHED_STATUS = [
  {
    value: true,
    displayName: 'Published',
  },
  {
    value: false,
    displayName: 'Not Published',
  },
];
