import { BASE_URL } from '../constants';

const approveRequest = async (
  can_stream = false,
  can_upload = false,
  approved = false,
  userId = 'default',
  channel_name = 'default',
) => {
  try {
    const postData = {
      can_stream,
      can_upload,
      userId,
      approved,
      channel_name,
    };

    const response = await fetch(`${BASE_URL}/api/user/approve_ugc_request`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });

    const json = await response.json();

    if (json.error) {
      console.error(json.error);
      return null;
    }

    return json.data;
  } catch (e) {
    return null;
  }
};

export default approveRequest;
