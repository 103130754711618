import axiosInstance from '../../axiosInstance';

const getUsersByIds = async ({ userIds = [] }) => {
  if (!userIds.length) return [];

  const response = await axiosInstance.get(`/api/admin/v2/users/`, {
    params: {
      ids: userIds.join(','),
    },
  });

  const { data } = response.data;

  return data.users;
};

export default getUsersByIds;
