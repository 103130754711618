import React, { useState } from 'react';
import { Paper, Typography, Button, TextField } from '@material-ui/core';
import useStyles from './styles';

const LargeTextInputBlock = ({
  buttonLabel = 'save',
  blockLabel = 'text block',
  initialText = '',
  onButtonPress = e => console.log(e),
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(initialText);

  return (
    <Paper elevation={3} className={classes.imageBlockContainer}>
      <Typography variant="h6">{blockLabel}</Typography>

      <TextField
        multiline
        value={value}
        rowsMax={10}
        fullWidth
        onChange={e => setValue(e.target.value)}
      />

      <Button variant="contained" onClick={() => onButtonPress(value)}>
        {buttonLabel}
      </Button>
    </Paper>
  );
};

export default LargeTextInputBlock;
