import React, { useEffect, useState } from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box, Container, Input } from '@material-ui/core';
import usePixellotTenants, {
  Tenant,
} from '../../../customHooks/Tenants/usePixellotTenants';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export default function PixellotTenants() {
  const classes = useStyles();
  const { tenants, error, loading } = usePixellotTenants();
  const [tenantName, setTenantName] = useState('');
  const [localTenants, setLocalTenants] = useState<Tenant[]>([]);

  const onSearchInputChange = (name: string) => {
    if (name === '') {
      setLocalTenants(tenants);
      setTenantName(name);
      return;
    }

    const filteredTenants = localTenants.filter(tenant =>
      tenant.tenant.toLowerCase().includes(name.toLowerCase()),
    );
    setLocalTenants(filteredTenants);
    setTenantName(name);
  };

  useEffect(() => {
    setLocalTenants(tenants);
  }, [tenants]);

  if (loading || error) return null;

  return (
    <Box my={5}>
      <Container maxWidth="lg">
        <Box display="flex" width="100%" justifyContent="flex-end" mb={2}>
          <Input
            placeholder="Tenant name"
            value={tenantName}
            onChange={e => onSearchInputChange(e.target.value)}
          />
        </Box>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>SV User ID</StyledTableCell>
                <StyledTableCell align="center">Tenant</StyledTableCell>
                <StyledTableCell align="center">Mobile</StyledTableCell>
                <StyledTableCell align="center">Fixed OTP</StyledTableCell>
                <StyledTableCell align="left">
                  Overlay provider VASs
                </StyledTableCell>
                <StyledTableCell align="left">
                  Stream enhancers VASs
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {localTenants.map(row => (
                <StyledTableRow key={row.user_id}>
                  <StyledTableCell component="th" scope="row">
                    {row.user_id}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    {row.tenant}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.mobile}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.fixed_otp}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <ul>
                      {row.joined_overlay_provider_vas.map(vas => {
                        return <li key={vas}>{vas}</li>;
                      })}
                    </ul>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <ul>
                      {row.joined_stream_enhancer_vas.map(vas => {
                        return <li key={vas}>{vas}</li>;
                      })}
                    </ul>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
}
