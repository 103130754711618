import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import RequestList from './RequestList';
import VerificationCard from './VerificationCard';

const Verifications = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/verify-request`}>
        <VerificationCard />
      </Route>
      <Route path={match.path}>
        <RequestList />
      </Route>
    </Switch>
  );
};

export default Verifications;
