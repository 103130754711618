import React from 'react';
import { Paper, Typography, Button } from '@material-ui/core';
import useStyles from './styles';

const StatusControllerAndId = ({
  isActive = 'Y',
  onClick = () => 'status controller button clicked',
  eventId = 'event id',
  media_path = '',
  stream_key = '',
  matchId = '',
}) => {
  const classes = useStyles();

  const renderVideoLink = () => (
    <div className={classes.statusControllerContainer}>
      <a href={`https://sportvot.com/stream/${matchId}`}>
        <Typography variant="h6">Video Link</Typography>
      </a>
    </div>
  );

  const renderWaiting = () => (
    <div className={classes.statusControllerContainer}>
      <Typography variant="h6">Link not yet generated</Typography>
    </div>
  );

  return (
    <Paper elevation={3}>
      <div className={classes.statusControllerContainer}>
        <Typography variant="h6">Status</Typography>
        <Button
          variant="outlined"
          color={isActive === 'Y' ? 'primary' : 'secondary'}
          onClick={onClick}
        >
          {isActive === 'Y' ? 'ENABLED' : 'DISABLED'}
        </Button>
      </div>
      <div className={classes.statusControllerContainer}>
        <Typography variant="h6">Stream Key</Typography>
        <Typography variant="h6">{eventId}</Typography>
      </div>
      <div className={classes.statusControllerContainer}>
        <Typography variant="h6">Match Id</Typography>
        <Typography variant="h6">{matchId}</Typography>
      </div>
      {!!media_path && renderVideoLink()}
      {!media_path && renderWaiting()}
    </Paper>
  );
};

export default StatusControllerAndId;
