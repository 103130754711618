import React, { useContext, useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Select from '@material-ui/core/Select';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import useStyles from './styles';
import {
  generateSportPositionDisplayName,
  generateSportDisplayName,
} from '../../Utils/genrateDisplayNames';
import Context from '../../Contexts';
import { MenuItem, FormControl, InputLabel } from '@material-ui/core';

export default function TeamTable({
  data,
  onDeletePlayer,
  onNavigateToTeamsPlayer,
  type,
  setAlphaValue,
  noOfGroup,
}) {
  console.log('type', type);
  const classes = useStyles();

  const context = useContext(Context);
  const { formConfig } = context;

  const generateAlphabets = () => {
    const alphabets = [];
    const start = 'A'.charCodeAt(0);
    const last = 'Z'.charCodeAt(0);
    for (let i = start; i <= last; i++) {
      alphabets.push(String.fromCharCode(i));
    }

    return alphabets;
  };

  const alphabetsData = generateAlphabets();
  const newArray = alphabetsData.splice(0, noOfGroup);

  return (
    <div className={classes.tableContainer}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Sr no</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align="center">Sport</TableCell>
              {type === 'LKOG' ? (
                <TableCell align="center">Group</TableCell>
              ) : (
                <div />
              )}
              <TableCell align="center">View</TableCell>
              <TableCell align="center">Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell component="th" scope="row">
                      {`${row.name}`}
                    </TableCell>

                    <TableCell align="center">
                      {generateSportDisplayName(formConfig, row.sport)}
                    </TableCell>

                    {type === 'LKOG' ? (
                      <FormControl
                        variant="outlined"
                        className={classes.formControlAlpha}
                      >
                        <InputLabel id="demo-simple-select-outlined-label">
                          Group
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={row.group_id}
                          onChange={e => setAlphaValue(e.target.value, row)}
                          label="Group"
                        >
                          <MenuItem value="">None</MenuItem>

                          {newArray.map((el, index) => (
                            <MenuItem key={index} value={el}>
                              {el}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    ) : (
                      <div />
                    )}

                    <TableCell align="center">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onNavigateToTeamsPlayer(row.team_id)}
                      >
                        View
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => onDeletePlayer(row.team_id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
