import axiosInstance from '../../axiosInstance';

const setAggPMSAutoCalc = async ({ sportKey, playerId }) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/stats/agg-player-match/${sportKey}/player/${playerId}/re-calculate`,
  );

  const { data } = response.data;

  return data.agg_player_match_stat;
};

export default setAggPMSAutoCalc;
