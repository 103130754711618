import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  searchContainer: {
    width: '100%',
  },
  addContainer: {
    marginTop: '10px',
    width: '100%',
    maxWidth: '700px',
  },
}));

export default useStyles;
