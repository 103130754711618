import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import swal from 'sweetalert';
import useStyles from './styles';
import Contexts from '../../../../Contexts';
import updateSPA from '../../../../API/subTracking/updateSPA';
import getSpaDetails from '../../../../API/subTracking/getSpaDetails';
import { getSpaFormFieldsFromSubTracking } from './service';
import SpaForm from '../../../../Components/Forms/SpaForm/SpaForm';

export default function SPA() {
  const classes = useStyles();
  const [initialData, setInitialData] = useState(null);
  const { addToast } = useToasts();
  const [formFields, setFormFields] = useState({});
  const context = useContext(Contexts);
  const { showLoadingSpinner } = context;

  const { userId } = useParams();

  const init = async () => {
    const { fixed_otp, subscription_tracking, user } = await getSpaDetails({
      userId,
    });

    setInitialData({
      subTracking: subscription_tracking,
      user,
      fixedOtp: fixed_otp,
    });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!initialData) return;

    const generatedFormFields = getSpaFormFieldsFromSubTracking({
      subTracking: initialData.subTracking,
      studioProfileType: initialData.user.studio_profile_type,
      isSpaUser: initialData.user.is_spa,
      fixedOtp: initialData.fixedOtp,
    });

    setFormFields(generatedFormFields);
  }, [initialData]);

  const handleSubmit = async (values, setSubmitting) => {
    const isYesSelected = await swal({
      title: 'Are you sure? ',
      text: values.is_spa_enabled
        ? 'This will override any plans this user may have already purchased'
        : 'This will remove any added usage limits and the user will have to purchase a plan',
      icon: 'warning',
      buttons: true,
    });

    if (!isYesSelected) {
      setSubmitting(false);
      return;
    }

    showLoadingSpinner(true);

    try {
      const {
        is_spa_enabled,
        fixed_otp,
        studio_profile_type,
        valid_till,
        stream_create_limit,
        streaming_minutes_balance,
        max_concurrent_streams,
        is_trial,
      } = values;

      await updateSPA({
        userId,
        is_spa_enabled,
        fixed_otp,
        studio_profile_type,
        valid_till,
        stream_create_limit,
        streaming_minutes_balance,
        max_concurrent_streams,
        is_trial,
      });

      setSubmitting(false);
      showLoadingSpinner(false);
      addToast('Success', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } catch (error) {
      showLoadingSpinner(false);

      setSubmitting(false);
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  if (!initialData) return <div>loading</div>;

  if (!Object.keys(formFields).length) return <div>loading</div>;

  return (
    <div className={classes.container}>
      <SpaForm {...formFields} onSubmit={handleSubmit} />
    </div>
  );
}
