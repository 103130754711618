import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';
import { HeadCell } from './types';

export const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }),
);

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }),
);

export const VIDEOS_HEAD_CELLS: HeadCell[] = [
  {
    id: 'title',
    align: 'left',
    label: 'Title',
  },
  { id: 'views', align: 'center', label: 'views' },
  { id: 'category', align: 'center', label: 'Category' },
  { id: 'channel', align: 'right', label: 'Channel' },
  { id: 'created_on', align: 'right', label: 'Created On' },
];

export const PLAYERS_HEAD_CELLS: HeadCell[] = [
  {
    id: 'name',
    align: 'left',
    label: 'Player Name',
  },
  { id: 'state', align: 'center', label: 'State' },
  { id: 'sport', align: 'center', label: 'Sport' },
  { id: 'position', align: 'center', label: 'Position' },
  { id: 'isProAthlete', align: 'right', label: 'Is Pro Athlete' },
];

export const EVENTS_HEAD_CELLS: HeadCell[] = [
  {
    id: 'name',
    align: 'left',
    label: 'Event Name',
  },
  { id: 'state', align: 'center', label: 'State' },
  { id: 'sport', align: 'center', label: 'Sport' },
  { id: 'event_id', align: 'center', label: 'Event Id' },
];
