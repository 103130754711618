import React from 'react';
import { Box } from '@material-ui/core';
import FormikTextInput from '../../Forms/MuiFormikComponents/FormikTextInput';
import FormikSubmit from '../../Forms/MuiFormikComponents/FormikSubmit';

function FormFields() {
  return (
    <Box display="flex" alignItems="center" style={{ gap: 10 }}>
      <FormikTextInput
        label="Section Name"
        name="section_name"
        type="text"
        fullWidth
        multiline={false}
        variant="outlined"
      />
      <FormikSubmit submitText="save" />
    </Box>
  );
}

export default FormFields;
