import { TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDebounce } from '../../customHooks/useDebounce';

function DebouncedTextField({
  initialValue = '',
  onDebouncedChange,
  debounceInterval = 1000,
  ...props
}) {
  const [value, setValue] = useState(initialValue);
  const debouncedValue = useDebounce(value, debounceInterval);

  useEffect(() => {
    if (debouncedValue !== initialValue) {
      onDebouncedChange(debouncedValue);
    }
  }, [debouncedValue]);

  return (
    <TextField
      {...props}
      value={value}
      onChange={e => {
        setValue(e.target.value);
      }}
    />
  );
}

export default DebouncedTextField;
