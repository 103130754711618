import React, { useContext } from 'react';
import { Box, IconButton, Switch, Typography } from '@material-ui/core';
import {
  DragIndicator as DragIndicatorIcon,
  DeleteOutline as DeleteIcon,
} from '@material-ui/icons';
import styles from './styles.module.css';
import Contexts from '../../../Contexts';
import { SectionData } from '../../../types/ManagedHomeFeed';
import { getSectionColorByType } from '../utils';

type SectionCardProps = {
  onToggle: (checked: boolean) => void;
  section: SectionData;
  onClick: () => void;
  selected: boolean;
  onDelete: () => void;
};

function SectionCard(props: SectionCardProps) {
  const { onToggle, section, selected, onClick, onDelete } = props;
  const { isDarkMode } = useContext(Contexts);

  const getEntityText = () => {
    if (section.section_type === 'player') return 'Players';
    if (section.section_type === 'event') return 'Events';
    return 'Videos';
  };

  return (
    <Box
      borderRadius={5}
      className={isDarkMode ? styles.section_card : styles.section_light_card}
      onClick={onClick}
      border={selected ? '1px solid rgb(0, 130, 213)' : 'none'}
      overflow="hidden"
    >
      <Box
        p={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderLeft={`6.5px solid ${getSectionColorByType(
          section.section_type,
        )}`}
        overflow="hidden"
        width="100%"
      >
        <Box className={styles.section_card_left}>
          <DragIndicatorIcon />
          <Box>
            <Typography variant="subtitle2">{section.eng}</Typography>
            <Typography variant="caption" style={{ opacity: 0.75 }}>
              {section.ordered_entity_ids.length}{' '}
              <span
                style={{
                  color: getSectionColorByType(section.section_type),
                  fontWeight: 'bold',
                }}
              >
                {getEntityText()}
              </span>
            </Typography>
          </Box>
        </Box>

        {section.is_editable && (
          <Box display="flex" alignItems="center" style={{ gap: 10 }}>
            <Switch
              checked={section.enabled}
              onChange={e => onToggle(e.target.checked)}
            />
            <IconButton
              onClick={e => {
                e.stopPropagation();
                onDelete();
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default SectionCard;
