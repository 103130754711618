import axiosInstance from '../../axiosInstance';

const changeJerseyNo = async (jersey_num, teamID, playerId) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/teams/${teamID}/player/${playerId}/jersey-num`,
    {
      jersey_num,
    },
  );

  const { data } = response.data;

  return data;
};

export default changeJerseyNo;
