import { BASE_URL } from '../constants';

const addPlayerToTeam = async (tournamentId, teamId, postData) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/v2/tournaments/${tournamentId}/teams/${teamId}/squad`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      },
    );

    const json = await response.json();

    if (json.error) {
      throw new Error(json.error.errorMessage);
    }

    return json;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default addPlayerToTeam;
