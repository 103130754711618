import React from 'react';
import { Typography, Box } from '@material-ui/core';
import useStyles from './Styles';

export default function Home() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div style={{ paddingTop: '50px' }}>
        <img height="250px" src="/sv_icon.png" alt="SportVot" />
      </div>
      <Typography component="div">
        <Box letterSpacing={6} m={1}>
          SportVot Admin
        </Box>
      </Typography>
      <Typography variant="caption">
        Find more options in the top left icon
      </Typography>
    </div>
  );
}
