import { BASE_URL } from '../constants';

const addSingleTouramentTeams = async (tourn_id, postData) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/v2/tournaments/${tourn_id}/team`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      },
    );

    const json = await response.json();

    if (json.error) {
      throw new Error(json.error.errorMessage);
    }

    return json;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default addSingleTouramentTeams;
