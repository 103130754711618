import axiosInstance from '../../axiosInstance';

const updateSinglePlayerMatchStat = async ({
  sportKey,
  playerId,
  matchId,
  stats,
}) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/stats/player-match/${sportKey}/player/${playerId}/match/${matchId}`,
    {
      stats,
    },
  );

  const { data } = response.data;

  return data.player_match_stat;
};

export default updateSinglePlayerMatchStat;
