import React, { useContext, useState, useRef, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import getSingleAthlete from '../../../../../API/athletes/getSingleAthlete';
import getPlayerMatchStatsPaginated from '../../../../../API/playerMatchStats/getPlayerMatchStatsPaginated';
import getSinglePlayerMatchStat from '../../../../../API/playerMatchStats/getSinglePlayerMatchStat';
import AthleteMatchStatsTable from '../../../../../Components/AthleteComponents/AthleteStatsComponents/AthleteMatchStatsTable';
import Contexts from '../../../../../Contexts';
import SearchOrAddNewPMS from './SearchOrAddNewPMS/SearchOrAddNewPMS';
import useStyles from './styles';

function ListAthleteMatchStats() {
  const classes = useStyles();

  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);
  const [athleteData, setAthleteData] = useState(null);
  const [statsList, setStatsList] = useState([]);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const { playerId } = useParams();

  const history = useHistory();

  const ref = useRef({
    pageNo: 1,
  });

  const loadAthleteData = async athleteId => {
    try {
      showLoadingSpinner(true);
      const data = await getSingleAthlete(athleteId);
      setAthleteData(data);
    } catch (error) {
      console.error(error);
      showMessageDialog('ERROR', error.message);
      showLoadingSpinner(false);
    }
  };

  const getStatsData = async sportKey => {
    try {
      showLoadingSpinner(true);

      const { pageNo } = ref.current;

      const data = await getPlayerMatchStatsPaginated({
        sportKey,
        playerId,
        page: pageNo,
        limit: 10,
      });

      if (data) {
        setStatsList(data.player_match_stats);
        setCount(data.total_count);
        showLoadingSpinner(false);
      }
    } catch (err) {
      showMessageDialog('ERROR', err.message);
      showLoadingSpinner(false);
    }
  };

  useEffect(() => {
    loadAthleteData(playerId);
  }, []);

  useEffect(() => {
    if (athleteData) {
      getStatsData(athleteData.sport);
    }
  }, [athleteData]);

  const handleRowActionClick = stat => {
    history.push({
      pathname: `/player/${playerId}/match-stats/${stat.match_id}`,
    });
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
    ref.current.pageNo = newPage + 1;
    getStatsData();
  };

  const handleSearch = mId => {
    history.push({
      pathname: `/player/${playerId}/match-stats/${mId}`,
    });
  };

  const handleAddNew = async mId => {
    showLoadingSpinner(true);
    let exists = false;
    try {
      await getSinglePlayerMatchStat({
        sportKey: athleteData.sport,
        playerId,
        matchId: mId,
      });
      exists = true;
    } catch (err) {
      exists = false;
    }
    showLoadingSpinner(false);

    if (exists) {
      handleSearch(mId);
    } else {
      history.push({
        pathname: `/player/${playerId}/match-stats/${mId}/create`,
      });
    }
  };

  useEffect(() => {
    getStatsData();
  }, []);

  return (
    <div className={classes.root}>
      <SearchOrAddNewPMS onSearch={handleSearch} onAddNew={handleAddNew} />
      <div className={classes.addContainer}>
        <AthleteMatchStatsTable
          actionLabel="View"
          data={statsList}
          onRowActionClicked={handleRowActionClick}
          count={count}
          page={page - 1}
          onChangePage={handlePageChange}
          rowsPerPage={10}
        />
      </div>
    </div>
  );
}

export default ListAthleteMatchStats;
