import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { DropzoneDialog } from 'material-ui-dropzone';
import { useToasts } from 'react-toast-notifications';
import useStyles from './styles';
import ImageBlock from '../../../../Components/ImageBlock';
import UploadingDialog from '../../../../Components/UploadingDialog';
import StatusControllerAndId from './StatusControllerAndId';
import Contexts from '../../../../Contexts';
import ArticleDetailsForm from '../../../../Components/Forms/ArticleDetailsForm';
import updateArticleThumbnail from '../../../../API/articles/updateArticleThumbnail';
import setArticleIsActive from '../../../../API/articles/setArticleIsActive';
import updateArticle from '../../../../API/articles/updateArticle';
import getArticlesData from '../../../../API/articles/getArticlesData';
import setArticlePreferredHomeSection from '../../../../API/articles/setArticlePreferredHomeSection';
import { Typography } from '@material-ui/core';
import { getAbsoluteMediaPath } from '../../../../Utils/mediaPathHelpers';

export default function ArticleDetails() {
  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);

  const classes = useStyles();
  const [formFields, setFormFields] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const [uploadingDialogOpen, setUploadingDialogOpen] = useState(false);
  const [imageUploadOpen, setImageUploadOpen] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [is_Active, setIs_Active] = useState('Y');

  const { addToast } = useToasts();

  // path params
  const { articleId } = useParams();

  const getArticleData = async id => {
    showLoadingSpinner(true);
    try {
      const data = await getArticlesData(id);

      const { article } = data;

      setFormFields(article);
      setDataFetched(true);

      setProfileImage(article.thumbnail);
      setIs_Active(article.is_active || 'Y');
    } catch (err) {
      showMessageDialog('ERROR', err.message);
    } finally {
      showLoadingSpinner(false);
    }
  };

  useEffect(() => {
    if (articleId) {
      getArticleData(articleId);
    }
  }, []);

  const handleSubmit = async (values, setSubmitting) => {
    showLoadingSpinner(true);
    try {
      const {
        title,
        state,
        content,
        district,
        pincode,
        orientation,
        channel_id,
        article_type,
        category,
        keywords,
        embed_url,
        event_id,
        country,
        match_id,
        language,
        preferred_section,
        creation_source,

        // jio stuff
        is_commentary,
        is_jio_stb,
        is_ppv,
        is_sub,
        is_ad,
        is_jio_store,
        is_jio_tv,
        is_all_jio,
        is_global,
      } = values;

      const data = await updateArticle(
        articleId,

        title,
        state,
        content,
        district,
        pincode,
        orientation,
        channel_id,
        article_type,
        category,
        keywords,
        embed_url,
        event_id,
        country,
        match_id,
        language,
        creation_source,

        // jio stuff
        is_commentary,
        is_jio_stb,
        is_ppv,
        is_sub,
        is_ad,
        is_jio_store,
        is_jio_tv,
        is_all_jio,
        is_global,
      );

      const resp2 = await setArticlePreferredHomeSection({
        articleId,
        preferredHomeSection: preferred_section,
      });

      if (data && resp2) {
        setSubmitting(false);
        showLoadingSpinner(false);

        addToast('Article Updated', {
          appearance: 'success',
          autoDismiss: 500,
        });
      }
    } catch (err) {
      showLoadingSpinner(false);
      showMessageDialog('ERROR', err.message);
    }
  };

  // ============= image upload helpers ========================
  const uploadImage = async file => {
    const imageUrl = await updateArticleThumbnail(file, articleId);

    if (imageUrl) {
      setProfileImage(imageUrl);

      setUploadingDialogOpen(false);
    }
  };

  const handleImageDialogSave = files => {
    const file = files[0];

    setImageUploadOpen(false);
    setUploadingDialogOpen(true);
    uploadImage(file);
  };

  const handleImageDialogClose = () => {
    setImageUploadOpen(false);
  };

  const handleEditImage = () => {
    setImageUploadOpen(true);
  };

  // ================= status controller ============
  const handleStatusButtonClick = async () => {
    setUploadingDialogOpen(true);

    const resp = await setArticleIsActive(articleId, !(is_Active === 'Y'));
    if (resp) {
      setUploadingDialogOpen(false);
      setIs_Active(is_Active === 'Y' ? 'N' : 'Y');
    }
  };

  return (
    <div className={classes.container}>
      {dataFetched && (
        <>
          <Typography variant="caption">
            media_path: {getAbsoluteMediaPath(formFields.media_path)}
          </Typography>
          <StatusControllerAndId
            isActive={is_Active}
            onClick={handleStatusButtonClick}
            articleId={articleId}
            media_path={formFields.media_path}
            media_uploaded={formFields.media_uploaded}
          />
          <ArticleDetailsForm {...formFields} onSubmit={handleSubmit} />
          <div className={classes.imageBlock}>
            <ImageBlock
              buttonLabel="Edit"
              blockLabel="Thumbnail (max size 150KB)"
              onButtonPress={handleEditImage}
              imgSrc={profileImage}
            />
          </div>
        </>
      )}

      <DropzoneDialog
        open={imageUploadOpen}
        onSave={handleImageDialogSave}
        acceptedFiles={['image/*']}
        showPreviews
        maxFileSize={150000}
        filesLimit={1}
        onClose={handleImageDialogClose}
        showAlerts={false}
      />
      <UploadingDialog text="" open={uploadingDialogOpen} />
    </div>
  );
}
