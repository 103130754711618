import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import VerificationCardContainer from './VerificationCardContainer';
import approveRequest from '../../../../../API/ugcRequests/approveRequest';

const VerificationCard = ({ location, history }) => {
  const {
    canUpload,
    canStream,
    email,
    mobile,
    userId,
    isProcessed,
    channelName,
  } = location.state;

  const [localCanUpload, setLocalCanUpload] = useState(canUpload);
  const [localCanStream, setLocalCanStream] = useState(canStream);

  const handleRequestProcessed = async (status = false) => {
    await approveRequest(
      localCanStream,
      localCanUpload,
      status,
      userId,
      channelName,
    );
    history.push('/channel-management');
  };

  return (
    <VerificationCardContainer
      canStream={localCanStream}
      canUpload={localCanUpload}
      setCanStream={setLocalCanStream}
      setCanUpload={setLocalCanUpload}
      mobile={mobile}
      email={email}
      userId={userId}
      onSubmit={handleRequestProcessed}
      isProcessed={isProcessed}
      channelName={channelName}
    />
  );
};

VerificationCard.propTypes = {
  location: PropTypes.object,
};

VerificationCard.defaultProps = {
  location: {},
};

export default withRouter(VerificationCard);
