import React from 'react';
import { Form, useFormikContext } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';
import FormikSubmit from '../MuiFormikComponents/FormikSubmit';
import FormikDropDown from '../MuiFormikComponents/FormikDropDown';
import TwoTeamFormField from './TwoTeamFormField';
import FormikSwitch from '../MuiFormikComponents/FormikSwitch';

const FormFields = ({ sportList, matchTitle = 'match title', sportKeyMap }) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const { sport, is_auto_managed } = values;

  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">{matchTitle}</Typography>
          </Grid>

          <Grid item xs={12}>
            <FormikSwitch label="Enabled" name="enabled" />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="caption">
              Auto-managed causes the scorecard to be updated from scoring app
              data
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormikSwitch label="Auto-managed" name="is_auto_managed" />
          </Grid>

          {!is_auto_managed && (
            <Grid item xs={6}>
              <FormikDropDown
                label="Sport"
                valueDisplayNameMap={sportList}
                name="sport"
              />
            </Grid>
          )}

          {!is_auto_managed && (
            <TwoTeamFormField label="Team Names" homeKey="t1" awayKey="t2" />
          )}

          {!is_auto_managed && (
            <TwoTeamFormField
              label="Score"
              homeKey="t1_score"
              awayKey="t2_score"
            />
          )}

          {sport &&
            !is_auto_managed &&
            sportKeyMap[sport].map(item => (
              <TwoTeamFormField
                key={item.label}
                label={item.label}
                homeKey={item.homeKey}
                awayKey={item.awayKey}
              />
            ))}

          {!!is_auto_managed && (
            <Grid item xs={12}>
              <Typography variant="h6">
                scorecard is set to auto managed
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="save" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

FormFields.propTypes = {
  sportKeyMap: PropTypes.object,
  sportList: PropTypes.array,
};

// for testing this format is required from parent
FormFields.defaultValues = {
  sportKeyMap: {
    s1: [
      { label: 'hi', homeKey: 'hk_1', awayKey: 'aw_1' },
      { label: 'yo', homeKey: 'hk_2', awayKey: 'aw_2' },
    ],
    s2: [
      { label: 'ih', homeKey: 'hk_3', awayKey: 'aw_3' },
      { label: 'oy', homeKey: 'hk_4', awayKey: 'aw_4' },
    ],
  },

  sportList: [
    { value: 's1', displayName: 'S1 label' },
    { value: 's2', displayName: 'S2 label' },
  ],
};

export default FormFields;
