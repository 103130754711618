import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  textField: {
    margin: 10,
  },
  table: {
    minWidth: 700,
  },
  tableContainer: {
    marginTop: 50,
  },
  addContainer: {
    marginTop: '10px',
    width: '100%',
    maxWidth: '750px',
  },
}));

export default useStyles;
