import axiosInstance from '../../axiosInstance';

const updateAthleteIsFeatured = async (
  userId = 'default',
  isFeatured = false,
) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/players/${userId}/is-featured`,
    { is_featured: isFeatured },
  );

  const { player } = response.data.data;

  return player;
};

export default updateAthleteIsFeatured;
