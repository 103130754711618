import React from 'react';
import { Paper, Typography, Button } from '@material-ui/core';
import useStyles from './styles';

const EnabledController = ({
  isEnabled = true,
  onClick = () => 'status controller button clicked',
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={3}>
      <div className={classes.statusControllerContainer}>
        <Typography variant="h6">Status</Typography>

        <Button
          variant="outlined"
          color={isEnabled ? 'primary' : 'secondary'}
          onClick={onClick}
        >
          {isEnabled ? 'ENABLED' : 'DISABLED'}
        </Button>
      </div>
    </Paper>
  );
};

export default EnabledController;
