import axiosInstance from '../../axiosInstance';

const getHeaderContent = async ({ isHomeFeed = true, sport = '' }) => {
  let endpoint;

  if (isHomeFeed) {
    endpoint = '/api/v2/header-content/non-sport-specific';
  } else {
    endpoint = `/api/v2/header-content/sport-specific/${sport}`;
  }

  const response = await axiosInstance.get(endpoint);

  const { data } = response.data;

  return data.header_content;
};

export default getHeaderContent;
