import axiosInstance from '../../axiosInstance';

const getSinglePlayerMatchStat = async ({ sportKey, playerId, matchId }) => {
  const response = await axiosInstance.get(
    `/api/v2/stats/player-match/${sportKey}/player/${playerId}/match/${matchId}`,
  );

  const { data } = response.data;

  return data.player_match_stat;
};

export default getSinglePlayerMatchStat;
