import React from 'react';
import { Grid } from '@material-ui/core';
import FormikTextInput from '../MuiFormikComponents/FormikTextInput';
import FormikDropDown from '../MuiFormikComponents/FormikDropDown';
import FormikSwitch from '../MuiFormikComponents/FormikSwitch';

const CommonUserFields = ({ displayChannelField }) => {
  return (
    <>
      <Grid item xs={12} sm={6} md={6}>
        <FormikTextInput label="First Name" name="first_name" />
      </Grid>

      <Grid item xs={12} sm={6} md={6}>
        <FormikTextInput label="Last Name" name="last_name" />
      </Grid>

      {!!displayChannelField && (
        <Grid item xs={12} sm={6} md={6}>
          <FormikTextInput label="Channel Name" name="channel_name" />
        </Grid>
      )}

      <Grid item xs={12} sm={6} md={6}>
        <FormikDropDown
          label="Profile Type"
          name="profile_type"
          valueDisplayNameMap={[
            { value: 'regular', displayName: 'Regular' },
            { value: 'athlete', displayName: 'Athlete' },
          ]}
          disabled
        />
      </Grid>

      {!!displayChannelField && (
        <Grid item xs={12} sm={12} md={12}>
          <FormikTextInput label="Description" name="description" multiline />
        </Grid>
      )}

      {!!displayChannelField && (
        <Grid item xs={12} sm={6} md={6}>
          <FormikSwitch label="Channel Verified" name="channel_verified" />
        </Grid>
      )}

      {!!displayChannelField && (
        <Grid item xs={12} sm={6} md={6}>
          <FormikSwitch label="Display Videos" name="display_videos" />
        </Grid>
      )}

      {!!displayChannelField && (
        <Grid item xs={12} sm={6} md={6}>
          <FormikSwitch label="Display Streams" name="display_streams" />
        </Grid>
      )}

      {!!displayChannelField && (
        <Grid item xs={12} sm={6} md={6}>
          <FormikSwitch label="Can Upload" name="can_upload" />
        </Grid>
      )}

      {false && (
        <Grid item xs={12} sm={6} md={6}>
          <FormikSwitch
            label="Can Use Position Based Lineups"
            name="is_position_based_lineups_enabled"
          />
        </Grid>
      )}
    </>
  );
};

export default CommonUserFields;
