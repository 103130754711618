import axiosInstance from '../../axiosInstance';

const getAutoClipsInfoForMatchFastly = async ({ matchId }) => {
  try {
    const response = await axiosInstance.get(
      `/api/admin/v2/auto-highlights/${matchId}`,
    );

    const {
      is_processing_needed,
      is_queued,
      window_seconds_after_scoring_event,
      window_seconds_before_scoring_event,
      times_un_successfully_processed,
      times_successfully_processed,
    } = response.data.data.data;

    return {
      is_processing_needed,
      is_queued,
      window_seconds_after_scoring_event,
      window_seconds_before_scoring_event,
      times_un_successfully_processed,
      times_successfully_processed,
    };
  } catch (error) {
    return {
      is_processing_needed: false,
      is_queued: false,
      seconds_after: 4,
      seconds_before: 20,
      times_failed_processing: 0,
      times_successfully_processed: 0,
    };
  }
};

export default getAutoClipsInfoForMatchFastly;
