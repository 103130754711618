import axiosInstance from '../../axiosInstance';

const convertRegularToAthlete = async (
  userId,
  {
    first_name = '',
    last_name = '',
    position = '',
    sport = '',
    state = '',
    isProAthlete = 'N',
    preferred_section = '',
    best_team_name = '',
    DOB = '',
    description = '',
    aadhar_num = '',
    email = '',
    cm_height = 0.0,
    kg_weight = 0.0,
  },
) => {
  const response = await axiosInstance.post(
    `/api/v2/players/${userId}/convert-to-player`,
    {
      first_name,
      last_name,
      position,
      sport,
      state,
      isProAthlete,
      preferred_section,
      best_team_name,
      DOB,
      description,
      aadhar_num,
      email,
      cm_height,
      kg_weight,
    },
  );

  const { id } = response.data.data;

  return id;
};

export default convertRegularToAthlete;
