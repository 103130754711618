import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import styles from './styles.module.css';
import CustomChip from '../../Common/CustomChip';
import { getImageUrl } from '../../../Utils/commonUtils';

function TemplateCard({ template, handleDelete = () => {} }) {
  return (
    <Box
      bgcolor="#e5e5e5"
      boxShadow={2}
      borderRadius={5}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      style={{ gap: 5 }}
      mb={1}
      overflow="hidden"
    >
      <Box display="flex" alignItems="flex-start" style={{ gap: 5 }}>
        <img
          src={getImageUrl(template.thumbnail)}
          alt="template"
          className={styles.thumbnail}
        />
        <Box height="100%">
          <Typography
            variant="subtitle2"
            style={{ textTransform: 'capitalize' }}
          >
            {template.name}
          </Typography>
          <Box display="flex" alignItems="center" style={{ gap: 5 }}>
            <CustomChip label={template.sport} bgcolor="rgb(0,130,213)" />
            <CustomChip label={template.scoring_mode} />
            <CustomChip
              bgcolor={template.is_ready ? 'green' : 'red'}
              label={template.is_ready ? 'PUBLISHED' : 'NOT PUBLISHED'}
            />
            <CustomChip
              bgcolor="#314e82"
              label={`Template ID: ${template._id}`}
            />
          </Box>
        </Box>
      </Box>

      <Box pr={1}>
        <DeleteOutline onClick={handleDelete} style={{ cursor: 'pointer' }} />
      </Box>
    </Box>
  );
}

export default TemplateCard;
