import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './styles.module.css';
import SectionCard from './SectionCard';
import AddSectionCard from './AddSectionCard';
import { getItemStyle, reorder } from '../utils';
import AddSectionForm from '../AddSectionForm';
import useManagedHomeFeedActions from '../../../customHooks/ManagedHomeFeeds/useManagedHomeFeedActions';
import useManagedHomeFeedStore from '../../../Store/useManagedHomeFeedStore';
import { FeedType } from '../../../API/ManagedHomeFeed/types';

type SectionSideBarProps = {
  feedType: FeedType;
};

const SectionSideBar: React.FC<SectionSideBarProps> = ({ feedType }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const {
    handleAddSectionInCategory,
    handleToggleSectionVisibilityInCategory,
    handleReorderSectionsInCategory,
    handleDeleteSectionInCategory,
  } = useManagedHomeFeedActions();
  const {
    managedHomeFeed,
    selectedSectionKey,
    setSelectedSectionKey,
  } = useManagedHomeFeedStore();

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      managedHomeFeed?.section_order,
      result.source.index,
      result.destination.index,
    ) as string[];

    handleReorderSectionsInCategory({ sectionKeys: items });
  };

  return (
    <Box className={styles.sidebar_container}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {managedHomeFeed?.section_order.map((sectionKey, index) => (
                <Draggable
                  key={sectionKey}
                  draggableId={sectionKey}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <SectionCard
                        section={managedHomeFeed.section_data[sectionKey]}
                        onToggle={checked =>
                          handleToggleSectionVisibilityInCategory({
                            enabled: checked,
                            sectionKey,
                          })
                        }
                        onClick={() => setSelectedSectionKey(sectionKey)}
                        selected={sectionKey === selectedSectionKey}
                        onDelete={() =>
                          handleDeleteSectionInCategory(sectionKey)
                        }
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {feedType !== 'JIO_SPORTS_HUB' && (
        <AddSectionCard onClick={() => setModalOpen(true)} />
      )}

      <AddSectionForm
        open={modalOpen}
        setOpen={setModalOpen}
        onSubmit={(sectionName, sectionType) => {
          handleAddSectionInCategory(sectionName, sectionType);
          setModalOpen(false);
        }}
      />
    </Box>
  );
};

export default SectionSideBar;
