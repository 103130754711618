import axiosInstance from '../../axiosInstance';

const updateTeamPlayerLineupStatus = async ({
  streamId,
  teamId,
  playerId,
  status,
}) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/streams/${streamId}/teams/${teamId}/lineup/${playerId}/status`,
    {
      status,
    },
  );
  const { data } = response.data;
  return data;
};

export default updateTeamPlayerLineupStatus;
