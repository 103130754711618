import React, { useContext, useState } from 'react';
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  DeleteOutline as DeleteIcon,
  EditOutlined as EditIcon,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import {
  Box,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { getImageUrl } from '../../../Utils/commonUtils';
import useManagedHomeFeedActions from '../../../customHooks/ManagedHomeFeeds/useManagedHomeFeedActions';
import useManagedHomeFeedStore from '../../../Store/useManagedHomeFeedStore';
import useSectionEvents from '../../../customHooks/ManagedHomeFeeds/useSectionEvents';
import Contexts from '../../../Contexts';
import {
  generateSportDisplayName,
  generateStateDisplayName,
} from '../../../Utils/genrateDisplayNames';
import { OTT_LINK } from '../../../API/constants';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }),
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }),
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {},
});

export default function EventsTable() {
  const { events } = useSectionEvents();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const {
    handleReorderEntitiesInSection,
    handleDeleteEntityInSection,
  } = useManagedHomeFeedActions();
  const { selectedSectionKey, managedHomeFeed } = useManagedHomeFeedStore();
  const context = useContext(Contexts);
  const { formConfig } = context;
  const [hoveredImg, setHoveredImg] = useState('');

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onMove = (currentIndex: number, id: string, targetIndex: number) => {
    if (!managedHomeFeed) return;

    const { ordered_entity_ids } = managedHomeFeed.section_data[
      selectedSectionKey
    ];

    const newOrderedIdsList = [...ordered_entity_ids];
    const [removed] = newOrderedIdsList.splice(currentIndex, 1);
    newOrderedIdsList.splice(targetIndex, 0, removed);

    handleReorderEntitiesInSection({
      sectionKey: selectedSectionKey,
      entityIds: newOrderedIdsList,
    });
  };

  const onDelete = (entityId: string) => {
    handleDeleteEntityInSection({
      sectionKey: selectedSectionKey,
      entityId,
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Actions</StyledTableCell>
            <StyledTableCell align="left">Event Name</StyledTableCell>
            <StyledTableCell align="right">State</StyledTableCell>
            <StyledTableCell align="right">Sport</StyledTableCell>
            <StyledTableCell align="right">Start Date</StyledTableCell>
            <StyledTableCell align="right">End Date</StyledTableCell>
            <StyledTableCell align="center">Deleted?</StyledTableCell>
            <StyledTableCell align="right">EVENT LINK</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {events.map((row, mainIndex) => (
            <StyledTableRow key={row._id}>
              <StyledTableCell component="th" scope="row">
                <Box display="flex" alignItems="center">
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{ minWidth: 60 }}
                  >
                    {mainIndex !== 0 && (
                      <Tooltip title="Move Up">
                        <IconButton
                          size="small"
                          onClick={() =>
                            onMove(mainIndex, row._id, mainIndex - 1)
                          }
                          style={{ color: 'white' }}
                        >
                          <ExpandLess />
                        </IconButton>
                      </Tooltip>
                    )}
                    {mainIndex !== events.length - 1 && (
                      <Tooltip title="Move Down">
                        <IconButton
                          size="small"
                          onClick={() =>
                            onMove(mainIndex, row._id, mainIndex + 1)
                          }
                          style={{ color: 'white' }}
                        >
                          <ExpandMore />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>
                  <IconButton onClick={() => onDelete(row.event_id)}>
                    <DeleteIcon />
                  </IconButton>{' '}
                  <IconButton>
                    <EditIcon />
                  </IconButton>
                </Box>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                <Box
                  aria-owns={open ? 'mouse-over-popover' : undefined}
                  aria-haspopup="true"
                  onMouseEnter={e => {
                    handlePopoverOpen(e);
                    setHoveredImg(getImageUrl(row.logo));
                  }}
                  onMouseLeave={handlePopoverClose}
                  display="flex"
                  alignItems="flex-start"
                  style={{ gap: 10 }}
                >
                  <img
                    src={getImageUrl(row.logo)}
                    style={{ width: 70, aspectRatio: '16/9' }}
                    alt="thumbnail"
                  />
                  <Typography variant="subtitle1"> {row.name}</Typography>
                </Box>
              </StyledTableCell>
              <TableCell align="center">
                {generateStateDisplayName(formConfig, row.state)}
              </TableCell>
              <TableCell align="center">
                {generateSportDisplayName(formConfig, row.sport)}
              </TableCell>
              <StyledTableCell align="right">
                {row.start_date && new Date(row.start_date).toLocaleString()}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.end_date && new Date(row.end_date).toLocaleString()}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.is_active === 'Y' && (
                  <span style={{ color: 'green', fontWeight: 'bold' }}>NO</span>
                )}
                {row.is_active === 'N' && (
                  <span style={{ color: 'red', fontWeight: 'bold' }}>YES</span>
                )}
              </StyledTableCell>
              <StyledTableCell align="right">
                <a target="_blank" href={`${OTT_LINK}/event/${row.event_id}`}>
                  VIEW ON OTT
                </a>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <img
          src={hoveredImg}
          style={{ width: 300, aspectRatio: '16/9' }}
          alt="thumbnail"
        />
      </Popover>
    </TableContainer>
  );
}
