import axiosInstance from '../../axiosInstance';

const getOrCreateRtmpEndpoint = async ({
  streamId,
  create_new = false,
  is_overlay_enabled = false,
}) => {
  const response = await axiosInstance.get(
    `/api/v2/aws-media-live/admin/${streamId}`,
    {
      params: {
        create_new,
        is_overlay_enabled,
      },
      headers: {
        'X-Api-Key': '1e785abc-42bb-445c-8feb-8919fdcb599a',
      },
    },
  );

  return response.data.data.aws_media_live;
};

export default getOrCreateRtmpEndpoint;
