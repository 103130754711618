import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormFields from './FormFields';

const LeaugeForm = ({
  NoT = 0,
  NoR = 0,
  PPW = 0,
  PPD = 0,

  onSubmit,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          NoT,
          NoR,
          PPW,
          PPD,
        }}
        validationSchema={Yup.object({
          NoT: Yup.number().required('Required'),
          NoR: Yup.number().required('Required'),
          PPW: Yup.number().required('Required'),
          PPD: Yup.number().required('Required'),
        })}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields />
      </Formik>
    </>
  );
};

export default LeaugeForm;
