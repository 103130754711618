import React, { useState } from 'react';
import Contexts from './Contexts';

const AppStorage = ({ children }) => {
  // should store the json from /public/FormConfig.json
  const [formConfig, setFormConfig] = useState({});

  const [userDoc, setUserDoc] = useState({});

  const [loggedIn, setLoggedIn] = useState(false);

  const [loadingSpinnerEnabled, setLoadingSpinnerEnabled] = useState(false);

  const [messageDialogState, setMessageDialogState] = useState({
    title: 'default title',
    message: 'default message',
    enabled: false,
  });

  const [categoriesDropDownList, setCategoriesDropDownList] = useState([]);

  const [sectionDetails, setSectionDetails] = useState({});

  const [isDarkMode, setIsDarkMode] = useState(true);

  const showMessageDialog = (
    title = 'default title',
    message = 'default message',
  ) => {
    setMessageDialogState({ enabled: true, title, message });
  };

  const hideMessageDialog = () => {
    setMessageDialogState({ enabled: false, title: '', message: '' });
  };

  const showLoadingSpinner = (enabled = false) => {
    setLoadingSpinnerEnabled(enabled);
  };

  /**
   * when status is false, token is optional. Localstorage is cleared
   * and isLoggedIn = N is set in the local storage
   *
   * when status is true, token may or may not be passed. If token is
   * passed jwtToen = token is set in the local storage
   *
   * also, setLoggedIn(status) is called to set the loggedIn flag
   * @param {*} status
   * @param {*} token
   */
  const setLoginStatusAndToken = (
    status = false,
    token = null,
    refreshToken,
    refreshId,
  ) => {
    if (status) {
      localStorage.setItem('loggedIn', 'Y');
      if (token) localStorage.setItem('jwtToken', token);
      if (refreshToken) localStorage.setItem('jwtRefreshToken', refreshToken);
      if (refreshId) localStorage.setItem('jwtRefreshId', refreshId);
    } else {
      localStorage.clear();
      localStorage.setItem('loggedIn', 'N');
    }

    setLoggedIn(status);
  };

  const contextObject = {
    formConfig,
    setFormConfig,

    userDoc,
    setUserDoc,

    messageDialogState,
    showMessageDialog,
    hideMessageDialog,

    loadingSpinnerEnabled,
    showLoadingSpinner,

    categoriesDropDownList,
    setCategoriesDropDownList,

    loggedIn,
    setLoginStatusAndToken,

    sectionDetails,
    setSectionDetails,

    isDarkMode,
    setIsDarkMode,
  };

  return (
    <Contexts.Provider value={contextObject}>{children}</Contexts.Provider>
  );
};

export default AppStorage;
