import axiosInstance from '../../axiosInstance';

export const X_API_KEY = '5yn7mertjho785t679e789gbyidfvtgbiuy';

export const getTemplates = async ({
  creator_id = '',
  page = 1,
  limit = 10,
  sport = '',
  name = '',
  scoring_mode = '',
  is_ready = true,
  template_id = '',
  is_thumbnail_template = false,
}) => {
  try {
    const response = await axiosInstance.get('/api/v2/templates', {
      params: {
        creator_id,
        page,
        limit,
        sport,
        name,
        scoring_mode,
        is_ready,
        _id: template_id,
        is_thumbnail_template,
      },
    });
    return {
      total_count: response.data.data.total_count,
      templates: response.data.data.templates,
    };
  } catch (e) {
    console.error('Error fetching templates', e);
    return {
      total_count: 0,
      templates: [],
    };
  }
};

export const getTemplateById = async templateId => {
  try {
    const response = await axiosInstance.get(
      `/api/v2/templates/${templateId}`,
      {
        headers: {
          'X-Api-Key': X_API_KEY,
        },
      },
    );
    return response.data.data.template;
  } catch (e) {
    console.error('Error fetching templates', e);
    return null;
  }
};

export const getUserWhitelistedTemplates = async userId => {
  const response = await axiosInstance.get(
    `/api/v2/templates/${userId}/whitelisted-templates`,
  );
  return response.data.data;
};
