import axiosInstance from '../../axiosInstance';

const cloneArticle = async articleId => {
  const response = await axiosInstance.post(
    `/api/admin/v2/articles/${articleId}/clone`,
  );

  const { data } = response.data;

  return data.article._id;
};

export default cloneArticle;
