import axiosInstance from '../../axiosInstance';

const setStreamPreferredHomeSection = async ({
  streamId = 'none',
  preferredHomeSection = '',
}) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/streams/${streamId}/preferred-home-section`,
    {
      preferred_home_section: preferredHomeSection,
    },
  );

  const { data } = response.data;
  return data;
};

export default setStreamPreferredHomeSection;
