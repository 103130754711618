import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import useStyles from './styles';
import ArticleDetailsForm from '../../../../Components/Forms/ArticleDetailsForm';
import createArticle from '../../../../API/articles/createArticle';
import Contexts from '../../../../Contexts';
import setArticlePreferredHomeSection from '../../../../API/articles/setArticlePreferredHomeSection';

export default function AddArticle() {
  const classes = useStyles();
  const history = useHistory();
  const { addToast } = useToasts();

  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);

  const handleSubmit = async (values, setSubmitting) => {
    try {
      showLoadingSpinner(true);
      const {
        title,
        state,
        content,
        district,
        pincode,
        orientation,
        channel_id,
        article_type,
        category,
        keywords,
        embed_url,
        event_id,
        country,
        match_id,
        language,
        preferred_section,
        creation_source,

        // jio stuff
        is_commentary,
        is_jio_stb,
        is_ppv,
        is_sub,
        is_ad,
        is_jio_store,
        is_jio_tv,
        is_all_jio,
        is_global,
      } = values;

      const article_id = await createArticle(
        title,
        state,
        content,
        district,
        pincode,
        orientation,
        channel_id,
        article_type,
        category,
        keywords,
        embed_url,
        event_id,
        country,
        match_id,
        language,
        creation_source,

        is_commentary,
        is_jio_stb,
        is_ppv,
        is_sub,
        is_ad,
        is_jio_store,
        is_jio_tv,
        is_all_jio,
        is_global,
      );

      const resp = await setArticlePreferredHomeSection({
        articleId: article_id,
        preferredHomeSection: preferred_section,
      });

      if (article_id && resp) {
        setSubmitting(false);
        showLoadingSpinner(false);

        addToast('Article Created', {
          appearance: 'success',
          autoDismiss: 500,
        });

        history.push({
          pathname: `/articles/${article_id}`,
        });
      }
    } catch (e) {
      showLoadingSpinner(false);
      showMessageDialog('ERROR', e.message);
    }
  };

  return (
    <div className={classes.container}>
      <ArticleDetailsForm onSubmit={handleSubmit} />
    </div>
  );
}
