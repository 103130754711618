import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { DropzoneDialog } from 'material-ui-dropzone';
import useStyles from './styles';
import StreamDetailsForm from '../../../../Components/Forms/StreamDetailsForm/StreamDetailsForm';
import updateStream from '../../../../API/streams/updateStream';
import StatusControllerAndId from './StatusControllerAndId';
import setIsActive from '../../../../API/streams/setIsActive';
import Contexts from '../../../../Contexts';
import ImageBlock from '../../../../Components/ImageBlock';
import UploadingDialog from '../../../../Components/UploadingDialog';
import updateStreamThumbNail from '../../../../API/streams/updateStreamThumbnail';
import getSingleStream from '../../../../API/streams/getSingleStream';
import setStreamPreferredHomeSection from '../../../../API/streams/setStreamPreferredHomeSection';
import { Link, Typography } from '@material-ui/core';
import { getAbsoluteMediaPath } from '../../../../Utils/mediaPathHelpers';

export default function StreamsDetails() {
  const classes = useStyles();
  const { addToast } = useToasts();
  const [formFields, setFormFields] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const [is_Active, setIs_Active] = useState('Y');
  const context = useContext(Contexts);
  const { showLoadingSpinner } = context;
  const [uploadingDialogOpen, setUploadingDialogOpen] = useState(false);
  const [imageUploadOpen, setImageUploadOpen] = useState(false);
  const [profileImage, setProfileImage] = useState('');

  // path params
  const { streamId } = useParams();

  const getEventData = async id => {
    const stream = await getSingleStream(id);
    setFormFields(stream);
    setDataFetched(true);
    setIs_Active(stream.is_active || 'Y');
    setProfileImage(stream.thumbnail);
  };

  useEffect(() => {
    if (streamId) {
      getEventData(streamId);
    }
  }, []);

  const handleSubmit = async (values, setSubmitting) => {
    showLoadingSpinner(true);

    try {
      const {
        title,
        state,
        start_timestamp,
        content,
        location,
        district,
        pincode,
        orientation,
        channel_id,
        article_type,
        is_tourn_stream,
        category,
        keywords,
        match_type,
        embed_url,
        event_id,
        group_id,
        round,
        num_of_entities,
        team_A,
        team_B,
        preferred_section,

        // jio stuff
        is_commentary,
        is_jio_stb,
        is_ppv,
        is_sub,
        is_ad,
        is_jio_store,
        is_jio_tv,
        is_all_jio,
        is_global,
      } = values;

      const resp = await updateStream(
        streamId,
        title,
        state,
        start_timestamp,
        content,
        location,
        district,
        pincode,
        orientation,
        channel_id,
        article_type,
        is_tourn_stream,
        category,
        keywords,
        match_type,
        embed_url,
        event_id,
        group_id,
        round,
        num_of_entities,
        team_A,
        team_B,

        formFields.gender_category_ids,
        formFields.age_category_ids,

        // jio stuff
        is_commentary,
        is_jio_stb,
        is_ppv,
        is_sub,
        is_ad,
        is_jio_store,
        is_jio_tv,
        is_all_jio,
        is_global,
      );

      const resp2 = await setStreamPreferredHomeSection({
        streamId,
        preferredHomeSection: preferred_section,
      });

      if (resp && resp2) {
        setSubmitting(false);
        showLoadingSpinner(false);
        addToast('Successfully Saved Stream Details', {
          appearance: 'success',
          autoDismiss: 500,
        });
      }
    } catch (error) {
      showLoadingSpinner(false);

      setSubmitting(false);
      addToast('Error While Saving Stream Details', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  // ============= image upload helpers ========================
  const uploadImage = async file => {
    const imageUrl = await updateStreamThumbNail(file, streamId);

    if (imageUrl) {
      setProfileImage(imageUrl);

      setUploadingDialogOpen(false);
    }
  };

  const handleImageDialogSave = files => {
    const file = files[0];

    setImageUploadOpen(false);
    setUploadingDialogOpen(true);
    uploadImage(file);
  };

  const handleImageDialogClose = () => {
    setImageUploadOpen(false);
  };

  const handleEditImage = () => {
    setImageUploadOpen(true);
  };

  // ================= status controller ============
  const handleStatusButtonClick = async () => {
    const resp = await setIsActive(streamId, !(is_Active === 'Y'));
    if (resp) {
      setIs_Active(is_Active === 'Y' ? 'N' : 'Y');
    }
  };

  return (
    <div className={classes.container}>
      {dataFetched && (
        <>
          <Typography variant="caption">
            media_path: {getAbsoluteMediaPath(formFields.media_path)}
          </Typography>

          {formFields.is_live && (
            <>
              <Link
                style={{ paddingTop: '10px', paddingBottom: '10px' }}
                href={`https://sv-stream-receiver.netlify.app/iframe/${formFields._id}`}
                color="secondary"
                target="_blank"
              >
                direct mobile live stream check link
              </Link>
            </>
          )}
          <StatusControllerAndId
            isActive={is_Active}
            onClick={handleStatusButtonClick}
            eventId={formFields.stream_key}
            media_path={formFields.media_path}
            stream_key={formFields.stream_key}
            matchId={formFields._id}
          />
          <StreamDetailsForm {...formFields} onSubmit={handleSubmit} />
          <div className={classes.imageBlock}>
            <ImageBlock
              buttonLabel="Edit"
              blockLabel="Thumbnail (max size 150KB)"
              onButtonPress={handleEditImage}
              imgSrc={profileImage}
            />
          </div>
          <DropzoneDialog
            open={imageUploadOpen}
            onSave={handleImageDialogSave}
            acceptedFiles={['image/*']}
            showPreviews
            maxFileSize={150000}
            filesLimit={1}
            onClose={handleImageDialogClose}
            showAlerts={false}
          />
          <UploadingDialog text="" open={uploadingDialogOpen} />
        </>
      )}
    </div>
  );
}
