import React, { useContext } from 'react';
import { Form, useFormikContext } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikTextInput from '../MuiFormikComponents/FormikTextInput';
import FormikSubmit from '../MuiFormikComponents/FormikSubmit';
import Context from '../../../Contexts';
import {
  generateStateDropDownList,
  generateSportDropDownList,
  generateCategoriesDropDown,
  generateHomeSectionsListFromCategory,
} from '../../../Utils/dropDownListGenerators';
import FormikDropDown from '../MuiFormikComponents/FormikDropDown';
import ChannelSearchFormComponent from '../MuiFormikComponents/ChannelSearchFormComponent/ChannelSearchFormComponent';
import FormikArrayInput from '../MuiFormikComponents/FormikArrayInput/FormikArrayInput';
import ReactDatePicker from '../MuiFormikComponents/ReactDatePicker';
import FormikSwitch from '../MuiFormikComponents/FormikSwitch';
import ArrayInputCommaString from '../MuiFormikComponents/ArrayInputCommaString';

const FormFields = () => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const context = useContext(Context);
  const { formConfig, categoriesDropDownList, sectionDetails } = context;

  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Stream Details</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Mandatory Fields</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <FormikTextInput label="Title *" name="title" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikDropDown
              valueDisplayNameMap={generateStateDropDownList(formConfig)}
              label="State *"
              name="state"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikDropDown
              valueDisplayNameMap={[
                { value: 'team', displayName: 'Team Based Match' },
                { value: 'na', displayName: 'Not Applicable' },
                { value: 'indi', displayName: 'Individual Player Match' },
              ]}
              label="Match Type *"
              name="match_type"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <ChannelSearchFormComponent label="Channel *" name="channel_id" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikDropDown
              valueDisplayNameMap={categoriesDropDownList}
              label="Category *"
              name="category"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikDropDown
              valueDisplayNameMap={[
                { value: 'landscape', displayName: 'Landscape' },
                { value: 'portrait', displayName: 'Portrait' },
              ]}
              label="Orientation *"
              name="orientation"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikDropDown
              valueDisplayNameMap={[
                { value: 'youtube', displayName: 'Youtube' },
                { value: 'stream', displayName: 'Stream' },
              ]}
              label="Type *"
              name="article_type"
            />
          </Grid>

          {values.article_type === 'youtube' && (
            <Grid item xs={12} sm={6} md={6}>
              <FormikTextInput label="Youtube URL *" name="embed_url" />
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={6}>
            <ReactDatePicker label="Start Time *" name="start_timestamp" />
          </Grid>

          {/* <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput
              label="No Of Entities *"
              name="num_of_entities"
              type="number"
            />
          </Grid> */}

          {values.category && (
            <Grid item xs={12}>
              <Typography variant="h6">Home Section</Typography>
            </Grid>
          )}

          {values.category && (
            <Grid item xs={12} sm={6} md={6}>
              <FormikDropDown
                valueDisplayNameMap={generateHomeSectionsListFromCategory(
                  sectionDetails,
                  values.category,
                  ['short_content', 'long_content', 'story'],
                )}
                label="Home Section"
                name="preferred_section"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <Typography variant="h6">Optional Fields</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Event ID" name="event_id" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Location" name="country" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Country" name="location" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="District" name="district" />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput type="number" label="Pincode" name="pincode" />
          </Grid>

          <Grid item xs={12}>
            <FormikTextInput multiline label="Description" name="content" />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <ArrayInputCommaString
              label="Keywords (comma separated)"
              name="keywords"
              fullwidth
              multiline
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">DEPRECATION NOTE:</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="caption">
              Team A, Team B fields will be deprecated soon.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">
              If using these fields, use the MANAGE TEAMS option (in the
              selected stream) in addition to setting this. This MANAGE TEAMS
              option is available once a stream has been created and can used to
              set the home and away teams from pre-created teams.
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Team A " name="team_A" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Team B " name="team_B" />
          </Grid>

          {values.article_type !== 'youtube' && (
            <>
              <Grid item xs={12}>
                <Typography variant="h6">Jio Related Fields</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikSwitch label="Has Commentary" name="is_commentary" />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikSwitch label="STB" name="is_jio_stb" />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikSwitch label="Pay Per View" name="is_ppv" />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikSwitch label="Has Subtitles" name="is_sub" />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikSwitch label="Ad Enabled" name="is_ad" />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikSwitch label="Store" name="is_jio_store" />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikSwitch label="TV" name="is_jio_tv" />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikSwitch label="All" name="is_all_jio" />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormikSwitch label="Global" name="is_global" />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="save" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
