export const CRICKET_INITIAL_VALUES = {
  is_draw: false,
  winning_team_id: '',
  max_wickets: 0,
  max_overs: 0,
  home_team_wickets: 0,
  home_team_balls_played: 0,
  home_team_runs: 0,
  away_team_wickets: 0,
  away_team_balls_played: 0,
  away_team_runs: 0,
};

export const CRICKET_FROM_FIELDS = [
  {
    id: 's1',
    name: 'General Details',
    type: 'generic',
    fields: [
      {
        id: 'ff5',
        name: 'is_test_match',
        label: 'Is Test Match?',
        type: 'switch',
      },
      {
        id: 'ff1',
        name: 'is_draw',
        label: 'Is Draw?',
        type: 'switch',
      },
      {
        id: 'ff2',
        name: 'winning_team_id',
        label: 'Winner',
        type: 'dropdown',
      },
      {
        id: 'ff3',
        name: 'max_wickets',
        label: 'Max Wickets',
        type: 'number',
        dropdown_options: [],
      },
      {
        id: 'ff4',
        name: 'max_overs',
        label: 'Max Overs',
        type: 'number',
        dropdown_options: [],
      },
    ],
  },
  {
    id: 's2',
    name: 'Home Team',
    type: 'home_team',
    fields: [
      {
        id: 'ff4',
        name: 'home_team_wickets',
        label: 'Wickets',
        type: 'number',
        dropdown_options: [],
      },
      {
        id: 'ff5',
        name: 'home_team_balls_played',
        label: 'Balls Played',
        type: 'number',
        dropdown_options: [],
      },
      {
        id: 'ff6',
        name: 'home_team_runs',
        label: 'Runs',
        type: 'number',
        dropdown_options: [],
      },
    ],
  },
  {
    id: 's3',
    name: 'Away Team',
    type: 'away_team',
    fields: [
      {
        id: 'ff7',
        name: 'away_team_wickets',
        label: 'Wickets',
        type: 'number',
        dropdown_options: [],
      },
      {
        id: 'ff8',
        name: 'away_team_balls_played',
        label: 'Balls Played',
        type: 'number',
        dropdown_options: [],
      },
      {
        id: 'ff9',
        name: 'away_team_runs',
        label: 'Runs',
        type: 'number',
        dropdown_options: [],
      },
    ],
  },
];
