/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import useStyles from './styles';
import UserSearchForm from '../../../../Components/Forms/SearchForms/UserSearchForm/UserSearchForm';
import Contexts from '../../../../Contexts';
import getUsers from '../../../../API/users/getUsers';
import revokeGoLivePermission from '../../../../API/SpecialUserPermissions/revokeGoLivePermission';
import grantGoLivePermission from '../../../../API/SpecialUserPermissions/grantGoLivePermission';
import SharingToUsersTable from '../../../../Components/UsersComponents/SharingToUsersTable/SharingToUsersTable';
import removeSharingToForUser from '../../../../API/users/removeSharingToForUser';
import addSharingToForUser from '../../../../API/users/addSharingToForUser';

export default function AddSharingTo() {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const { showLoadingSpinner } = useContext(Contexts);

  const { userId } = useParams();

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  const ref = useRef({
    mobile: '',
    first_name: '',
    last_name: '',
    has_go_live_permission: false,
    user_id_from_channel_search: '',
    user_id: '',
    page: 1,
  });

  const getData = async () => {
    showLoadingSpinner(true);
    const {
      mobile,
      first_name,
      last_name,
      has_go_live_permission,
      user_id,
      user_id_from_channel_search,
      spa_only,
      page,
    } = ref.current;

    const data = await getUsers({
      page,
      mobile,
      firstName: first_name,
      lastName: last_name,
      id: user_id_from_channel_search || user_id || null,
      includesAuthority: has_go_live_permission ? 'CAN_GO_LIVE' : null,
      spa_only,
    });

    setUsers(data.users);
    setCount(data.total_count);
    showLoadingSpinner(false);
  };

  const onSubmit = async (values, setSubmitting) => {
    const {
      mobile,
      first_name,
      last_name,
      has_go_live_permission,
      user_id,
      user_id_from_channel_search,
      spa_only,
    } = values;

    ref.current = {
      ...ref.current,
      mobile,
      first_name,
      last_name,
      has_go_live_permission,
      user_id,
      user_id_from_channel_search,
      spa_only,
    };

    // reset pages
    setPage(1);
    ref.current.page = 1;

    await getData();
    setSubmitting(false);
  };

  const handleGoLivePermissionToggle = async user => {
    try {
      showLoadingSpinner(true);
      const { _id: userId } = user;

      const hasGoLiveAuthority = user.authorities.includes('CAN_GO_LIVE');

      if (hasGoLiveAuthority) {
        await revokeGoLivePermission({ userId });
      } else {
        await grantGoLivePermission({ userId });
      }

      getData();
    } catch (error) {
      showLoadingSpinner(false);
    }
  };

  const handleRowViewClicked = user => {
    window.open(`/users/${user._id}`);
  };

  const handlePageChange = (event, newPage) => {
    // newPage + 1 adjustment because our pages are not 0 indexed
    // hence when passing to the table too we pass page - 1 and
    // not page
    setPage(newPage + 1);
    ref.current.page = newPage + 1;
    getData();
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSharingToggle = async user => {
    try {
      showLoadingSpinner(true);

      const shouldPerformUnShare = user.sharing_from_accounts.includes(userId);

      if (shouldPerformUnShare) {
        await removeSharingToForUser({ userId, sharingToUserId: user._id });
      } else {
        await addSharingToForUser({ userId, sharingToUserId: user._id });
      }

      getData();
    } catch (error) {
      showLoadingSpinner(false);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.searchContainer}>
        <UserSearchForm onSubmit={onSubmit} />
      </div>

      <SharingToUsersTable
        count={count}
        rowsPerPage={10}
        // not the page - 1 here. Because we don't use zero indexing
        page={page - 1}
        onChangePage={handlePageChange}
        data={users}
        onRowGoLivePermissionToggled={handleGoLivePermissionToggle}
        onRowViewClicked={handleRowViewClicked}
        targetUserId={userId}
        onSharingToggle={user => handleSharingToggle(user)}
      />
    </div>
  );
}
