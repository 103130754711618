import { BASE_URL } from '../constants';

const addScoreCard = async (stream_key = 'none', postData = {}) => {
  try {
    const data = {
      stream_key,
      ...postData,
    };

    const response = await fetch(`${BASE_URL}/api/streams/add_scorecard`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const json = await response.json();
    console.log('TCL: addScoreCard -> json', json);

    if (json.error) {
      console.error(json.error);
      return null;
    }

    return json.data;
  } catch (e) {
    return null;
  }
};

export default addScoreCard;
