import React from 'react';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from 'react-router-dom';
import useStyles from './styles';

export default function SelectedArticle() {
  const classes = useStyles();
  const history = useHistory();
  const { articleId } = useParams();

  const handleManageMediaClicked = () => {
    history.push({
      pathname: `/articles/${articleId}/media`,
    });
  };

  const handleMangeDetailsClicked = () => {
    history.push({
      pathname: `/articles/${articleId}/details`,
    });
  };

  const onLinkedPlayersClicked = () => {
    history.push({
      pathname: `/articles/${articleId}/linked-players`,
    });
  };

  return (
    <div className={classes.container}>
      <Button
        className={classes.buttons}
        variant="outlined"
        onClick={handleMangeDetailsClicked}
      >
        Manage Details
      </Button>

      <Button
        className={classes.buttons}
        variant="outlined"
        onClick={handleManageMediaClicked}
      >
        Manage Media
      </Button>

      <Button
        className={classes.buttons}
        variant="contained"
        onClick={onLinkedPlayersClicked}
      >
        Linked Players
      </Button>
    </div>
  );
}
