import axiosInstance from '../../axiosInstance';

const getSingleTeamDetails = async (id = '') => {
  const response = await axiosInstance(`/api/v2/teams/${id}`);

  const { data } = response.data;

  return data;
};

export default getSingleTeamDetails;
