import axiosInstance from '../../axiosInstance';

const setIsListed = async (event_id = 'default', status = false) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/events/${event_id}/is-listed`,
    {
      status,
    },
  );

  const { data } = response.data;

  return data;
};

export default setIsListed;
