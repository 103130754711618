import axiosInstance from '../../axiosInstance';
import { X_SFA_API_KEY, X_SFA_API_KEY_HEADER } from './sfaApiHeaders';

const getSfaMappingInfo = async streamId => {
  const requestHeaders = {};
  requestHeaders[X_SFA_API_KEY_HEADER] = X_SFA_API_KEY;
  const response = await axiosInstance.get(
    `/api/v2/third-party/sfa/stream/${streamId}/mapping-info`,
    {
      headers: requestHeaders,
    },
  );
  return response.data.data;
};

export default getSfaMappingInfo;
