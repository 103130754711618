import axiosInstance from '../../axiosInstance';

const deleteAllMergedAndIndividualClips = async streamId => {
  const response = await axiosInstance.delete(
    `/api/admin/v2/streams/${streamId}/all-merged-and-individual-clips`,
  );

  const { data } = response.data;

  return data;
};

export default deleteAllMergedAndIndividualClips;
