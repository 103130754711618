import React, { useState, useEffect, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useParams, useHistory } from 'react-router-dom';
import KnockoutForm from '../../../../Components/Forms/KnockoutForm/KnockoutForm';
import useStyles from './styles';
import Contexts from '../../../../Contexts';
import getTournamnetRules from '../../../../API/tournament/getTournamnetRules';
import updateKnockoutData from '../../../../API/tournament/updateKnockoutData';

export default function KnockoutDetails() {
  const classes = useStyles();
  const [formFields, setFormFields] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);
  const { teamId } = useParams();
  const { addToast } = useToasts();
  const history = useHistory();

  const getLeagueData = async () => {
    showLoadingSpinner(true);
    try {
      const data = await getTournamnetRules(teamId, 'KO');
      setFormFields(data);
      setDataFetched(true);
    } catch (err) {
      showMessageDialog('ERROR', err.message);
    } finally {
      showLoadingSpinner(false);
    }
  };

  useEffect(() => {
    if (teamId) {
      getLeagueData();
    }
  }, []);

  const handleSubmit = async (values, setSubmitting) => {
    showLoadingSpinner(true);

    try {
      const resp = await updateKnockoutData(values, teamId);

      if (resp.data) {
        showLoadingSpinner(false);
        setSubmitting(false);
        addToast('Successfully Saved', {
          appearance: 'success',
          autoDismiss: 500,
        });
        history.goBack();
      }
    } catch (error) {
      showLoadingSpinner(false);
      setSubmitting(false);
      addToast('Error While Saving', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  return (
    <div className={classes.container}>
      {dataFetched && <KnockoutForm {...formFields} onSubmit={handleSubmit} />}
    </div>
  );
}
