import axiosInstance from '../../axiosInstance';

const updateFeaturedTextSection = async (
  updatedText,
  userId,
  sectionKey,
  lang = 'eng',
) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/players/${userId}/featured-text-section/${sectionKey}`,
    { text: updatedText },
    {
      params: {
        lang,
      },
    },
  );

  const { data } = response.data;

  return data;
};

export default updateFeaturedTextSection;
