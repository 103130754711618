import axiosInstance from '../../axiosInstance';

const updateTeamDetails = async (
  teamId,
  {
    name,
    sport,
    state,
    district = '',
    association = '',
    gender_category_ids = [],
    age_category_ids = [],
  },
) => {
  const response = await axiosInstance.patch(`/api/admin/v2/teams/${teamId}`, {
    name,
    sport,
    district,
    association,
    state,
    gender_category_ids,
    age_category_ids,
  });
  const { data } = response.data;

  return data;
};

export default updateTeamDetails;
