import axiosInstance from '../../axiosInstance';

const addSinglePlayer = async (
  team_id,
  { player_id, jersey_num = 0, position = '' },
) => {
  const response = await axiosInstance.post(
    `/api/admin/v2/teams/${team_id}/player`,
    {
      player_id,
      jersey_num,
      position,
    },
  );

  const { data } = response.data;

  return data;
};

export default addSinglePlayer;
