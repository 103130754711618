import { BASE_URL } from '../constants';

const getIndividualPlayer = async tourn_id => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/v2/tournaments/${tourn_id}/players`,
    );

    const json = await response.json();

    if (json.error) {
      throw new Error(json.error.errorMessage);
    }

    return json.data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default getIndividualPlayer;
