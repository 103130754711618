import axiosInstance from '../../axiosInstance';

const getAggPMSByPlayerId = async ({ sportKey, playerId }) => {
  const response = await axiosInstance.get(
    `/api/v2/stats/agg-player-match/${sportKey}/player/${playerId}`,
  );

  const { data } = response.data;

  return data.agg_player_match_stat;
};

export default getAggPMSByPlayerId;
