import React, { useState } from 'react';
import {
  Typography,
  Dialog,
  DialogContentText,
  TextField,
  CircularProgress,
} from '@material-ui/core';
import {
  CustomDialogActions,
  CustomDialogContent,
  CustomTitle,
} from '../../UI/UIElements/CustomDialogComponents';
import { CustomButton } from '../../UI/UIElements/CustomButton';

export default function ConfirmationModal({
  open,
  handleCancel,
  handleConfirm,
  title = '',
  description = '',
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  type = 'red',
  onClose = () => {},
  note = '',
  isLoading,
  askPassword = true,
}) {
  const [password, setPassword] = useState('');

  return (
    <div style={{ zIndex: 99999999 }}>
      <Dialog
        onClose={onClose || handleCancel}
        aria-labelledby="customized-dialog-title"
        open={open}
        style={{ zIndex: 99999999 }}
      >
        {' '}
        <CustomTitle>{title}</CustomTitle>{' '}
        <CustomDialogContent>
          <DialogContentText>{description}</DialogContentText>
          {note && (
            <Typography variant="caption" style={{ opacity: 0.8 }}>
              {note}
            </Typography>
          )}
          {askPassword && (
            <TextField
              placeholder="Password"
              label="Password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              type="password"
            />
          )}
        </CustomDialogContent>
        <CustomDialogActions>
          <CustomButton
            color="transparent"
            size="small"
            variant="outlined"
            style={{ padding: '0.75vh 1vw' }}
            onClick={handleCancel}
          >
            {cancelText}
          </CustomButton>
          <CustomButton
            color={type === 'red' ? 'red' : 'blue'}
            size="small"
            variant="contained"
            onClick={() => handleConfirm(password)}
            disabled={!password && askPassword}
          >
            {isLoading && (
              <CircularProgress size={12} style={{ marginRight: 10 }} />
            )}
            {confirmText}
          </CustomButton>
        </CustomDialogActions>
      </Dialog>
    </div>
  );
}
