import axiosInstance from '../../axiosInstance';

// ref: https://docs.aws.amazon.com/AWSEC2/latest/APIReference/API_InstanceState.html

const getStreamingServerState = async () => {
  const response = await axiosInstance.get(
    `/api/admin-actions/v2/ec2-state/streaming-server/status`,
  );

  const { data } = response.data;

  return data.instance_state;
};

export default getStreamingServerState;
