import { Container, Box, Typography, Divider, Button } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ConfirmationModal from '../../../../Components/ConfirmationModel';
import getSfaMappingInfo from '../../../../API/sfaMapping/getSfaMappingInfo';
import deleteSfaMappingInfo from '../../../../API/sfaMapping/deleteSfaMappingInfo';

function SFAMapping() {
  const { streamId } = useParams();
  const [isModelVisible, setIsModelVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mappingInfo, setMappingInfo] = useState();

  const getMapping = async () => {
    const mapping = await getSfaMappingInfo(streamId);
    console.log(mapping);
    setMappingInfo(mapping);
  };

  useEffect(() => {
    getMapping();
  }, []);

  const handleDeleteMapping = async password => {
    setIsLoading(true);
    try {
      await deleteSfaMappingInfo(streamId, password);
    } catch (e) {
      // ignored
    } finally {
      setIsModelVisible(false);
      setIsLoading(false);
      getMapping();
    }
  };

  const getStreamDetails = () => {
    if (!mappingInfo) return null;
    if (!mappingInfo.stream)
      return (
        <Typography variant="subtitle2" style={{ color: '#f5b342' }}>
          [NOT FOUND]: Stream not found for this id
        </Typography>
      );
    const { stream } = mappingInfo;
    const { title, category } = stream;
    return (
      <>
        <Typography variant="subtitle2">title: {title}</Typography>
        <Typography variant="subtitle2">category: {category}</Typography>
      </>
    );
  };

  const getExternalMappingDetails = () => {
    if (!mappingInfo) return null;
    if (!mappingInfo.external_mapping)
      return (
        <Typography variant="subtitle2" style={{ color: '#f5b342' }}>
          [NOT FOUND]: ExternalMapping not found for this match
        </Typography>
      );
    const { external_mapping } = mappingInfo;
    const {
      attributes,
      _id,
      internal_id,
      external_id,
      entity,
      source,
    } = external_mapping;
    const attributeEntries = Object.entries(attributes);
    return (
      <>
        <Typography variant="subtitle2">_id: {_id}</Typography>
        <Typography variant="subtitle2">internal_id: {internal_id}</Typography>
        <Typography variant="subtitle2">external_id: {external_id}</Typography>
        <Typography variant="subtitle2">entity: {entity}</Typography>
        <Typography variant="subtitle2">source: {source}</Typography>
        <Typography variant="subtitle2">attributes:</Typography>

        {attributeEntries.map(([key, value]) => (
          <Typography variant="subtitle2">
            <strong>{key}:</strong> {value}
          </Typography>
        ))}
      </>
    );
  };

  const getCloudProductionDetails = () => {
    if (!mappingInfo) return null;
    if (!mappingInfo.cloud_production)
      return (
        <Typography variant="subtitle2" style={{ color: '#f5b342' }}>
          [NOT FOUND]: CloudProduction not found for this match
        </Typography>
      );
    const { cloud_production } = mappingInfo;
    const {
      is_terminate_initiated,
      is_terminated,
      match_id,
      ip,
      is_ready,
    } = cloud_production;
    return (
      <>
        <Typography variant="subtitle2">match_id: {match_id}</Typography>
        <Typography variant="subtitle2">
          is_ready: {is_ready.toString()}
        </Typography>
        <Typography variant="subtitle2">ip: {ip}</Typography>
        <Typography variant="subtitle2">
          is_terminate_initiated: {is_terminate_initiated.toString()}
        </Typography>
        <Typography variant="subtitle2">
          is_terminated: {is_terminated.toString()}
        </Typography>
      </>
    );
  };

  return (
    <Container maxWidth="md">
      <Box
        display="flex"
        border="1px solid #333333"
        mt={2}
        borderRadius={5}
        p={2}
      >
        <Box width="100%">
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            Match ID: {streamId}
          </Typography>
          <Divider />

          <Typography variant="h6">Stream</Typography>
          {getStreamDetails()}

          <Divider />

          <Typography variant="h6">External Mapping</Typography>
          {getExternalMappingDetails()}

          <Divider />

          <Typography variant="h6">Cloud Production</Typography>
          {getCloudProductionDetails()}
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="center" mt={2}>
        <Button
          style={{ backgroundColor: 'red', color: '#fff' }}
          onClick={() => setIsModelVisible(true)}
        >
          DELETE SFA MAPPING
        </Button>
      </Box>

      <ConfirmationModal
        open={isModelVisible}
        title="Delete SFA Mapping"
        description="Are you sure you want to delete this?"
        handleCancel={() => setIsModelVisible(false)}
        handleConfirm={password => handleDeleteMapping(password)}
        confirmText="Yes, Delete"
        isLoading={isLoading}
      />
    </Container>
  );
}

export default SFAMapping;
