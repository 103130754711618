import React, { useContext } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import useStyles from './styles';
import { generateSportPositionDisplayName } from '../../Utils/genrateDisplayNames';
import Context from '../../Contexts';
import { generateSportBasedPositionDropdown } from '../../Utils/dropDownListGenerators';
import DebouncedTextField from '../CustomComponents/DeboucedTextField';

export default function TeamPlayerTable({
  data,
  onDeletePlayer,
  onJerseyNoChange,
  onPositionChange,
  onDummyPlayerNameChange,
  onViewProfileClicked,
  sport = '',
}) {
  const classes = useStyles();

  const context = useContext(Context);
  const { formConfig } = context;

  const handleDebouchedFirstNameOrLastNameChange = ({
    playerId,
    firstName,
    lastName,
    position,
    jerseyNum,
  }) => {
    onDummyPlayerNameChange({
      playerId,
      firstName,
      lastName,
      position,
      jerseyNum,
    });
  };

  return (
    <div className={classes.tableContainer}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">Sr no</TableCell>
              <TableCell>First Name</TableCell>
              <TableCell>Last Name</TableCell>
              <TableCell align="center">Position</TableCell>
              <TableCell align="center">Jersey No.</TableCell>
              <TableCell align="center">Delete</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => {
                console.log({
                  [row.position]: generateSportPositionDisplayName(
                    formConfig,
                    sport,
                    row.position,
                  ),
                });
                return (
                  <TableRow key={row.player_id}>
                    <TableCell align="center">{index + 1}</TableCell>

                    {!row.is_dummy_plr && (
                      <TableCell component="th" scope="row">
                        {`${row.first_name}`}
                      </TableCell>
                    )}

                    {row.is_dummy_plr && (
                      <TableCell component="th" scope="row">
                        <DebouncedTextField
                          style={{ width: 150 }}
                          id="outlined-basic"
                          label="First Name"
                          variant="outlined"
                          initialValue={row.first_name}
                          debounceInterval={750}
                          onDebouncedChange={value => {
                            handleDebouchedFirstNameOrLastNameChange({
                              playerId: row.player_id,
                              firstName: value,
                              lastName: row.last_name,
                              position: row.position,
                              jerseyNum: row.jersey_num,
                            });
                          }}
                        />
                      </TableCell>
                    )}

                    {!row.is_dummy_plr && (
                      <TableCell component="th" scope="row">
                        {`${row.last_name}`}
                      </TableCell>
                    )}

                    {row.is_dummy_plr && (
                      <TableCell component="th" scope="row">
                        <DebouncedTextField
                          style={{ width: 150 }}
                          id="outlined-basic"
                          label="Last Name"
                          variant="outlined"
                          initialValue={row.last_name}
                          debounceInterval={750}
                          onDebouncedChange={value => {
                            handleDebouchedFirstNameOrLastNameChange({
                              playerId: row.player_id,
                              firstName: row.first_name,
                              lastName: value,
                              position: row.position,
                              jerseyNum: row.jersey_num,
                            });
                          }}
                        />
                      </TableCell>
                    )}

                    <TableCell align="center">
                      <FormControl fullWidth className={classes.formControl}>
                        <Select
                          value={row.position}
                          className={classes.selectEmpty}
                          onChange={e => {
                            onPositionChange(e.target.value, row.player_id);
                          }}
                        >
                          <MenuItem value="">None</MenuItem>
                          {generateSportBasedPositionDropdown(
                            formConfig,
                            sport,
                          ).map(item => (
                            <MenuItem key={item.value} value={item.value}>
                              {item.displayName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>

                    <TableCell align="center">
                      <DebouncedTextField
                        type="number"
                        style={{ width: 90 }}
                        id="outlined-basic"
                        label="No."
                        variant="outlined"
                        initialValue={row.jersey_num}
                        debounceInterval={750}
                        onDebouncedChange={value => {
                          onJerseyNoChange(value, row.player_id);
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => onDeletePlayer(row.player_id)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => onViewProfileClicked(row.player_id)}
                      >
                        Profile
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
