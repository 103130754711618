import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(1),
    width: theme.spacing(40),
    height: theme.spacing(40),
  },
  inputsContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '10px',
    alignItems: 'center',
  },
  inputBox: {
    margin: '10px',
  },
  loginButton: {
    marginTop: '20px',
  },
}));

export default useStyles;
