import axiosInstance from '../../axiosInstance';

const getSingleStream = async streamId => {
  const response = await axiosInstance(`/api/v2/streams/${streamId}`);

  const { data } = response.data;

  return data.stream;
};

export default getSingleStream;
