import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  textField: {
    margin: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 50,
  },
}));

export default useStyles;
