import React, { useContext } from 'react';
import { Form, useFormikContext } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikSubmit from '../../MuiFormikComponents/FormikSubmit';
import FormikTextInput from '../../MuiFormikComponents/FormikTextInput';
import FormikDropDown from '../../MuiFormikComponents/FormikDropDown';
import Contexts from '../../../../Contexts';
import {
  generateStateDropDownList,
  generateHomeSectionsListFromCategory,
  generateSportDropDownList,
} from '../../../../Utils/dropDownListGenerators';
import ReactDatePicker from '../../MuiFormikComponents/ReactDatePicker';

const FormFields = ({ isForManagedHomeFeed = false }) => {
  const classes = useStyles();
  const { values } = useFormikContext();

  const context = useContext(Contexts);
  const { formConfig, sectionDetails } = context;

  return (
    <Form>
      <Paper
        elevation={3}
        className={classes.formContainer}
        style={{
          maxWidth: !isForManagedHomeFeed ? '600px' : '100%',
          marginTop: !isForManagedHomeFeed && 0,
        }}
      >
        <Grid container spacing={3}>
          {!isForManagedHomeFeed && (
            <Grid item xs={12}>
              <Typography variant="h4">Events Search</Typography>
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={6} lg={isForManagedHomeFeed ? 3 : 6}>
            <FormikTextInput label="Event Name" name="name" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={isForManagedHomeFeed ? 3 : 6}>
            <FormikTextInput label="Event id" name="event_id" />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={isForManagedHomeFeed ? 3 : 6}>
            <FormikDropDown
              valueDisplayNameMap={generateStateDropDownList(formConfig)}
              label="State"
              name="state"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={isForManagedHomeFeed ? 3 : 6}>
            <FormikDropDown
              valueDisplayNameMap={generateSportDropDownList(formConfig)}
              label="Sport"
              name="sport"
            />
          </Grid>

          {isForManagedHomeFeed && (
            <>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <ReactDatePicker
                  disableTime
                  label="Start Date"
                  name="start_date"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <ReactDatePicker disableTime label="End Date" name="end_date" />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={isForManagedHomeFeed ? 3 : 6}
              >
                <FormikTextInput label="Channel Id" name="creator_id" />
              </Grid>
            </>
          )}

          {values.sport && !isForManagedHomeFeed && (
            <Grid item xs={12} sm={6} md={6} lg={isForManagedHomeFeed ? 3 : 6}>
              <FormikDropDown
                valueDisplayNameMap={generateHomeSectionsListFromCategory(
                  sectionDetails,
                  values.sport,
                  ['event'],
                )}
                label="Home Section"
                name="preferred_section"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="Search" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
