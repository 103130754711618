import React from 'react';
import { FieldArray, useField, useFormikContext } from 'formik';
import {
  Button,
  IconButton,
  TextField,
  Container,
  List,
  ListItem,
  ListItemSecondaryAction,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import useStyles from './styles'; // Assuming you have a separate file for JSS styles

// Include bitrates in the qualityOptions
const qualityOptions = [
  { label: '1080p', value: '1080p', bitrateKbps: 5000 },
  { label: '720p', value: '720p', bitrateKbps: 2800 },
  { label: '480p', value: '480p', bitrateKbps: 1400 },
];

const FormikFieldArrayForCostCalcHlsQualityLevels = ({ name }) => {
  const { values, setFieldValue } = useFormikContext();
  const [, meta] = useField(name);
  const classes = useStyles();
  const items = values[name];

  const handleNameChange = (index, newValue) => {
    const selectedOption = qualityOptions.find(
      option => option.value === newValue,
    );
    const newItems = items.map((item, i) =>
      i === index
        ? { ...item, name: newValue, bitrateKbps: selectedOption.bitrateKbps }
        : item,
    );
    setFieldValue(name, newItems);
  };

  return (
    <Container>
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <div>
            <List>
              {items.length > 0 ? (
                items.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem key={index} className={classes.listItem}>
                    <FormControl style={{ marginRight: 8, minWidth: 120 }}>
                      <InputLabel>Quality</InputLabel>
                      <Select
                        value={item.name}
                        onChange={e => handleNameChange(index, e.target.value)}
                      >
                        {qualityOptions.map(option => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      label="Bitrate (Kbps)"
                      InputProps={{ readOnly: true }}
                      style={{ marginLeft: 8, marginRight: 8, minWidth: 120 }}
                      value={item.bitrateKbps}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))
              ) : (
                <Button
                  type="button"
                  onClick={() =>
                    arrayHelpers.push({
                      name: qualityOptions[0].value,
                      bitrateKbps: qualityOptions[0].bitrateKbps,
                    })
                  }
                >
                  Add Quality Level
                </Button>
              )}
            </List>
            {items.length > 0 && items.length < qualityOptions.length && (
              <Button
                startIcon={<AddIcon />}
                onClick={() =>
                  arrayHelpers.push({
                    name: qualityOptions[0].value,
                    bitrateKbps: qualityOptions[0].bitrateKbps,
                  })
                }
                className={classes.addButton}
              >
                Add Another Quality Level
              </Button>
            )}
            {meta.touched && meta.error && (
              <div className={classes.error}>{meta.error}</div>
            )}
          </div>
        )}
      />
    </Container>
  );
};

export default FormikFieldArrayForCostCalcHlsQualityLevels;
