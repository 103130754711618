import { useState, useEffect } from 'react';
import getUserById from '../API/users/getUserById';

const useUserData = userId => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchUser = async () => {
    try {
      const userData = await getUserById(userId);
      if (userData) {
        setUser(userData);
      }
    } catch (err) {
      console.error(`failed to fetch user with id ${userId}`);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [userId]);

  return { user, loading, error, refetchUser: fetchUser };
};

export default useUserData;
