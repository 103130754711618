import { Button, withStyles } from '@material-ui/core';
import { color } from '../color/colorConfig';

const styledBy = (property, mapping) => props => mapping[props[property]];

export const CustomButton = withStyles(theme => ({
  root: {
    color: '#fff',
    background: styledBy('color', {
      blue: color.blue.main_color,
      red: color.red.main_color,
      green: color.green.main_color,
      white: color.white.main_color,
      yellow: color.yellow.main_color,
      orange: color.orange.main_color,
    }),
    '&:hover': {
      background: styledBy('color', {
        blue: color.blue.hover_color,
        red: color.red.hover_color,
        green: color.green.hover_color,
        white: color.white.hover_color,
        yellow: color.yellow.hover_color,
        orange: color.orange.main_color,
      }),
    },
    '&$disabled': {
      color: 'grey',
      backgroundColor: '#2e2e2e',
    },
  },
  disabled: {},
  outlined: {
    color: theme.palette.secondary.light,
  },
}))(Button);

export const BorderedButton = withStyles(theme => ({
  root: {
    color: '#fff',
    backgroundColor: color.black.main_color,
    borderStyle: 'solid',
    borderColor: '#fff',
    borderWidth: 1,
    borderRadius: 5,
    margin: 10,
    '&:hover': {
      backgroundColor: color.black.hover_color,
    },

    '&$disabled': {
      color: 'grey',
      backgroundColor: '#2e2e2e',
    },
  },
  disabled: {},
}))(Button);

export default null;
