/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import getGameState from '../../API/GameState';

interface UseGameStateData {
  id: string;
  sport: string;
}

function useGameStateData(params: UseGameStateData) {
  const { id, sport } = params;
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<unknown>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getGameStateData = async () => {
    setLoading(true);
    try {
      const { game_state } = await getGameState(id, sport);
      setData(game_state);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getGameStateData();

    return () => {
      setData(null);
      setError(null);
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sport]);

  return { data, error, loading, setData, refetch: getGameStateData };
}

export default useGameStateData;
