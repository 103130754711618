import React, { useState, useContext, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import { useHistory, useRouteMatch, useParams } from 'react-router-dom';
import useStyles from './styles';
import { BASE_URL } from '../../../../API/constants';
import Contexts from '../../../../Contexts/Contexts';
import TeamTable from '../../../../Components/TournamentComponenet/TeamTable';
import updateTeamGroupId from '../../../../API/tournament/updateTeamGroupId';

export default function Teams() {
  const classes = useStyles();
  const context = useContext(Contexts);
  const { configState } = context;
  const [playersData, setPlayersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { teamId } = useParams();
  const { path } = useRouteMatch();
  const [singleTeam, setSingleTeam] = useState({});

  const history = useHistory();

  const getPlayerByTeam = async () => {
    const response = await fetch(
      `${BASE_URL}/api/v2/tournaments/${teamId}/teams`,
    );
    const { data } = await response.json();
    console.log('AA', data);
    setPlayersData(data.teams);
    setSingleTeam(data.tournament_details);

    setLoading(false);
  };

  useEffect(() => {
    getPlayerByTeam();
  }, []);

  const deletePlayer = async playerId => {
    const postBody = { team_id: playerId };
    try {
      const response = await fetch(
        `${BASE_URL}/api/v2/tournaments/${teamId}/teams`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postBody),
        },
      );

      const json = await response.json();
      if (json.error) {
        throw new Error(JSON.stringify(json.error));
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const handleDeletePlayer = e => {
    console.log('playrData', playersData);
    const value = playersData.filter(el => el.team_id !== e);

    // setPlayersData(playersData.splice(0, playersData.length));
    setPlayersData([...value]);
    deletePlayer(e);
  };
  const onNavigateToTeamsPlayer = i => {
    setSingleTeam(i);
    history.push({
      pathname: `/tournament/${teamId}/teams/${i}`,
    });
  };

  const setAlphaData = (value, row) => {
    const temp = [];
    playersData.forEach(element => {
      if (element.team_id === row.team_id) {
        element.group_id = value;
      }
      temp.push(element);
    });
    setPlayersData(temp);
    updateTeamGroupId(teamId, row.team_id, value);
  };

  return (
    <div className={classes.root}>
      <div style={{ display: 'flex' }}>
        <p style={{ marginRight: 10 }}>Add Teams </p>
        <IconButton
          edge="start"
          className={classes.searchIcon}
          color="default"
          aria-label="menu"
          onClick={() => {
            history.push({
              pathname: `/tournament/${teamId}/teams/add`,
            });
          }}
        >
          <Add />
        </IconButton>
      </div>

      {!loading && playersData.length > 0 ? (
        <>
          <TeamTable
            data={playersData}
            onDeletePlayer={handleDeletePlayer}
            onNavigateToTeamsPlayer={onNavigateToTeamsPlayer}
            type={singleTeam.type}
            setAlphaValue={setAlphaData}
            noOfGroup={singleTeam.LKOG ? singleTeam.LKOG_rules.NoG : 2}
          />
        </>
      ) : (
        <div />
      )}
    </div>
  );
}
