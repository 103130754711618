import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import useStyles from './styles';
import { BASE_URL } from '../../../../API/constants';
import updateTournamentProfile from '../../../../API/users/updateTournamentProfile';
import ImageBlock from '../../../../Components/ImageBlock';
import { DropzoneDialog } from 'material-ui-dropzone';
import UploadingDialog from '../../../../Components/UploadingDialog';
import updateTournamentLogo from '../../../../API/users/updateTournamentLogo';
import AddTournamentForm from '../../../../Components/Forms/AddTournamentForm/AddTournamentForm';
import Contexts from '../../../../Contexts';

export default function TournamentDetails() {
  const imageType = {
    PROFILE: 'profile',
    BACKGROUND: 'background',
  };
  const ref = useRef({
    userId: 'default',
    editImageType: imageType.PROFILE,
  });
  const { addToast } = useToasts();
  const classes = useStyles();
  const [formFields, setFormFields] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const [uploadingDialogOpen, setUploadingDialogOpen] = useState(false);
  const [imageUploadOpen, setImageUploadOpen] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const context = useContext(Contexts);
  const { showLoadingSpinner } = context;

  // path params
  const { teamId } = useParams();

  const getUserData = async id => {
    const response = await fetch(`${BASE_URL}/api/v2/tournaments/${id}`);
    const { data } = await response.json();
    setFormFields(data);
    setDataFetched(true);
    setProfileImage(data.logo);
  };

  useEffect(() => {
    if (teamId) {
      getUserData(teamId);
    }
  }, []);

  const handleSubmit = async (values, setSubmitting) => {
    showLoadingSpinner(true);

    try {
      const resp = await updateTournamentProfile(values, teamId);

      if (resp.data) {
        showLoadingSpinner(false);
        setSubmitting(false);
        addToast('Successfully Saved Tournament Details', {
          appearance: 'success',
          autoDismiss: 500,
        });
      }
    } catch (error) {
      showLoadingSpinner(false);
      setSubmitting(false);
      addToast('Error While Saving Tournament Details', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  const uploadImage = async file => {
    const resp = await updateTournamentLogo(file, teamId);

    if (resp) {
      if (ref.current.editImageType === imageType.PROFILE) {
        setProfileImage(resp.imageUrl);
      }
      setUploadingDialogOpen(false);
    }
  };

  const handleImageDialogSave = files => {
    const file = files[0];

    setImageUploadOpen(false);
    setUploadingDialogOpen(true);
    uploadImage(file);
  };

  const handleImageDialogClose = () => {
    setImageUploadOpen(false);
  };

  const handleEditImage = type => {
    ref.current.editImageType = type;
    setImageUploadOpen(true);
  };

  return (
    <div className={classes.container}>
      {dataFetched && (
        <AddTournamentForm {...formFields} onSubmit={handleSubmit} />
      )}
      <div className={classes.imageBlock}>
        <ImageBlock
          buttonLabel="Edit"
          blockLabel="Profile Image"
          onButtonPress={() => handleEditImage(imageType.PROFILE)}
          imgSrc={profileImage}
        />
      </div>

      <DropzoneDialog
        open={imageUploadOpen}
        onSave={handleImageDialogSave}
        acceptedFiles={['image/*']}
        showPreviews={true}
        maxFileSize={5000000}
        filesLimit={1}
        onClose={handleImageDialogClose}
        showAlerts={false}
      />
      <UploadingDialog open={uploadingDialogOpen} />
    </div>
  );
}
