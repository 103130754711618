import React, { useEffect } from 'react';
import { Form, useFormikContext } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikSubmit from '../../MuiFormikComponents/FormikSubmit';
import FormikTextInput from '../../MuiFormikComponents/FormikTextInput';
import FormikDropDown from '../../MuiFormikComponents/FormikDropDown';
import FormikSwitch from '../../MuiFormikComponents/FormikSwitch';

const FormFields = ({ stateDropDownList, sportDropDownList }) => {
  const classes = useStyles();

  const {
    values: { enabled_only, disabled_only },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    if (enabled_only) {
      setFieldValue('enabled_only', true);
      setFieldValue('disabled_only', false);
    }
  }, [enabled_only]);

  useEffect(() => {
    if (disabled_only) {
      setFieldValue('enabled_only', false);
      setFieldValue('disabled_only', true);
    }
  }, [disabled_only]);

  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Teams Search</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Team Name" name="name" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Team id" name="team_id" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikDropDown
              valueDisplayNameMap={stateDropDownList}
              label="State"
              name="state"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikDropDown
              valueDisplayNameMap={sportDropDownList}
              label="Sport"
              name="sport"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Creator id" name="creator_id" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch name="enabled_only" label="Enabled Only" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikSwitch name="disabled_only" label="Disabled Only" />
          </Grid>

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="Search" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
