import React from 'react';
import { Dialog, Typography, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './Styles';

const UploadingDialogWithProgress = ({
  open,
  textUploading,
  textUploadComplete,
  progressValue,
}) => {
  const classes = useStyles();
  return (
    <Dialog open={open}>
      <div className={classes.uploadingDialog}>
        <Typography variant="overline">
          {progressValue >= 100 ? textUploadComplete : textUploading}
        </Typography>
        <CircularProgress
          variant={`${progressValue >= 100 ? 'indeterminate' : 'static'}`}
          value={progressValue}
        />
      </div>
    </Dialog>
  );
};

UploadingDialogWithProgress.propTypes = {
  open: PropTypes.bool,
  textUploading: PropTypes.string,
  textUploadComplete: PropTypes.string,
  progressValue: PropTypes.number,
};

UploadingDialogWithProgress.defaultProps = {
  open: false,
  textUploading: 'Uploading',
  progressValue: 25,
  textUploadComplete: 'Processing',
};

export default UploadingDialogWithProgress;
