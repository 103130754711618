/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Formik } from 'formik';
import FormFields from './FormFields';
import { authoritiesList } from '../../../Utils/authorities';

const UserPermissionForm = ({ onSubmit, ...props }) => {
  const getInitialValues = () => {
    const initialAuthorities = {};

    authoritiesList.forEach(authority => {
      let isAuthorityPresent = false;
      if (props[authority]) isAuthorityPresent = true;
      initialAuthorities[authority] = isAuthorityPresent;
    });

    return initialAuthorities;
  };

  return (
    <>
      <Formik
        initialValues={getInitialValues()}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields />
      </Formik>
    </>
  );
};

export default UserPermissionForm;
