import React from 'react';
import { Grid } from '@material-ui/core';
import FormikTextInput from '../MuiFormikComponents/FormikTextInput';

const StatsField = ({ label = 'label', name = 'default_key' }) => {
  return (
    <>
      <Grid item xs={12} sm={6} md={6}>
        <FormikTextInput label={label} name={name} />
      </Grid>
    </>
  );
};

export default StatsField;
