import React, { useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useStyles from './styles';
import StreamDetailsForm from '../../../../Components/Forms/StreamDetailsForm/StreamDetailsForm';
import createStream from '../../../../API/streams/createStream';
import Contexts from '../../../../Contexts';
import setStreamPreferredHomeSection from '../../../../API/streams/setStreamPreferredHomeSection';

export default function AddStream() {
  const classes = useStyles();
  const history = useHistory();
  const { addToast } = useToasts();
  const context = useContext(Contexts);
  const { showLoadingSpinner } = context;

  const queryParams = queryString.parse(useLocation().search);

  const handleSubmit = async (values, setSubmitting) => {
    showLoadingSpinner(true);
    try {
      const {
        title,
        state,
        start_timestamp,
        content,
        location,
        district,
        pincode,
        orientation,
        channel_id,
        article_type,
        is_tourn_stream,
        category,
        keywords,
        match_type,
        embed_url,
        event_id,
        round,
        country,
        group_id,
        num_of_entities,
        team_A,
        team_B,
        preferred_section,

        // jio stuff
        is_commentary,
        is_jio_stb,
        is_ppv,
        is_sub,
        is_ad,
        is_jio_store,
        is_jio_tv,
        is_all_jio,
        is_global,
      } = values;

      const streamId = await createStream(
        title,
        state,
        start_timestamp,
        content,
        location,
        district,
        pincode,
        orientation,
        channel_id,
        article_type,
        is_tourn_stream,
        category,
        keywords,
        match_type,
        embed_url,
        event_id,
        round,
        country,
        group_id,
        num_of_entities,
        team_A,
        team_B,

        // jio stuff
        is_commentary,
        is_jio_stb,
        is_ppv,
        is_sub,
        is_ad,
        is_jio_store,
        is_jio_tv,
        is_all_jio,
        is_global,
      );

      const resp = await setStreamPreferredHomeSection({
        streamId,
        preferredHomeSection: preferred_section,
      });

      if (streamId && resp) {
        showLoadingSpinner(false);

        setSubmitting(false);
        addToast('Successfully Created Stream', {
          appearance: 'success',
          autoDismiss: 500,
        });

        history.goBack();
      }
    } catch (error) {
      showLoadingSpinner(false);

      setSubmitting(false);
      addToast('Error While Creating Stream', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  return (
    <div className={classes.container}>
      <StreamDetailsForm {...queryParams} onSubmit={handleSubmit} />
    </div>
  );
}
