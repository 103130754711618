import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import Contexts from '../../../../Contexts';
import getAutoClipsInfoForMatchFastly from '../../../../API/autoClipGenFast/getAutoClipsInfoForMatchFastly';
import AutoClipsGenFormFastly from '../../../../Components/Forms/AutoClipsGenFormFast/AutoClipsGenFormFastly';
import queueAutoClipGenForMatchFastly from '../../../../API/autoClipGenFast/queueAutoClipGenForMatchFastly';
import getSingleStream from '../../../../API/streams/getSingleStream';

export default function GenerateAutoClipsFastly() {
  const { showLoadingSpinner } = useContext(Contexts);
  const classes = useStyles();
  const { streamId } = useParams();
  const [isInitComplete, setIsInitComplete] = useState(false);
  const [stream, setStream] = useState({});

  const [currentStatus, setCurrentStatus] = useState({
    is_processing_needed: false,
    is_queued: false,
    window_seconds_after_scoring_event: 4,
    window_seconds_before_scoring_event: 20,
    times_un_successfully_processed: 0,
    times_successfully_processed: 0,
  });

  const getInitialData = async () => {
    showLoadingSpinner(true);
    const fetchedCurrentStatus = await getAutoClipsInfoForMatchFastly({
      matchId: streamId,
    });
    const fetchedStream = await getSingleStream(streamId);
    setStream(fetchedStream);
    setCurrentStatus(fetchedCurrentStatus);
    setIsInitComplete(true);
    showLoadingSpinner(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const handleOnSubmit = async (values, setSubmitting) => {
    const {
      window_seconds_before_scoring_event,
      window_seconds_after_scoring_event,
    } = values;

    const newStatus = await queueAutoClipGenForMatchFastly({
      matchId: streamId,
      windowSecondsBeforeScoringEvent: window_seconds_before_scoring_event,
      windowSecondsAfterScoringEvent: window_seconds_after_scoring_event,
    });

    setCurrentStatus(newStatus);
    setSubmitting(false);
  };

  const shouldShowForm =
    !currentStatus.is_processing_needed && !currentStatus.is_queued;

  let currentStateText = '';

  if (currentStatus.is_processing_needed) {
    currentStateText = 'PROCESSING';
  }

  if (currentStatus.is_queued) {
    currentStateText = 'QUEUED';
  }

  const { media_path = '', is_complete = false } = stream;

  const isValidStream = media_path.includes('.m3u8') && is_complete;

  if (!isInitComplete) return <div>loading</div>;

  if (!isValidStream)
    return <div>Stream must be Non-YT and complete to generate auto clips</div>;

  return (
    <div className={classes.root}>
      <div
        style={{
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption">
          ABOUT: this will generate auto clips that create match highlights and
          links clips to player profiles too
        </Typography>

        <Typography variant="caption">
          Works for football,BB,crick,kabaddi,Hockey ONLY if detailed scoring is
          done
        </Typography>

        <Typography variant="overline">
          Delayed scoring will cause clips to be missed in the generation
          window. Use Seconds before and after options accordingly if need to
          change default clip window size
        </Typography>

        {shouldShowForm && (
          <AutoClipsGenFormFastly
            seconds_after={currentStatus.seconds_after}
            seconds_before={currentStatus.seconds_before}
            onSubmit={handleOnSubmit}
          />
        )}

        {!shouldShowForm && (
          <Typography variant="h5"> STATE: {currentStateText} </Typography>
        )}

        <Typography variant="caption">
          times_successfully_processed:{' '}
          {currentStatus.times_successfully_processed}{' '}
        </Typography>

        <Typography variant="caption">
          times_un_successfully_processed:{' '}
          {currentStatus.times_un_successfully_processed}{' '}
        </Typography>

        <Typography variant="caption">
          window_seconds_after_scoring_event:{' '}
          {currentStatus.window_seconds_after_scoring_event}{' '}
        </Typography>

        <Typography variant="caption">
          window_seconds_before_scoring_event:{' '}
          {currentStatus.window_seconds_before_scoring_event}{' '}
        </Typography>

        <Typography variant="caption">
          is_processing_needed: {currentStatus.is_processing_needed ? 'Y' : 'N'}{' '}
        </Typography>

        <Typography variant="caption">
          is_queued: {currentStatus.is_queued ? 'Y' : 'N'}{' '}
        </Typography>
      </div>
    </div>
  );
}
