import axiosInstance from '../../axiosInstance';

const setPMSForAutoCalc = async ({ sportKey, playerId, matchId }) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/stats/player-match/${sportKey}/player/${playerId}/match/${matchId}/re-calculate`,
  );

  const { data } = response.data;

  return data.player_match_stat;
};

export default setPMSForAutoCalc;
