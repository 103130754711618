import React from 'react';
import { Paper, Typography, Button } from '@material-ui/core';
import useStyles from './styles';

const AthleteMatchStatDetailsControls = ({
  isAutoGenerateSupported,
  isCalcRequired,
  isUserEdited,
  isEnabled,
  onStatusClick,
  onSetForAutoCalcClick,
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={3}>
      <div className={classes.statusControllerContainer}>
        <Typography variant="h6">Status</Typography>
        <Button
          variant="outlined"
          color={isEnabled ? 'primary' : 'secondary'}
          onClick={onStatusClick}
        >
          {isEnabled ? 'ENABLED' : 'DISABLED'}
        </Button>
      </div>
      {isAutoGenerateSupported && isUserEdited && (
        <div className={classes.statusControllerContainer}>
          <Typography variant="h6">Set For Auto-Calculation?</Typography>
          <Button
            variant="outlined"
            color={isCalcRequired ? 'primary' : 'secondary'}
            onClick={onSetForAutoCalcClick}
            disabled={isCalcRequired}
          >
            {isCalcRequired ? 'YES' : 'NO'}
          </Button>
        </div>
      )}
      <div className={classes.statusControllerContainer}>
        {isUserEdited && !isCalcRequired && isAutoGenerateSupported && (
          <Typography variant="caption">
            * this is a USER-EDITED stat
          </Typography>
        )}
        {isUserEdited && !isCalcRequired && !isAutoGenerateSupported && (
          <Typography variant="caption">
            * this is a USER-GENERATED stat
          </Typography>
        )}
        {!isUserEdited && !isCalcRequired && (
          <Typography variant="caption">
            * this is an AUTO-GENERATED stat
          </Typography>
        )}
      </div>
    </Paper>
  );
};

export default AthleteMatchStatDetailsControls;
