import axiosInstance from '../../axiosInstance';
import {
  X_SFA_API_KEY,
  X_SFA_API_KEY_HEADER,
  X_SFA_PASSWORD_HEADER,
} from './sfaApiHeaders';

const deleteSfaMappingInfo = async (streamId, password) => {
  const requestHeaders = {};
  requestHeaders[X_SFA_API_KEY_HEADER] = X_SFA_API_KEY;
  requestHeaders[X_SFA_PASSWORD_HEADER] = password;

  const response = await axiosInstance.delete(
    `/api/v2/third-party/sfa/stream/${streamId}/mapping-info`,
    {
      headers: requestHeaders,
    },
  );
  return response.data.data;
};

export default deleteSfaMappingInfo;
