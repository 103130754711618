import queryString from 'query-string';
import axiosInstance from '../../axiosInstance';

const getUserByMobile = async mobile => {
  try {
    const queryObj = {
      page: 1,
      limit: 1,
      mobile,
    };

    const queryParams = queryString.stringify(queryObj, { sort: false });
    const response = await axiosInstance(`/api/admin/v2/users/?${queryParams}`);

    const { data, error } = response.data;

    if (error) {
      throw new Error(error.errorMessage);
    }

    if (!data.users.length) {
      throw new Error(`no user found with mobile ${mobile}`);
    }

    return data.users[0];
  } catch (e) {
    throw new Error(e.message);
  }
};

export default getUserByMobile;
