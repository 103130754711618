import React from 'react';
import { Formik } from 'formik';
import FormFields from './FormFields';

const UserSearchForm = ({
  onSubmit = () => console.log('events search form clicked'),
  mobile,
  first_name,
  last_name,
  has_go_live_permission,
  user_id_from_channel_search,
  user_id,
  spa_only,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          mobile,
          first_name,
          last_name,
          has_go_live_permission,
          user_id_from_channel_search,
          user_id,
          spa_only,
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields />
      </Formik>
    </>
  );
};

export default UserSearchForm;
