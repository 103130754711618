import { useState, useEffect } from 'react';
import getSingleAthlete from '../API/athletes/getSingleAthlete';

export const useAthleteData = athleteId => {
  const [value, setValue] = useState(null);

  const fetchAthlete = async key => {
    try {
      const athleteData = await getSingleAthlete(key);
      if (athleteData) {
        setValue(athleteData);
      }
    } catch (error) {
      console.log(`failed to fetch athlete with id ${athleteId}`);
    }
  };

  useEffect(() => {
    if (athleteId) {
      fetchAthlete(athleteId);
    }
  }, [athleteId]);

  return value;
};

export default null;
