import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  TableFooter,
  TablePagination,
  Button,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';

export default function AthleteMatchStatsTable({
  actionLabel = 'View',
  data = [],
  onRowActionClicked,
  onChangePage,
  count = 10,
  rowsPerPage = 12,
  page = 1,
}) {
  const classes = useStyles();
  return (
    <div className={classes.tableContainer}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Sr no</TableCell>
              <TableCell align="center">Match Id</TableCell>
              <TableCell align="left">Teams</TableCell>
              <TableCell align="center">Enabled</TableCell>
              <TableCell align="center">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow key={row.composite_id}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>

                  <TableCell component="th" scope="row">
                    {row.match_id}
                  </TableCell>

                  <TableCell align="left">{`${row.match_data.home_team_name} vs ${row.match_data.away_team_name}`}</TableCell>

                  <TableCell align="center">
                    <Typography
                      variant="body2"
                      color={row.is_enabled ? 'primary' : 'secondary'}
                    >
                      {row.is_enabled ? 'YES' : 'NO'}
                    </Typography>
                  </TableCell>

                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onRowActionClicked(row)}
                    >
                      {actionLabel}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={12}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={onChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
