import React from 'react';
import { useParams } from 'react-router-dom';
import useOverrideGameResult from '../../../../customHooks/OverrideGameResult/useOverrideGameResult';
import { Box, Container, Switch, Typography } from '@material-ui/core';
import OverrideForm from './OverrideForm';
import useStream from '../../../../customHooks/Stream/useStream';
import {
  CricketGameResult,
  GameResultBySport,
} from '../../../../API/OverrideGameResult/types';
import {
  createOverrideGameResult,
  toggleOverrideGameResultIsApplied,
  updateOverrideGameResult,
} from '../../../../API/OverrideGameResult';
import useGameStateData from '../../../../customHooks/GameState/useGameState';

const OverridePointsTable = () => {
  const { streamId } = useParams();
  const { isLoading, gameResult, refetchGameResult } = useOverrideGameResult(
    streamId,
  );
  const { stream, loading } = useStream(streamId);
  const { data, loading: gsLoading } = useGameStateData({
    id: streamId,
    sport: stream?.category,
  });

  const onCreateOrUpdateGameResult = async (
    values: GameResultBySport,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      if (!gameResult) {
        await createOverrideGameResult(streamId, {
          cricket_game_result: {
            ...values,
          } as CricketGameResult,
        });
      } else {
        await updateOverrideGameResult(streamId, {
          cricket_game_result: values as CricketGameResult,
        });
      }

      refetchGameResult();
    } catch (err) {
      console.error(err);
    } finally {
      setSubmitting(false);
    }
  };

  const onToggleIsApplied = async (applied: boolean) => {
    try {
      await toggleOverrideGameResultIsApplied(streamId, applied);
      refetchGameResult();
    } catch (err) {
      console.error(err);
    }
  };

  const getIsFormEnabled = () => {
    if (gameResult && !gameResult.is_applied) return false;
    return true;
  };

  if (isLoading || loading || !stream || gsLoading) return null;

  return (
    <Container maxWidth="md">
      <Box mt={4} mb={1}>
        <Typography
          variant="h6"
          style={{ textTransform: 'capitalize', fontWeight: 'bold' }}
        >
          Points Table Override -{'>'} {stream.title}
        </Typography>
      </Box>
      <Box
        border="1px solid #ffffff3a"
        borderRadius={10}
        bgcolor="rgba(0,0,0,0.25)"
        p={4}
        boxShadow={5}
      >
        {gameResult && (
          <Box mb={2} display="flex" alignItems="center" style={{ gap: 5 }}>
            <Typography>Applied?</Typography>
            <Switch
              checked={gameResult ? gameResult?.is_applied : true}
              onChange={e => onToggleIsApplied(e.target.checked)}
            />
          </Box>
        )}
        <Box
          style={{
            opacity: getIsFormEnabled() ? 1 : 0.5,
            pointerEvents: getIsFormEnabled() ? 'all' : 'none',
          }}
        >
          <OverrideForm
            gameResult={gameResult}
            sport={stream.category}
            onSubmit={onCreateOrUpdateGameResult}
            gs={data}
            stream={stream}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default OverridePointsTable;
