import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@material-ui/core';
import queryString from 'query-string';
import useStyles from './styles';
import getSingleTournament from '../../../../API/tournament/getSingleTournament';
import getGroupStageFixture from '../../../../API/tournament/getGroupStageFixture';
import FixtureGroupTable from '../../../../Components/TournamentComponenet/FixtureGroupTable';
import sportToCategoryMap from '../../../../Utils/sportToCategoryMap';

export default function GroupStageFixture() {
  const { teamId } = useParams();

  const queryParams = queryString.parse(useLocation().search);
  const location = useLocation();

  const classes = useStyles();
  const history = useHistory();
  const [groupArray, setGroupArray] = useState([]);
  const [roundArray, setRoundArray] = useState([]);
  const [streamData, setStreamData] = useState([]);
  const [groupData, setGroupData] = useState(queryParams.group_id || 'A');
  const [roundData, setRoundData] = useState(queryParams.round || 'R_1');

  const [tournamentData, setTournamentData] = useState({});

  const generateAlphabets = () => {
    const alphabets = [];
    const start = 'A'.charCodeAt(0);
    const last = 'Z'.charCodeAt(0);
    for (let i = start; i <= last; i += 1) {
      alphabets.push(String.fromCharCode(i));
    }

    return alphabets;
  };
  const alphabetsData = generateAlphabets();

  const getTournData = async () => {
    const res = await getSingleTournament(teamId);
    if (res) {
      setTournamentData(res);
      const newGroup = alphabetsData.splice(0, res.LKOG_rules.NoG);
      setGroupArray(newGroup);
      const newRound = [];
      for (let i = 0; i < res.LKOG_rules.NoR; i += 1) {
        newRound.push(`R_${i + 1}`);
      }
      setRoundArray(newRound);
    }
  };

  useEffect(() => {
    getTournData();
  }, []);

  const getStreamGroupFixture = async () => {
    const data = await getGroupStageFixture(teamId, groupData, roundData);
    setStreamData(data);
  };

  useEffect(() => {
    getStreamGroupFixture();
  }, [groupData, roundData]);

  const onCreateNewBTN = () => {
    const { name = 'tourn_name', sport = 'kab', event_id } = tournamentData;

    const queryObj = {
      round: roundData,
      group_id: groupData,
      category: sportToCategoryMap[sport],
      is_tourn_stream: true,
      match_type: 'team',
      event_id,
      redirect_path: btoa(location.pathname),
      redirect_params: btoa(location.search),
    };

    const options = queryString.stringify(queryObj, { sort: false });

    history.push({
      pathname: `/streams/add`,
      search: `?${options}`,
    });
  };

  const handleFixtureViewClicked = row => {
    history.push({
      pathname: `/streams/${row.stream_key}`,
    });
  };

  const handleGroupChange = e => {
    history.push({
      pathname: location.pathname,
      search: `?round=${roundData}&group_id=${e.target.value}`,
    });
    setGroupData(e.target.value);
  };

  const handleRoundChange = e => {
    history.push({
      pathname: location.pathname,
      search: `?round=${e.target.value}&group_id=${groupData}`,
    });
    setRoundData(e.target.value);
  };

  const renderGroupSelector = () => (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">Group</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={groupData}
        onChange={handleGroupChange}
        label="Group"
      >
        {groupArray.map((el, i) => (
          <MenuItem key={i} value={el}>
            {el}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderRoundSelector = () => (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id="demo-simple-select-outlined-label">Round</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={roundData}
        onChange={handleRoundChange}
        label="Round"
      >
        {roundArray.map((el, i) => (
          <MenuItem key={i} value={el}>
            {el}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderTable = () => (
    <div className={classes.root}>
      <FixtureGroupTable
        data={streamData}
        onViewClick={handleFixtureViewClicked}
      />
    </div>
  );

  return (
    <div>
      <div className={classes.root}>
        {renderGroupSelector()}

        {renderRoundSelector()}

        <Button variant="contained" onClick={onCreateNewBTN}>
          Create New
        </Button>
      </div>

      {!!streamData && renderTable()}
    </div>
  );
}
