import axiosInstance from '../../axiosInstance';

const uploadUserBgImage = async ({ userId, image }) => {
  const formData = new FormData();
  formData.append('image', image);

  const response = await axiosInstance.patch(
    `/api/admin/v2/users/${userId}/bg-image`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  const { imageUrl } = response.data.data;
  return imageUrl;
};

export default uploadUserBgImage;
