// Return styles according to theme preference

import { createMuiTheme } from '@material-ui/core/styles';

export const pageScrollBar = isDark => {
  return {
    '&::-webkit-scrollbar': {
      width: '0.5em',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: isDark ? '#070223' : '#fff',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255,255,255,0.4)',
      borderRadius: 10,
    },
  };
};

export const componentScrollBar = isDark => {
  return {
    '&::-webkit-scrollbar': {
      width: '0.4em',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: isDark ? 'rgba(255,255,255,0.4)' : '#e9e9e9',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(255,255,255,0.25)',
      borderRadius: 10,
    },
  };
};

export const toggleSwitch = isDark => {
  return {
    track: {
      backgroundColor: isDark ? '#fff' : '#ffffff3f',
    },
    switchBase: {
      color: isDark ? '#fff' : '#ffffff3f',
      '&.Mui-disabled': {
        color: '#303030',
      },
      '&.Mui-checked': {
        color: '#95cc97',
      },
      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#4CAF50',
      },
    },

    colorPrimary: {
      '&.Mui-checked': {
        color: '#006aad',
      },
      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#006aad',
      },
    },
    colorSecondary: {
      '&.Mui-checked': {
        color: '#DDCA20',
      },
      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#DDCA20',
      },
    },
  };
};

export const drawerListButton = isDark => {
  return {
    root: {
      '&$selected': {
        backgroundImage:
          'linear-gradient(to right, #0082d5, #0064a2, #004872, #002c45, #00121d)',
        transition: 'background-color 0.75s',
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.15)',
        },
      },
    },
    button: {
      transition: 'background-color 0.75s',
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.15)',
      },
    },
  };
};

export const switchTheme = createMuiTheme({
  overrides: {
    MuiSwitch: {
      switchBase: {
        // Controls default (unchecked) color for the thumb
        color: '#00ff00',
      },
      colorSecondary: {
        '&$checked': {
          // Controls checked color for the thumb
          color: '#ff0000',
        },
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.2,
        backgroundColor: '#00ff00',
        '$checked$checked + &': {
          // Controls checked color for the track
          opacity: 0.7,
          backgroundColor: '#ff0000',
        },
      },
    },
  },
});
