import React, { useEffect, useContext, useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import swal from 'sweetalert';
import useStyles from './styles';
import Contexts from '../../../Contexts';
import getStreamingServerState from '../../../API/ec2State/getStreamingServerState';
import updateStreamingServerState from '../../../API/ec2State/updateStreamingServerState';
import { IS_TEST } from '../../../API/constants';

export default function StreamingServer() {
  const [instanceState, setInstanceState] = useState(null);
  const { showLoadingSpinner } = useContext(Contexts);
  const classes = useStyles();
  const { streamId } = useParams();
  const { addToast } = useToasts();

  const getInitialData = async () => {
    showLoadingSpinner(true);
    const fetchedData = await getStreamingServerState(streamId);
    setInstanceState(fetchedData);
    showLoadingSpinner(false);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const handleStartClick = async () => {
    try {
      const isYesSelected = await swal({
        title: 'Are you sure? ',
        text: 'This will START the streaming server.',
        icon: 'warning',
        buttons: true,
      });

      if (!isYesSelected) return;

      showLoadingSpinner(true);
      const fetchedData = await updateStreamingServerState({ isStarted: true });
      setInstanceState(fetchedData);
    } catch (error) {
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
    showLoadingSpinner(false);
  };

  const handleStopClick = async () => {
    try {
      const isYesSelected = await swal({
        title: 'Are you sure? ',
        text: 'This will STOP the streaming server.',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      });

      if (!isYesSelected) return;

      showLoadingSpinner(true);
      const fetchedData = await updateStreamingServerState({
        isStarted: false,
      });
      setInstanceState(fetchedData);
    } catch (error) {
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
    showLoadingSpinner(false);
  };

  if (!instanceState) return <div>loading...</div>;

  const { code } = instanceState;

  // codes reference: https://docs.aws.amazon.com/AWSEC2/latest/APIReference/API_InstanceState.html
  const isRunning = code === 0 || code === 16;

  if (IS_TEST)
    return (
      <div className={classes.root}>
        <div
          style={{
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            gap: '20px',
            alignItems: 'center',
          }}
        >
          <Typography variant="caption">
            please use main server at https://manage.sportvot.com
          </Typography>
        </div>
      </div>
    );

  return (
    <div className={classes.root}>
      <div
        style={{
          display: 'flex',
          textAlign: 'center',
          flexDirection: 'column',
          gap: '20px',
          alignItems: 'center',
        }}
      >
        <Typography variant="caption">
          Current Streaming Server Status:
        </Typography>
        <Typography variant="h3">
          {isRunning ? 'RUNNING' : 'STOPPED'}
        </Typography>
        <Button
          variant="contained"
          onClick={handleStartClick}
          disabled={isRunning}
        >
          Start Server
        </Button>
        <Button
          variant="contained"
          onClick={handleStopClick}
          disabled={!isRunning}
        >
          Stop Server
        </Button>
        <Typography
          variant="caption"
          style={{ maxWidth: '250px', textAlign: 'center' }}
        >
          NOTE: The Server will take about a minute to start / stop. Hence, if
          you start / stop the server too fast you will get a "access denied"
          error.
        </Typography>
      </div>
    </div>
  );
}
