import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import addNewPMS from '../../../../../API/playerMatchStats/addNewPMS';
import AthleteMatchStatsForm from '../../../../../Components/Forms/AthleteStatsForms/AthleteMatchStatsForm/AthleteMatchStatsForm';
import Contexts from '../../../../../Contexts';
import { useAthleteData } from '../../../../../customHooks/useAthleteData';
import { useSportConfig } from '../../../../../customHooks/useSportConfig';
import useStyles from './styles';

const constructConfig = (existingStats, sportConfig) => {
  const { editable_plr_match_stats = [], plr_match_stats = {} } = sportConfig;

  const retVal = [];

  editable_plr_match_stats.forEach(elem => {
    retVal.push({
      displayName: plr_match_stats[elem] ? plr_match_stats[elem].eng : '',
      isEditable: true,
      key: elem,
      initialValue:
        (existingStats && existingStats.stats && existingStats.stats[elem]) ||
        0,
    });
  });

  const remainingKeys = Object.keys(plr_match_stats || {}).filter(
    elem => !editable_plr_match_stats.includes(elem),
  );

  remainingKeys.forEach(elem => {
    retVal.push({
      displayName: plr_match_stats[elem] ? plr_match_stats[elem].eng : '',
      isEditable: false,
      key: elem,
      initialValue:
        (existingStats && existingStats.stats && existingStats.stats[elem]) ||
        0,
    });
  });

  return retVal;
};

function AddNewPMSPage() {
  const { showLoadingSpinner } = useContext(Contexts);
  const { addToast } = useToasts();
  const classes = useStyles();

  const [sportKey, setSportKey] = useState(null);
  const { matchId, playerId } = useParams();

  const athlete = useAthleteData(playerId);
  const sportConfig = useSportConfig(sportKey);

  const history = useHistory();

  useEffect(() => {
    if (athlete) {
      setSportKey(athlete.sport);
    }
  }, [athlete]);

  const onSubmit = async (values, setSubmitting) => {
    try {
      showLoadingSpinner(true);
      await addNewPMS({ sportKey, playerId, matchId, stats: values });
      addToast('Added', {
        appearance: 'success',
        autoDismiss: 500,
      });
      showLoadingSpinner(false);
      history.goBack();
    } catch (error) {
      showLoadingSpinner(false);
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
      setSubmitting(false);
    }
  };

  if (!athlete) {
    return (
      <div className={classes.container}>
        <div>Loading Athlete Data</div>
      </div>
    );
  }

  if (!sportConfig) {
    return (
      <div className={classes.container}>
        <div>Loading Config</div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <AthleteMatchStatsForm
        onSubmit={onSubmit}
        formConfigAndInitialValues={constructConfig(null, sportConfig)}
      />
    </div>
  );
}

export default AddNewPMSPage;
