import axiosInstance from '../../axiosInstance';

const getTeams = async (
  name = '',
  sport = '',
  state = '',
  team_id = '',
  creator_id = '',
  page = '1',
  limit = '10',
  enabled_only = false,
  disabled_only = false,
) => {
  const response = await axiosInstance.get(`/api/admin/v2/teams/`, {
    params: {
      name,
      state,
      sport,
      team_id,
      page,
      limit,
      creator_id,
      enabled_only,
      disabled_only,
    },
  });

  const { data } = response.data;

  return data;
};

export default getTeams;
