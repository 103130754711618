import { BASE_URL } from '../constants';

const updateTeamGroupId = async (tourn_id, team_id, group_id) => {
  try {
    const postData = {
      group_id,
    };

    const response = await fetch(
      `${BASE_URL}/api/v2/tournaments/${tourn_id}/teams/${team_id}/group_id`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      },
    );

    const json = await response.json();

    console.log('postbody ', postData);
    console.log('json ', json);
    console.log('response', response);

    if (json.error) {
      throw new Error(JSON.stringify(json.error));
    }

    return json.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default updateTeamGroupId;
