import React from 'react';
import { Formik } from 'formik';
import FormFields from './FormFields';

const AthletesSearchForm = ({
  first_name = '',
  last_name = '',
  sport = '',
  mobile = '',
  state = '',
  position = '',
  prferred_section = '',
  is_featured = false,
  playerId = '',
  onSubmit = () => console.log('athlete search form clicked'),
  isForManagedHomeFeed = false,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          first_name,
          last_name,
          state,
          sport,
          mobile,
          position,
          prferred_section,
          is_featured,
          playerId,
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields isForManagedHomeFeed={isForManagedHomeFeed} />
      </Formik>
    </>
  );
};

export default AthletesSearchForm;
