import React, { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import Add from '@material-ui/icons/Add';
import { IconButton, Typography } from '@material-ui/core';
import useStyles from './styles';
import TournamentTable from '../../../../Components/TournamentComponenet/TournamentTable';
import TournamentSearchForm from '../../../../Components/Forms/SearchForms/TournamentSearchForm/TournamentSearchForm';
import getTournamentSearch from '../../../../API/tournament/getTournamentSearch';

export default function TournamentSearch() {
  const classes = useStyles();
  const [tournamentData, setTournamentData] = useState([]);

  const { path } = useRouteMatch();

  const history = useHistory();

  const onSubmit = async (values, setSubmitting) => {
    const { name, sport, tournament_id, start_date, end_date } = values;

    const data = await getTournamentSearch(
      name,
      sport,
      tournament_id,
      start_date,
      end_date,
    );
    if (data) {
      setTournamentData(data);
      setSubmitting(false);
    }
  };

  const navigateToDetails = id => {
    history.push({
      pathname: `${path}${id}`,
    });
  };

  return (
    <div className={classes.root}>
      <div>
        <TournamentSearchForm onSubmit={onSubmit} />
        <div>
          <Typography variant="overline">Add Tournament</Typography>
          <IconButton
            edge="start"
            className={classes.searchIcon}
            color="default"
            aria-label="menu"
            onClick={() => {
              history.push({
                pathname: `${path}add`,
              });
            }}
          >
            <Add />
          </IconButton>
        </div>
      </div>

      {tournamentData.length > 0 ? (
        <TournamentTable
          data={tournamentData}
          TeamNavigate={i => navigateToDetails(i)}
        />
      ) : (
        <div />
      )}
    </div>
  );
}
