import axiosInstance from '../../axiosInstance';

const getUserAuthorities = async id => {
  try {
    const response = await axiosInstance(
      `/api/admin/v2/users/${id}/authorities`,
    );

    const { data, error } = response.data;

    if (error) {
      throw new Error(error.errorMessage);
    }

    return data.authorities;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default getUserAuthorities;
