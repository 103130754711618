import React from 'react';
import { Paper, Button } from '@material-ui/core';
import useStyles from './styles';

/**
 *
 * selected language should be one of eng, hi, mr
 *
 * onLanguageSelect passes the one of the above
 * in the callback depending on the selected language
 */
const LanguageSelector = ({
  selectedLanguage = 'eng',
  onLanguageSelect = lang => console.log('selected language', lang),
}) => {
  const classes = useStyles();

  return (
    <Paper elevation={3}>
      <div className={classes.statusControllerContainer}>
        <Button
          variant={selectedLanguage === 'eng' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onLanguageSelect('eng')}
        >
          English
        </Button>

        <Button
          variant={selectedLanguage === 'hi' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onLanguageSelect('hi')}
        >
          Hindi
        </Button>

        <Button
          variant={selectedLanguage === 'mr' ? 'contained' : 'outlined'}
          color="primary"
          onClick={() => onLanguageSelect('mr')}
        >
          Marathi
        </Button>
      </div>
    </Paper>
  );
};

export default LanguageSelector;
