import axiosInstance from '../../axiosInstance';

const changePosition = async (position, teamID, playerId) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/teams/${teamID}/player/${playerId}/position`,
    {
      position,
    },
  );

  const { data } = response.data;

  return data;
};

export default changePosition;
