import React, { useState, useRef, useContext, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useParams, useHistory } from 'react-router-dom';
import { IconButton, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import useStyles from './styles';
import Contexts from '../../../../Contexts';
import AddAthleteTable from '../../../../Components/StreamsComponent/IndividualPlayerTable/AddAthleteTable';
import AthletesSearchForm from '../../../../Components/Forms/SearchForms/AthletesSearchForm';
import getAthletes from '../../../../API/athletes/getAthletes';
import getIndividualPlayer from '../../../../API/streams/getIndividualPlayer';
import setIndividualPlayer from '../../../../API/streams/setIndividualPlayer';

export default function IndividualPlayer() {
  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);
  const classes = useStyles();
  const [playersData, setPlayersData] = useState([]);
  const { streamKey } = useParams();
  const history = useHistory();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [currentSquad, setCurrentSquad] = useState([]);
  const { addToast } = useToasts();

  const [currentInSquadAllData, setCurrentInSquadAllData] = useState({});

  const ref = useRef({
    first_name: '',
    last_name: '',
    mobile: '',
    sport: '',
    position: '',
    state: '',
    pageNo: 1,
  });
  const generateAlphabets = () => {
    const alphabets = [];
    const start = 'A'.charCodeAt(0);
    const last = 'Z'.charCodeAt(0);
    for (let i = start; i <= last; i += 1) {
      alphabets.push(String.fromCharCode(i));
    }

    return alphabets;
  };
  const alphabetsData = generateAlphabets();

  const getData = async () => {
    showLoadingSpinner(true);
    try {
      const {
        first_name,
        last_name,
        mobile,
        sport,
        position,
        state,
        pageNo,
      } = ref.current;

      const data = await getAthletes(
        first_name,
        last_name,
        mobile,
        state,
        sport,
        position,
        pageNo,
        10,
      );

      if (data) {
        setPlayersData(data.players);
        setCount(data.total_count);
        showLoadingSpinner(false);
      }
    } catch (err) {
      showMessageDialog('ERROR', err.message);
      showLoadingSpinner(false);
    }
  };

  const onSubmit = async (values, setSubmitting) => {
    const { first_name, last_name, mobile, sport, position, state } = values;
    ref.current = {
      ...ref.current,
      first_name,
      last_name,
      mobile,
      sport,
      position,
      state,
    };

    // reset pages
    setPage(1);
    ref.current.pageNo = 1;

    await getData();
    setSubmitting(false);
  };

  const handleRowActionClick = async (player, row) => {
    const array = [...currentSquad];
    let data = [];
    if (array.length > 0) {
      data = array.splice(alphabetsData.indexOf(row), 1);
    }
    array.splice(alphabetsData.indexOf(row), 0, player._id);
    setCurrentSquad(array);

    if (array.includes('')) {
      addToast('Add Another Player', {
        appearance: 'success',
        autoDismiss: 500,
      });
    } else {
      try {
        const res = await setIndividualPlayer(streamKey, array);
        if (res.data) {
          addToast('Successfully Added Player', {
            appearance: 'success',
            autoDismiss: 500,
          });
        }
      } catch (error) {
        addToast('Error While Adding Player', {
          appearance: 'error',
          autoDismiss: 500,
        });
      }
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage + 1);
    ref.current.pageNo = newPage + 1;
    getData();
  };

  const getIndividualPlayerData = async () => {
    const res = await getIndividualPlayer(streamKey);
    setCurrentInSquadAllData(res.stream_details);

    const noOfEntries = res.stream_details.num_of_entities;
    const newSquad = [];

    for (let i = 0; i < noOfEntries; i++) {
      newSquad.push(
        res.players[`player_${alphabetsData[i]}`][
          `player_${alphabetsData[i]}_id`
        ]
          ? res.players[`player_${alphabetsData[i]}`][
              `player_${alphabetsData[i]}_id`
            ]
          : '',
      );
    }
    setCurrentSquad(newSquad);
  };

  useEffect(() => {
    getData();
    getIndividualPlayerData();
  }, []);

  return (
    <div className={classes.searchContainer}>
      <div className={classes.root}>
        <AthletesSearchForm onSubmit={onSubmit} />

        <div>
          <Typography variant="overline">Add Player</Typography>
          <IconButton
            edge="start"
            className={classes.searchIcon}
            color="default"
            aria-label="menu"
            onClick={() => {
              history.push({
                pathname: `/player/add`,
              });
            }}
          >
            <Add />
          </IconButton>
        </div>

        <div className={classes.addContainer}>
          <AddAthleteTable
            noOfPlayer={currentInSquadAllData.num_of_entities}
            data={playersData}
            inSquad={currentSquad}
            onRowActionClicked={handleRowActionClick}
            count={count}
            page={page - 1}
            onChangePage={handlePageChange}
            rowsPerPage={10}
          />
        </div>
      </div>
    </div>
  );
}
