import axiosInstance from '../../axiosInstance';

const updateAggPMS = async ({ sportKey, playerId, stats }) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/stats/agg-player-match/${sportKey}/player/${playerId}`,
    {
      stats,
    },
  );

  const { data } = response.data;

  return data.agg_player_match_stat;
};

export default updateAggPMS;
