import React, { useState, useRef, useEffect } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormFields from './FormFields';
import getFormConfig from '../../../API/scoreCard/getFormConfig';
import getAthleteMatchStatsConfig from '../../../API/athleteMatchStats/getAthleteMatchStatsConfig';

const getSportValues = mapping => {
  const sportValues = [];

  const { sports } = mapping;
  sports.forEach(val => {
    sportValues.push({
      displayName: mapping[val].displayName,
      value: val,
    });
  });

  return sportValues;
};

const getAllKeys = mapping => {
  const allKeys = {
    sport: '',
  };

  const { sports } = mapping;

  sports.forEach(sport => {
    const { stats } = mapping[sport];

    stats.forEach(stat => {
      allKeys[`${sport}_${stat}`] = '';
    });
  });

  return allKeys;
};

const getSportKeyMap = mapping => {
  const retVal = {};

  const { sports } = mapping;

  sports.forEach(sport => {
    retVal[sport] = [];
    const { stats } = mapping[sport];

    stats.forEach(stat => {
      const temp = {
        label: mapping[sport][stat].displayName,
        name: `${sport}_${stat}`,
      };
      retVal[sport].push(temp);
    });
  });

  return retVal;
};

const AthleteMatchStatsForm = ({ matchId, statsData = null, onSubmit }) => {
  const ref = useRef({
    formConfig: {},
  });
  const [fetchedConfig, setFetchedConfig] = useState(false);

  const getConfig = async () => {
    try {
      const config = await getAthleteMatchStatsConfig();
      ref.current.formConfig = config;

      if (!config) throw new Error('Failed to get scorecard form config');

      setFetchedConfig(true);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getConfig();
  }, []);

  const constructData = values => {
    const retValue = {};

    const { sport } = values;

    if (!sport) {
      console.error('Select a sport');
    }

    retValue.sport = sport;

    retValue.stats = {};

    const { stats } = ref.current.formConfig[sport];

    stats.forEach(stat => {
      const temp = values[`${sport}_${stat}`];

      if (temp) retValue.stats[`${stat}`] = temp;
    });

    return retValue;
  };

  const getInitialValues = mapping => {
    try {
      const allKeys = getAllKeys(mapping);
      if (!statsData) return allKeys;

      // modify allKeys to take initial values form statsData
      const { sport, stats } = statsData;

      allKeys.sport = sport;

      const keys = Object.keys(stats);
      keys.forEach(elem => {
        allKeys[`${sport}_${elem}`] = stats[elem];
      });

      return allKeys;
    } catch (e) {
      console.error(e);
    }
  };

  const renderForm = () => {
    return (
      <>
        <Formik
          initialValues={getInitialValues(ref.current.formConfig)}
          onSubmit={(values, { setSubmitting }) => {
            const postData = constructData(values);
            onSubmit(postData, setSubmitting);
          }}
          validationSchema={Yup.object({
            sport: Yup.string().required('Required'),
          })}
        >
          <FormFields
            matchId={matchId}
            sportList={getSportValues(ref.current.formConfig)}
            sportKeyMap={getSportKeyMap(ref.current.formConfig)}
          />
        </Formik>
      </>
    );
  };

  return <>{fetchedConfig && renderForm()}</>;
};

AthleteMatchStatsForm.propTypes = {};

AthleteMatchStatsForm.defaultProps = {};

export default AthleteMatchStatsForm;
