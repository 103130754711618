import React, { useContext } from 'react';
import { Form, useFormikContext } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikTextInput from '../MuiFormikComponents/FormikTextInput';
import FormikSubmit from '../MuiFormikComponents/FormikSubmit';
import ReactDatePicker from '../MuiFormikComponents/ReactDatePicker';
import FormikDropDown from '../MuiFormikComponents/FormikDropDown';
import Context from '../../../Contexts';
import {
  generateStateDropDownList,
  generateSportDropDownList,
  generateSportBasedPositionDropdown,
  generateHomeSectionsListFromCategory,
} from '../../../Utils/dropDownListGenerators';

const FormFields = () => {
  const classes = useStyles();

  const { values } = useFormikContext();
  const context = useContext(Context);
  const { formConfig, sectionDetails } = context;

  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4"> Athlete Details</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="First Name *" name="first_name" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Last Name *" name="last_name" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Mobile" name="mobile" disabled />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikDropDown
              valueDisplayNameMap={generateStateDropDownList(formConfig)}
              label="State *"
              name="state"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikDropDown
              valueDisplayNameMap={generateSportDropDownList(formConfig)}
              label="Sport *"
              name="sport"
            />
          </Grid>

          {values.sport && (
            <Grid item xs={12} sm={6} md={6}>
              <FormikDropDown
                valueDisplayNameMap={generateSportBasedPositionDropdown(
                  formConfig,
                  values.sport,
                )}
                label="Position *"
                name="position"
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6} md={6}>
            <FormikDropDown
              label="Pro Athlete *"
              name="isProAthlete"
              valueDisplayNameMap={[
                { value: 'Y', displayName: 'Y' },
                { value: 'N', displayName: 'N' },
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Best Team Name" name="best_team_name" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Email" name="email" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Aadhar Number" name="aadhar_num" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <ReactDatePicker disableTime label="Date of Birth" name="DOB" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput
              type="number"
              label="Height (cm)"
              name="cm_height"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput
              type="number"
              label="Weight (kg)"
              name="kg_weight"
            />
          </Grid>

          {values.sport && (
            <Grid item xs={12}>
              <Typography variant="h6">Home Section</Typography>
            </Grid>
          )}

          {values.sport && (
            <Grid item xs={12} sm={6} md={6}>
              <FormikDropDown
                valueDisplayNameMap={generateHomeSectionsListFromCategory(
                  sectionDetails,
                  values.sport,
                  ['player', 'story'],
                )}
                label="Home Section"
                name="preferred_section"
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormikTextInput multiline label="Description" name="description" />
          </Grid>

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="save" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
