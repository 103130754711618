import { useEffect, useState } from 'react';
import { getUserWhitelistedTemplates } from '../../API/Templates';

export default function useUserWhitelistedTemplates(
  userId,
  is_thumbnail_template = false,
) {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    try {
      const data = await getUserWhitelistedTemplates(userId);
      setTemplates(
        data.templates.filter(
          t => t.is_thumbnail_template === is_thumbnail_template,
        ),
      );
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const forceRefresh = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  return {
    templates,
    loading,
    error,
    forceRefresh,
  };
}
