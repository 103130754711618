import React, { useContext } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { TableFooter, TablePagination, Button } from '@material-ui/core';
import Contexts from '../../Contexts/Contexts';
import useStyles from './styles';
import {
  generateSportPositionDisplayName,
  generateSportDisplayName,
  generateStateDisplayName,
} from '../../Utils/genrateDisplayNames';

export default function AthleteTable({
  actionLabel = 'View',
  data = [],
  onRowActionClicked = () => console.log('event row clicked'),
  onChangePage = () => console.log('page change clicked'),
  count = 10,
  rowsPerPage = 12,
  page = 1,
}) {
  const classes = useStyles();
  const context = useContext(Contexts);
  const { formConfig } = context;
  return (
    <div className={classes.tableContainer}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Sr no</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell align="left">State</TableCell>
              <TableCell align="left">Sport</TableCell>
              <TableCell align="left">Position</TableCell>
              <TableCell align="center">Pro Athlete</TableCell>
              <TableCell align="center">Action</TableCell>
              <TableCell align="center">Player Id</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>

                  <TableCell component="th" scope="row">
                    {`${row.first_name} ${row.last_name}`}
                  </TableCell>

                  <TableCell align="left">
                    {generateStateDisplayName(formConfig, row.state)}
                  </TableCell>

                  <TableCell align="left">
                    {generateSportDisplayName(formConfig, row.sport)}
                  </TableCell>

                  <TableCell align="left">
                    {generateSportPositionDisplayName(
                      formConfig,
                      row.sport,
                      row.position,
                    )}
                  </TableCell>

                  <TableCell align="center">{row.isProAthlete}</TableCell>

                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onRowActionClicked(row)}
                    >
                      {actionLabel}
                    </Button>
                  </TableCell>

                  <TableCell component="th" scope="row">
                    {`${row._id}`}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rows
                colSpan={12}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={onChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
