/* eslint-disable no-param-reassign */
import React, { useState, useRef, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import Add from '@material-ui/icons/Add';
import { IconButton, Typography } from '@material-ui/core';
import useStyles from './styles';
import StreamSearchForm from '../../../../Components/Forms/SearchForms/StreamsSearchForm/StreamSearchForm';
import getStreams from '../../../../API/streams/getStreams';
import StreamsTable from '../../../../Components/StreamsComponent/StreamsTable';
import setIsActive from '../../../../API/streams/setIsActive';
import UploadingDialog from '../../../../Components/UploadingDialog';
import axiosInstance from '../../../../axiosInstance';
import cloneStream from '../../../../API/streams/cloneStream';
// eslint-disable-next-line import/extensions
import ArticlesTableFormHomeFeed from '../../../../Components/ManagedHomeFeed/AddVideoDialog/ArticlesTable';

export default function StreamSearch({
  isForManagedHomeFeed = false,
  categoryCode = '',
}) {
  const classes = useStyles();
  const [streamsData, setStreamsData] = useState([]);
  const [isWaitSpinnerOpen, setIsWaitSpinnerOpen] = useState(false);

  // for pagination
  const [count, setCount] = useState(0);

  // pageNo is also used in ref
  // this page no is for display purposes in the table pagination
  // the ref pageNo is to fetch new pages to avoid issue
  // of async setPage
  const [page, setPage] = useState(1);

  // setting the search params in ref
  // so that they can be resused in pagination
  const ref = useRef({
    stream_key: '',
    title: '',
    channel_id: '',
    category: categoryCode,
    preferred_section: '',
    page: 1,
    // only applied if set to true by api function
    is_live: false,
    event_id: '',
    _id: '',
    article_type: '',

    // jio stuff
    is_commentary: false,
    is_jio_stb: false,
    is_ppv: false,
    is_sub: false,
    is_ad: false,
    is_jio_store: false,
    is_jio_tv: false,
    is_all_jio: false,
    is_global: false,
    start_date: '',
    end_date: '',
    include_demo_and_trial_streams: isForManagedHomeFeed,
    should_sort_by_views: isForManagedHomeFeed,
  });

  const { path } = useRouteMatch();

  const history = useHistory();

  const getData = async () => {
    setIsWaitSpinnerOpen(true);
    const {
      stream_key,
      title,
      channel_id,
      category,
      preferred_section,
      page,
      is_live,
      event_id,
      _id,
      article_type,

      // jio stuff
      is_commentary,
      is_jio_stb,
      is_ppv,
      is_sub,
      is_ad,
      is_jio_store,
      is_jio_tv,
      is_all_jio,
      is_global,
      start_date,
      end_date,
      include_demo_and_trial_streams,
      should_sort_by_views,
    } = ref.current;

    const data = await getStreams(
      stream_key,
      title,
      channel_id,
      category,
      preferred_section,
      page,
      is_live,
      event_id,
      _id,
      article_type,

      // jio stuff
      is_commentary,
      is_jio_stb,
      is_ppv,
      is_sub,
      is_ad,
      is_jio_store,
      is_jio_tv,
      is_all_jio,
      is_global,
      include_demo_and_trial_streams,
      start_date,
      end_date,
      should_sort_by_views ? 'views:desc' : '_id:desc',
    );
    if (data) {
      setStreamsData(data.streams);
      setCount(data.total_count);
      setIsWaitSpinnerOpen(false);
    }
  };

  const onSubmit = async (values, setSubmitting) => {
    const {
      stream_key,
      title,
      channel_id,
      category,
      preferred_section,
      is_live,
      event_id,
      _id,
      article_type,

      // jio stuff
      is_commentary,
      is_jio_stb,
      is_ppv,
      is_sub,
      is_ad,
      is_jio_store,
      is_jio_tv,
      is_all_jio,
      is_global,
      start_date,
      end_date,
      include_demo_and_trial_streams,
      should_sort_by_views,
    } = values;
    ref.current = {
      ...ref.current,
      stream_key,
      title,
      channel_id,
      category,
      preferred_section,
      is_live,
      event_id,
      _id,
      article_type,

      // jio stuff
      is_commentary,
      is_jio_stb,
      is_ppv,
      is_sub,
      is_ad,
      is_jio_store,
      is_jio_tv,
      is_all_jio,
      is_global,
      start_date,
      end_date,
      include_demo_and_trial_streams,
      should_sort_by_views,
    };

    // reset pages
    setPage(1);
    ref.current.page = 1;

    await getData();
    setSubmitting(false);
  };

  const handleRowViewClicked = row => {
    history.push({
      pathname: `${path}${row._id}`,
    });
  };

  const handleStopStream = async stream_id => {
    const response = await axiosInstance(
      `/api/admin/v2/streams/${stream_id}/stop_stream`,
    );
    const { data } = response.data;
    if (data) {
      getData();
    }
  };

  const handleStartStream = async stream_id => {
    const response = await axiosInstance(
      `/api/admin/v2/streams/${stream_id}/start_stream`,
    );
    const { data } = response.data;
    if (data) {
      getData();
    }
  };

  const onRowStartStopClicked = row => {
    const { _id } = row;
    if (!row.is_live && !row.is_complete) {
      handleStartStream(_id);
    } else if (row.is_live && !row.is_complete) {
      handleStopStream(_id);
    } else {
      console.log('error');
    }
  };

  // toggle status (is_active)
  const handleRowStatusClicked = async row => {
    const { _id } = row;
    const temp = [];
    let foundMatch = false;
    let newStatus = null;

    if (_id) {
      streamsData.forEach(elem => {
        if (elem._id && elem._id === _id) {
          const { is_active } = elem;

          elem.is_active = is_active === 'Y' ? 'N' : 'Y';
          newStatus = elem.is_active;

          foundMatch = true;
          temp.push(elem);
        } else {
          temp.push(elem);
        }
      });
    }

    if (foundMatch) {
      setIsWaitSpinnerOpen(true);

      const resp = await setIsActive(_id, newStatus === 'Y');

      if (resp) {
        setStreamsData(temp);
        setIsWaitSpinnerOpen(false);
      }
    }
  };

  const handlePageChange = (event, newPage) => {
    // newPage + 1 adjustment because our pages are not 0 indexed
    // hence when passing to the table too we pasee page - 1 and
    // not page
    setPage(newPage + 1);
    ref.current.page = newPage + 1;
    getData();
  };

  const handleCloneStream = async streamId => {
    try {
      // reset pages
      setPage(1);
      ref.current.page = 1;
      await cloneStream(streamId);
      getData();
    } catch (error) {}
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.searchContainer}>
        <StreamSearchForm
          onSubmit={onSubmit}
          isForManagedHomeFeed={isForManagedHomeFeed}
          category={categoryCode}
        />
        {!isForManagedHomeFeed && (
          <div>
            <Typography variant="overline">Add Live Stream</Typography>
            <IconButton
              edge="start"
              className={classes.searchIcon}
              color="default"
              aria-label="menu"
              onClick={() => {
                history.push({
                  pathname: `${path}add`,
                });
              }}
            >
              <Add />
            </IconButton>
          </div>
        )}
      </div>

      {!isForManagedHomeFeed && streamsData.length > 0 ? (
        <StreamsTable
          count={count}
          rowsPerPage={10}
          // not the page - 1 here. Becuase we dont use zero indexing
          page={page - 1}
          onChangePage={handlePageChange}
          data={streamsData}
          onRowViewClicked={row => handleRowViewClicked(row)}
          onRowStatusClicked={row => handleRowStatusClicked(row)}
          onRowStartStopClicked={row => onRowStartStopClicked(row)}
          onCloneStreamClicked={row => handleCloneStream(row._id)}
        />
      ) : (
        <div />
      )}

      {isForManagedHomeFeed && streamsData.length > 0 && (
        <ArticlesTableFormHomeFeed
          totalCount={count}
          rowsPerPage={10}
          page={page - 1}
          onChangePage={handlePageChange}
          data={streamsData}
        />
      )}

      <UploadingDialog text="Please wait..." open={isWaitSpinnerOpen} />
    </div>
  );
}
