import React from 'react';
import { Formik } from 'formik';
import FormFields from './FormFields';

const SearchUserForm = ({
  mobile = null,

  onSubmit = () => console.log('user search form clicked'),
}) => {
  return (
    <>
      <Formik
        initialValues={{
          mobile,
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields />
      </Formik>
    </>
  );
};

export default SearchUserForm;
