import { Box, Typography } from '@material-ui/core';
import React from 'react';
import styles from '../SectionSidebar/styles.module.css';

type SectionTypeCardProps = {
  sectionType: string;
  selected: boolean;
  onClick: () => void;
  color: string;
};

function SectionTypeCard(props: SectionTypeCardProps) {
  const { sectionType, selected, onClick, color } = props;
  return (
    <Box
      p={1}
      borderRadius={5}
      border={selected ? '3px solid #fff' : '1px solid #ffffff3a'}
      onClick={onClick}
      display="flex"
      justifyContent="center"
      style={{ cursor: 'pointer' }}
      className={styles.section_card}
      bgcolor={color}
    >
      <Typography variant="caption">{sectionType}</Typography>
    </Box>
  );
}

export default SectionTypeCard;
