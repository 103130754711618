import React, { useState, useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import useStyles from './styles';
import LoginBox from '../../Components/LoginBox';
import OtpBox from '../../Components/OtpBox';
import getOTP from '../../API/login/getOTP';
import verifyOTP from '../../API/login/verifyOTP';
import Contexts from '../../Contexts';

const Login = () => {
  const classes = useStyles();
  const { setLoginStatusAndToken } = useContext(Contexts);

  const [mobile, setMobile] = useState('');
  const [otp, setOtp] = useState('');

  const [displayOtpView, setDisplayOtpView] = useState(false);

  const { addToast } = useToasts();

  const onSubmitMobile = async () => {
    try {
      if (mobile && mobile.length === 10) {
        const data = await getOTP(mobile);
        if (data) {
          setDisplayOtpView(true);
          addToast('Enter OTP', {
            appearance: 'success',
            autoDismiss: 500,
          });
        }
      }
    } catch (error) {
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  // const reload = () => {
  //   window.location.reload();
  // };

  const onSubmmitOtp = async () => {
    try {
      if (otp) {
        const responseData = await verifyOTP(mobile, otp);
        const {
          access: token,
          refresh_id: refreshId,
          refresh_token: refreshToken,
        } = responseData;

        if (token) {
          addToast('Logged In', {
            appearance: 'success',
            autoDismiss: 500,
          });

          const newToken = token
            .replace('Bearer', '')
            .split(' ')
            .join('');

          setLoginStatusAndToken(true, newToken, refreshToken, refreshId);
          // reload();
        }
      }
    } catch (error) {
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  const renderLoginScreen = () => (
    <LoginBox mobile={mobile} onChange={setMobile} onSubmit={onSubmitMobile} />
  );

  const renderOtpScreen = () => (
    <OtpBox otp={otp} onChange={setOtp} onSubmit={onSubmmitOtp} />
  );

  return (
    <div className={classes.root}>
      {displayOtpView ? renderOtpScreen() : renderLoginScreen()}
    </div>
  );
};

export default Login;
