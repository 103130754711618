import { BASE_URL } from '../constants';

const getNewAccessToken = async ({ refreshToken = '', refreshId = '' }) => {
  try {
    const response = await fetch(`${BASE_URL}/api/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refresh_token: refreshToken,
        refresh_id: refreshId,
      }),
    });

    const { data = {} } = await response.json();
    const { access = null } = data;

    return access;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default getNewAccessToken;
