import { BASE_URL } from '../constants';

const updateTournamentLogo = async (image, teamId) => {
  try {
    // eslint-disable-next-line no-undef
    const formData = new FormData();
    formData.append('image', image);

    const response = await fetch(
      `${BASE_URL}/api/v2/tournaments/${teamId}/logo
    `,
      {
        method: 'POST',
        body: formData,
      },
    );

    const json = await response.json();
    console.log('res', response);

    if (json.error) {
      throw new Error(JSON.stringify(json.error));
    }

    return json.data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default updateTournamentLogo;
