import { BASE_URL } from '../constants';

const getGroupStageFixture = async (tourn_id, group_id, round) => {
  try {
    const response = await fetch(
      `${BASE_URL}/api/v2/streams/?cache=false&stream_key=&page=&limit=&title=&is_active=&state=&category=&channel_id=&event_id=${tourn_id}&group_id=${group_id}&round=${round}`,
    );

    const json = await response.json();

    if (json.error) {
      throw new Error(json.error.errorMessage);
    }

    return json.data.streams;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default getGroupStageFixture;
