import axiosInstance from '../../axiosInstance';

const createStream = async (
  title = '',
  state = '',
  start_timestamp = '',
  content = '',
  location = '',
  district = '',
  pincode = '',
  orientation = '',
  channel_id = '',
  article_type = '',
  is_tourn_stream,
  category = '',
  keywords = [],
  match_type = '',
  embed_url = '',
  event_id = '',
  round = '',
  country = '',
  group_id = '',
  num_of_entities = 2,
  team_A,
  team_B,

  // jio stuff
  is_commentary = false,
  is_jio_stb = false,
  is_ppv = false,
  is_sub = false,
  is_ad = false,
  is_jio_store = false,
  is_jio_tv = false,
  is_all_jio = false,
  is_global = true,
) => {
  try {
    const postData = {
      title,
      state,
      start_timestamp,
      content,
      location,
      district,
      pincode,
      orientation,
      channel_id,
      article_type,
      match_type,
      is_tourn_stream,
      category,
      keywords,
      embed_url,
      event_id,
      round,
      country,
      group_id,
      num_of_entities: num_of_entities < 2 ? 2 : num_of_entities,
      team_A,
      team_B,

      // jio stuff
      is_commentary,
      is_jio_stb,
      is_ppv,
      is_sub,
      is_ad,
      is_jio_store,
      is_jio_tv,
      is_all_jio,
      is_global,
    };

    const response = await axiosInstance(`/api/admin/v2/streams/`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(postData),
    });

    const { data, error } = response.data;

    if (error) {
      throw new Error(error.errorMessage);
    }

    return data.stream_id;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default createStream;
