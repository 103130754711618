import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { DropzoneDialog } from 'material-ui-dropzone';
import { Typography } from '@material-ui/core';
import useStyles from './styles';
import { BASE_URL } from '../../../../API/constants';
import ImageBlock from '../../../../Components/ImageBlock';
import AutoHighlightBlock from '../../../../Components/AutoHighlightBlock';
import UploadingDialog from '../../../../Components/UploadingDialog';
import EventDetailsForm from '../../../../Components/Forms/EventDetailsForm/EventDetailsForm';
import updateEvent from '../../../../API/events/updateEvent';
import setEventLogo from '../../../../API/events/setEventLogo';
import StatusControllerAndId from './StatusControllerAndId';
import setIsActive from '../../../../API/events/setIsActive';
import Contexts from '../../../../Contexts';
import setEventVerticalLogo from '../../../../API/events/setEventVerticalLogo';

const IMAGE_TYPE = {
  LOGO: 'logo',
  LOGO_VERTICAL: 'logo_vertical',
};

export default function EventDetails() {
  const { showLoadingSpinner, showMessageDialog } = useContext(Contexts);
  const { addToast } = useToasts();
  const classes = useStyles();
  const [formFields, setFormFields] = useState({});
  const [dataFetched, setDataFetched] = useState(false);
  const [uploadingDialogOpen, setUploadingDialogOpen] = useState(false);
  const [imageUploadOpen, setImageUploadOpen] = useState(false);
  const [images, setImages] = useState({
    logo: '',
    logoVertical: '',
  });
  const [is_Active, setIs_Active] = useState('Y');

  const ref = useRef({
    selectedImage: IMAGE_TYPE.LOGO,
  });

  // path params
  const { eventId } = useParams();

  const getEventData = async id => {
    showLoadingSpinner(true);
    try {
      const response = await fetch(`${BASE_URL}/api/events/${id}`);
      const { data } = await response.json();
      setFormFields(data);
      setDataFetched(true);

      setImages({
        logo: data.logo,
        logoVertical: data.logo_vertical,
      });

      setIs_Active(data.is_active || 'Y');
    } catch (err) {
      showMessageDialog('ERROR', err.message);
    } finally {
      showLoadingSpinner(false);
    }
  };

  useEffect(() => {
    if (eventId) {
      getEventData(eventId);
    }
  }, []);

  const handleSubmit = async (values, setSubmitting) => {
    showLoadingSpinner(true);
    try {
      const {
        name,
        sport,
        state,
        start_date,
        end_date,
        location,
        description,
        district,
        organised_by,
        is_sport,
        preferred_section,
        event_league_config,
      } = values;

      const resp = await updateEvent(
        eventId,
        name,
        sport,
        state,
        start_date,
        end_date,
        location,
        district,
        description,
        organised_by,
        is_sport,
        preferred_section,
        formFields.gender_category_ids,
        formFields.age_category_ids,
        event_league_config,
      );

      if (resp.data) {
        showLoadingSpinner(false);
        setSubmitting(false);
        addToast('Successfully Saved Event Details', {
          appearance: 'success',
          autoDismiss: 500,
        });
      }
    } catch (error) {
      showLoadingSpinner(false);
      setSubmitting(false);
      addToast('Error While Saving Event', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  // ============= image upload helpers ========================
  const uploadImage = async file => {
    let imageUrl;
    if (ref.current.selectedImage === IMAGE_TYPE.LOGO) {
      imageUrl = await setEventLogo(file, eventId);
    }

    if (ref.current.selectedImage === IMAGE_TYPE.LOGO_VERTICAL) {
      imageUrl = await setEventVerticalLogo(file, eventId);
    }

    if (imageUrl) {
      if (ref.current.selectedImage === IMAGE_TYPE.LOGO) {
        setImages({
          ...images,
          logo: imageUrl,
        });
      }

      if (ref.current.selectedImage === IMAGE_TYPE.LOGO_VERTICAL) {
        setImages({
          ...images,
          logoVertical: imageUrl,
        });
      }

      setUploadingDialogOpen(false);
    }
  };

  const handleImageDialogSave = files => {
    const file = files[0];

    setImageUploadOpen(false);
    setUploadingDialogOpen(true);
    uploadImage(file);
  };

  const handleImageDialogClose = () => {
    setImageUploadOpen(false);
  };

  const handleImageEditClick = selectedImage => {
    ref.current.selectedImage = selectedImage;
    setImageUploadOpen(true);
  };

  // ================= status controller ============
  const handleStatusButtonClick = async () => {
    setUploadingDialogOpen(true);

    const resp = await setIsActive(eventId, !(is_Active === 'Y'));
    if (resp) {
      setUploadingDialogOpen(false);
      setIs_Active(is_Active === 'Y' ? 'N' : 'Y');
    }
  };

  return (
    <div className={classes.container}>
      {dataFetched && (
        <>
          <Typography variant="caption">
            OLD URL: https://sportvot.com/event?event_id=
            {encodeURIComponent(eventId)}
          </Typography>
          <StatusControllerAndId
            isActive={is_Active}
            onClick={handleStatusButtonClick}
            eventId={eventId}
          />
          <EventDetailsForm {...formFields} onSubmit={handleSubmit} />
          <AutoHighlightBlock eventId={eventId} />
          <div className={classes.imageBlock}>
            <ImageBlock
              buttonLabel="Edit"
              blockLabel="Event Image"
              onButtonPress={() => handleImageEditClick(IMAGE_TYPE.LOGO)}
              imgSrc={images.logo}
            />
          </div>
          <div className={classes.imageBlock}>
            <ImageBlock
              buttonLabel="Edit"
              blockLabel="Event Image Vertical ( 9:16 )"
              onButtonPress={() =>
                handleImageEditClick(IMAGE_TYPE.LOGO_VERTICAL)
              }
              imgSrc={images.logoVertical}
            />
          </div>
        </>
      )}

      <DropzoneDialog
        open={imageUploadOpen}
        onSave={handleImageDialogSave}
        acceptedFiles={['image/*']}
        showPreviews
        maxFileSize={5000000}
        filesLimit={1}
        onClose={handleImageDialogClose}
        showAlerts={false}
      />
      <UploadingDialog text="" open={uploadingDialogOpen} />
    </div>
  );
}
