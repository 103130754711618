import axiosInstance from '../../axiosInstance';
import { BASE_URL } from '../constants';

const setAutoHighlightsConfig = async (
  event_id = 'default',
  statusPostStream,
  statusLiveStream,
  windowSecondsBeforeScoringEvent,
  windowSecondsAfterScoringEvent,
) => {
  const response = await axiosInstance.patch(
    `${BASE_URL}/api/admin/v2/events/${event_id}/auto-highlights-config`,

    {
      is_auto_highlights_required: statusPostStream,
      is_auto_highlights_required_live_stream: statusLiveStream,
      window_seconds_before_scoring_event: windowSecondsBeforeScoringEvent,
      window_seconds_after_scoring_event: windowSecondsAfterScoringEvent,
    },
  );

  console.log(response.data.data);

  return response.data;
};

export default setAutoHighlightsConfig;
