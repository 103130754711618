import axiosInstance from '../../axiosInstance';

const getPlayerMatchStatsPaginated = async ({
  sportKey,
  playerId,
  sort = '_id:desc',
  page = 1,
  limit = 10,
}) => {
  const response = await axiosInstance.get(
    `/api/admin/v2/stats/player-match/${sportKey}/player/${playerId}`,
    {
      params: {
        sort,
        page,
        limit,
      },
    },
  );

  const { data } = response.data;

  return data;
};

export default getPlayerMatchStatsPaginated;
