import { BASE_URL } from '../constants';

const updateTournamentProfile = async (postBody, userId = '') => {
  try {
    const postData = {
      ...postBody,
    };

    const response = await fetch(`${BASE_URL}/api/v2/tournaments/${userId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });

    const json = await response.json();
    if (json.error) {
      throw new Error(JSON.stringify(json.error));
    }

    return json;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export default updateTournamentProfile;
