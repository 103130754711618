import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
    padding: 0,
    maxWidth: 500,
    flexDirection: 'column',
    marginTop: theme.spacing(4),
  },
}));
export default useStyles;
