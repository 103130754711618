import React from 'react';
import Paper from '@material-ui/core/Paper';
import { TextField, Button, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './styles';

const LoginBox = ({ mobile, onChange, onSubmit }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.card} elevation={3}>
      <div className={classes.inputsContainer}>
        <Typography component="div">
          <Box letterSpacing={6} m={1}>
            SportVot Admin
          </Box>
        </Typography>
        <TextField
          className={classes.inputBox}
          type="number"
          id="mobile"
          label="Mobile No"
          variant="outlined"
          value={mobile}
          onChange={e => onChange(e.target.value)}
        />

        <Button
          className={classes.loginButton}
          variant="outlined"
          color="primary"
          onClick={() => onSubmit()}
        >
          GET OTP
        </Button>
      </div>
    </Paper>
  );
};

LoginBox.propTypes = {
  mobile: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
};

export default LoginBox;
