import { useState, useEffect } from 'react';
import getSportConfigBySportKey from '../API/config/getSportConfigBySportKey';

export const useSportConfig = sportKey => {
  const [value, setValue] = useState(null);

  const fetchConfig = async key => {
    try {
      const configData = await getSportConfigBySportKey(key);
      setValue(configData);
    } catch (error) {
      console.log(`failed to get sport config for sportKey = ${sportKey}`);
    }
  };

  useEffect(() => {
    if (sportKey) {
      fetchConfig(sportKey);
    }
  }, [sportKey]);

  return value;
};

export default null;
