import axiosInstance from '../../axiosInstance';

const updateHeaderContentIsEnabled = async ({
  isHomeFeed = true,
  sport = '',
  is_enabled,
}) => {
  let endpoint;

  if (isHomeFeed) {
    endpoint = '/api/admin/v2/header-content/non-sport-specific/is-enabled';
  } else {
    endpoint = `/api/admin/v2/header-content/sport-specific/${sport}/is-enabled`;
  }

  const response = await axiosInstance.patch(endpoint, {
    is_enabled,
  });

  const { data } = response.data;

  return data.header_content;
};

export default updateHeaderContentIsEnabled;
