import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  rowButtons: {
    paddingLeft: theme.spacing(1),
    alignSelf: 'flex-end',
  },
}));

export default useStyles;
