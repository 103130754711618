import axiosInstance from '../../axiosInstance';
import { GameResultRequest } from './requestTypes';
import { GameResult } from './types';

export const createOverrideGameResult = async (
  matchId: string,
  gameResult: GameResultRequest,
): Promise<{ game_result: GameResult }> => {
  const resp = await axiosInstance.post(
    `/api/admin/v2/override-game-result/${matchId}`,
    gameResult,
  );

  return resp.data.data;
};

export const updateOverrideGameResult = async (
  matchId: string,
  gameResult: GameResultRequest,
): Promise<{ game_result: GameResult }> => {
  const resp = await axiosInstance.patch(
    `/api/admin/v2/override-game-result/${matchId}`,
    gameResult,
  );

  return resp.data.data;
};

export const getOverrideGameResult = async (
  matchId: string,
): Promise<{ game_result: GameResult }> => {
  const resp = await axiosInstance.get(
    `/api/admin/v2/override-game-result/${matchId}`,
  );
  return resp.data.data;
};

export const toggleOverrideGameResultIsApplied = async (
  matchId: string,
  isApplied: boolean,
): Promise<{ game_result: GameResult }> => {
  const resp = await axiosInstance.patch(
    `/api/admin/v2/override-game-result/${matchId}/is-applied`,
    { is_applied: isApplied },
  );

  return resp.data.data;
};
