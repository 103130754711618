import React, { useContext } from 'react';
import { Form } from 'formik';
import { Paper, Grid, Typography } from '@material-ui/core';
import useStyles from './styles';
import FormikTextInput from '../MuiFormikComponents/FormikTextInput';
import FormikSubmit from '../MuiFormikComponents/FormikSubmit';
import Context from '../../../Contexts';
import {
  generateStateDropDownList,
  generateSportDropDownList,
} from '../../../Utils/dropDownListGenerators';
import FormikDropDown from '../MuiFormikComponents/FormikDropDown';
import FormikSwitch from '../MuiFormikComponents/FormikSwitch';

const FormFields = ({ enableAddDummyPlayersOption }) => {
  const classes = useStyles();

  const context = useContext(Context);
  const { formConfig } = context;

  return (
    <Form>
      <Paper elevation={3} className={classes.formContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4">Team Details</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Team Name" name="name" required />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikDropDown
              valueDisplayNameMap={generateStateDropDownList(formConfig)}
              label="State *"
              name="state"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikDropDown
              valueDisplayNameMap={generateSportDropDownList(formConfig)}
              label="Sport *"
              name="sport"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="Association" name="association" />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormikTextInput label="District" name="district" />
          </Grid>

          {!!enableAddDummyPlayersOption && (
            <Grid item xs={12} sm={6} md={6}>
              <FormikSwitch label="Add Dummy Players" name="addDummyPlayers" />
            </Grid>
          )}

          <Grid item xs={12}>
            <FormikSubmit name="submitButon" submitText="save" />
          </Grid>
        </Grid>
      </Paper>
    </Form>
  );
};

export default FormFields;
