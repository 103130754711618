import axiosInstance from '../../axiosInstance';

const setArticleIsActive = async (article_id = 'default', status = true) => {
  try {
    const postData = {
      status,
    };

    const response = await axiosInstance(
      `/api/admin/v2/articles/${article_id}/is_active`,
      {
        method: 'patch',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(postData),
      },
    );

    const responseData = response.data;

    if (responseData.error) {
      throw new Error(responseData.error.errorMessage);
    }

    return responseData.data;
  } catch (e) {
    throw new Error(e.message);
  }
};

export default setArticleIsActive;
