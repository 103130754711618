import React from 'react';
import { Paper, Typography, Button } from '@material-ui/core';
import useStyles from './styles';

const DummyPlayerControls = ({ isDummyPlayer, onUpgradeClick }) => {
  const classes = useStyles();

  if (!isDummyPlayer) {
    return <div />;
  }

  return (
    <Paper elevation={3}>
      <div className={classes.statusControllerContainer}>
        <Typography variant="h6">this is a dummy player</Typography>

        <Button variant="outlined" color="primary" onClick={onUpgradeClick}>
          UPGRADE
        </Button>
      </div>
    </Paper>
  );
};

export default DummyPlayerControls;
