import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { VIDEOS_HEAD_CELLS, useStyles } from '../TableComponents/TableUtils';
import { EnhancedTableHead, EnhancedTableToolbar } from '../TableComponents';
import useManagedHomeFeedActions from '../../../customHooks/ManagedHomeFeeds/useManagedHomeFeedActions';
import useManagedHomeFeedStore from '../../../Store/useManagedHomeFeedStore';
import { getViewCountInDifferentFormat } from '../../../Utils/commonUtils';

type ArticlesTableProps = {
  data: any[];
  totalCount: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (e) => void;
};

export default function ArticlesTableFormHomeFeed(props: ArticlesTableProps) {
  const {
    data,
    totalCount = 0,
    page = 0,
    rowsPerPage = 0,
    onChangePage,
  } = props;
  const classes = useStyles();
  const [selected, setSelected] = React.useState<string[]>([]);
  const { handleAddEntityInSection } = useManagedHomeFeedActions();
  const { selectedSectionKey } = useManagedHomeFeedStore();

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedList = data.map(n => n._id);
      setSelected(newSelectedList);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleAdd={() =>
            handleAddEntityInSection({
              entityIds: selected,
              sectionKey: selectedSectionKey,
            })
          }
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={data.length}
              headCells={VIDEOS_HEAD_CELLS}
            />
            <TableBody>
              {data.map((row, index) => {
                const isItemSelected = isSelected(row._id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, row._id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row._id}
                    selected={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{
                          'aria-labelledby': labelId,
                        }}
                      />
                    </TableCell>

                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.title}
                    </TableCell>
                    <TableCell align="center">
                      {getViewCountInDifferentFormat(row.views)}
                    </TableCell>
                    <TableCell align="center">{`${row.category}`}</TableCell>
                    <TableCell align="right">{row.channel}</TableCell>
                    <TableCell align="right">
                      {row.created_on &&
                        new Date(row.created_on).toLocaleString()}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onChangePage={onChangePage}
          variant="body"
        />
      </Paper>
    </div>
  );
}
