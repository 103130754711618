import React from 'react';
import { Box, Typography } from '@material-ui/core';
import styles from './styles.module.css';
import { getImageUrl } from '../../../Utils/commonUtils';

function UserCard({ user }) {
  return (
    <Box display="flex" alignItems="flex-start" style={{ gap: 15 }}>
      <img
        src={getImageUrl(user.profileImage)}
        alt="user profile"
        className={styles.user_profile}
      />

      <Box>
        <Typography variant="subtitle1" className={styles.username}>
          {user.first_name} {user.last_name}
        </Typography>
        <Typography variant="subtitle2">{user.description}</Typography>
      </Box>
    </Box>
  );
}

export default UserCard;
