import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormFieldsFast from './FormFieldsFast';

const AutoClipsGenFormFastly = ({
  onSubmit = () => console.log('AutoClipsGenFormFastly submit clicked'),
  window_seconds_before_scoring_event = 16,
  window_seconds_after_scoring_event = 4,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          window_seconds_before_scoring_event,
          window_seconds_after_scoring_event,
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
        validationSchema={Yup.object({
          window_seconds_before_scoring_event: Yup.number()
            .min(4, 'min 4')
            .max(60, 'max 60')
            .required('Required'),
          window_seconds_after_scoring_event: Yup.number()
            .min(4, 'min 4')
            .max(60, 'max 60')
            .required('Required'),
        })}
      >
        <FormFieldsFast />
      </Formik>
    </>
  );
};

export default AutoClipsGenFormFastly;
