import React from 'react';
import { TextField, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import ClearIcon from '@material-ui/icons/ClearRounded';

import PropTypes from 'prop-types';
import useStyles from './styles';

const SearchBox = ({
  disableInput = false,
  type = 'text',
  label,
  value,
  onChangeValue,
  onSearch,
  onClear,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.searchContainer}>
      <TextField
        value={value}
        label={label}
        type={type}
        variant="outlined"
        onChange={e => !disableInput && onChangeValue(e.target.value)}
      />
      <IconButton
        edge="start"
        className={classes.searchIcon}
        color="default"
        aria-label="menu"
        onClick={onSearch}
      >
        <SearchIcon />
      </IconButton>
      <IconButton
        edge="start"
        className={classes.searchIcon}
        color="default"
        aria-label="menu"
        onClick={onClear}
      >
        <ClearIcon />
      </IconButton>
    </div>
  );
};

SearchBox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func.isRequired,
};

SearchBox.defaultProps = {
  value: '',
  label: 'default',
};

export default SearchBox;
