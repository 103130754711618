import axiosInstance from '../../axiosInstance';

const approveChannelVerification = async id => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/verifications/channel/${id}/approve`,
  );

  const { data } = response.data;

  return data.channel_verification;
};

export default approveChannelVerification;
