import React from 'react';
import Button from '@material-ui/core/Button';
import { useHistory, useRouteMatch } from 'react-router-dom';
import useStyles from './styles';

export default function SelectedPlayer() {
  const classes = useStyles();
  const history = useHistory();
  const { url } = useRouteMatch();

  const onMangeDetailsBTN = () => {
    history.push({
      pathname: `${url}/details`,
    });
  };

  const handleFeaturedAthleteClicked = () => {
    history.push({
      pathname: `${url}/featured`,
    });
  };

  const handleMatchLevelStatsClicked = () => {
    history.push({
      pathname: `${url}/match-stats`,
    });
  };

  const handleAggStatsClicked = () => {
    history.push({
      pathname: `${url}/agg-match-stats`,
    });
  };

  return (
    <div className={classes.container}>
      <Button
        className={classes.buttons}
        variant="contained"
        onClick={onMangeDetailsBTN}
      >
        Manage Details
      </Button>

      <Button
        className={classes.buttons}
        variant="contained"
        onClick={handleFeaturedAthleteClicked}
      >
        Featured Athlete
      </Button>

      <Button
        className={classes.buttons}
        variant="contained"
        onClick={handleMatchLevelStatsClicked}
      >
        Match Level Stats
      </Button>

      <Button
        className={classes.buttons}
        variant="contained"
        onClick={handleAggStatsClicked}
      >
        Aggregated Stats
      </Button>
    </div>
  );
}
