import React from 'react';
import { Box } from '@material-ui/core';
import { Formik } from 'formik';
import FormFields from './FormFields';

const UserTemplatesFilter = ({
  handleFilter = () => {},
  sport = 'football',
  scoring_mode = '',
  is_ready = true,
  creator_id = '',
  template_id = '',
  is_thumbnail_template = false,
}) => {
  return (
    <Box pt={3}>
      <Formik
        initialValues={{
          sport,
          scoring_mode,
          is_ready,
          creator_id,
          template_id,
          is_thumbnail_template,
        }}
        onSubmit={(values1, { setSubmitting }) => {
          handleFilter(values1, setSubmitting);
        }}
      >
        <FormFields />
      </Formik>
    </Box>
  );
};

export default UserTemplatesFilter;
