import React, { useContext } from 'react';
import {
  Paper,
  TableRow,
  TableHead,
  TableFooter,
  TablePagination,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
} from '@material-ui/core';
import Contexts from '../../../Contexts';
import useStyles from './styles';
import {
  generateSportPositionDisplayName,
  generateSportDisplayName,
  generateStateDisplayName,
} from '../../../Utils/genrateDisplayNames';

export default function AddAthleteTable({
  data = [],
  onRowActionClicked = () => console.log('event row clicked'),
  onChangePage = () => console.log('page change clicked'),
  count = 10,
  rowsPerPage = 12,
  page = 1,
  inSquad,
  noOfPlayer = 2,
}) {
  const classes = useStyles();
  const context = useContext(Contexts);
  const { formConfig } = context;

  const generateAlphabets = () => {
    const alphabets = [];
    const start = 'A'.charCodeAt(0);
    const last = 'Z'.charCodeAt(0);
    for (let i = start; i <= last; i += 1) {
      alphabets.push(String.fromCharCode(i));
    }

    return alphabets;
  };
  const alphabetsData = generateAlphabets();
  const newGroup = alphabetsData.splice(0, noOfPlayer);

  return (
    <div className={classes.tableContainer}>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Sr no</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell align="left">State</TableCell>
              <TableCell align="left">Sport</TableCell>
              {newGroup.map(el => (
                <TableCell align="center">{`Team ${el}`}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data &&
              data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>

                  <TableCell component="th" scope="row">
                    {`${row.first_name} ${row.last_name}`}
                  </TableCell>

                  <TableCell align="left">
                    {generateStateDisplayName(formConfig, row.state)}
                  </TableCell>

                  <TableCell align="left">
                    {generateSportDisplayName(formConfig, row.sport)}
                  </TableCell>

                  {newGroup.map((el, i) => (
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor:
                            inSquad.length > 0 &&
                            inSquad.includes(row._id) &&
                            i === inSquad.indexOf(row._id)
                              ? '#00796b'
                              : '#0288d1',
                          color: 'white',
                        }}
                        onClick={() => onRowActionClicked(row, el)}
                      >
                        {inSquad.length > 0 &&
                        inSquad.includes(row._id) &&
                        i === inSquad.indexOf(row._id)
                          ? 'ADDED'
                          : 'ADD'}
                      </Button>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rows
                colSpan={12}
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onChangePage={onChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
}
