import { useEffect, useState } from 'react';
import { getManagedHomeFeedByCategoryCode } from '../../API/ManagedHomeFeed';
import useManagedHomeFeedStore from '../../Store/useManagedHomeFeedStore';
import { FeedType } from '../../API/ManagedHomeFeed/types';

const useManagedHomeFeed = (
  categoryCode: string,
  feedType: FeedType = 'HOME_FEED',
) => {
  const [error, setError] = useState<any>(null);
  const {
    setManagedHomeFeed,
    managedHomeFeed,
    isLoading,
    setIsLoading,
    setSelectedCategory,
  } = useManagedHomeFeedStore();

  useEffect(() => {
    const handleFetch = async () => {
      setIsLoading(true);
      try {
        const { managed_home_feed } = await getManagedHomeFeedByCategoryCode(
          categoryCode,
          feedType,
        );
        setManagedHomeFeed(managed_home_feed);
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    handleFetch();
  }, [categoryCode, feedType]);

  return {
    managedHomeFeed,
    isLoading,
    error,
  };
};

export default useManagedHomeFeed;
