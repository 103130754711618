import React, { useContext } from 'react';
import { useToasts } from 'react-toast-notifications';
import { useHistory } from 'react-router-dom';
import useStyles from './styles';
import EventDetailsForm from '../../../../Components/Forms/EventDetailsForm/EventDetailsForm';
import createEvent from '../../../../API/events/createEvent';
import Contexts from '../../../../Contexts';

export default function AddTeam() {
  const classes = useStyles();
  const { addToast } = useToasts();
  const history = useHistory();
  const context = useContext(Contexts);
  const { showLoadingSpinner } = context;

  const handleSubmit = async (values, setSubmitting) => {
    showLoadingSpinner(true);
    setSubmitting(true);
    try {
      const {
        name,
        sport,
        state,
        start_date,
        end_date,
        location,
        description,
        district,
        organised_by,
        is_sport,
        preferred_section,
        event_league_config,
      } = values;

      const res = await createEvent(
        name,
        sport,
        state,
        start_date,
        end_date,
        location,
        district,
        description,
        organised_by,
        is_sport,
        preferred_section,
        event_league_config,
      );

      if (res.data.event_id) {
        setSubmitting(false);
        showLoadingSpinner(false);

        addToast('Successfully Created Event', {
          appearance: 'success',
          autoDismiss: 500,
        });
        history.goBack();
      }
    } catch (error) {
      setSubmitting(false);
      showLoadingSpinner(false);

      addToast('Error While Creating Event', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  return (
    <div className={classes.container}>
      <EventDetailsForm onSubmit={handleSubmit} />
    </div>
  );
}
