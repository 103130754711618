import axiosInstance from '../../axiosInstance';

const cloneStream = async streamId => {
  const response = await axiosInstance.post(
    `/api/admin/v2/streams/${streamId}/clone`,
  );

  const { data } = response.data;

  return data.stream_id;
};

export default cloneStream;
