import axiosInstance from '../../axiosInstance';

const updateDummyPlayerData = async ({
  teamId,
  playerId,
  firstName,
  lastName,
  position,
  jerseyNum,
}) => {
  const response = await axiosInstance.patch(
    `/api/admin/v2/teams/${teamId}/player/${playerId}/dummy-data`,
    {
      first_name: firstName,
      last_name: lastName,
      position,
      jersey_num: jerseyNum,
    },
  );

  const { data } = response.data;

  return data;
};

export default updateDummyPlayerData;
