export const IS_TEST = false;

const BASE_URL_TEST = 'https://interapis.test.sportvot.com';
const BASE_URL_PROD = 'https://admin.interapis.sportvot.com';
export const BASE_URL = IS_TEST ? BASE_URL_TEST : BASE_URL_PROD;
// export const BASE_URL = 'http://localhost:2341';

export const S3_BUCKET_BASE_URL = 'https://fhp-news-bucket.s3.amazonaws.com';

export const FORM_CONFIG_BASE_URL = '';

// ----------------- token check -----------------------

// the loop interval(in seconds) that checks how close the access token
// is to being expired
export const TOKEN_CHECK_INTERVAL = 15;

// if the access token expiry is <= these many seconds away,
// a new access token is retrieved
export const TOKEN_EXPIRY_PROXIMITY_LIMIT = 900;

export const AUTO_CLIPS_BASE_URL = 'https://clips.auto.sportvot.com';

export const CLOUDFRONT_BASE_URL = 'https://d25s2jqw4qdf1e.cloudfront.net';
export const DEFAULT_PROFILE_IMAGE_CHANNELS = `${S3_BUCKET_BASE_URL}/a73b3c42-6991-475f-8d6a-ce016d8e61f6.png`;
export const DEFAULT_THUMBNAIL = `${CLOUDFRONT_BASE_URL}/2796a499-23dd-46c6-bcf1-63385a9053be.png`;

export const THEME_PREFERENCE = '@SPORTVOT_ADMIN_DASH_THEME_PREFERENCE';

const OTT_LINK_TEST = 'https://ott-test.sportvot.com';
const OTT_LINK_PROD = 'https://sportvot.com';
export const OTT_LINK = IS_TEST ? OTT_LINK_TEST : OTT_LINK_PROD;
