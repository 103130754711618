import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import useStyles from './styles';
import UserPermissionForm from '../../../../Components/Forms/UserPermissionForm';
import getUserAuthorities from '../../../../API/permission/getUserAuthorities';
import setUserPermissions from '../../../../API/permission/setUserPermissions';
import { authoritiesList } from '../../../../Utils/authorities';

export default function UserPermission() {
  const classes = useStyles();
  const { user_id } = useParams();
  const [loading, setLoading] = useState(true);
  const { addToast } = useToasts();

  const ref = useRef({ formFields: {} });

  const getUserAuthoritiesData = async () => {
    try {
      const userAuthorities = await getUserAuthorities(user_id);

      const temp = {};

      // set intial form fields
      if (userAuthorities.length > 0) {
        authoritiesList.forEach(authority => {
          if (userAuthorities.includes(authority)) temp[authority] = true;
        });
      }

      ref.current.formFields = temp;
      setLoading(false);
    } catch (err) {
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }
  };

  useEffect(() => {
    getUserAuthoritiesData();
  }, []);

  const onSubmit = async (values, setSubmitting) => {
    try {
      const newAuthorities = [];

      authoritiesList.forEach(authority => {
        if (values[authority]) {
          newAuthorities.push(authority);
        }
      });

      const data = await setUserPermissions(user_id, newAuthorities);
      if (data) {
        addToast('Permissions Updated', {
          appearance: 'success',
          autoDismiss: 500,
        });
      }
    } catch (error) {
      console.log(error);
      addToast('Error', {
        appearance: 'error',
        autoDismiss: 500,
      });
    }

    setSubmitting(false);
  };

  return (
    <div className={classes.root}>
      <div className={classes.searchContainer}>
        {!loading && (
          <UserPermissionForm {...ref.current.formFields} onSubmit={onSubmit} />
        )}
      </div>
    </div>
  );
}
