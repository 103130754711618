import React from 'react';
import { Formik } from 'formik';
import FormFields from './FormFields';

const StreamsSearchFormForPixHighlightsVas = ({
  stream_key = '',
  title = '',
  channel_id = '',
  category = '',
  onSubmit = () => console.log('StreamSearchForm search form clicked'),
  _id = '',
  start_date = '',
  end_date = '',
  highlight_mixer_vas_status = null,
  include_demo_and_trial_streams = true,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          stream_key,
          title,
          channel_id,
          category,
          _id,

          highlight_mixer_vas_status,
          include_demo_and_trial_streams,
          start_date,
          end_date,
        }}
        onSubmit={(values, { setSubmitting }) => {
          onSubmit(values, setSubmitting);
        }}
      >
        <FormFields />
      </Formik>
    </>
  );
};

export default StreamsSearchFormForPixHighlightsVas;
