import axiosInstance from '../../axiosInstance';

const queueAutoClipGenForMatchFastly = async ({
  matchId,
  windowSecondsBeforeScoringEvent,
  windowSecondsAfterScoringEvent,
}) => {
  try {
    const response = await axiosInstance.put(
      `/api/admin/v2/auto-highlights/${matchId}`,

      {
        window_seconds_before_scoring_event: windowSecondsBeforeScoringEvent,
        window_seconds_after_scoring_event: windowSecondsAfterScoringEvent,
      },
    );

    const {
      is_processing_needed,
      is_queued,
      window_seconds_after_scoring_event,
      window_seconds_before_scoring_event,
      times_un_successfully_processed,
      times_successfully_processed,
    } = response.data.data.data;

    return {
      is_processing_needed,
      is_queued,
      window_seconds_after_scoring_event,
      window_seconds_before_scoring_event,
      times_un_successfully_processed,
      times_successfully_processed,
    };
  } catch (error) {
    return {
      is_processing_needed: false,
      is_queued: false,
      window_seconds_after_scoring_event: 4,
      window_seconds_before_scoring_event: 16,
      times_un_successfully_processed: 0,
      times_successfully_processed: 0,
    };
  }
};

export default queueAutoClipGenForMatchFastly;
