import React from 'react';

import { Switch, Route, useRouteMatch } from 'react-router-dom';
import TournamentSearch from './TournamentSearch/TournamentSearch';
import SelectedTournament from './SelectedTournament/SelectedTournament';
import TournamentDetails from './TournamentDetails/TournamentDetails';
import AddTournament from './AddTournament/AddTournament';
import Teams from './Teams/Teams';
import AddTeams from './AddTeam/AddTeams';
import TournamentTeamsPlayer from './TournamentTeamsPlayer/TournamentTeamsPlayer';
import TournamentRules from './TournamentRules/TournamentRules';
import LeagueDetails from './RulesForm/LeagueDetails';
import KnockoutDetails from './RulesForm/KnockoutDetails';
import LeagueKnockoutDetails from './RulesForm/LeagueKnockoutDetails';
import FixtureLKOG from './Fixtures/FixtureLKOG';
import GroupStageFixture from './GroupStage/GroupStageFixture';
import IndividualPlayer from './IndividualPlayer/IndividualPlayer';

export default function TournamentManagement() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <TournamentSearch />
      </Route>
      <Route exact path={`${path}/add`}>
        <AddTournament />
      </Route>
      <Route exact path={`${path}/:teamId`}>
        <SelectedTournament />
      </Route>
      <Route exact path={`${path}/:teamId/details`}>
        <TournamentDetails />
      </Route>
      <Route exact path={`${path}/:teamId/individual`}>
        <IndividualPlayer />
      </Route>
      <Route exact path={`${path}/:teamId/teams`}>
        <Teams />
      </Route>
      <Route exact path={`${path}/:teamId/teams/add`}>
        <AddTeams />
      </Route>
      <Route exact path={`${path}/:tournamentId/teams/:teamId`}>
        <TournamentTeamsPlayer />
      </Route>
      <Route exact path={`${path}/:teamId/:type/rule`}>
        <TournamentRules />
      </Route>
      <Route exact path={`${path}/:teamId/:type/rule/Lform`}>
        <LeagueDetails />
      </Route>
      <Route exact path={`${path}/:teamId/:type/rule/KOform`}>
        <KnockoutDetails />
      </Route>
      <Route exact path={`${path}/:teamId/:type/rule/LKOGform`}>
        <LeagueKnockoutDetails />
      </Route>
      <Route exact path={`${path}/:teamId/:type/fixtures`}>
        <FixtureLKOG />
      </Route>
      <Route exact path={`${path}/:teamId/:type/fixtures/group_stage`}>
        <GroupStageFixture />
      </Route>
    </Switch>
  );
}
